export const bscPools = [
  {
    id: 'babygrok-wbnb',
    index: 0,
    name: 'BABYGROK-BNB LP',
    token: 'BABYGROK-BNB LP',
    logo: 'bnb-pairs/BABYGROK-BNB.png',
    tokenDescription: 'PancakeSwap (BabyGrok)',
    tokenAddress: '0xDA574013e8E67E822D0547D9Cd9e8E1075827E3A',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'BABYGROK-BNB',
    earnedTokenAddress: '0x7076855E3f986c1f31525EE22818c2cEE5e91AFA',
    earnContractAddress: '0x708033e77e4c7015F7552cBD776744aB96Cd20F5',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'babygrok-wbnb',
    oraclePrice: 1,
    depositsPaused: false,
    status: 'active',
    platform: 'babygrok.ai',
    assets: ['BABYGROK', 'BNB'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MICRO',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    addLiquidityUrl:
      'https://pancakeswap.finance/v2/add/BNB/0x88da9901b3a02fe24e498e1ed683d2310383e295',
    buyTokenUrl:
      'https://pancakeswap.finance/swap?outputCurrency=0x88da9901b3a02fe24e498e1ed683d2310383e295',
    createdAt: 1720958400,
  },
  {
    id: 'babygrok-usdt',
    index: 1,
    name: 'BABYGROK-USDT LP',
    token: 'BABYGROK-USDT LP',
    logo: 'bnb-pairs/BABYGROK-USDT.png',
    tokenDescription: 'PancakeSwap (BABYGROK)',
    tokenAddress: '0x9bf805b0f2288d8615193b448319ba7721729a6e',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'BABYGROK-USDT',
    earnedTokenAddress: '0x2977684189Ff6DdB17de83CDF1ACf02282958460',
    earnContractAddress: '0x708033e77e4c7015F7552cBD776744aB96Cd20F5',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'babygrok-usdt',
    oraclePrice: 1,
    depositsPaused: false,
    status: 'active',
    platform: 'babygrok.ai',
    assets: ['BABYGROK', 'USDT'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_LOW',
      'MCAP_MICRO',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    addLiquidityUrl:
      'https://pancakeswap.finance/v2/add/0x88DA9901B3A02fE24E498e1eD683D2310383E295/0x55d398326f99059fF775485246999027B3197955',
    buyTokenUrl:
      'https://pancakeswap.finance/swap?inputCurrency=0x88DA9901B3A02fE24E498e1eD683D2310383E295&outputCurrency=0x55d398326f99059fF775485246999027B3197955',
    createdAt: 1720958400,
  },
  {
    id: 'grokcat-wbnb',
    index: 2,
    name: 'GROKCAT-BNB LP',
    token: 'GROKCAT-BNB LP',
    logo: 'bnb-pairs/GROKCAT-BNB.png',
    tokenDescription: 'PancakeSwap (GROKCAT)',
    tokenAddress: '0x608aF8006c8b027855d46F6a851141df1948C96E',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'GROKCAT-BNB',
    earnedTokenAddress: '0x74306b0Fe16D583dF777B1F9D95B27759A32aBc3',
    earnContractAddress: '0x708033e77e4c7015F7552cBD776744aB96Cd20F5',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'grokcat-wbnb',
    oraclePrice: 1,
    depositsPaused: false,
    status: 'active',
    platform: 'babygrok.ai',
    assets: ['GROKCAT', 'BNB'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MICRO',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    addLiquidityUrl:
      'https://pancakeswap.finance/v2/add/BNB/0x4615C8E2db74517a34712C9BdEA5C418D999014B',
    buyTokenUrl:
      'https://pancakeswap.finance/swap?outputCurrency=0x4615c8e2db74517a34712c9bdea5c418d999014b',
    createdAt: 1720958400,
  },
  {
    id: 'grokcat-usdt',
    index: 3,
    name: 'GROKCAT-USDT LP',
    token: 'GROKCAT-USDT LP',
    logo: 'bnb-pairs/GROKCAT-USDT.png',
    tokenDescription: 'PancakeSwap (GROKCAT)',
    tokenAddress: '0x2e973Cd595dEeeFE70BA2BA4Cd9D4BD2f5BFa6fF',
    tokenDecimals: 18,
    tokenDescriptionUrl: '#',
    earnedToken: 'GROKCAT-USDT',
    earnedTokenAddress: '0x430b1f3F46e205E00120b8B3ED2a3D7Ae793CC17',
    earnContractAddress: '0x708033e77e4c7015F7552cBD776744aB96Cd20F5',
    pricePerFullShare: 1,
    tvl: 0,
    oracle: 'lps',
    oracleId: 'grokcat-usdt',
    oraclePrice: 0,
    depositsPaused: false,
    status: 'active',
    platform: 'babygrok.ai',
    assets: ['GROKCAT', 'USDT'],
    risks: [
      'COMPLEXITY_LOW',
      'BATTLE_TESTED',
      'IL_HIGH',
      'MCAP_MICRO',
      'AUDIT',
      'CONTRACTS_VERIFIED',
    ],
    stratType: 'StratLP',
    addLiquidityUrl:
      'https://pancakeswap.finance/v2/add/0x4615C8E2db74517a34712C9BdEA5C418D999014B/0x55d398326f99059fF775485246999027B3197955',
    buyTokenUrl:
      'https://pancakeswap.finance/swap?inputCurrency=0x4615C8E2db74517a34712C9BdEA5C418D999014B&outputCurrency=0x55d398326f99059fF775485246999027B3197955',
    createdAt: 1720958400,
  },
  // {
  //   id: 'bomb-bshare-wbnb',
  //   name: 'BSHARE-BNB LP',
  //   token: 'BSHARE-BNB LP',
  //   logo: 'bnb-pairs/BSHARE-BNB.png',
  //   tokenDescription: 'PancakeSwap (Bomb)',
  //   tokenAddress: '0x1303246855b5B5EbC71F049Fdb607494e97218f8',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooBombBSHARE-BNB',
  //   earnedTokenAddress: '0xd86D7D2D335954095E5ccc0d823B8ABc65Ac35B2',
  //   earnContractAddress: '0xd86D7D2D335954095E5ccc0d823B8ABc65Ac35B2',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'bomb-bshare-wbnb',
  //   oraclePrice: 0,
  //   depositsPaused: false,
  //   status: 'active',
  //   platform: 'Bomb.Money',
  //   assets: ['BSHARE', 'BNB'],
  //   risks: [
  //     'COMPLEXITY_LOW',
  //     'BATTLE_TESTED',
  //     'IL_HIGH',
  //     'MCAP_MICRO',
  //     'AUDIT',
  //     'CONTRACTS_VERIFIED',
  //   ],
  //   stratType: 'StratLP',
  //   addLiquidityUrl:
  //     'https://pancakeswap.finance/add/BNB/0x531780FAcE85306877D7e1F05d713D1B50a37F7A',
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?outputCurrency=0x531780FAcE85306877D7e1F05d713D1B50a37F7A',
  //   createdAt: 1651236072,
  // },

  // {
  //   id: 'bomb-bomb-btcb',
  //   name: 'BOMB-BTCB LP',
  //   token: 'BOMB-BTCB LP',
  //   tokenDescription: 'PancakeSwap (Bomb)',
  //   tokenAddress: '0x84392649eb0bC1c1532F2180E58Bae4E1dAbd8D6',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooBombBOMB-BTCB',
  //   earnedTokenAddress: '0xD2FeCe7Ff1B791F8fE7f35424165abB8BD1671f2',
  //   earnContractAddress: '0xD2FeCe7Ff1B791F8fE7f35424165abB8BD1671f2',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'bomb-bomb-btcb',
  //   oraclePrice: 0,
  //   depositsPaused: false,
  //   status: 'active',
  //   platform: 'Bomb.Money',
  //   assets: ['BOMB', 'BTCB'],
  //   risks: [
  //     'COMPLEXITY_LOW',
  //     'BATTLE_TESTED',
  //     'IL_LOW',
  //     'MCAP_MICRO',
  //     'AUDIT',
  //     'CONTRACTS_VERIFIED',
  //   ],
  //   stratType: 'StratLP',
  //   addLiquidityUrl:
  //     'https://pancakeswap.finance/add/0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c/0x522348779DCb2911539e76A1042aA922F9C47Ee3',
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?inputCurrency=0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c&outputCurrency=0x522348779DCb2911539e76A1042aA922F9C47Ee3',
  //   createdAt: 1651233272,
  // },
  // {
  //   id: 'bifi-maxi',
  //   logo: 'single-assets/BIFI.png',
  //   name: 'BIFI Maxi',
  //   token: 'BIFI',
  //   tokenDescription: 'Beefy',
  //   tokenAddress: '0xCa3F508B8e4Dd382eE878A314789373D80A5190A',
  //   tokenDecimals: 18,
  //   earnedToken: 'mooBIFIV2',
  //   earnedTokenAddress: '0x7828ff4ABA7aAb932D8407C78324B069D24284c9',
  //   earnContractAddress: '0x7828ff4ABA7aAb932D8407C78324B069D24284c9',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'tokens',
  //   oracleId: 'BIFI',
  //   oraclePrice: 0,
  //   depositsPaused: false,
  //   status: 'active',
  //   platform: 'Beefy',
  //   assets: ['BIFI'],
  //   risks: [
  //     'COMPLEXITY_LOW',
  //     'BATTLE_TESTED',
  //     'IL_NONE',
  //     'MCAP_MEDIUM',
  //     'AUDIT',
  //     'CONTRACTS_VERIFIED',
  //   ],
  //   stratType: 'SingleStake',
  //   withdrawalFee: '0%',
  //   buyTokenUrl: 'https://app.1inch.io/#/56/swap/BNB/BIFI',
  //   createdAt: 1648477070,
  // },
  // {
  //   id: 'baby-bnb-usdt',
  //   name: 'BNB-USDT LP',
  //   token: 'BNB-USDT LP',
  //   tokenDescription: 'BabySwap',
  //   tokenAddress: '0x04580ce6dEE076354e96fED53cb839DE9eFb5f3f',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooBabyBNB-USDT',
  //   earnedTokenAddress: '0xFC744521a921fEc005Fe5e91cca09878086E8526',
  //   earnContractAddress: '0xFC744521a921fEc005Fe5e91cca09878086E8526',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'baby-bnb-usdt',
  //   oraclePrice: 0,
  //   depositsPaused: false,
  //   status: 'active',
  //   platform: 'Other',
  //   assets: ['BNB', 'USDT'],
  //   risks: [
  //     'COMPLEXITY_LOW',
  //     'BATTLE_TESTED',
  //     'IL_LOW',
  //     'MCAP_LARGE',
  //     'AUDIT',
  //     'CONTRACTS_VERIFIED',
  //   ],
  //   stratType: 'StratLP',
  //   addLiquidityUrl:
  //     'https://exchange.babyswap.finance/#/add/0x55d398326f99059fF775485246999027B3197955/BNB',
  //   buyTokenUrl:
  //     'https://exchange.babyswap.finance/#/swap?outputcurrency=0x55d398326f99059fF775485246999027B3197955&inputCurrency=BNB',
  //   createdAt: 1653450803,
  // },
  // {
  //   id: 'ellipsis-usdd2pool',
  //   name: 'USDD/BUSD',
  //   token: 'usdd/2pool',
  //   tokenDescription: 'Ellipsis (DotDot)',
  //   tokenAddress: '0xB343F4cDE5e2049857898E800CD385247e21836D',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooEllipsisUSDD2Pool',
  //   earnedTokenAddress: '0xEbBD4e6aC71AA562883051708eece0d03C668a5F',
  //   earnContractAddress: '0xEbBD4e6aC71AA562883051708eece0d03C668a5F',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'ellipsis-usdd2pool',
  //   oraclePrice: 0,
  //   depositsPaused: false,
  //   status: 'active',
  //   platform: 'Ellipsis',
  //   assets: ['USDD', 'BUSD'],
  //   withdrawalFee: '0%',
  //   risks: [
  //     'COMPLEXITY_LOW',
  //     'NEW_STRAT',
  //     'IL_NONE',
  //     'MCAP_LARGE',
  //     'AUDIT',
  //     'CONTRACTS_VERIFIED',
  //     'ALGO_STABLE',
  //   ],
  //   stratType: 'StratMultiLP',
  //   addLiquidityUrl: 'https://ellipsis.finance/pool/0x408A61e158D7BC0CD339BC76917b8Ea04739d473',
  //   buyTokenUrl: 'https://ellipsis.finance/pool/0x408A61e158D7BC0CD339BC76917b8Ea04739d473',
  //   createdAt: 1653037944,
  // },

  // {
  //   id: 'banana-usdt-busd',
  //   name: 'USDT-BUSD LP',
  //   token: 'USDT-BUSD ALP',
  //   tokenDescription: 'ApeSwap',
  //   tokenAddress: '0x2e707261d086687470B515B320478Eb1C88D49bb',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooApeUSDT-BUSD',
  //   earnedTokenAddress: '0x69C2AD8572c475bD4B703c5826eAe878c0ace869',
  //   earnContractAddress: '0x69C2AD8572c475bD4B703c5826eAe878c0ace869',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'banana-usdt-busd',
  //   oraclePrice: 0,
  //   depositsPaused: false,
  //   status: 'active',
  //   platform: 'ApeSwap',
  //   assets: ['USDT', 'BUSD'],
  //   risks: [
  //     'COMPLEXITY_LOW',
  //     'BATTLE_TESTED',
  //     'IL_NONE',
  //     'MCAP_LARGE',
  //     'AUDIT',
  //     'CONTRACTS_VERIFIED',
  //   ],
  //   stratType: 'StratLP',
  //   addLiquidityUrl:
  //     'https://apeswap.finance/add/0x55d398326f99059fF775485246999027B3197955/0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
  //   buyTokenUrl:
  //     'https://apeswap.finance/swap?outputCurrency=0x55d398326f99059fF775485246999027B3197955',
  //   createdAt: 1652795668,
  // },

  // {
  //   id: 'ellipsis-usdd',
  //   name: 'USDD/BUSD/USDC/USDT',
  //   token: 'usdd/3eps',
  //   tokenDescription: 'Ellipsis (DotDot)',
  //   tokenAddress: '0xB7d9b83c7Dc3C7Fab2d0159f8b3fA7C4fB081741',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooEllipsisUSDD',
  //   earnedTokenAddress: '0x897380e900685E6b6619E2A1e519F9658F3e12C6',
  //   earnContractAddress: '0x897380e900685E6b6619E2A1e519F9658F3e12C6',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'ellipsis-usdd',
  //   oraclePrice: 0,
  //   depositsPaused: false,
  //   status: 'active',
  //   platform: 'Ellipsis',
  //   assets: ['USDD', 'BUSD', 'USDT', 'USDC'],
  //   withdrawalFee: '0%',
  //   risks: [
  //     'COMPLEXITY_LOW',
  //     'NEW_STRAT',
  //     'IL_NONE',
  //     'MCAP_LARGE',
  //     'AUDIT',
  //     'CONTRACTS_VERIFIED',
  //     'ALGO_STABLE',
  //   ],
  //   stratType: 'StratMultiLP',
  //   addLiquidityUrl: 'https://ellipsis.finance/pool/0xC2cF01F785C587645440ccD488B188945C9505e7',
  //   buyTokenUrl: 'https://ellipsis.finance/pool/0xC2cF01F785C587645440ccD488B188945C9505e7',
  //   createdAt: 1652702923,
  // },
  // {
  //   id: 'biswap-usdt-btcb',
  //   name: 'BTCB-USDT LP',
  //   token: 'BTCB-USDT BiLP',
  //   tokenDescription: 'BiSwap',
  //   tokenAddress: '0xa987f0b7098585c735cD943ee07544a84e923d1D',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooBiSwapUSDT-BTCB',
  //   earnedTokenAddress: '0xa4113209bD56f534A24CADc3E3778B5D4B806180',
  //   earnContractAddress: '0xa4113209bD56f534A24CADc3E3778B5D4B806180',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'biswap-usdt-btcb',
  //   oraclePrice: 0,
  //   depositsPaused: false,
  //   status: 'active',
  //   platform: 'BiSwap',
  //   assets: ['BTCB', 'USDT'],
  //   risks: [
  //     'COMPLEXITY_LOW',
  //     'BATTLE_TESTED',
  //     'IL_LOW',
  //     'MCAP_LARGE',
  //     'AUDIT',
  //     'CONTRACTS_VERIFIED',
  //   ],
  //   stratType: 'StratLP',
  //   addLiquidityUrl:
  //     'https://exchange.biswap.org/#/add/0x55d398326f99059fF775485246999027B3197955/0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c',
  //   buyTokenUrl:
  //     'https://exchange.biswap.org/#/swap?inputCurrency=0x55d398326f99059fF775485246999027B3197955&outputCurrency=0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c',
  //   createdAt: 1651747222,
  // },
  // {
  //   id: 'cakev2-gal-bnb',
  //   name: 'GAL-BNB LP',
  //   token: 'GAL-BNB LP',
  //   tokenDescription: 'PancakeSwap',
  //   tokenAddress: '0xbe6A4f74fdDc88853612C50D7404E059b37692D8',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooCakeV2GAL-BNB',
  //   earnedTokenAddress: '0xf48f9f754cB2A0F71A2b9A6E9e465E55B43245ab',
  //   earnContractAddress: '0xf48f9f754cB2A0F71A2b9A6E9e465E55B43245ab',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'cakev2-gal-bnb',
  //   oraclePrice: 0,
  //   depositsPaused: false,
  //   status: 'active',
  //   platform: 'PancakeSwap',
  //   assets: ['GAL', 'BNB'],
  //   risks: [
  //     'COMPLEXITY_LOW',
  //     'BATTLE_TESTED',
  //     'IL_HIGH',
  //     'MCAP_MICRO',
  //     'AUDIT',
  //     'CONTRACTS_VERIFIED',
  //   ],
  //   stratType: 'StratLP',
  //   addLiquidityUrl:
  //     'https://pancakeswap.finance/add/BNB/0xe4Cc45Bb5DBDA06dB6183E8bf016569f40497Aa5',
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?outputCurrency=0xe4Cc45Bb5DBDA06dB6183E8bf016569f40497Aa5',
  //   createdAt: 1651839069,
  // },
  // {
  //   id: 'ripae-pbnb-bnb',
  //   name: 'pBNB-BNB LP',
  //   token: 'pBNB-BNB LP',
  //   tokenDescription: 'BiSwap (Ripae)',
  //   tokenAddress: '0xC7DC9343C90Be0Ea2af6776EFe5e19B2734F8D0d',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooRipaepBNB-BNB',
  //   earnedTokenAddress: '0x8C6069624e746513Ac1B1d1434aABbEDA152f181',
  //   earnContractAddress: '0x8C6069624e746513Ac1B1d1434aABbEDA152f181',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'ripae-pbnb-bnb',
  //   oraclePrice: 0,
  //   depositsPaused: false,
  //   status: 'active',
  //   platform: 'Ripae',
  //   assets: ['pBNB', 'BNB'],
  //   risks: [
  //     'COMPLEXITY_LOW',
  //     'BATTLE_TESTED',
  //     'IL_LOW',
  //     'MCAP_MICRO',
  //     'AUDIT',
  //     'CONTRACTS_VERIFIED',
  //   ],
  //   stratType: 'StratLP',
  //   addLiquidityUrl:
  //     'https://exchange.biswap.org/#/add/ETH/0xA2315cC5A1e4aE3D0a491ED4Fe45EBF8356fEaC7',
  //   buyTokenUrl:
  //     'https://exchange.biswap.org/#/swap?outputCurrency=0xA2315cC5A1e4aE3D0a491ED4Fe45EBF8356fEaC7',
  //   createdAt: 1651849086,
  // },

  // {
  //   id: 'ripae-pae-bnb',
  //   name: 'PAE-BNB LP',
  //   token: 'PAE-BNB LP',
  //   tokenDescription: 'BiSwap (Ripae)',
  //   tokenAddress: '0x8eA4875469e8Fd7ad3790b4c7DEeF768ca1e806f',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooRipaePAE-BNB',
  //   earnedTokenAddress: '0x1592550E083286C35282E53732FDE11800A187E1',
  //   earnContractAddress: '0x1592550E083286C35282E53732FDE11800A187E1',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'ripae-pae-bnb',
  //   oraclePrice: 0,
  //   depositsPaused: false,
  //   status: 'active',
  //   platform: 'Ripae',
  //   assets: ['PAE', 'BNB'],
  //   risks: [
  //     'COMPLEXITY_LOW',
  //     'BATTLE_TESTED',
  //     'IL_HIGH',
  //     'MCAP_MICRO',
  //     'AUDIT',
  //     'CONTRACTS_VERIFIED',
  //   ],
  //   stratType: 'StratLP',
  //   addLiquidityUrl:
  //     'https://exchange.biswap.org/#/add/ETH/0x6c7fc3Fd4a9f1Cfa2a69B83F92b9DA7EC26240A2',
  //   buyTokenUrl:
  //     'https://exchange.biswap.org/#/swap?outputCurrency=0x6c7fc3Fd4a9f1Cfa2a69B83F92b9DA7EC26240A2',
  //   createdAt: 1651849085,
  // },

  // {
  //   id: 'biswap-btcb-wbnb',
  //   name: 'BTCB-BNB LP',
  //   token: 'BTCB-BNB BiLP',
  //   tokenDescription: 'BiSwap',
  //   tokenAddress: '0xC7e9d76ba11099AF3F330ff829c5F442d571e057',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooBiSwapBTCB-BNB',
  //   earnedTokenAddress: '0x9A10Cc2024744681fBce1F93Aeee3fd7863B1A12',
  //   earnContractAddress: '0x9A10Cc2024744681fBce1F93Aeee3fd7863B1A12',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'biswap-btcb-wbnb',
  //   oraclePrice: 0,
  //   depositsPaused: false,
  //   status: 'active',
  //   platform: 'BiSwap',
  //   assets: ['BTCB', 'BNB'],
  //   risks: [
  //     'COMPLEXITY_LOW',
  //     'BATTLE_TESTED',
  //     'IL_LOW',
  //     'MCAP_LARGE',
  //     'AUDIT',
  //     'CONTRACTS_VERIFIED',
  //   ],
  //   stratType: 'StratLP',
  //   addLiquidityUrl:
  //     'https://exchange.biswap.org/#/add/ETH/0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c',
  //   buyTokenUrl:
  //     'https://exchange.biswap.org/#/swap?inputCurrency=ETH&outputCurrency=0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c',
  //   createdAt: 1651748230,
  // },

  // {
  //   id: 'biswap-wbnb-busd',
  //   name: 'BUSD-BNB LP',
  //   token: 'BUSD-BNB BiLP',
  //   tokenDescription: 'BiSwap',
  //   tokenAddress: '0xaCAac9311b0096E04Dfe96b6D87dec867d3883Dc',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooBiSwapBUSD-BNB',
  //   earnedTokenAddress: '0x92d46fFaB61914fEF6f0F2e83289f5F38B54ae7c',
  //   earnContractAddress: '0x92d46fFaB61914fEF6f0F2e83289f5F38B54ae7c',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'biswap-wbnb-busd',
  //   oraclePrice: 0,
  //   depositsPaused: false,
  //   status: 'active',
  //   platform: 'BiSwap',
  //   assets: ['BUSD', 'BNB'],
  //   risks: [
  //     'COMPLEXITY_LOW',
  //     'BATTLE_TESTED',
  //     'IL_LOW',
  //     'MCAP_LARGE',
  //     'AUDIT',
  //     'CONTRACTS_VERIFIED',
  //   ],
  //   stratType: 'StratLP',
  //   addLiquidityUrl:
  //     'https://exchange.biswap.org/#/add/ETH/0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
  //   buyTokenUrl:
  //     'https://exchange.biswap.org/#/swap?inputCurrency=ETH&outputCurrency=0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
  //   createdAt: 1651748798,
  // },

  // {
  //   id: 'biswap-eth-usdt',
  //   name: 'ETH-USDT LP',
  //   token: 'ETH-USDT BiLP',
  //   tokenDescription: 'BiSwap',
  //   tokenAddress: '0x63b30de1A998e9E64FD58A21F68D323B9BcD8F85',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooBiSwapETH-USDT',
  //   earnedTokenAddress: '0x2ea77B1d17C9e2a99a2C019abC8eA2f6A59A1044',
  //   earnContractAddress: '0x2ea77B1d17C9e2a99a2C019abC8eA2f6A59A1044',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'biswap-eth-usdt',
  //   oraclePrice: 0,
  //   depositsPaused: false,
  //   status: 'active',
  //   platform: 'BiSwap',
  //   assets: ['ETH', 'USDT'],
  //   risks: [
  //     'COMPLEXITY_LOW',
  //     'BATTLE_TESTED',
  //     'IL_LOW',
  //     'MCAP_LARGE',
  //     'AUDIT',
  //     'CONTRACTS_VERIFIED',
  //   ],
  //   stratType: 'StratLP',
  //   addLiquidityUrl:
  //     'https://exchange.biswap.org/#/add/0x55d398326f99059fF775485246999027B3197955/0x2170Ed0880ac9A755fd29B2688956BD959F933F8',
  //   buyTokenUrl:
  //     'https://exchange.biswap.org/#/swap?inputCurrency=0x55d398326f99059fF775485246999027B3197955&outputCurrency=0x2170Ed0880ac9A755fd29B2688956BD959F933F8',
  //   createdAt: 1651749366,
  // },

  // {
  //   id: 'biswap-eth-wbnb',
  //   name: 'ETH-BNB LP',
  //   token: 'ETH-BNB BiLP',
  //   tokenDescription: 'BiSwap',
  //   tokenAddress: '0x5bf6941f029424674bb93A43b79fc46bF4A67c21',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooBiSwapETH-BNB',
  //   earnedTokenAddress: '0x48E60f935B9dbd3d1530fb66d443a8A6d934F963',
  //   earnContractAddress: '0x48E60f935B9dbd3d1530fb66d443a8A6d934F963',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'biswap-eth-wbnb',
  //   oraclePrice: 0,
  //   depositsPaused: false,
  //   status: 'active',
  //   platform: 'BiSwap',
  //   assets: ['ETH', 'BNB'],
  //   risks: [
  //     'COMPLEXITY_LOW',
  //     'BATTLE_TESTED',
  //     'IL_LOW',
  //     'MCAP_LARGE',
  //     'AUDIT',
  //     'CONTRACTS_VERIFIED',
  //   ],
  //   stratType: 'StratLP',
  //   addLiquidityUrl:
  //     'https://exchange.biswap.org/#/add/ETH/0x2170Ed0880ac9A755fd29B2688956BD959F933F8',
  //   buyTokenUrl:
  //     'https://exchange.biswap.org/#/swap?inputCurrency=ETH&outputCurrency=0x2170Ed0880ac9A755fd29B2688956BD959F933F8',
  //   createdAt: 1651750026,
  // },

  // {
  //   id: 'biswap-ust-busd-eol',
  //   name: 'UST-BUSD LP',
  //   token: 'UST-BUSD BiLP',
  //   tokenDescription: 'BiSwap',
  //   tokenAddress: '0x9E78183dD68cC81bc330CAF3eF84D354a58303B5',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooBiSwapUST-BUSD',
  //   earnedTokenAddress: '0x2e2Cc6eFCf8B6193A32a92B33747C081FE44bf1d',
  //   earnContractAddress: '0x2e2Cc6eFCf8B6193A32a92B33747C081FE44bf1d',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'biswap-ust-busd',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   retireReason: 'rewards',
  //   platform: 'BiSwap',
  //   assets: ['UST', 'BUSD'],
  //   risks: [
  //     'COMPLEXITY_LOW',
  //     'BATTLE_TESTED',
  //     'MCAP_LARGE',
  //     'AUDIT',
  //     'CONTRACTS_VERIFIED',
  //     'ALGO_STABLE',
  //   ],
  //   stratType: 'StratLP',
  //   addLiquidityUrl:
  //     'https://exchange.biswap.org/#/add/0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56/0x23396cF899Ca06c4472205fC903bDB4de249D6fC',
  //   buyTokenUrl:
  //     'https://exchange.biswap.org/#/swap?inputCurrency=0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56&outputCurrency=0x23396cF899Ca06c4472205fC903bDB4de249D6fC',
  //   createdAt: 1651750575,
  // },

  // {
  //   id: 'biswap-cake-wbnb',
  //   name: 'CAKE-BNB LP',
  //   token: 'CAKE-BNB BiLP',
  //   tokenDescription: 'BiSwap',
  //   tokenAddress: '0x3d94d03eb9ea2D4726886aB8Ac9fc0F18355Fd13',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooBiSwapCAKE-BNB',
  //   earnedTokenAddress: '0x323818ba51C7ff001cC1DAAFb6143a9AB7f292A9',
  //   earnContractAddress: '0x323818ba51C7ff001cC1DAAFb6143a9AB7f292A9',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'biswap-cake-wbnb',
  //   oraclePrice: 0,
  //   depositsPaused: false,
  //   status: 'active',
  //   platform: 'BiSwap',
  //   assets: ['CAKE', 'BNB'],
  //   risks: [
  //     'COMPLEXITY_LOW',
  //     'BATTLE_TESTED',
  //     'IL_HIGH',
  //     'MCAP_LARGE',
  //     'AUDIT',
  //     'CONTRACTS_VERIFIED',
  //   ],
  //   stratType: 'StratLP',
  //   addLiquidityUrl:
  //     'https://exchange.biswap.org/#/add/0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82/ETH',
  //   buyTokenUrl:
  //     'https://exchange.biswap.org/#/swap?inputCurrency=ETH&outputCurrency=0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82',
  //   createdAt: 1651751256,
  // },

  // {
  //   id: 'biswap-usdt-busd',
  //   name: 'USDT-BUSD LP',
  //   token: 'USDT-BUSD BiLP',
  //   tokenDescription: 'BiSwap',
  //   tokenAddress: '0xDA8ceb724A06819c0A5cDb4304ea0cB27F8304cF',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooBiSwapUSDT-BUSD',
  //   earnedTokenAddress: '0x164fb78cAf2730eFD63380c2a645c32eBa1C52bc',
  //   earnContractAddress: '0x164fb78cAf2730eFD63380c2a645c32eBa1C52bc',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'biswap-usdt-busd',
  //   oraclePrice: 0,
  //   depositsPaused: false,
  //   status: 'active',
  //   platform: 'BiSwap',
  //   assets: ['USDT', 'BUSD'],
  //   risks: [
  //     'COMPLEXITY_LOW',
  //     'BATTLE_TESTED',
  //     'IL_NONE',
  //     'MCAP_LARGE',
  //     'AUDIT',
  //     'CONTRACTS_VERIFIED',
  //   ],
  //   stratType: 'StratLP',
  //   addLiquidityUrl:
  //     'https://exchange.biswap.org/#/add/0x55d398326f99059fF775485246999027B3197955/0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
  //   buyTokenUrl:
  //     'https://exchange.biswap.org/#/swap?inputCurrency=0x55d398326f99059fF775485246999027B3197955&outputCurrency=0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
  //   createdAt: 1649932290,
  // },

  // {
  //   id: 'cakev2-wbnb-abnbc',
  //   name: 'aBNBc-BNB LP',
  //   token: 'aBNBc-BNB LP',
  //   tokenDescription: 'PancakeSwap',
  //   tokenAddress: '0x272c2CF847A49215A3A1D4bFf8760E503A06f880',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooCakeV2aBNBc-BNB',
  //   earnedTokenAddress: '0x4065e3b7623CA46c72960bD56F55eE89CaF5498c',
  //   earnContractAddress: '0x4065e3b7623CA46c72960bD56F55eE89CaF5498c',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'cakev2-wbnb-abnbc',
  //   oraclePrice: 0,
  //   depositsPaused: false,
  //   status: 'active',
  //   platform: 'PancakeSwap',
  //   assets: ['aBNBc', 'BNB'],
  //   risks: [
  //     'COMPLEXITY_LOW',
  //     'BATTLE_TESTED',
  //     'MCAP_MICRO',
  //     'IL_NONE',
  //     'AUDIT',
  //     'CONTRACTS_VERIFIED',
  //   ],
  //   stratType: 'StratLP',
  //   addLiquidityUrl:
  //     'https://pancakeswap.finance/add/BNB/0xE85aFCcDaFBE7F2B096f268e31ccE3da8dA2990A',
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?outputCurrency=0xE85aFCcDaFBE7F2B096f268e31ccE3da8dA2990A',
  //   createdAt: 1651001831,
  // },

  // {
  //   id: 'cakev2-wbnb-ceek',
  //   name: 'CEEK-BNB LP',
  //   token: 'CEEK-BNB LP',
  //   tokenDescription: 'PancakeSwap',
  //   tokenAddress: '0x046A9B3A9b743340eE2Bc4C6dDD35543E237C6c2',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooCakeV2CEEK-BNB',
  //   earnedTokenAddress: '0xbf3fEE15ae8e41F1A63B011aba9dC9d6a732DBB0',
  //   earnContractAddress: '0xbf3fEE15ae8e41F1A63B011aba9dC9d6a732DBB0',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'cakev2-wbnb-ceek',
  //   oraclePrice: 0,
  //   depositsPaused: false,
  //   status: 'active',
  //   platform: 'PancakeSwap',
  //   assets: ['CEEK', 'BNB'],
  //   risks: [
  //     'COMPLEXITY_LOW',
  //     'BATTLE_TESTED',
  //     'MCAP_MICRO',
  //     'IL_HIGH',
  //     'AUDIT',
  //     'CONTRACTS_VERIFIED',
  //   ],
  //   stratType: 'StratLP',
  //   addLiquidityUrl:
  //     'https://pancakeswap.finance/add/BNB/0xe0F94Ac5462997D2BC57287Ac3a3aE4C31345D66',
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?outputCurrency=0xe0F94Ac5462997D2BC57287Ac3a3aE4C31345D66',
  //   createdAt: 1651000775,
  // },

  // {
  //   id: 'cakev2-pex-wbnb',
  //   name: 'PEX-BNB LP',
  //   token: 'PEX-BNB LP',
  //   tokenDescription: 'PancakeSwap',
  //   tokenAddress: '0x5ca96E8bDe0Bc587DaC9e02422Fd205b1102DAa4',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooCakeV2PEX-BNB',
  //   earnedTokenAddress: '0xe744dF11a036abC219e28Dd11ba4923784515125',
  //   earnContractAddress: '0xe744dF11a036abC219e28Dd11ba4923784515125',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'cakev2-pex-wbnb',
  //   oraclePrice: 0,
  //   depositsPaused: false,
  //   status: 'active',
  //   platform: 'PancakeSwap',
  //   assets: ['PEX', 'BNB'],
  //   risks: [
  //     'COMPLEXITY_LOW',
  //     'BATTLE_TESTED',
  //     'MCAP_MICRO',
  //     'IL_HIGH',
  //     'AUDIT',
  //     'CONTRACTS_VERIFIED',
  //   ],
  //   stratType: 'StratLP',
  //   addLiquidityUrl:
  //     'https://pancakeswap.finance/add/BNB/0x6a0b66710567b6beb81A71F7e9466450a91a384b',
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?outputCurrency=0x6a0b66710567b6beb81A71F7e9466450a91a384b',
  //   createdAt: 1651001420,
  // },

  // {
  //   id: 'cakev2-wbnb-froyo',
  //   name: 'FROYO-BNB LP',
  //   token: 'FROYO-BNB LP',
  //   tokenDescription: 'PancakeSwap',
  //   tokenAddress: '0x1Ce76390Dd210B9C9ae28373FDf79714206ECb73',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooCakeV2FROYO-BNB',
  //   earnedTokenAddress: '0x873d2b4B57143FD6B99A99d63C50F46B96740E94',
  //   earnContractAddress: '0x873d2b4B57143FD6B99A99d63C50F46B96740E94',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'cakev2-wbnb-froyo',
  //   oraclePrice: 0,
  //   depositsPaused: false,
  //   status: 'active',
  //   platform: 'PancakeSwap',
  //   assets: ['FROYO', 'BNB'],
  //   risks: [
  //     'COMPLEXITY_LOW',
  //     'BATTLE_TESTED',
  //     'MCAP_MICRO',
  //     'IL_HIGH',
  //     'AUDIT',
  //     'CONTRACTS_VERIFIED',
  //   ],
  //   stratType: 'StratLP',
  //   addLiquidityUrl:
  //     'https://pancakeswap.finance/add/BNB/0xe369fec23380f9F14ffD07a1DC4b7c1a9fdD81c9',
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?outputCurrency=0xe369fec23380f9F14ffD07a1DC4b7c1a9fdD81c9',
  //   createdAt: 1651001123,
  // },

  // {
  //   id: 'cakev2-cake-froyo',
  //   name: 'FROYO-CAKE LP',
  //   token: 'FROYO-CAKE LP',
  //   tokenDescription: 'PancakeSwap',
  //   tokenAddress: '0x1CCc3cC95c8148477Afd18a552f03835Be9D182a',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooCakeV2FROYO-CAKE',
  //   earnedTokenAddress: '0x768e36F0B40341758f5D06d4f96D54B01D335c90',
  //   earnContractAddress: '0x768e36F0B40341758f5D06d4f96D54B01D335c90',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'cakev2-cake-froyo',
  //   oraclePrice: 0,
  //   depositsPaused: false,
  //   status: 'active',
  //   platform: 'PancakeSwap',
  //   assets: ['FROYO', 'CAKE'],
  //   risks: [
  //     'COMPLEXITY_LOW',
  //     'BATTLE_TESTED',
  //     'MCAP_MICRO',
  //     'IL_HIGH',
  //     'AUDIT',
  //     'CONTRACTS_VERIFIED',
  //   ],
  //   stratType: 'StratLP',
  //   addLiquidityUrl:
  //     'https://pancakeswap.finance/add/0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82/0xe369fec23380f9F14ffD07a1DC4b7c1a9fdD81c9',
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?inputCurrency=0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82&outputCurrency=0xe369fec23380f9F14ffD07a1DC4b7c1a9fdD81c9',
  //   createdAt: 1651001735,
  // },

  // {
  //   id: 'bomb-busm-busd',
  //   name: 'BUSM-BUSD LP',
  //   token: 'BUSM-BUSD LP',
  //   tokenDescription: 'PancakeSwap (Bomb)',
  //   tokenAddress: '0xEe46Bd06a8876c3cc86027dc7D2Df19af513cD12',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooBombBUSM-BUSD',
  //   earnedTokenAddress: '0xC43e5065c28Dcf85f9a5D31feAC658c6EB7F23D6',
  //   earnContractAddress: '0xC43e5065c28Dcf85f9a5D31feAC658c6EB7F23D6',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'bomb-busm-busd',
  //   oraclePrice: 0,
  //   depositsPaused: false,
  //   status: 'active',
  //   platform: 'Bomb.Money',
  //   assets: ['BUSM', 'BUSD'],
  //   risks: [
  //     'COMPLEXITY_LOW',
  //     'BATTLE_TESTED',
  //     'IL_LOW',
  //     'MCAP_MICRO',
  //     'AUDIT',
  //     'CONTRACTS_VERIFIED',
  //   ],
  //   stratType: 'StratLP',
  //   addLiquidityUrl:
  //     'https://pancakeswap.finance/add/0x6216B17f696B14701E17BCB24Ec14430261Be94A/0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?inputCurrency=0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56&outputCurrency=0x6216B17f696B14701E17BCB24Ec14430261Be94A',
  //   createdAt: 1651233170,
  // },

  // {
  //   id: 'valas-valas-bnb',
  //   name: 'VALAS-BNB LP',
  //   token: 'VALAS-BNB LP',
  //   tokenDescription: 'PancakeSwap (Valas)',
  //   tokenAddress: '0x829F540957DFC652c4466a7F34de611E172e64E8',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooValasVALAS-BNB',
  //   earnedTokenAddress: '0x020007Da76C0cA9F36e405478AAFFB67512F06a7',
  //   earnContractAddress: '0x020007Da76C0cA9F36e405478AAFFB67512F06a7',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'valas-valas-bnb',
  //   oraclePrice: 0,
  //   depositsPaused: false,
  //   status: 'active',
  //   platform: 'Valas',
  //   assets: ['VALAS', 'BNB'],
  //   risks: ['COMPLEXITY_LOW', 'BATTLE_TESTED', 'IL_HIGH', 'MCAP_MICRO', 'CONTRACTS_VERIFIED'],
  //   stratType: 'StratLP',
  //   addLiquidityUrl:
  //     'https://pancakeswap.finance/add/0xb1ebdd56729940089ecc3ad0bbeeb12b6842ea6f/0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c',
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?outputCurrency=0xb1ebdd56729940089ecc3ad0bbeeb12b6842ea6f',
  //   createdAt: 1650115724,
  // },

  // {
  //   id: 'valas-wbtc',
  //   name: 'BTCB',
  //   token: 'BTCB Valas',
  //   logo: 'single-assets/BTCB.svg',
  //   tokenDescription: 'Valas',
  //   tokenAddress: '0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooValasBTCB',
  //   earnedTokenAddress: '0x6bCD9493dFA06D07003aB6920DacE6D4F854E162',
  //   earnContractAddress: '0x6bCD9493dFA06D07003aB6920DacE6D4F854E162',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'tokens',
  //   oracleId: 'BTCB',
  //   oraclePrice: 0,
  //   depositsPaused: false,
  //   status: 'active',
  //   platform: 'Valas',
  //   assets: ['BTCB'],
  //   withdrawalFee: '0.01%',
  //   risks: [
  //     'COMPLEXITY_MEDIUM',
  //     'BATTLE_TESTED',
  //     'IL_NONE',
  //     'MCAP_HIGH',
  //     'ADMIN_WITH_TIMELOCK',
  //     'CONTRACTS_VERIFIED',
  //   ],
  //   stratType: 'Lending',
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?outputCurrency=0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c',
  //   createdAt: 1650126214,
  // },

  // {
  //   id: 'valas-eth',
  //   name: 'ETH',
  //   token: 'ETH Valas',
  //   logo: 'single-assets/ETH.svg',
  //   tokenDescription: 'Valas',
  //   tokenAddress: '0x2170Ed0880ac9A755fd29B2688956BD959F933F8',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooValasETH',
  //   earnedTokenAddress: '0x725E14C3106EBf4778e01eA974e492f909029aE8',
  //   earnContractAddress: '0x725E14C3106EBf4778e01eA974e492f909029aE8',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'tokens',
  //   oracleId: 'ETH',
  //   oraclePrice: 0,
  //   depositsPaused: false,
  //   status: 'active',
  //   platform: 'Valas',
  //   assets: ['ETH'],
  //   withdrawalFee: '0.01%',
  //   risks: [
  //     'COMPLEXITY_MEDIUM',
  //     'BATTLE_TESTED',
  //     'IL_NONE',
  //     'MCAP_HIGH',
  //     'ADMIN_WITH_TIMELOCK',
  //     'CONTRACTS_VERIFIED',
  //   ],
  //   stratType: 'Lending',
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?outputCurrency=0x2170Ed0880ac9A755fd29B2688956BD959F933F8',
  //   createdAt: 1650127009,
  // },

  // {
  //   id: 'valas-busd',
  //   name: 'BUSD',
  //   token: 'BUSD Valas',
  //   logo: 'single-assets/BUSD.svg',
  //   tokenDescription: 'Valas',
  //   tokenAddress: '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooValasBUSD',
  //   earnedTokenAddress: '0xB78b6A0137ad8745784D3B23c16abeA8F527ff54',
  //   earnContractAddress: '0xB78b6A0137ad8745784D3B23c16abeA8F527ff54',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'tokens',
  //   oracleId: 'BUSD',
  //   oraclePrice: 0,
  //   depositsPaused: false,
  //   status: 'active',
  //   platform: 'Valas',
  //   assets: ['BUSD'],
  //   withdrawalFee: '0.01%',
  //   risks: [
  //     'COMPLEXITY_MEDIUM',
  //     'BATTLE_TESTED',
  //     'IL_NONE',
  //     'MCAP_HIGH',
  //     'ADMIN_WITH_TIMELOCK',
  //     'CONTRACTS_VERIFIED',
  //   ],
  //   stratType: 'Lending',
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?outputCurrency=0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
  //   createdAt: 1650127645,
  // },

  // {
  //   id: 'valas-usdt',
  //   name: 'USDT',
  //   token: 'USDT Valas',
  //   logo: 'single-assets/USDT.svg',
  //   tokenDescription: 'Valas',
  //   tokenAddress: '0x55d398326f99059fF775485246999027B3197955',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooValasUSDT',
  //   earnedTokenAddress: '0x5cED4Dc963F607C82D2C2F22C2AB0a975adc4Bb9',
  //   earnContractAddress: '0x5cED4Dc963F607C82D2C2F22C2AB0a975adc4Bb9',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'tokens',
  //   oracleId: 'USDT',
  //   oraclePrice: 0,
  //   depositsPaused: false,
  //   status: 'active',
  //   platform: 'Valas',
  //   assets: ['USDT'],
  //   withdrawalFee: '0.01%',
  //   risks: [
  //     'COMPLEXITY_MEDIUM',
  //     'BATTLE_TESTED',
  //     'IL_NONE',
  //     'MCAP_HIGH',
  //     'ADMIN_WITH_TIMELOCK',
  //     'CONTRACTS_VERIFIED',
  //   ],
  //   stratType: 'Lending',
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?outputCurrency=0x55d398326f99059fF775485246999027B3197955',
  //   createdAt: 1650128131,
  // },

  // {
  //   id: 'valas-usdc',
  //   name: 'USDC',
  //   token: 'USDC Valas',
  //   logo: 'single-assets/USDC.svg',
  //   tokenDescription: 'Valas',
  //   tokenAddress: '0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooValasUSDC',
  //   earnedTokenAddress: '0x517493d1Fb90aB0a3cE3a5084065B706e33e0fEA',
  //   earnContractAddress: '0x517493d1Fb90aB0a3cE3a5084065B706e33e0fEA',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'tokens',
  //   oracleId: 'USDC',
  //   oraclePrice: 0,
  //   depositsPaused: false,
  //   status: 'active',
  //   platform: 'Valas',
  //   assets: ['USDC'],
  //   withdrawalFee: '0.01%',
  //   risks: [
  //     'COMPLEXITY_MEDIUM',
  //     'BATTLE_TESTED',
  //     'IL_NONE',
  //     'MCAP_HIGH',
  //     'ADMIN_WITH_TIMELOCK',
  //     'CONTRACTS_VERIFIED',
  //   ],
  //   stratType: 'Lending',
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?outputCurrency=0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d',
  //   createdAt: 1650308625,
  // },

  // {
  //   id: 'valas-dai',
  //   name: 'DAI',
  //   token: 'DAI Valas',
  //   logo: 'single-assets/DAI.svg',
  //   tokenDescription: 'Valas',
  //   tokenAddress: '0x1AF3F329e8BE154074D8769D1FFa4eE058B1DBc3',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooValasDAI',
  //   earnedTokenAddress: '0xF66e8edf8A86b2b405fF23e73B72DAC01070c8B8',
  //   earnContractAddress: '0xF66e8edf8A86b2b405fF23e73B72DAC01070c8B8',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'tokens',
  //   oracleId: 'DAI',
  //   oraclePrice: 0,
  //   depositsPaused: false,
  //   status: 'active',
  //   platform: 'Valas',
  //   assets: ['DAI'],
  //   withdrawalFee: '0.01%',
  //   risks: [
  //     'COMPLEXITY_MEDIUM',
  //     'BATTLE_TESTED',
  //     'IL_NONE',
  //     'MCAP_HIGH',
  //     'ADMIN_WITH_TIMELOCK',
  //     'CONTRACTS_VERIFIED',
  //     'OVER_COLLAT_ALGO_STABLECOIN',
  //   ],
  //   stratType: 'Lending',
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?outputCurrency=0x1AF3F329e8BE154074D8769D1FFa4eE058B1DBc3',
  //   createdAt: 1650308955,
  // },

  // {
  //   id: 'valas-tusd',
  //   name: 'TUSD',
  //   token: 'TUSD Valas',
  //   logo: 'single-assets/TUSD.png',
  //   tokenDescription: 'Valas',
  //   tokenAddress: '0x14016E85a25aeb13065688cAFB43044C2ef86784',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooValasTUSD',
  //   earnedTokenAddress: '0x9749b2D46f0caa661bD84D26264811DA968bC4Fc',
  //   earnContractAddress: '0x9749b2D46f0caa661bD84D26264811DA968bC4Fc',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'tokens',
  //   oracleId: 'TUSD',
  //   oraclePrice: 0,
  //   depositsPaused: false,
  //   status: 'active',
  //   platform: 'Valas',
  //   assets: ['TUSD'],
  //   withdrawalFee: '0.01%',
  //   risks: [
  //     'COMPLEXITY_MEDIUM',
  //     'BATTLE_TESTED',
  //     'IL_NONE',
  //     'MCAP_HIGH',
  //     'ADMIN_WITH_TIMELOCK',
  //     'CONTRACTS_VERIFIED',
  //   ],
  //   stratType: 'Lending',
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?outputCurrency=0x14016E85a25aeb13065688cAFB43044C2ef86784',
  //   createdAt: 1650309426,
  // },

  // {
  //   id: 'biswap-bsw',
  //   name: 'BSW',
  //   token: 'BSW',
  //   logo: 'single-assets/BSW.svg',
  //   tokenDescription: 'BiSwap',
  //   tokenAddress: '0x965F527D9159dCe6288a2219DB51fc6Eef120dD1',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooBiSwapBSW',
  //   earnedTokenAddress: '0x0BE6B4CAC0D586f474AA4e059CDc05Bc54f61B03',
  //   earnContractAddress: '0x0BE6B4CAC0D586f474AA4e059CDc05Bc54f61B03',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'tokens',
  //   oracleId: 'BSW',
  //   oraclePrice: 0,
  //   depositsPaused: false,
  //   status: 'active',
  //   platform: 'BiSwap',
  //   assets: ['BSW'],
  //   risks: [
  //     'COMPLEXITY_LOW',
  //     'BATTLE_TESTED',
  //     'IL_NONE',
  //     'MCAP_MEDIUM',
  //     'AUDIT',
  //     'CONTRACTS_VERIFIED',
  //   ],
  //   stratType: 'SingleStake',
  //   withdrawalFee: '0%',
  //   buyTokenUrl:
  //     'https://exchange.biswap.org/#/swap?outputCurrency=0x965f527d9159dce6288a2219db51fc6eef120dd1',
  //   createdAt: 1649884692,
  // },
  // {
  //   id: 'biswap-bsw-wbnb',
  //   name: 'BSW-BNB LP',
  //   token: 'BSW-BNB BiLP',
  //   tokenDescription: 'BiSwap',
  //   tokenAddress: '0x46492B26639Df0cda9b2769429845cb991591E0A',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooBiSwapBSW-BNB',
  //   earnedTokenAddress: '0xd4548D0b71D4f925aaA2be59E10c6B9248d1EF70',
  //   earnContractAddress: '0xd4548D0b71D4f925aaA2be59E10c6B9248d1EF70',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'biswap-bsw-wbnb',
  //   oraclePrice: 0,
  //   depositsPaused: false,
  //   status: 'active',
  //   platform: 'BiSwap',
  //   assets: ['BSW', 'BNB'],
  //   risks: [
  //     'COMPLEXITY_LOW',
  //     'BATTLE_TESTED',
  //     'IL_LOW',
  //     'MCAP_MEDIUM',
  //     'AUDIT',
  //     'CONTRACTS_VERIFIED',
  //   ],
  //   stratType: 'StratLP',
  //   addLiquidityUrl:
  //     'https://exchange.biswap.org/#/add/ETH/0x965F527D9159dCe6288a2219DB51fc6Eef120dD1',
  //   buyTokenUrl:
  //     'https://exchange.biswap.org/#/swap?outputCurrency=0x965F527D9159dCe6288a2219DB51fc6Eef120dD1',
  //   createdAt: 1649884691,
  // },
  // {
  //   id: 'biswap-usdt-bsw',
  //   name: 'BSW-USDT LP',
  //   token: 'BSW-USDT BiLP',
  //   tokenDescription: 'BiSwap',
  //   tokenAddress: '0x2b30c317ceDFb554Ec525F85E79538D59970BEb0',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooBiSwapUSDT-BSW',
  //   earnedTokenAddress: '0x216EEE15D1e3fAAD34181f66dd0B665f556a638d',
  //   earnContractAddress: '0x216EEE15D1e3fAAD34181f66dd0B665f556a638d',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'biswap-usdt-bsw',
  //   oraclePrice: 0,
  //   depositsPaused: false,
  //   status: 'active',
  //   platform: 'BiSwap',
  //   assets: ['BSW', 'USDT'],
  //   risks: [
  //     'COMPLEXITY_LOW',
  //     'BATTLE_TESTED',
  //     'IL_LOW',
  //     'MCAP_MEDIUM',
  //     'AUDIT',
  //     'CONTRACTS_VERIFIED',
  //   ],
  //   stratType: 'StratLP',
  //   addLiquidityUrl:
  //     'https://exchange.biswap.org/#/add/0x55d398326f99059fF775485246999027B3197955/0x965F527D9159dCe6288a2219DB51fc6Eef120dD1',
  //   buyTokenUrl:
  //     'https://exchange.biswap.org/#/swap?inputCurrency=0x55d398326f99059fF775485246999027B3197955&outputCurrency=0x965f527d9159dce6288a2219db51fc6eef120dd1',
  //   createdAt: 1649884690,
  // },
  // {
  //   id: 'biswap-eth-btcb',
  //   name: 'BTCB-ETH LP',
  //   token: 'BTCB-ETH BiLP',
  //   tokenDescription: 'BiSwap',
  //   tokenAddress: '0x6216E04cd40DB2c6FBEd64f1B5830A98D3A91740',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooBiSwapETH-BTCB',
  //   earnedTokenAddress: '0xEeB87e7bAbF17cA97F0Eb897F24Bf475e0A9Aef7',
  //   earnContractAddress: '0xEeB87e7bAbF17cA97F0Eb897F24Bf475e0A9Aef7',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'biswap-eth-btcb',
  //   oraclePrice: 0,
  //   depositsPaused: false,
  //   status: 'active',
  //   platform: 'BiSwap',
  //   assets: ['BTCB', 'ETH'],
  //   risks: [
  //     'COMPLEXITY_LOW',
  //     'BATTLE_TESTED',
  //     'IL_LOW',
  //     'MCAP_LARGE',
  //     'AUDIT',
  //     'CONTRACTS_VERIFIED',
  //   ],
  //   stratType: 'StratLP',
  //   addLiquidityUrl:
  //     'https://exchange.biswap.org/#/add/0x2170Ed0880ac9A755fd29B2688956BD959F933F8/0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c',
  //   buyTokenUrl:
  //     'https://exchange.biswap.org/#/swap?inputCurrency=0x2170Ed0880ac9A755fd29B2688956BD959F933F8&outputCurrency=0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c',
  //   createdAt: 1649884689,
  // },
  // {
  //   id: 'biswap-usdt-wbnb',
  //   name: 'USDT-BNB LP',
  //   token: 'USDT-BNB BiLP',
  //   tokenDescription: 'BiSwap',
  //   tokenAddress: '0x8840C6252e2e86e545deFb6da98B2a0E26d8C1BA',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooBiSwapUSDT-BNB',
  //   earnedTokenAddress: '0xe2AD2c5702f6c9073f85b00E4743066E1D1035f8',
  //   earnContractAddress: '0xe2AD2c5702f6c9073f85b00E4743066E1D1035f8',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'biswap-usdt-wbnb',
  //   oraclePrice: 0,
  //   depositsPaused: false,
  //   status: 'active',
  //   platform: 'BiSwap',
  //   assets: ['USDT', 'BNB'],
  //   risks: [
  //     'COMPLEXITY_LOW',
  //     'BATTLE_TESTED',
  //     'IL_LOW',
  //     'MCAP_LARGE',
  //     'AUDIT',
  //     'CONTRACTS_VERIFIED',
  //   ],
  //   stratType: 'StratLP',
  //   addLiquidityUrl:
  //     'https://exchange.biswap.org/#/add/0x55d398326f99059fF775485246999027B3197955/ETH',
  //   buyTokenUrl:
  //     'https://exchange.biswap.org/#/swap?outputCurrency=0x55d398326f99059fF775485246999027B3197955',
  //   createdAt: 1649884687,
  // },
  // {
  //   id: 'biswap-wbnb-bifi',
  //   name: 'BIFI-BNB LP',
  //   token: 'BIFI-BNB BiLP',
  //   tokenDescription: 'BiSwap',
  //   tokenAddress: '0xeb80530DbBdf489e7a20175Ceaa80d4253062DD4',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooBiSwapBNB-BIFI',
  //   earnedTokenAddress: '0xcb979E17c039001DAd363093CeFca7170f144816',
  //   earnContractAddress: '0xcb979E17c039001DAd363093CeFca7170f144816',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'biswap-wbnb-bifi',
  //   oraclePrice: 0,
  //   depositsPaused: false,
  //   status: 'active',
  //   platform: 'BiSwap',
  //   assets: ['BIFI', 'BNB'],
  //   risks: [
  //     'COMPLEXITY_LOW',
  //     'BATTLE_TESTED',
  //     'IL_LOW',
  //     'MCAP_MEDIUM',
  //     'AUDIT',
  //     'CONTRACTS_VERIFIED',
  //   ],
  //   stratType: 'StratLP',
  //   addLiquidityUrl:
  //     'https://exchange.biswap.org/#/add/ETH/0xCa3F508B8e4Dd382eE878A314789373D80A5190A',
  //   buyTokenUrl:
  //     'https://exchange.biswap.org/#/swap?outputCurrency=0xCa3F508B8e4Dd382eE878A314789373D80A5190A',
  //   createdAt: 1649884686,
  // },
  // {
  //   id: 'emp-emp-eth',
  //   name: 'EMP-ETH LP',
  //   token: 'EMP-ETH LP',
  //   tokenDescription: 'PancakeSwap (EMP)',
  //   tokenAddress: '0x84821bb588f049913Dc579Dc511E5e31EB22d5E4',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooEmpEMP-ETH',
  //   earnedTokenAddress: '0xC78d2624745775c8Da178CF8AEc0e7B734c344a0',
  //   earnContractAddress: '0xC78d2624745775c8Da178CF8AEc0e7B734c344a0',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'emp-emp-eth',
  //   oraclePrice: 0,
  //   depositsPaused: false,
  //   status: 'active',
  //   platform: 'EMP.Money',
  //   assets: ['EMP', 'ETH'],
  //   risks: [
  //     'COMPLEXITY_LOW',
  //     'BATTLE_TESTED',
  //     'IL_LOW',
  //     'MCAP_MICRO',
  //     'AUDIT',
  //     'CONTRACTS_VERIFIED',
  //   ],
  //   stratType: 'StratLP',
  //   addLiquidityUrl:
  //     'https://pancakeswap.finance/add/0x3b248CEfA87F836a4e6f6d6c9b42991b88Dc1d58/0x2170Ed0880ac9A755fd29B2688956BD959F933F8',
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?inputCurrency=0x2170Ed0880ac9A755fd29B2688956BD959F933F8&outputCurrency=0x3b248CEfA87F836a4e6f6d6c9b42991b88Dc1d58',
  //   createdAt: 1648825484,
  // },
  // {
  //   id: 'emp-eshare-wbnb',
  //   name: 'ESHARE-BNB LP',
  //   token: 'ESHARE-BNB LP',
  //   tokenDescription: 'PancakeSwap (EMP)',
  //   tokenAddress: '0x1747AF98EBF0B22d500014c7dd52985d736337d2',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooEmpESHARE-BNB',
  //   earnedTokenAddress: '0x39E5C480bc28b77D8B5960Abc248A21C0cF4bE30',
  //   earnContractAddress: '0x39E5C480bc28b77D8B5960Abc248A21C0cF4bE30',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'emp-eshare-wbnb',
  //   oraclePrice: 0,
  //   depositsPaused: false,
  //   status: 'active',
  //   platform: 'EMP.Money',
  //   assets: ['ESHARE', 'BNB'],
  //   risks: [
  //     'COMPLEXITY_LOW',
  //     'BATTLE_TESTED',
  //     'IL_HIGH',
  //     'MCAP_MICRO',
  //     'AUDIT',
  //     'CONTRACTS_VERIFIED',
  //   ],
  //   stratType: 'StratLP',
  //   addLiquidityUrl:
  //     'https://pancakeswap.finance/add/0xDB20F6A8665432CE895D724b417f77EcAC956550/0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?outputCurrency=0xDB20F6A8665432CE895D724b417f77EcAC956550',
  //   createdAt: 1648773315,
  // },
  // {
  //   id: 'bifi-maxi-eol',
  //   logo: 'single-assets/BIFI.png',
  //   name: 'BIFI Maxi Old',
  //   token: 'BIFI',
  //   tokenDescription: 'Beefy',
  //   tokenAddress: '0xCa3F508B8e4Dd382eE878A314789373D80A5190A',
  //   tokenDecimals: 18,
  //   earnedToken: 'mooBIFI',
  //   earnedTokenAddress: '0xf7069e41C57EcC5F122093811d8c75bdB5f7c14e',
  //   earnContractAddress: '0xf7069e41C57EcC5F122093811d8c75bdB5f7c14e',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'tokens',
  //   oracleId: 'BIFI',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'Beefy',
  //   assets: ['BIFI'],
  //   risks: [
  //     'COMPLEXITY_LOW',
  //     'BATTLE_TESTED',
  //     'IL_NONE',
  //     'MCAP_MEDIUM',
  //     'AUDIT',
  //     'CONTRACTS_VERIFIED',
  //   ],
  //   stratType: 'SingleStake',
  //   withdrawalFee: '0.05%',
  //   buyTokenUrl: 'https://app.1inch.io/#/56/swap/BNB/BIFI',
  //   createdAt: 1606511757,
  //   retireReason: 'upgrade',
  // },
  // {
  //   id: 'stargate-bsc-busd',
  //   name: 'BUSD LP',
  //   token: 'S*BUSD',
  //   logo: 'single-assets/BUSD.svg',
  //   tokenDescription: 'Stargate',
  //   tokenAddress: '0x98a5737749490856b401DB5Dc27F522fC314A4e1',
  //   tokenDecimals: 6,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooStargateBUSD',
  //   earnedTokenAddress: '0x220711F15D09bdDFD1f0ba0dfC905074Ea204C4E',
  //   earnContractAddress: '0x220711F15D09bdDFD1f0ba0dfC905074Ea204C4E',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'tokens',
  //   oracleId: 'sbBUSD',
  //   oraclePrice: 0,
  //   depositsPaused: false,
  //   status: 'active',
  //   platform: 'Stargate',
  //   assets: ['sbBUSD'],
  //   risks: [
  //     'COMPLEXITY_LOW',
  //     'BATTLE_TESTED',
  //     'IL_NONE',
  //     'MCAP_LARGE',
  //     'AUDIT',
  //     'CONTRACTS_VERIFIED',
  //   ],
  //   stratType: 'SingleStake',
  //   withdrawalFee: '0%',
  //   addLiquidityUrl: 'https://stargate.finance/pool/BUSD-BSC',
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?outputCurrency=0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
  //   createdAt: 1648672017,
  // },
  // {
  //   id: 'stargate-bsc-usdt',
  //   name: 'USDT LP',
  //   token: 'S*USDT',
  //   logo: 'single-assets/USDT.svg',
  //   tokenDescription: 'Stargate',
  //   tokenAddress: '0x9aA83081AA06AF7208Dcc7A4cB72C94d057D2cda',
  //   tokenDecimals: 6,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooStargateUSDT',
  //   earnedTokenAddress: '0x0383E88A19E5c387FeBafbF51E5bA642d2ad8bE0',
  //   earnContractAddress: '0x0383E88A19E5c387FeBafbF51E5bA642d2ad8bE0',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'tokens',
  //   oracleId: 'sbUSDT',
  //   oraclePrice: 0,
  //   depositsPaused: false,
  //   status: 'active',
  //   platform: 'Stargate',
  //   assets: ['sbUSDT'],
  //   risks: [
  //     'COMPLEXITY_LOW',
  //     'BATTLE_TESTED',
  //     'IL_NONE',
  //     'MCAP_LARGE',
  //     'AUDIT',
  //     'CONTRACTS_VERIFIED',
  //   ],
  //   stratType: 'SingleStake',
  //   withdrawalFee: '0%',
  //   addLiquidityUrl: 'https://stargate.finance/pool/USDT-BSC',
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?outputCurrency=0x55d398326f99059fF775485246999027B3197955',
  //   createdAt: 1648675805,
  // },
  // {
  //   id: 'banana-ape-wbnb',
  //   name: 'APE-BNB LP',
  //   token: 'APE-BNB ALP',
  //   tokenDescription: 'ApeSwap',
  //   tokenAddress: '0x8B3EBE3422aabEA9920ca820fE43679e3e2E78D3',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooApeAPE-BNB',
  //   earnedTokenAddress: '0x4fdaF14fBAf0569Ea35050843bDa9288F7198602',
  //   earnContractAddress: '0x4fdaF14fBAf0569Ea35050843bDa9288F7198602',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'banana-ape-wbnb',
  //   oraclePrice: 0,
  //   depositsPaused: false,
  //   status: 'active',
  //   platform: 'ApeSwap',
  //   assets: ['APE', 'BNB'],
  //   risks: [
  //     'COMPLEXITY_LOW',
  //     'BATTLE_TESTED',
  //     'IL_LOW',
  //     'MCAP_LARGE',
  //     'AUDIT',
  //     'CONTRACTS_VERIFIED',
  //   ],
  //   stratType: 'StratLP',
  //   addLiquidityUrl:
  //     'https://apeswap.finance/add/0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c/0x0b079B33B6e72311c6BE245F9f660CC385029fc3',
  //   buyTokenUrl:
  //     'https://apeswap.finance/swap?outputCurrency=0x0b079B33B6e72311c6BE245F9f660CC385029fc3',
  //   createdAt: 1648050845,
  // },
  // {
  //   id: 'cakev2-cake-duet',
  //   name: 'DUET-CAKE LP',
  //   token: 'DUET-CAKE LP',
  //   tokenDescription: 'PancakeSwap',
  //   tokenAddress: '0xbDF0aA1D1985Caa357A6aC6661D838DA8691c569',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooCakeV2DUET-CAKE',
  //   earnedTokenAddress: '0xD811C6D89D5e18569A2AA78647B27A0B471c7360',
  //   earnContractAddress: '0xD811C6D89D5e18569A2AA78647B27A0B471c7360',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'cakev2-cake-duet',
  //   oraclePrice: 0,
  //   depositsPaused: false,
  //   status: 'active',
  //   platform: 'PancakeSwap',
  //   assets: ['DUET', 'CAKE'],
  //   risks: [
  //     'COMPLEXITY_LOW',
  //     'BATTLE_TESTED',
  //     'MCAP_MICRO',
  //     'IL_HIGH',
  //     'AUDIT',
  //     'CONTRACTS_VERIFIED',
  //   ],
  //   stratType: 'StratLP',
  //   addLiquidityUrl:
  //     'https://pancakeswap.finance/add/0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82/0x95EE03e1e2C5c4877f9A298F1C0D6c98698FAB7B',
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?outputCurrency=0x95EE03e1e2C5c4877f9A298F1C0D6c98698FAB7B',
  //   createdAt: 1647893595,
  // },
  // {
  //   id: 'cakev2-era-wbnb',
  //   name: 'ERA-BNB LP',
  //   token: 'ERA-BNB LP',
  //   tokenDescription: 'PancakeSwap',
  //   tokenAddress: '0x53a63ac301D6410915385294527f947aFf616599',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooCakeV2ERA-BNB',
  //   earnedTokenAddress: '0xbaAcbb2A18Db15D185AE5fAdc53bEe21Ed626a5e',
  //   earnContractAddress: '0xbaAcbb2A18Db15D185AE5fAdc53bEe21Ed626a5e',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'cakev2-era-wbnb',
  //   oraclePrice: 0,
  //   depositsPaused: false,
  //   status: 'active',
  //   platform: 'PancakeSwap',
  //   assets: ['ERA', 'BNB'],
  //   risks: [
  //     'COMPLEXITY_LOW',
  //     'BATTLE_TESTED',
  //     'MCAP_MICRO',
  //     'IL_HIGH',
  //     'AUDIT',
  //     'CONTRACTS_VERIFIED',
  //   ],
  //   stratType: 'StratLP',
  //   addLiquidityUrl:
  //     'https://pancakeswap.finance/add/0x6f9F0c4ad9Af7EbD61Ac5A1D4e0F2227F7B0E5f9/BNB',
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?outputCurrency=0x6f9F0c4ad9Af7EbD61Ac5A1D4e0F2227F7B0E5f9',
  //   createdAt: 1647893669,
  // },
  // {
  //   id: 'cakev2-bsw-wbnb',
  //   name: 'BSW-BNB LP',
  //   token: 'BSW-BNB LP',
  //   tokenDescription: 'PancakeSwap',
  //   tokenAddress: '0x8CA3fF14A52b080C54A6d1a405eecA02959d39fE',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooCakeV2BSW-BNB',
  //   earnedTokenAddress: '0x3f13FdC46d03888bE3451FF830EE6503014BecEf',
  //   earnContractAddress: '0x3f13FdC46d03888bE3451FF830EE6503014BecEf',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'cakev2-bsw-wbnb',
  //   oraclePrice: 0,
  //   depositsPaused: false,
  //   status: 'active',
  //   platform: 'PancakeSwap',
  //   assets: ['BSW', 'BNB'],
  //   risks: [
  //     'COMPLEXITY_LOW',
  //     'BATTLE_TESTED',
  //     'MCAP_MICRO',
  //     'IL_LOW',
  //     'AUDIT',
  //     'CONTRACTS_VERIFIED',
  //   ],
  //   stratType: 'StratLP',
  //   addLiquidityUrl:
  //     'https://pancakeswap.finance/add/0x965F527D9159dCe6288a2219DB51fc6Eef120dD1/BNB',
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?outputCurrency=0x965F527D9159dCe6288a2219DB51fc6Eef120dD1',
  //   createdAt: 1621957523,
  // },
  // {
  //   id: 'cakev2-gmt-usdc',
  //   name: 'GMT-USDC LP',
  //   token: 'GMT-USDC LP',
  //   tokenDescription: 'PancakeSwap',
  //   tokenAddress: '0x007EC643C7Cc33a70C083fC305c283dd009C8b94',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooCakeV2GMT-USDC',
  //   earnedTokenAddress: '0xCc2ba0401799857e4e14bFEF6572f46fD5D0Ac23',
  //   earnContractAddress: '0xCc2ba0401799857e4e14bFEF6572f46fD5D0Ac23',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'cakev2-gmt-usdc',
  //   oraclePrice: 0,
  //   depositsPaused: false,
  //   status: 'active',
  //   platform: 'PancakeSwap',
  //   assets: ['GMT', 'USDC'],
  //   risks: [
  //     'COMPLEXITY_LOW',
  //     'BATTLE_TESTED',
  //     'MCAP_MICRO',
  //     'IL_HIGH',
  //     'AUDIT',
  //     'CONTRACTS_VERIFIED',
  //   ],
  //   stratType: 'StratLP',
  //   addLiquidityUrl:
  //     'https://pancakeswap.finance/add/0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d/0x3019BF2a2eF8040C242C9a4c5c4BD4C81678b2A1',
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?outputCurrency=0x3019BF2a2eF8040C242C9a4c5c4BD4C81678b2A1',
  //   createdAt: 1647719886,
  // },
  // {
  //   id: 'cakev2-raca-busd',
  //   name: 'RACA-BUSD LP',
  //   token: 'RACA-BUSD LP',
  //   tokenDescription: 'PancakeSwap',
  //   tokenAddress: '0x8e744Ec2795c8B836689d1b4EBE1489204357dAC',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooCakeV2RACA-BUSD',
  //   earnedTokenAddress: '0xF390114CBB24D1E258e87F274A7b840f11cb0Ac4',
  //   earnContractAddress: '0xF390114CBB24D1E258e87F274A7b840f11cb0Ac4',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'cakev2-raca-busd',
  //   oraclePrice: 0,
  //   depositsPaused: false,
  //   status: 'active',
  //   platform: 'PancakeSwap',
  //   assets: ['RACA', 'BUSD'],
  //   risks: ['COMPLEXITY_LOW', 'BATTLE_TESTED', 'MCAP_MEDIUM', 'AUDIT', 'CONTRACTS_VERIFIED'],
  //   stratType: 'StratLP',
  //   addLiquidityUrl:
  //     'https://pancakeswap.finance/add/0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56/0x12BB890508c125661E03b09EC06E404bc9289040',
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?outputCurrency=0x12BB890508c125661E03b09EC06E404bc9289040',
  //   createdAt: 1645891282,
  // },
  // {
  //   id: 'baby-avax-usdt',
  //   name: 'AVAX-USDT LP',
  //   token: 'AVAX-USDT LP',
  //   tokenDescription: 'BabySwap',
  //   tokenAddress: '0x74c4DA0DAca1A9e52Faec732d96BC7dEA9FB3ac1',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooBabyAVAX-USDT',
  //   earnedTokenAddress: '0xC5BB189E9fb1Fb0Ce81a6F5B16Db4B6D30bC6dAB',
  //   earnContractAddress: '0xC5BB189E9fb1Fb0Ce81a6F5B16Db4B6D30bC6dAB',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'baby-avax-usdt',
  //   oraclePrice: 0,
  //   depositsPaused: false,
  //   status: 'active',
  //   platform: 'Other',
  //   assets: ['AVAX', 'USDT'],
  //   risks: [
  //     'COMPLEXITY_LOW',
  //     'BATTLE_TESTED',
  //     'IL_HIGH',
  //     'MCAP_MEDIUM',
  //     'AUDIT',
  //     'CONTRACTS_VERIFIED',
  //   ],
  //   stratType: 'StratLP',
  //   addLiquidityUrl:
  //     'https://exchange.babyswap.finance/#/add/0x1CE0c2827e2eF14D5C4f29a091d735A204794041/0x55d398326f99059fF775485246999027B3197955',
  //   buyTokenUrl:
  //     'https://exchange.babyswap.finance/#/swap?outputcurrency=0x55d398326f99059fF775485246999027B3197955&inputCurrency=0x1CE0c2827e2eF14D5C4f29a091d735A204794041',
  //   createdAt: 1644816966,
  // },
  // {
  //   id: 'charge-static-busd-eol',
  //   name: 'Static-BUSD LP',
  //   token: 'Static-BUSD LP',
  //   tokenDescription: 'Pancake (Charge)',
  //   tokenAddress: '0x69758726b04e527238B261ab00236AFE9F34929D',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooChargeSTATIC-BUSD',
  //   earnedTokenAddress: '0xaAe97E1B198406D691d75B5aA60Ac0b4e4b0E5CD',
  //   earnContractAddress: '0xaAe97E1B198406D691d75B5aA60Ac0b4e4b0E5CD',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'charge-static-busd',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   retireReason: 'rewards',
  //   platform: 'Other',
  //   assets: ['STATIC', 'BUSD'],
  //   risks: [
  //     'COMPLEXITY_LOW',
  //     'BATTLE_TESTED',
  //     'IL_LOW',
  //     'MCAP_MICRO',
  //     'AUDIT',
  //     'CONTRACTS_VERIFIED',
  //   ],
  //   stratType: 'StratLP',
  //   withdrawalFee: '0%',
  //   addLiquidityUrl:
  //     'https://pancakeswap.finance/add/0x7dEb9906BD1d77B410a56E5C23c36340Bd60C983/0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?outputCurrency=0x7dEb9906BD1d77B410a56E5C23c36340Bd60C983',
  //   createdAt: 1638426684,
  // },
  // {
  //   id: 'charge-charge-busd',
  //   name: 'Charge-BUSD LP',
  //   token: 'Charge-BUSD LP',
  //   tokenDescription: 'Pancake (Charge)',
  //   tokenAddress: '0xB73b4eeb4c4912C1d1869219A22660eB478B57eA',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooChargeCHARGE-BUSD',
  //   earnedTokenAddress: '0xDf988851e4CbA99565A9949706ff75Fd7f3b1b7A',
  //   earnContractAddress: '0xDf988851e4CbA99565A9949706ff75Fd7f3b1b7A',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'charge-charge-busd',
  //   oraclePrice: 0,
  //   depositsPaused: false,
  //   status: 'active',
  //   platform: 'Other',
  //   assets: ['CHARGE', 'BUSD'],
  //   risks: [
  //     'COMPLEXITY_LOW',
  //     'BATTLE_TESTED',
  //     'IL_HIGH',
  //     'MCAP_MICRO',
  //     'AUDIT',
  //     'CONTRACTS_VERIFIED',
  //   ],
  //   stratType: 'StratLP',
  //   withdrawalFee: '0%',
  //   addLiquidityUrl:
  //     'https://pancakeswap.finance/add/0x1C6bc8e962427dEb4106aE06A7fA2d715687395c/0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?outputCurrency=0x1C6bc8e962427dEb4106aE06A7fA2d715687395c',
  //   createdAt: 1638425869,
  // },
  // {
  //   id: 'baby-ftm-usdt',
  //   name: 'FTM-USDT LP',
  //   token: 'FTM-USDT LP',
  //   tokenDescription: 'BabySwap',
  //   tokenAddress: '0xc85471a1bC8ae143b29fcDe6539507FBED075b15',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooBabyFTM-USDT',
  //   earnedTokenAddress: '0x45b37a28d6eFb45C257Fc15d9F08AA7D32568eE7',
  //   earnContractAddress: '0x45b37a28d6eFb45C257Fc15d9F08AA7D32568eE7',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'baby-ftm-usdt',
  //   oraclePrice: 0,
  //   depositsPaused: false,
  //   status: 'active',
  //   platform: 'Other',
  //   assets: ['FTM', 'USDT'],
  //   risks: [
  //     'COMPLEXITY_LOW',
  //     'BATTLE_TESTED',
  //     'IL_HIGH',
  //     'MCAP_MEDIUM',
  //     'AUDIT',
  //     'CONTRACTS_VERIFIED',
  //   ],
  //   stratType: 'StratLP',
  //   addLiquidityUrl:
  //     'https://exchange.babyswap.finance/#/add/0x55d398326f99059fF775485246999027B3197955/0xAD29AbB318791D579433D831ed122aFeAf29dcfe',
  //   buyTokenUrl:
  //     'https://exchange.babyswap.finance/#/swap?inputCurrency=0x55d398326f99059fF775485246999027B3197955&outputCurrency=0xAD29AbB318791D579433D831ed122aFeAf29dcfe',
  //   createdAt: 1643664184,
  // },
  // {
  //   id: 'banana-sushi-eth',
  //   name: 'SUSHI-ETH LP',
  //   token: 'SUSHI-ETH ALP',
  //   tokenDescription: 'ApeSwap',
  //   tokenAddress: '0x044F2b275A344D4edfc3d98e1cb7c02B30e6484e',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooApeSUSHI-ETH',
  //   earnedTokenAddress: '0x42376cd79F916Fdae6225Dd647f154D055165d83',
  //   earnContractAddress: '0x42376cd79F916Fdae6225Dd647f154D055165d83',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'banana-sushi-eth',
  //   oraclePrice: 0,
  //   depositsPaused: false,
  //   status: 'active',
  //   platform: 'ApeSwap',
  //   assets: ['SUSHI', 'ETH'],
  //   risks: [
  //     'COMPLEXITY_LOW',
  //     'BATTLE_TESTED',
  //     'IL_HIGH',
  //     'MCAP_MEDIUM',
  //     'AUDIT',
  //     'CONTRACTS_VERIFIED',
  //   ],
  //   stratType: 'StratLP',
  //   addLiquidityUrl:
  //     'https://dex.apeswap.finance/#/add/0x947950bcc74888a40ffa2593c5798f11fc9124c4/0x2170ed0880ac9a755fd29b2688956bd959f933f8',
  //   buyTokenUrl:
  //     'https://dex.apeswap.finance/#/swap?inputCurrency=0x2170ed0880ac9a755fd29b2688956bd959f933f8&outputCurrency=0x947950bcc74888a40ffa2593c5798f11fc9124c4',
  //   createdAt: 1643427366,
  // },
  // {
  //   id: 'cakev2-cake-bnb',
  //   name: 'CAKE-BNB LP',
  //   token: 'CAKE-BNB LP2',
  //   tokenDescription: 'PancakeSwap',
  //   tokenAddress: '0x0eD7e52944161450477ee417DE9Cd3a859b14fD0',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooCakeV2CAKE-BNB',
  //   earnedTokenAddress: '0xb26642B6690E4c4c9A6dAd6115ac149c700C7dfE',
  //   earnContractAddress: '0xb26642B6690E4c4c9A6dAd6115ac149c700C7dfE',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'cakev2-cake-bnb',
  //   oraclePrice: 0,
  //   depositsPaused: false,
  //   status: 'active',
  //   platform: 'PancakeSwap',
  //   assets: ['CAKE', 'BNB'],
  //   risks: [
  //     'COMPLEXITY_LOW',
  //     'BATTLE_TESTED',
  //     'IL_HIGH',
  //     'MCAP_LARGE',
  //     'AUDIT',
  //     'CONTRACTS_VERIFIED',
  //   ],
  //   stratType: 'StratLP',
  //   addLiquidityUrl:
  //     'https://pancakeswap.finance/add/0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82/BNB',
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?outputCurrency=0x5B6DcF557E2aBE2323c48445E8CC948910d8c2c9',
  //   createdAt: 1619385194,
  // },
  // {
  //   id: 'cakev2-ertha-wbnb-eol',
  //   name: 'ERTHA-BNB LP',
  //   token: 'ERTHA-BNB LP',
  //   tokenDescription: 'PancakeSwap',
  //   tokenAddress: '0x70531B39E2Bb4d8dA59E2Ce41a98eBA2990F8497',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooCakeV2ERTHA-BNB',
  //   earnedTokenAddress: '0x74aAA646a7ccCCE0e58a6C3137266a227F12b6F9',
  //   earnContractAddress: '0x74aAA646a7ccCCE0e58a6C3137266a227F12b6F9',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'cakev2-ertha-wbnb',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   retireReason: 'rewards',
  //   platform: 'PancakeSwap',
  //   assets: ['ERTHA', 'BNB'],
  //   risks: [
  //     'COMPLEXITY_LOW',
  //     'BATTLE_TESTED',
  //     'IL_HIGH',
  //     'MCAP_MICRO',
  //     'AUDIT',
  //     'CONTRACTS_VERIFIED',
  //   ],
  //   stratType: 'StratLP',
  //   addLiquidityUrl:
  //     'https://pancakeswap.finance/add/0x62823659d09F9F9D2222058878f89437425eB261/BNB',
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?outputCurrency=0x62823659d09F9F9D2222058878f89437425eB261',
  //   createdAt: 1643275444,
  // },
  // {
  //   id: 'cakev2-fuse-wbnb-eol',
  //   name: 'FUSE-BNB LP',
  //   token: 'FUSE-BNB LP',
  //   tokenDescription: 'PancakeSwap',
  //   tokenAddress: '0x6483F166b9E4310A165a55FEa04F867499aded06',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooCakeV2FUSE-BNB',
  //   earnedTokenAddress: '0x834DdeE66c381811ce0233B4669ddC3b97fd08cd',
  //   earnContractAddress: '0x834DdeE66c381811ce0233B4669ddC3b97fd08cd',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'cakev2-fuse-wbnb',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   retireReason: 'rewards',
  //   platform: 'PancakeSwap',
  //   assets: ['FUSE', 'BNB'],
  //   risks: [
  //     'COMPLEXITY_LOW',
  //     'BATTLE_TESTED',
  //     'IL_HIGH',
  //     'MCAP_LARGE',
  //     'AUDIT',
  //     'CONTRACTS_VERIFIED',
  //   ],
  //   stratType: 'StratLP',
  //   addLiquidityUrl:
  //     'https://pancakeswap.finance/add/0x5857c96DaE9cF8511B08Cb07f85753C472D36Ea3/BNB',
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?outputCurrency=0x5857c96DaE9cF8511B08Cb07f85753C472D36Ea3',
  //   createdAt: 1642793949,
  // },
  // {
  //   id: 'baby-aot-usdt-eol',
  //   name: 'A.O.T-USDT LP',
  //   token: 'AOT-USDT LP',
  //   tokenDescription: 'BabySwap',
  //   tokenAddress: '0x0866Bbea720FBFE4B22e39B8fd318982Bf6DD448',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooBabyAOT-USDT',
  //   earnedTokenAddress: '0xC9215F674876da17A671f22C2083E200eD78D0C8',
  //   earnContractAddress: '0xC9215F674876da17A671f22C2083E200eD78D0C8',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'baby-aot-usdt',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   retireReason: 'rewards',
  //   platform: 'Other',
  //   assets: ['AOT', 'USDT'],
  //   risks: [
  //     'COMPLEXITY_LOW',
  //     'BATTLE_TESTED',
  //     'IL_HIGH',
  //     'MCAP_MICRO',
  //     'AUDIT',
  //     'CONTRACTS_VERIFIED',
  //   ],
  //   stratType: 'StratLP',
  //   addLiquidityUrl:
  //     'https://exchange.babyswap.finance/#/add/0x9589014F7a8547B89A6331eEEe32b7fBd5852af9/0x55d398326f99059fF775485246999027B3197955',
  //   buyTokenUrl:
  //     'https://exchange.babyswap.finance/#/swap?outputCurrency=0x9589014F7a8547B89A6331eEEe32b7fBd5852af9',
  //   createdAt: 1641217338,
  // },
  // {
  //   id: 'mdex-lac-busd-eol',
  //   name: 'LAC-BUSD LP',
  //   token: 'LAC-BUSD LP',
  //   tokenDescription: 'Mdex',
  //   tokenAddress: '0xa269E050DD2262E7BdC8A481D76880A562dD1d5E',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooMdexLAC-BUSD',
  //   earnedTokenAddress: '0xa0a2609800F5FfA4b9FaF8286fD44070BeD6931C',
  //   earnContractAddress: '0xa0a2609800F5FfA4b9FaF8286fD44070BeD6931C',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'mdex-lac-busd',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   retireReason: 'rewards',
  //   platform: 'Mdex',
  //   assets: ['LAC', 'BUSD'],
  //   risks: [
  //     'COMPLEXITY_LOW',
  //     'BATTLE_TESTED',
  //     'IL_HIGH',
  //     'MCAP_MICRO',
  //     'AUDIT',
  //     'CONTRACTS_VERIFIED',
  //   ],
  //   stratType: 'StratLP',
  //   buyTokenUrl:
  //     'https://bsc.mdex.me/#/swap?inputCurrency=0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56&outputCurrency=0xe6f079E74000a0AFc517c1EFf9624d866d163B75',
  //   addLiquidityUrl:
  //     'https://bsc.mdex.me/#/add/0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56/0xe6f079E74000a0AFc517c1EFf9624d866d163B75',
  //   createdAt: 1642253102,
  // },
  // {
  //   id: 'dibs-dibs-bnb',
  //   name: 'DIBS-BNB',
  //   token: 'DIBS-BNB LP',
  //   tokenDescription: 'Pancake (Dibs)',
  //   tokenAddress: '0x9bEBe118018d0De55b00787B5eeABB9EDa8A9e0A',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooDibsDIBS-BNB',
  //   earnedTokenAddress: '0xd3bcD18c24F42a9928651BE0b7fF6C9Ab56B3B4a',
  //   earnContractAddress: '0xd3bcD18c24F42a9928651BE0b7fF6C9Ab56B3B4a',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'dibs-dibs-bnb',
  //   oraclePrice: 0,
  //   depositsPaused: false,
  //   status: 'active',
  //   platform: 'Other',
  //   assets: ['DIBS', 'BNB'],
  //   risks: [
  //     'COMPLEXITY_LOW',
  //     'BATTLE_TESTED',
  //     'IL_LOW',
  //     'MCAP_MICRO',
  //     'AUDIT',
  //     'CONTRACTS_VERIFIED',
  //   ],
  //   stratType: 'StratLP',
  //   withdrawalFee: '0%',
  //   addLiquidityUrl:
  //     'https://pancakeswap.finance/add/BNB/0xFd81Ef21EA7CF1dC00e9c6Dd261B4F3BE0341d5c',
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?outputCurrency=0xFd81Ef21EA7CF1dC00e9c6Dd261B4F3BE0341d5c',
  //   createdAt: 1641804594,
  // },
  // {
  //   id: 'dibs-dshare-bnb',
  //   name: 'DSHARE-BNB',
  //   token: 'DSHARE-BNB LP',
  //   tokenDescription: 'Pancake (Dibs)',
  //   tokenAddress: '0x5998AF8868e5e4Fbd7c60dA221b76b201e441612',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooDibsDSHARE-BNB',
  //   earnedTokenAddress: '0xFf494b7CB341311f86dA21A49C5fF176637aA85e',
  //   earnContractAddress: '0xFf494b7CB341311f86dA21A49C5fF176637aA85e',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'dibs-dshare-bnb',
  //   oraclePrice: 0,
  //   depositsPaused: false,
  //   status: 'active',
  //   platform: 'Other',
  //   assets: ['DSHARE', 'BNB'],
  //   risks: [
  //     'COMPLEXITY_LOW',
  //     'BATTLE_TESTED',
  //     'IL_HIGH',
  //     'MCAP_MICRO',
  //     'AUDIT',
  //     'CONTRACTS_VERIFIED',
  //   ],
  //   stratType: 'StratLP',
  //   withdrawalFee: '0%',
  //   addLiquidityUrl:
  //     'https://pancakeswap.finance/add/BNB/0x26d3163b165BE95137CEe97241E716b2791a7572',
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?outputCurrency=0x26d3163b165BE95137CEe97241E716b2791a7572',
  //   createdAt: 1641805104,
  // },
  // {
  //   id: 'banana-nfty-wbnb',
  //   name: 'NFTY-BNB LP',
  //   token: 'NFTY-BNB BLP',
  //   tokenDescription: 'ApeSwap',
  //   tokenAddress: '0x884BE30e2c95b9cFed614aD2B5Edf40AF2A144ad',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooApeNFTY-BNB',
  //   earnedTokenAddress: '0x69476255A2703Df58C0F6Bb60D1c5deC9a8406C1',
  //   earnContractAddress: '0x69476255A2703Df58C0F6Bb60D1c5deC9a8406C1',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'banana-nfty-wbnb',
  //   oraclePrice: 0,
  //   depositsPaused: false,
  //   status: 'active',
  //   platform: 'ApeSwap',
  //   assets: ['NFTY', 'BNB'],
  //   risks: [
  //     'COMPLEXITY_LOW',
  //     'BATTLE_TESTED',
  //     'IL_HIGH',
  //     'MCAP_MICRO',
  //     'AUDIT',
  //     'CONTRACTS_VERIFIED',
  //   ],
  //   stratType: 'StratLP',
  //   addLiquidityUrl:
  //     'https://dex.apeswap.finance/#/add/ETH/0x5774B2fc3e91aF89f89141EacF76545e74265982',
  //   buyTokenUrl:
  //     'https://dex.apeswap.finance/#/swap?outputCurrency=0x5774B2fc3e91aF89f89141EacF76545e74265982',
  //   createdAt: 1633961805,
  // },
  // {
  //   id: 'cakev2-insur-wbnb-eol',
  //   name: 'INSUR-BNB LP',
  //   token: 'INSUR-BNB LP2',
  //   tokenDescription: 'PancakeSwap',
  //   tokenAddress: '0xD01bf29EdCA0285A004a25e325A449ba56e5926E',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooCakeV2INSUR-BNB',
  //   earnedTokenAddress: '0x4E6AB04823B5eFEEF3c51C6AbeE0F4B8306f7AcF',
  //   earnContractAddress: '0x4E6AB04823B5eFEEF3c51C6AbeE0F4B8306f7AcF',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'cakev2-insur-wbnb',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   retireReason: 'rewards',
  //   platform: 'PancakeSwap',
  //   assets: ['INSUR', 'BNB'],
  //   risks: [
  //     'COMPLEXITY_LOW',
  //     'BATTLE_TESTED',
  //     'IL_HIGH',
  //     'MCAP_MEDIUM',
  //     'AUDIT',
  //     'CONTRACTS_VERIFIED',
  //   ],
  //   stratType: 'StratLP',
  //   addLiquidityUrl:
  //     'https://pancakeswap.finance/add/BNB/0x3192CCDdf1CDcE4Ff055EbC80f3F0231b86A7E30',
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?outputCurrency=0x3192CCDdf1CDcE4Ff055EbC80f3F0231b86A7E30',
  //   createdAt: 1641572289,
  // },
  // {
  //   id: 'cakev2-wbnb-gm-eol',
  //   name: 'GM-BNB LP',
  //   token: 'GM-BNB LP2',
  //   tokenDescription: 'PancakeSwap',
  //   tokenAddress: '0x1C640a98a0c62120B0AD23C15FfF8dC1a2Fb9C4D',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooCakeV2GM-BNB',
  //   earnedTokenAddress: '0xb7aD14a25163D763B7d339d97F29f2Cc59B83C8e',
  //   earnContractAddress: '0xb7aD14a25163D763B7d339d97F29f2Cc59B83C8e',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'cakev2-wbnb-gm',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   retireReason: 'tvl',
  //   platform: 'PancakeSwap',
  //   assets: ['GM', 'BNB'],
  //   risks: [
  //     'COMPLEXITY_LOW',
  //     'BATTLE_TESTED',
  //     'IL_HIGH',
  //     'MCAP_SMALL',
  //     'AUDIT',
  //     'CONTRACTS_VERIFIED',
  //   ],
  //   stratType: 'StratLP',
  //   addLiquidityUrl:
  //     'https://pancakeswap.finance/add/BNB/0xe2604C9561D490624AA35e156e65e590eB749519',
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?outputCurrency=0xe2604C9561D490624AA35e156e65e590eB749519',
  //   createdAt: 1641572043,
  // },
  // {
  //   id: 'cakev2-woop-wbnb-eol',
  //   name: 'WOOP-BNB LP',
  //   token: 'WOOP-BNB LP2',
  //   tokenDescription: 'PancakeSwap',
  //   tokenAddress: '0x2AE94A6C768D59f5DDc25bd7f12C7cBE1D51dc04',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooCakeV2WOOP-BNB',
  //   earnedTokenAddress: '0x7F4D5dB01f640e73dfc4205244cC5799948e426e',
  //   earnContractAddress: '0x7F4D5dB01f640e73dfc4205244cC5799948e426e',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'cakev2-woop-wbnb',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   retireReason: 'tvl',
  //   platform: 'PancakeSwap',
  //   assets: ['WOOP', 'BNB'],
  //   risks: [
  //     'COMPLEXITY_LOW',
  //     'BATTLE_TESTED',
  //     'IL_HIGH',
  //     'MCAP_SMALL',
  //     'AUDIT',
  //     'CONTRACTS_VERIFIED',
  //   ],
  //   stratType: 'StratLP',
  //   addLiquidityUrl:
  //     'https://pancakeswap.finance/add/BNB/0x8b303d5BbfBbf46F1a4d9741E491e06986894e18',
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?outputCurrency=0x8b303d5BbfBbf46F1a4d9741E491e06986894e18',
  //   createdAt: 1641571866,
  // },
  // {
  //   id: 'cakev2-high-busd',
  //   name: 'HIGH-BUSD LP',
  //   token: 'HIGH-BUSD LP2',
  //   tokenDescription: 'PancakeSwap',
  //   tokenAddress: '0xe98ac95A1dB2fCaaa9c7D4ba7ecfCE4877ca2bEa',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooCakeV2HIGH-BUSD',
  //   earnedTokenAddress: '0x39699485b9a96B6f6aD6f8b4c06F5AF8e71cDeb8',
  //   earnContractAddress: '0x39699485b9a96B6f6aD6f8b4c06F5AF8e71cDeb8',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'cakev2-high-busd',
  //   oraclePrice: 0,
  //   depositsPaused: false,
  //   status: 'active',
  //   platform: 'PancakeSwap',
  //   assets: ['HIGH', 'BUSD'],
  //   risks: [
  //     'COMPLEXITY_LOW',
  //     'BATTLE_TESTED',
  //     'IL_HIGH',
  //     'MCAP_SMALL',
  //     'AUDIT',
  //     'CONTRACTS_VERIFIED',
  //   ],
  //   stratType: 'StratLP',
  //   addLiquidityUrl:
  //     'https://pancakeswap.finance/add/0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56/0x5f4Bde007Dc06b867f86EBFE4802e34A1fFEEd63',
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?outputCurrency=0x5f4Bde007Dc06b867f86EBFE4802e34A1fFEEd63',
  //   createdAt: 1641571686,
  // },
  // {
  //   id: 'cakev2-ccar-wbnb-eol',
  //   name: 'CCAR-BNB LP',
  //   token: 'CCAR-BNB LP2',
  //   tokenDescription: 'PancakeSwap',
  //   tokenAddress: '0x845d301C864d48027DB73ec4394e6DDBE52Cbc39',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooCakeV2CCAR-BNB',
  //   earnedTokenAddress: '0x964EF09C33BA7Acc0B53e522A6d4664a993B4Fce',
  //   earnContractAddress: '0x964EF09C33BA7Acc0B53e522A6d4664a993B4Fce',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'cakev2-ccar-wbnb',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   retireReason: 'rewards',
  //   platform: 'PancakeSwap',
  //   assets: ['CCAR', 'BNB'],
  //   risks: [
  //     'COMPLEXITY_LOW',
  //     'BATTLE_TESTED',
  //     'IL_HIGH',
  //     'MCAP_SMALL',
  //     'AUDIT',
  //     'CONTRACTS_VERIFIED',
  //   ],
  //   stratType: 'StratLP',
  //   addLiquidityUrl:
  //     'https://pancakeswap.finance/add/BNB/0x50332bdca94673F33401776365b66CC4e81aC81d',
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?outputCurrency=0x50332bdca94673F33401776365b66CC4e81aC81d',
  //   createdAt: 1641571293,
  // },
  // {
  //   id: 'cakev2-wbnb-dpt-eol',
  //   name: 'DPT-BNB LP',
  //   token: 'DPT-BNB LP2',
  //   tokenDescription: 'PancakeSwap',
  //   tokenAddress: '0x141e9558f66Cc21c93628400cCa7d830c15c2c24',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooCakeV2DPT-BNB',
  //   earnedTokenAddress: '0x9BDE33da0c9D029bEf653Fa0C374d886b894b072',
  //   earnContractAddress: '0x9BDE33da0c9D029bEf653Fa0C374d886b894b072',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'cakev2-wbnb-dpt',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   retireReason: 'tvl',
  //   platform: 'PancakeSwap',
  //   assets: ['DPT', 'BNB'],
  //   risks: [
  //     'COMPLEXITY_LOW',
  //     'BATTLE_TESTED',
  //     'IL_HIGH',
  //     'MCAP_SMALL',
  //     'AUDIT',
  //     'CONTRACTS_VERIFIED',
  //   ],
  //   stratType: 'StratLP',
  //   addLiquidityUrl:
  //     'https://pancakeswap.finance/add/BNB/0xE69cAef10A488D7AF31Da46c89154d025546e990',
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?outputCurrency=0xE69cAef10A488D7AF31Da46c89154d025546e990',
  //   createdAt: 1641571143,
  // },
  // {
  //   id: 'cakev2-thg-wbnb',
  //   name: 'THG-BNB LP',
  //   token: 'THG-BNB LP2',
  //   tokenDescription: 'PancakeSwap',
  //   tokenAddress: '0x486697ae24469cB1122F537924Aa46E705B142Aa',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooCakeV2THG-BNB',
  //   earnedTokenAddress: '0xaDcED4C0c7fBd616946dCED244DB4Cb01F1Fa750',
  //   earnContractAddress: '0xaDcED4C0c7fBd616946dCED244DB4Cb01F1Fa750',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'cakev2-thg-wbnb',
  //   oraclePrice: 0,
  //   depositsPaused: false,
  //   status: 'active',
  //   platform: 'PancakeSwap',
  //   assets: ['THG', 'BNB'],
  //   risks: [
  //     'COMPLEXITY_LOW',
  //     'BATTLE_TESTED',
  //     'IL_HIGH',
  //     'MCAP_SMALL',
  //     'AUDIT',
  //     'CONTRACTS_VERIFIED',
  //   ],
  //   stratType: 'StratLP',
  //   addLiquidityUrl:
  //     'https://pancakeswap.finance/add/BNB/0x9fD87aEfe02441B123c3c32466cD9dB4c578618f',
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?outputCurrency=0x9fD87aEfe02441B123c3c32466cD9dB4c578618f',
  //   createdAt: 1641570951,
  // },
  // {
  //   id: 'baby-usdt-milk',
  //   name: 'MILK-USDT LP',
  //   token: 'MILK-USDT LP',
  //   tokenDescription: 'BabySwap',
  //   tokenAddress: '0xDB6c248902AD2722A5E01E9689824c28bd0A5f75',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooBabyUSDT-MILK',
  //   earnedTokenAddress: '0xBBEfaf1f7585EDc59180fDdc5bAf46b8A5D7A206',
  //   earnContractAddress: '0xBBEfaf1f7585EDc59180fDdc5bAf46b8A5D7A206',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'baby-usdt-milk',
  //   oraclePrice: 0,
  //   depositsPaused: false,
  //   status: 'active',
  //   platform: 'Other',
  //   assets: ['MILK', 'USDT'],
  //   risks: [
  //     'COMPLEXITY_LOW',
  //     'BATTLE_TESTED',
  //     'IL_HIGH',
  //     'MCAP_MICRO',
  //     'AUDIT',
  //     'CONTRACTS_VERIFIED',
  //   ],
  //   stratType: 'StratLP',
  //   addLiquidityUrl:
  //     'https://exchange.babyswap.finance/#/add/0x55d398326f99059fF775485246999027B3197955/0xBf37f781473f3b50E82C668352984865eac9853f',
  //   buyTokenUrl:
  //     'https://exchange.babyswap.finance/#/swap?outputCurrency=0xBf37f781473f3b50E82C668352984865eac9853f',
  //   createdAt: 1640097622,
  // },
  // {
  //   id: 'ellipsis-renbtc-eol',
  //   logo: 'uncategorized/epsRENBTC.png',
  //   name: 'renBTC/BTCB',
  //   token: 'renBTC-BTCB EPS LP',
  //   tokenDescription: 'Ellipsis',
  //   tokenAddress: '0x2a435Ecb3fcC0E316492Dc1cdd62d0F189be5640',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'MooEllipsisRenBTC',
  //   earnedTokenAddress: '0x24AE9e5424575690aCab61a384B6A76d69F4f89c',
  //   earnContractAddress: '0x24AE9e5424575690aCab61a384B6A76d69F4f89c',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'ellipsis-renbtc',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   retireReason: 'rewards',
  //   platform: 'Ellipsis',
  //   assets: ['renBTC', 'BTCB'],
  //   risks: [
  //     'COMPLEXITY_LOW',
  //     'BATTLE_TESTED',
  //     'IL_NONE',
  //     'MCAP_LARGE',
  //     'AUDIT',
  //     'CONTRACTS_VERIFIED',
  //   ],
  //   stratType: 'StratLP',
  //   addLiquidityUrl: 'https://ellipsis.finance/pool/btceps',
  //   createdAt: 1618661786,
  // },
  // {
  //   id: 'banana-banana-busd',
  //   logo: 'degens/banana-busd.svg',
  //   name: 'BANANA-BUSD LP',
  //   token: 'BANANA-BUSD LP',
  //   tokenDescription: 'ApeSwap',
  //   tokenAddress: '0x7Bd46f6Da97312AC2DBD1749f82E202764C0B914',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooApeBANANA-BUSD',
  //   earnedTokenAddress: '0x651b496bCe2C184282a2B9AeF3f8BaDBAC36Dd7f',
  //   earnContractAddress: '0x651b496bCe2C184282a2B9AeF3f8BaDBAC36Dd7f',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'banana-banana-busd',
  //   oraclePrice: 0,
  //   depositsPaused: false,
  //   status: 'active',
  //   platform: 'ApeSwap',
  //   assets: ['BANANA', 'BUSD'],
  //   addLiquidityUrl:
  //     'https://dex.apeswap.finance/#/add/0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56/0x603c7f932ED1fc6575303D8Fb018fDCBb0f39a95',
  //   buyTokenUrl:
  //     'https://dex.apeswap.finance/#/swap?inputCurrency=0x603c7f932ed1fc6575303d8fb018fdcbb0f39a95&outputCurrency=0xe9e7cea3dedca5984780bafc599bd69add087d56',
  //   createdAt: 1614766209,
  // },
  // {
  //   id: 'banana-crush-wbnb-eol',
  //   name: 'CRUSH-BNB LP',
  //   token: 'CRUSH-BNB BLP',
  //   tokenDescription: 'ApeSwap',
  //   tokenAddress: '0x8A10489f1255fb63217Be4cc96B8F4CD4D42a469',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooApeCRUSH-BNB',
  //   earnedTokenAddress: '0x566b68521968569aC04873c4CCFfa45C8d653463',
  //   earnContractAddress: '0x566b68521968569aC04873c4CCFfa45C8d653463',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'banana-crush-wbnb',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   retireReason: 'rewards',
  //   platform: 'ApeSwap',
  //   assets: ['CRUSH', 'BNB'],
  //   risks: [
  //     'COMPLEXITY_LOW',
  //     'BATTLE_TESTED',
  //     'IL_HIGH',
  //     'MCAP_MICRO',
  //     'AUDIT',
  //     'CONTRACTS_VERIFIED',
  //   ],
  //   stratType: 'StratLP',
  //   addLiquidityUrl:
  //     'https://dex.apeswap.finance/#/add/ETH/0x0Ef0626736c2d484A792508e99949736D0AF807e',
  //   buyTokenUrl:
  //     'https://dex.apeswap.finance/#/swap?outputCurrency=0x0Ef0626736c2d484A792508e99949736D0AF807e',
  //   createdAt: 1639735318,
  // },
  // {
  //   id: 'banana-bnb-stars',
  //   name: 'STARS-BNB LP',
  //   token: 'STARS-BNB LP',
  //   tokenDescription: 'ApeSwap',
  //   tokenAddress: '0xBc8a4caD743d87e8754Fd5F704C62E378802CBfF',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooApeBNB-STARS',
  //   earnedTokenAddress: '0xd6Cf54a0545675AdB0aa5175b9461Cd3d6a61f8e',
  //   earnContractAddress: '0xd6Cf54a0545675AdB0aa5175b9461Cd3d6a61f8e',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'banana-bnb-stars',
  //   oraclePrice: 0,
  //   depositsPaused: false,
  //   status: 'active',
  //   platform: 'ApeSwap',
  //   assets: ['STARS', 'BNB'],
  //   addLiquidityUrl:
  //     'https://dex.apeswap.finance/#/add/0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c/0xbD83010eB60F12112908774998F65761cf9f6f9a',
  //   buyTokenUrl:
  //     'https://dex.apeswap.finance/#/swap?inputCurrency=0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c&outputCurrency=0xbD83010eB60F12112908774998F65761cf9f6f9a',
  //   createdAt: 1627616650,
  // },
  // {
  //   id: 'bison-bison-bnb-eol',
  //   name: 'BISON-BNB LP',
  //   token: 'BISON-BNB ALP',
  //   tokenDescription: 'ApeSwap (BiShares)',
  //   tokenAddress: '0xEC1214Ee197304c17EB9e427E246a4Fd37BA718E',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooBisonBISON-BNB',
  //   earnedTokenAddress: '0x813EBFD51604E0066bFA4E0Bf6d2324eA8CebE60',
  //   earnContractAddress: '0x813EBFD51604E0066bFA4E0Bf6d2324eA8CebE60',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'bison-bison-bnb',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   retireReason: 'rewards',
  //   platform: 'Other',
  //   assets: ['BISON', 'BNB'],
  //   risks: [
  //     'COMPLEXITY_LOW',
  //     'BATTLE_TESTED',
  //     'IL_HIGH',
  //     'MCAP_MICRO',
  //     'AUDIT',
  //     'CONTRACTS_VERIFIED',
  //   ],
  //   stratType: 'StratLP',
  //   addLiquidityUrl:
  //     'https://dex.apeswap.finance/#/add/ETH/0x19A6Da6e382b85F827088092a3DBe864d9cCba73',
  //   buyTokenUrl:
  //     'https://dex.apeswap.finance/#/swap?outputCurrency=0x19A6Da6e382b85F827088092a3DBe864d9cCba73',
  //   createdAt: 1637682462,
  // },
  // {
  //   id: 'cakev2-nabox-busd-eol',
  //   name: 'NABOX-BUSD LP',
  //   token: 'NABOX-BUSD LP2',
  //   tokenDescription: 'PancakeSwap',
  //   tokenAddress: '0x29b4abb0f8734EA672a0e82FA47998F710B6A07a',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooCakeV2NABOX-BUSD',
  //   earnedTokenAddress: '0x9D03b1115c6B2e4a7A29bA45c0Cb463965b77F20',
  //   earnContractAddress: '0x9D03b1115c6B2e4a7A29bA45c0Cb463965b77F20',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'cakev2-nabox-busd',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   retireReason: 'rewards',
  //   platform: 'PancakeSwap',
  //   assets: ['NABOX', 'BUSD'],
  //   risks: [
  //     'COMPLEXITY_LOW',
  //     'BATTLE_TESTED',
  //     'IL_LOW',
  //     'MCAP_SMALL',
  //     'AUDIT',
  //     'CONTRACTS_VERIFIED',
  //   ],
  //   stratType: 'StratLP',
  //   addLiquidityUrl:
  //     'https://pancakeswap.finance/add/0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56/0x755f34709E369D37C6Fa52808aE84A32007d1155',
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?outputCurrency=0x755f34709E369D37C6Fa52808aE84A32007d1155',
  //   createdAt: 1638803417,
  // },
  // {
  //   id: 'cakev2-santos-wbnb-eol',
  //   name: 'SANTOS-BNB LP',
  //   token: 'SANTOS-BNB LP2',
  //   tokenDescription: 'PancakeSwap',
  //   tokenAddress: '0x06043B346450BbCfdE066ebc39fdc264FdFFeD74',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooCakeV2SANTOS-BNB',
  //   earnedTokenAddress: '0x710A1A9d868a566E3624e1522B531402e144DcaB',
  //   earnContractAddress: '0x710A1A9d868a566E3624e1522B531402e144DcaB',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'cakev2-santos-wbnb',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   retireReason: 'tvl',
  //   platform: 'PancakeSwap',
  //   assets: ['SANTOS', 'BNB'],
  //   risks: [
  //     'COMPLEXITY_LOW',
  //     'BATTLE_TESTED',
  //     'IL_LOW',
  //     'MCAP_SMALL',
  //     'AUDIT',
  //     'CONTRACTS_VERIFIED',
  //   ],
  //   stratType: 'StratLP',
  //   addLiquidityUrl:
  //     'https://pancakeswap.finance/add/BNB/0xA64455a4553C9034236734FadDAddbb64aCE4Cc7',
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?outputCurrency=0xA64455a4553C9034236734FadDAddbb64aCE4Cc7',
  //   createdAt: 1638803879,
  // },
  // {
  //   id: 'cakev2-quidd-wbnb-eol',
  //   name: 'QUIDD-BNB LP',
  //   token: 'QUIDD-BNB LP2',
  //   tokenDescription: 'PancakeSwap',
  //   tokenAddress: '0xD6d206F59cC5a3BfA4Cc10bc8Ba140ac37Ad1C89',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooCakeV2QUIDD-BNB',
  //   earnedTokenAddress: '0x2Fc49fDA984333e618b4980eB97086Eb37D7C333',
  //   earnContractAddress: '0x2Fc49fDA984333e618b4980eB97086Eb37D7C333',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'cakev2-quidd-wbnb',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   retireReason: 'rewards',
  //   platform: 'PancakeSwap',
  //   assets: ['QUIDD', 'BNB'],
  //   risks: [
  //     'COMPLEXITY_LOW',
  //     'BATTLE_TESTED',
  //     'IL_LOW',
  //     'MCAP_SMALL',
  //     'AUDIT',
  //     'CONTRACTS_VERIFIED',
  //   ],
  //   stratType: 'StratLP',
  //   addLiquidityUrl:
  //     'https://pancakeswap.finance/add/BNB/0x7961Ade0a767c0E5B67Dd1a1F78ba44F727642Ed',
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?outputCurrency=0x7961Ade0a767c0E5B67Dd1a1F78ba44F727642Ed',
  //   createdAt: 1638804800,
  // },
  // {
  //   id: 'cakev2-zoo-wbnb-eol',
  //   name: 'Zoo-BNB LP',
  //   token: 'Zoo-BNB LP2',
  //   tokenDescription: 'PancakeSwap',
  //   tokenAddress: '0x85e5889Fc3Ed01B4e8B56bbc717D7643294d2c31',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooCakeV2Zoo-BNB',
  //   earnedTokenAddress: '0x8661D810CBc40156Cb4d01923d5af110876ED24B',
  //   earnContractAddress: '0x8661D810CBc40156Cb4d01923d5af110876ED24B',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'cakev2-zoo-wbnb',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   retireReason: 'rewards',
  //   platform: 'PancakeSwap',
  //   assets: ['bZoo', 'BNB'],
  //   risks: [
  //     'COMPLEXITY_LOW',
  //     'BATTLE_TESTED',
  //     'IL_LOW',
  //     'MCAP_SMALL',
  //     'AUDIT',
  //     'CONTRACTS_VERIFIED',
  //   ],
  //   stratType: 'StratLP',
  //   addLiquidityUrl:
  //     'https://pancakeswap.finance/add/BNB/0x1D229B958D5DDFca92146585a8711aECbE56F095',
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?outputCurrency=0x1D229B958D5DDFca92146585a8711aECbE56F095',
  //   createdAt: 1638807261,
  // },
  // {
  //   id: 'banana-wbnb-ceek',
  //   name: 'CEEK-BNB LP',
  //   token: 'CEEK-BNB BLP',
  //   tokenDescription: 'ApeSwap',
  //   tokenAddress: '0x119D6Ebe840966c9Cf4fF6603E76208d30BA2179',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooApeCEEK-BNB',
  //   earnedTokenAddress: '0xd244fD01Ca42415b5B3f9dCe164C762eB28d6eA4',
  //   earnContractAddress: '0xd244fD01Ca42415b5B3f9dCe164C762eB28d6eA4',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'banana-wbnb-ceek',
  //   oraclePrice: 0,
  //   depositsPaused: false,
  //   status: 'active',
  //   platform: 'ApeSwap',
  //   assets: ['CEEK', 'BNB'],
  //   risks: [
  //     'COMPLEXITY_LOW',
  //     'BATTLE_TESTED',
  //     'IL_HIGH',
  //     'MCAP_MICRO',
  //     'AUDIT',
  //     'CONTRACTS_VERIFIED',
  //   ],
  //   stratType: 'StratLP',
  //   addLiquidityUrl:
  //     'https://dex.apeswap.finance/#/add/ETH/0xe0F94Ac5462997D2BC57287Ac3a3aE4C31345D66',
  //   buyTokenUrl:
  //     'https://dex.apeswap.finance/#/swap?outputCurrency=0xe0F94Ac5462997D2BC57287Ac3a3aE4C31345D66',
  //   createdAt: 1634289884,
  // },
  // {
  //   id: 'belt-beltbtc',
  //   logo: 'single-assets/BTCB.svg',
  //   name: 'beltBTC',
  //   token: 'beltBTC',
  //   tokenDescription: 'Belt',
  //   tokenAddress: '0x51bd63F240fB13870550423D208452cA87c44444',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooBeltBTC',
  //   earnedTokenAddress: '0xD411121C948Cff739857513E1ADF25ED448623f8',
  //   earnContractAddress: '0xD411121C948Cff739857513E1ADF25ED448623f8',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'belt-beltbtc',
  //   oraclePrice: 0,
  //   depositsPaused: false,
  //   status: 'active',
  //   platform: 'Belt',
  //   assets: ['BTCB'],
  //   risks: [
  //     'COMPLEXITY_LOW',
  //     'BATTLE_TESTED',
  //     'IL_NONE',
  //     'MCAP_LARGE',
  //     'AUDIT',
  //     'CONTRACTS_VERIFIED',
  //   ],
  //   stratType: 'SingleStake',
  //   withdrawalFee: '0.01%',
  //   addLiquidityUrl: 'https://belt.fi/',
  //   createdAt: 1619606003,
  // },
  // {
  //   id: 'banana-bananav2',
  //   logo: 'degens/BANANA.svg',
  //   name: 'BANANA',
  //   token: 'BANANA',
  //   tokenDescription: 'ApeSwap',
  //   tokenAddress: '0x603c7f932ED1fc6575303D8Fb018fDCBb0f39a95',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooBanana',
  //   earnedTokenAddress: '0xED4ea41Daf652964789b16e3Ff76eC07d99d2340',
  //   earnContractAddress: '0xED4ea41Daf652964789b16e3Ff76eC07d99d2340',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'tokens',
  //   oracleId: 'BANANA',
  //   oraclePrice: 0,
  //   depositsPaused: false,
  //   status: 'active',
  //   platform: 'ApeSwap',
  //   assets: ['BANANA'],
  //   risks: [
  //     'COMPLEXITY_LOW',
  //     'BATTLE_TESTED',
  //     'IL_NONE',
  //     'MCAP_MEDIUM',
  //     'AUDIT',
  //     'CONTRACTS_VERIFIED',
  //   ],
  //   stratType: 'SingleStake',
  //   withdrawalFee: '0%',
  //   buyTokenUrl:
  //     'https://dex.apeswap.finance/#/swap?inputCurrency=0x603c7f932ED1fc6575303D8Fb018fDCBb0f39a95&outputCurrency=0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c',
  //   createdAt: 1628853807,
  // },
  // {
  //   id: 'banana-banana-bnb',
  //   logo: 'degens/banana-bnb.svg',
  //   name: 'BANANA-BNB LP',
  //   token: 'BANANA-BNB LP',
  //   tokenDescription: 'ApeSwap',
  //   tokenAddress: '0xF65C1C0478eFDe3c19b49EcBE7ACc57BB6B1D713',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooApeBANANA-BNB',
  //   earnedTokenAddress: '0x15396D3BD9338A14AE90613Fc2b85c5F7b5621CF',
  //   earnContractAddress: '0x15396D3BD9338A14AE90613Fc2b85c5F7b5621CF',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'banana-banana-bnb',
  //   oraclePrice: 0,
  //   depositsPaused: false,
  //   status: 'active',
  //   platform: 'ApeSwap',
  //   assets: ['BANANA', 'BNB'],
  //   risks: [
  //     'COMPLEXITY_LOW',
  //     'BATTLE_TESTED',
  //     'IL_HIGH',
  //     'MCAP_MEDIUM',
  //     'AUDIT',
  //     'CONTRACTS_VERIFIED',
  //   ],
  //   stratType: 'StratLP',
  //   addLiquidityUrl:
  //     'https://dex.apeswap.finance/#/add/ETH/0x603c7f932ED1fc6575303D8Fb018fDCBb0f39a95',
  //   buyTokenUrl:
  //     'https://dex.apeswap.finance/#/swap?inputCurrency=0x603c7f932ED1fc6575303D8Fb018fDCBb0f39a95&outputCurrency=0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c',
  //   createdAt: 1614764749,
  // },
  // {
  //   id: 'alpaca-ibalpaca-eol',
  //   logo: 'single-assets/ALPACA.png',
  //   name: 'ibALPACA',
  //   token: 'ibALPACA',
  //   tokenDescription: 'Alpaca',
  //   tokenAddress: '0xf1bE8ecC990cBcb90e166b71E368299f0116d421',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooIbAlpaca',
  //   earnedTokenAddress: '0x6EB4F8975b15F34AdccFDE830087Fc8FdB006C36',
  //   earnContractAddress: '0x6EB4F8975b15F34AdccFDE830087Fc8FdB006C36',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'alpaca-ibalpaca',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   retireReason: 'rewards',
  //   platform: 'Other',
  //   assets: ['ALPACA'],
  //   withdrawalFee: '0%',
  //   addLiquidityUrl: 'https://app.alpacafinance.org/lend/ALPACA/deposit',
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?outputCurrency=0x8F0528cE5eF7B51152A59745bEfDD91D97091d2F',
  //   createdAt: 1628765745,
  // },
  // {
  //   id: '1inch-1inch-eol',
  //   logo: 'single-assets/INCH.png',
  //   name: '1INCH',
  //   token: '1INCH',
  //   tokenDescription: '1Inch',
  //   tokenAddress: '0x111111111117dC0aa78b770fA6A738034120C302',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'moo1INCH1INCH',
  //   earnedTokenAddress: '0xBa53AF4C2f1649F82e8070FB306DDBF2771A1950',
  //   earnContractAddress: '0xBa53AF4C2f1649F82e8070FB306DDBF2771A1950',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'tokens',
  //   oracleId: '1INCH',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'Other',
  //   assets: ['1INCH'],
  //   risks: [
  //     'COMPLEXITY_LOW',
  //     'BATTLE_TESTED',
  //     'IL_NONE',
  //     'MCAP_MEDIUM',
  //     'AUDIT',
  //     'CONTRACTS_VERIFIED',
  //   ],
  //   stratType: 'SingleStake',
  //   retireReason: 'rewards',
  //   buyTokenUrl:
  //     'https://1inch.exchange/#/r/0xF4cb25a1FF50E319c267b3E51CBeC2699FB2A43B/BNB/1INCH/?network=56',
  //   createdAt: 1616916127,
  // },
  // {
  //   id: 'banana-oasis-wbnb',
  //   name: 'OASIS-BNB LP',
  //   token: 'OASIS-BNB BLP',
  //   tokenDescription: 'ApeSwap',
  //   tokenAddress: '0xeF2b0B3e0077106249946c42CDf3F6a29942d44A',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooApeOASIS-BNB',
  //   earnedTokenAddress: '0xb69fE74aeD526BBEe0fc47db72Ced2BDBF9747CF',
  //   earnContractAddress: '0xb69fE74aeD526BBEe0fc47db72Ced2BDBF9747CF',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'banana-oasis-wbnb',
  //   oraclePrice: 0,
  //   depositsPaused: false,
  //   status: 'active',
  //   platform: 'ApeSwap',
  //   assets: ['OASIS', 'BNB'],
  //   risks: [
  //     'COMPLEXITY_LOW',
  //     'BATTLE_TESTED',
  //     'IL_HIGH',
  //     'MCAP_MICRO',
  //     'AUDIT',
  //     'CONTRACTS_VERIFIED',
  //   ],
  //   stratType: 'StratLP',
  //   addLiquidityUrl:
  //     'https://dex.apeswap.finance/#/add/ETH/0xb19289b436b2F7A92891ac391D8f52580d3087e4',
  //   buyTokenUrl:
  //     'https://dex.apeswap.finance/#/swap?outputCurrency=0xb19289b436b2F7A92891ac391D8f52580d3087e4',
  //   createdAt: 1637137660,
  // },
  // {
  //   id: 'bison-bison-eol',
  //   logo: 'single-assets/BISON.png',
  //   name: 'BISON',
  //   token: 'BISON',
  //   tokenDescription: 'BiShares',
  //   tokenAddress: '0x19A6Da6e382b85F827088092a3DBe864d9cCba73',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooBison',
  //   earnedTokenAddress: '0x7886484722747F66B53A27b2854eF4e6Cdd80881',
  //   earnContractAddress: '0x7886484722747F66B53A27b2854eF4e6Cdd80881',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'tokens',
  //   oracleId: 'BISON',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   retireReason: 'rewards',
  //   platform: 'Other',
  //   assets: ['BISON'],
  //   risks: [
  //     'COMPLEXITY_LOW',
  //     'BATTLE_TESTED',
  //     'IL_NONE',
  //     'MCAP_MICRO',
  //     'AUDIT',
  //     'CONTRACTS_VERIFIED',
  //   ],
  //   stratType: 'SingleStake',
  //   withdrawalFee: '0%',
  //   buyTokenUrl:
  //     'https://dex.apeswap.finance/#/swap?outputCurrency=0x19A6Da6e382b85F827088092a3DBe864d9cCba73',
  //   createdAt: 1637917799,
  // },
  // {
  //   id: 'blockmine-nugget-goldcoin-eol',
  //   name: 'NUGGET-GOLDCOIN LP',
  //   token: 'NUGGET-GOLDCOIN ALP',
  //   tokenDescription: 'ApeSwap (BlockMine)',
  //   tokenAddress: '0x50EF5ccB4E34b751B25e9F449Dc58C248554c465',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooMineNUGGET-GOLDCOIN',
  //   earnedTokenAddress: '0xd3FC1C726E1FaF21bADc029382FD10EC6c9Bc9A8',
  //   earnContractAddress: '0xd3FC1C726E1FaF21bADc029382FD10EC6c9Bc9A8',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'blockmine-nugget-goldcoin',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'Other',
  //   assets: ['NUGGET', 'GOLDCOIN'],
  //   risks: [
  //     'COMPLEXITY_LOW',
  //     'BATTLE_TESTED',
  //     'IL_HIGH',
  //     'MCAP_MICRO',
  //     'NO_AUDIT',
  //     'CONTRACTS_VERIFIED',
  //   ],
  //   stratType: 'StratLP',
  //   addLiquidityUrl:
  //     'https://dex.apeswap.finance/#/add/0xE0B58022487131eC9913C1F3AcFD8F74FC6A6C7E/0xF2f02f60fD1a376270e777Aa2a4667329E3984eD',
  //   buyTokenUrl:
  //     'https://dex.apeswap.finance/#/swap?inputCurrency=0xE0B58022487131eC9913C1F3AcFD8F74FC6A6C7E&outputCurrency=0xF2f02f60fD1a376270e777Aa2a4667329E3984eD',
  //   createdAt: 1637847086,
  //   retiredReason: 'tvl',
  // },
  // {
  //   id: 'cakev2-qi-wbnb-eol',
  //   name: 'QI-BNB LP',
  //   token: 'QI-BNB LP2',
  //   tokenDescription: 'PancakeSwap',
  //   tokenAddress: '0xf924E642f05ACC57fc3b14990c2B1a137683b201',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooCakeV2QI-BNB',
  //   earnedTokenAddress: '0xcc3D722E518fF86b113Fa6aa4434fBAA0449a0e1',
  //   earnContractAddress: '0xcc3D722E518fF86b113Fa6aa4434fBAA0449a0e1',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'cakev2-qi-wbnb',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   retireReason: 'rewards',
  //   platform: 'PancakeSwap',
  //   assets: ['bQI', 'BNB'],
  //   risks: [
  //     'COMPLEXITY_LOW',
  //     'BATTLE_TESTED',
  //     'IL_LOW',
  //     'MCAP_SMALL',
  //     'AUDIT',
  //     'CONTRACTS_VERIFIED',
  //   ],
  //   stratType: 'StratLP',
  //   addLiquidityUrl:
  //     'https://pancakeswap.finance/add/BNB/0x8729438EB15e2C8B576fCc6AeCdA6A148776C0F5',
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?outputCurrency=0x8729438EB15e2C8B576fCc6AeCdA6A148776C0F5',
  //   createdAt: 1637506051,
  // },
  // {
  //   id: 'cakev2-kart-wbnb-eol',
  //   name: 'KART-BNB LP',
  //   token: 'KART-BNB LP2',
  //   tokenDescription: 'PancakeSwap',
  //   tokenAddress: '0x0927C49A18eAc4512112e7a226275e2c36f2C3Db',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooCakeV2KART-BNB',
  //   earnedTokenAddress: '0xC21Da6237db8e5870dEfde290aE62D07b58E16C5',
  //   earnContractAddress: '0xC21Da6237db8e5870dEfde290aE62D07b58E16C5',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'cakev2-kart-wbnb',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'PancakeSwap',
  //   assets: ['KART', 'BNB'],
  //   risks: [
  //     'COMPLEXITY_LOW',
  //     'BATTLE_TESTED',
  //     'IL_LOW',
  //     'MCAP_MICRO',
  //     'AUDIT',
  //     'CONTRACTS_VERIFIED',
  //   ],
  //   stratType: 'StratLP',
  //   addLiquidityUrl:
  //     'https://pancakeswap.finance/add/BNB/0x8BDd8DBcBDf0C066cA5f3286d33673aA7A553C10',
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?outputCurrency=0x8BDd8DBcBDf0C066cA5f3286d33673aA7A553C10',
  //   createdAt: 1637507602,
  //   retiredReason: 'tvl',
  // },
  // {
  //   id: 'cakev2-porto-wbnb-eol',
  //   name: 'PORTO-BNB LP',
  //   token: 'PORTO-BNB LP2',
  //   tokenDescription: 'PancakeSwap',
  //   tokenAddress: '0x0A292e96ABb35297786a38fDD67Dc4f82689E670',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooCakeV2EPORTO-BNB',
  //   earnedTokenAddress: '0x9aA837B6786245eD223E9424ac03C71A82105144',
  //   earnContractAddress: '0x9aA837B6786245eD223E9424ac03C71A82105144',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'cakev2-porto-wbnb',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   retireReason: 'tvl',
  //   platform: 'PancakeSwap',
  //   assets: ['PORTO', 'BNB'],
  //   risks: [
  //     'COMPLEXITY_LOW',
  //     'BATTLE_TESTED',
  //     'IL_LOW',
  //     'MCAP_MICRO',
  //     'AUDIT',
  //     'CONTRACTS_VERIFIED',
  //   ],
  //   stratType: 'StratLP',
  //   addLiquidityUrl:
  //     'https://pancakeswap.finance/add/BNB/0x49f2145d6366099e13B10FbF80646C0F377eE7f6',
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?outputCurrency=0x49f2145d6366099e13B10FbF80646C0F377eE7f6',
  //   createdAt: 1637506484,
  // },
  // {
  //   id: 'cakev2-wbnb-eternal-eol',
  //   name: 'ETERNAL-BNB LP',
  //   token: 'ETERNAL-BNB LP2',
  //   tokenDescription: 'PancakeSwap',
  //   tokenAddress: '0xbd26e08411483C4BEBba80939FA5a775beE22338',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooCakeV2ETERNAL-BNB',
  //   earnedTokenAddress: '0x9509E50AB2bC9ff6081B5E45C57f3bf015E06b7C',
  //   earnContractAddress: '0x9509E50AB2bC9ff6081B5E45C57f3bf015E06b7C',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'cakev2-wbnb-eternal',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   retireReason: 'rewards',
  //   platform: 'PancakeSwap',
  //   assets: ['ETERNAL', 'BNB'],
  //   risks: [
  //     'COMPLEXITY_LOW',
  //     'BATTLE_TESTED',
  //     'IL_LOW',
  //     'MCAP_MICRO',
  //     'AUDIT',
  //     'CONTRACTS_VERIFIED',
  //   ],
  //   stratType: 'StratLP',
  //   addLiquidityUrl:
  //     'https://pancakeswap.finance/add/BNB/0xD44FD09d74cd13838F137B590497595d6b3FEeA4',
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?outputCurrency=0xD44FD09d74cd13838F137B590497595d6b3FEeA4',
  //   createdAt: 1637503683,
  // },
  // {
  //   id: 'cakev2-xwg-usdc',
  //   name: 'XWG-USDC LP',
  //   token: 'XWG-USDC LP2',
  //   tokenDescription: 'PancakeSwap',
  //   tokenAddress: '0x936928146a21AfCcd30DfA84824A780572B1630B',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooCakeV2XWG-USDC',
  //   earnedTokenAddress: '0xe350a5908643076819Dcac25ed38102F8755D224',
  //   earnContractAddress: '0xe350a5908643076819Dcac25ed38102F8755D224',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'cakev2-xwg-usdc',
  //   oraclePrice: 0,
  //   depositsPaused: false,
  //   status: 'active',
  //   platform: 'PancakeSwap',
  //   assets: ['XWG', 'USDC'],
  //   risks: [
  //     'COMPLEXITY_LOW',
  //     'BATTLE_TESTED',
  //     'IL_LOW',
  //     'MCAP_MICRO',
  //     'AUDIT',
  //     'CONTRACTS_VERIFIED',
  //   ],
  //   stratType: 'StratLP',
  //   addLiquidityUrl:
  //     'https://pancakeswap.finance/add/0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d/0x6b23C89196DeB721e6Fd9726E6C76E4810a464bc',
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?outputCurrency=0x6b23C89196DeB721e6Fd9726E6C76E4810a464bc',
  //   createdAt: 1637503217,
  // },
  // {
  //   id: 'banana-frax-busd',
  //   name: 'FRAX-BUSD LP',
  //   token: 'FRAX-BUSD BLP',
  //   tokenDescription: 'ApeSwap',
  //   tokenAddress: '0x5292600758A090490D34367d4864ed6291D254fe',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooApeFRAX-BUSD',
  //   earnedTokenAddress: '0xfa9Dac01A3648e05Ef801DD4d2c6506499204B15',
  //   earnContractAddress: '0xfa9Dac01A3648e05Ef801DD4d2c6506499204B15',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'banana-frax-busd',
  //   oraclePrice: 0,
  //   depositsPaused: false,
  //   status: 'active',
  //   platform: 'ApeSwap',
  //   assets: ['FRAX', 'BUSD'],
  //   risks: [
  //     'COMPLEXITY_LOW',
  //     'BATTLE_TESTED',
  //     'IL_NONE',
  //     'MCAP_HIGH',
  //     'AUDIT',
  //     'CONTRACTS_VERIFIED',
  //   ],
  //   stratType: 'StratLP',
  //   addLiquidityUrl:
  //     'https://dex.apeswap.finance/#/add/0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56/0x90C97F71E18723b0Cf0dfa30ee176Ab653E89F40',
  //   buyTokenUrl:
  //     'https://dex.apeswap.finance/#/swap?inputCurrency=0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56&outputCurrency=0x90C97F71E18723b0Cf0dfa30ee176Ab653E89F40',
  //   createdAt: 1637257193,
  // },
  // {
  //   id: 'banana-wbnb-fxs',
  //   name: 'FXS-BNB LP',
  //   token: 'FXS-BNB BLP',
  //   tokenDescription: 'ApeSwap',
  //   tokenAddress: '0x8210D92a8951d50de3D46AC0ee39cb5E2C14e18A',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooApeBNB-FXS',
  //   earnedTokenAddress: '0x7Cc3C893968FA2ddB515D19a81CaCEF280d573CA',
  //   earnContractAddress: '0x7Cc3C893968FA2ddB515D19a81CaCEF280d573CA',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'banana-wbnb-fxs',
  //   oraclePrice: 0,
  //   depositsPaused: false,
  //   status: 'active',
  //   platform: 'ApeSwap',
  //   assets: ['FXS', 'BNB'],
  //   risks: [
  //     'COMPLEXITY_LOW',
  //     'BATTLE_TESTED',
  //     'IL_HIGH',
  //     'MCAP_MEDIUM',
  //     'AUDIT',
  //     'CONTRACTS_VERIFIED',
  //   ],
  //   stratType: 'StratLP',
  //   addLiquidityUrl:
  //     'https://dex.apeswap.finance/#/add/ETH/0xe48A3d7d0Bc88d552f730B62c006bC925eadB9eE',
  //   buyTokenUrl:
  //     'https://dex.apeswap.finance/#/swap?outputCurrency=0xe48A3d7d0Bc88d552f730B62c006bC925eadB9eE',
  //   createdAt: 1637170658,
  // },
  // {
  //   id: 'betu-betu',
  //   logo: 'single-assets/BETU.png',
  //   name: 'BETU',
  //   token: 'BETU',
  //   tokenDescription: 'BETU',
  //   tokenAddress: '0x0df1B3F30865C5b324797F8dB9d339514caC4e94',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooBetu',
  //   earnedTokenAddress: '0x22b3d90BDdC3Ad5F2948bE3914255C64Ebc8c9b3',
  //   earnContractAddress: '0x22b3d90BDdC3Ad5F2948bE3914255C64Ebc8c9b3',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'tokens',
  //   oracleId: 'BETU',
  //   oraclePrice: 0,
  //   depositsPaused: false,
  //   status: 'active',
  //   platform: 'Other',
  //   assets: ['BETU'],
  //   risks: [
  //     'COMPLEXITY_LOW',
  //     'BATTLE_TESTED',
  //     'IL_NONE',
  //     'MCAP_MICRO',
  //     'AUDIT',
  //     'CONTRACTS_VERIFIED',
  //   ],
  //   stratType: 'SingleStake',
  //   withdrawalFee: '0%',
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?outputCurrency=0x0df1B3F30865C5b324797F8dB9d339514caC4e94',
  //   createdAt: 1637068928,
  // },
  // {
  //   id: 'cakev2-dar-wbnb',
  //   name: 'DAR-BNB LP',
  //   token: 'DAR-BNB LP2',
  //   tokenDescription: 'PancakeSwap',
  //   tokenAddress: '0x062f88E2B4896e823ac78Ac314468c29eEC4186d',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooCakeV2DAR-BNB',
  //   earnedTokenAddress: '0x9f3371AA6F7b48485Cb9eb3D6A79ea9298565410',
  //   earnContractAddress: '0x9f3371AA6F7b48485Cb9eb3D6A79ea9298565410',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'cakev2-dar-wbnb',
  //   oraclePrice: 0,
  //   depositsPaused: false,
  //   status: 'active',
  //   platform: 'PancakeSwap',
  //   assets: ['DAR', 'BNB'],
  //   risks: [
  //     'COMPLEXITY_LOW',
  //     'BATTLE_TESTED',
  //     'IL_LOW',
  //     'MCAP_MICRO',
  //     'AUDIT',
  //     'CONTRACTS_VERIFIED',
  //   ],
  //   stratType: 'StratLP',
  //   addLiquidityUrl:
  //     'https://pancakeswap.finance/add/BNB/0x23CE9e926048273eF83be0A3A8Ba9Cb6D45cd978',
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?outputCurrency=0x23CE9e926048273eF83be0A3A8Ba9Cb6D45cd978',
  //   createdAt: 1636769590,
  // },
  // {
  //   id: 'nfty-nfty',
  //   logo: 'single-assets/NFTY.png',
  //   name: 'NFTY',
  //   token: 'NFTY',
  //   tokenDescription: 'NFTY',
  //   tokenAddress: '0x5774B2fc3e91aF89f89141EacF76545e74265982',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooNfty',
  //   earnedTokenAddress: '0x55669f1c00D55F55bA1E736A23cEE54877D922Be',
  //   earnContractAddress: '0x55669f1c00D55F55bA1E736A23cEE54877D922Be',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'tokens',
  //   oracleId: 'NFTY',
  //   oraclePrice: 0,
  //   depositsPaused: false,
  //   status: 'active',
  //   platform: 'Other',
  //   assets: ['NFTY'],
  //   risks: [
  //     'COMPLEXITY_LOW',
  //     'BATTLE_TESTED',
  //     'IL_NONE',
  //     'MCAP_MICRO',
  //     'AUDIT',
  //     'CONTRACTS_VERIFIED',
  //   ],
  //   stratType: 'SingleStake',
  //   buyTokenUrl:
  //     'https://dex.apeswap.finance/#/swap?outputCurrency=0x5774B2fc3e91aF89f89141EacF76545e74265982',
  //   createdAt: 1636533443,
  // },
  // {
  //   id: 'banana-exp-wbnb-eol',
  //   name: 'EXP-BNB LP',
  //   token: 'EXP-BNB BLP',
  //   tokenDescription: 'ApeSwap',
  //   tokenAddress: '0xe93855Cb88fAa221e648c157670a28f0c2449A60',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooApeEXP-BNB',
  //   earnedTokenAddress: '0x5B5ADf5cA7a7149194182a86fDA329019c57524b',
  //   earnContractAddress: '0x5B5ADf5cA7a7149194182a86fDA329019c57524b',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'banana-exp-wbnb',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'ApeSwap',
  //   assets: ['EXP', 'BNB'],
  //   risks: [
  //     'COMPLEXITY_LOW',
  //     'BATTLE_TESTED',
  //     'IL_HIGH',
  //     'MCAP_MICRO',
  //     'AUDIT',
  //     'CONTRACTS_VERIFIED',
  //   ],
  //   stratType: 'StratLP',
  //   retireReason: 'rewards',
  //   addLiquidityUrl:
  //     'https://dex.apeswap.finance/#/add/ETH/0x639d4C62F58a4048AD0F69B8CE675dB1A3e8e00e',
  //   buyTokenUrl:
  //     'https://dex.apeswap.finance/#/swap?outputCurrency=0x639d4C62F58a4048AD0F69B8CE675dB1A3e8e00e',
  //   createdAt: 1636020665,
  // },
  // {
  //   id: 'cakev2-lazio-wbnb',
  //   name: 'LAZIO-BNB LP',
  //   token: 'LAZIO-BNB LP2',
  //   tokenDescription: 'PancakeSwap',
  //   tokenAddress: '0x11c0b2BB4fbB430825d07507A9E24e4c32f7704D',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooCakeV2LAZIO-BNB',
  //   earnedTokenAddress: '0x3CB18Ba59dD2eB47D4594D1a80a07a721e8a9B2A',
  //   earnContractAddress: '0x3CB18Ba59dD2eB47D4594D1a80a07a721e8a9B2A',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'cakev2-lazio-wbnb',
  //   oraclePrice: 0,
  //   depositsPaused: false,
  //   status: 'active',
  //   platform: 'PancakeSwap',
  //   assets: ['LAZIO', 'BNB'],
  //   risks: [
  //     'COMPLEXITY_LOW',
  //     'BATTLE_TESTED',
  //     'IL_LOW',
  //     'MCAP_MICRO',
  //     'AUDIT',
  //     'CONTRACTS_VERIFIED',
  //   ],
  //   stratType: 'StratLP',
  //   addLiquidityUrl:
  //     'https://pancakeswap.finance/add/BNB/0x77d547256A2cD95F32F67aE0313E450Ac200648d',
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?outputCurrency=0x77d547256A2cD95F32F67aE0313E450Ac200648d',
  //   createdAt: 1635781837,
  // },
  // {
  //   id: 'cakev2-dkt-wbnb-eol',
  //   name: 'DKT-BNB LP',
  //   token: 'DKT-BNB LP2',
  //   tokenDescription: 'PancakeSwap',
  //   tokenAddress: '0x365c3F921b2915a480308D0b1C04aEF7B99c2876',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooCakeV2DKT-BNB',
  //   earnedTokenAddress: '0x16E60cF196653847325936FD1978435a12D580E3',
  //   earnContractAddress: '0x16E60cF196653847325936FD1978435a12D580E3',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'cakev2-dkt-wbnb',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'PancakeSwap',
  //   assets: ['DKT', 'BNB'],
  //   risks: [
  //     'COMPLEXITY_LOW',
  //     'BATTLE_TESTED',
  //     'IL_LOW',
  //     'MCAP_MICRO',
  //     'AUDIT',
  //     'CONTRACTS_VERIFIED',
  //   ],
  //   stratType: 'StratLP',
  //   addLiquidityUrl:
  //     'https://pancakeswap.finance/add/BNB/0x7Ceb519718A80Dd78a8545AD8e7f401dE4f2faA7',
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?outputCurrency=0x7Ceb519718A80Dd78a8545AD8e7f401dE4f2faA7',
  //   createdAt: 1635781676,
  //   retiredReason: 'tvl',
  // },
  // {
  //   id: 'cakev2-rusd-busd',
  //   name: 'rUSD-BUSD LP',
  //   token: 'rUSD-BUSD LP2',
  //   tokenDescription: 'PancakeSwap',
  //   tokenAddress: '0x59FaC9e98479fc9979aE2a0C7422Af50bCBB9B26',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooCakeV2rUSD-BUSD',
  //   earnedTokenAddress: '0xf49ea5eF72CE090f390e888a5B73971E2E274C61',
  //   earnContractAddress: '0xf49ea5eF72CE090f390e888a5B73971E2E274C61',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'cakev2-rusd-busd',
  //   oraclePrice: 0,
  //   depositsPaused: false,
  //   status: 'active',
  //   platform: 'PancakeSwap',
  //   assets: ['rUSD', 'BUSD'],
  //   risks: [
  //     'COMPLEXITY_LOW',
  //     'BATTLE_TESTED',
  //     'IL_NONE',
  //     'MCAP_MEDIUM',
  //     'AUDIT',
  //     'CONTRACTS_VERIFIED',
  //   ],
  //   stratType: 'StratLP',
  //   addLiquidityUrl:
  //     'https://pancakeswap.finance/add/0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56/0x07663837218A003e66310a01596af4bf4e44623D',
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?outputCurrency=0x07663837218A003e66310a01596af4bf4e44623D',
  //   createdAt: 1629635688,
  // },
  // {
  //   id: 'cakev2-bmon-wbnb-eol',
  //   name: 'BMON-BNB LP',
  //   token: 'BMON-BNB LP2',
  //   tokenDescription: 'PancakeSwap',
  //   tokenAddress: '0x3C2b7B578Dd2175A1c3524Aa0D515106282Bf108',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooCakeV2BMON-WBNB',
  //   earnedTokenAddress: '0xb6f1f0797F975FE72a19AfF84e216ea9BDf1C6Ca',
  //   earnContractAddress: '0xb6f1f0797F975FE72a19AfF84e216ea9BDf1C6Ca',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'cakev2-bmon-wbnb',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   retireReason: 'rewards',
  //   platform: 'PancakeSwap',
  //   assets: ['BMON', 'BNB'],
  //   risks: [
  //     'COMPLEXITY_LOW',
  //     'BATTLE_TESTED',
  //     'IL_LOW',
  //     'MCAP_MICRO',
  //     'AUDIT',
  //     'CONTRACTS_VERIFIED',
  //   ],
  //   stratType: 'StratLP',
  //   addLiquidityUrl:
  //     'https://pancakeswap.finance/add/0x08ba0619b1e7A582E0BCe5BBE9843322C954C340/0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?inputCurrency=0x08ba0619b1e7A582E0BCe5BBE9843322C954C340&outputCurrency=0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
  //   createdAt: 1628027029,
  // },
  // {
  //   id: 'belt-beltbnb',
  //   logo: 'single-assets/BNB.png',
  //   name: 'beltBNB',
  //   token: 'beltBNB',
  //   tokenDescription: 'Belt',
  //   tokenAddress: '0xa8Bb71facdd46445644C277F9499Dd22f6F0A30C',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooBeltBNB',
  //   earnedTokenAddress: '0xC34Ae91312A3c3F9420691922040a5DEe1698E52',
  //   earnContractAddress: '0xC34Ae91312A3c3F9420691922040a5DEe1698E52',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'belt-beltbnb',
  //   oraclePrice: 0,
  //   depositsPaused: false,
  //   status: 'active',
  //   platform: 'Belt',
  //   assets: ['BNB'],
  //   risks: [
  //     'COMPLEXITY_LOW',
  //     'BATTLE_TESTED',
  //     'IL_NONE',
  //     'MCAP_LARGE',
  //     'AUDIT',
  //     'CONTRACTS_VERIFIED',
  //   ],
  //   stratType: 'SingleStake',
  //   withdrawalFee: '0.01%',
  //   addLiquidityUrl: 'https://belt.fi/',
  //   createdAt: 1619542263,
  // },
  // {
  //   id: 'belt-belteth',
  //   logo: 'single-assets/ETH.svg',
  //   name: 'beltETH',
  //   token: 'beltETH',
  //   tokenDescription: 'Belt',
  //   tokenAddress: '0xAA20E8Cb61299df2357561C2AC2e1172bC68bc25',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooBeltETH',
  //   earnedTokenAddress: '0xf2064C230b285AA6Cf45c6267DA86a8E3505D0AA',
  //   earnContractAddress: '0xf2064C230b285AA6Cf45c6267DA86a8E3505D0AA',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'belt-belteth',
  //   oraclePrice: 0,
  //   depositsPaused: false,
  //   status: 'active',
  //   platform: 'Belt',
  //   assets: ['ETH'],
  //   risks: [
  //     'COMPLEXITY_LOW',
  //     'BATTLE_TESTED',
  //     'IL_NONE',
  //     'MCAP_LARGE',
  //     'AUDIT',
  //     'CONTRACTS_VERIFIED',
  //   ],
  //   stratType: 'SingleStake',
  //   withdrawalFee: '0.01%',
  //   addLiquidityUrl: 'https://belt.fi/',
  //   createdAt: 1619606249,
  // },
  // {
  //   id: 'banana-bifi-bnb',
  //   logo: 'degens/BIFI-BNB-banana.svg',
  //   name: 'BIFI-BNB LP',
  //   token: 'BIFI-BNB BLP',
  //   tokenDescription: 'ApeSwap',
  //   tokenAddress: '0xDDd3f9d5Bd347c55D18752c0C2075698EC657750',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooApeBIFI-BNB',
  //   earnedTokenAddress: '0x6ebA4A31c46937B42E66f44a0A1165a08495a38F',
  //   earnContractAddress: '0x6ebA4A31c46937B42E66f44a0A1165a08495a38F',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'banana-bifi-bnb',
  //   oraclePrice: 0,
  //   depositsPaused: false,
  //   status: 'active',
  //   platform: 'ApeSwap',
  //   assets: ['BIFI', 'BNB'],
  //   risks: [
  //     'COMPLEXITY_LOW',
  //     'BATTLE_TESTED',
  //     'IL_LOW',
  //     'MCAP_MEDIUM',
  //     'AUDIT',
  //     'CONTRACTS_VERIFIED',
  //   ],
  //   stratType: 'StratLP',
  //   addLiquidityUrl:
  //     'https://dex.apeswap.finance/#/add/ETH/0xCa3F508B8e4Dd382eE878A314789373D80A5190A',
  //   createdAt: 1618286775,
  // },
  // {
  //   id: 'wsg-wsg',
  //   logo: 'single-assets/WSG.svg',
  //   name: 'WSG',
  //   token: 'WSG',
  //   tokenDescription: 'WSG',
  //   tokenAddress: '0xA58950F05FeA2277d2608748412bf9F802eA4901',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooWSG',
  //   earnedTokenAddress: '0xf6581Be77F4933FDcf92C9E0D49e7f85e5360705',
  //   earnContractAddress: '0xf6581Be77F4933FDcf92C9E0D49e7f85e5360705',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'tokens',
  //   oracleId: 'WSG',
  //   oraclePrice: 0,
  //   depositsPaused: false,
  //   status: 'active',
  //   platform: 'Other',
  //   assets: ['WSG'],
  //   risks: [
  //     'COMPLEXITY_LOW',
  //     'BATTLE_TESTED',
  //     'IL_HIGH',
  //     'MCAP_MICRO',
  //     'AUDIT',
  //     'CONTRACTS_VERIFIED',
  //   ],
  //   stratType: 'SingleStake',
  //   withdrawalFee: '0%',
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?inputCurrency=0xA58950F05FeA2277d2608748412bf9F802eA4901&outputCurrency=0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
  //   createdAt: 1635156908,
  // },
  // {
  //   id: 'wsg-wsg-bnb',
  //   name: 'WSG-BNB LP',
  //   token: 'WSG-BNB LP',
  //   tokenDescription: 'PancakeSwap (WSG)',
  //   tokenAddress: '0x37Ff7D4459ad96E0B01275E5efffe091f33c2CAD',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooWsgWSG-WBNB',
  //   earnedTokenAddress: '0xc408b3f112deE85eeA36bCdf8A65c2C6DD86966a',
  //   earnContractAddress: '0xc408b3f112deE85eeA36bCdf8A65c2C6DD86966a',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'wsg-wsg-bnb',
  //   oraclePrice: 0,
  //   depositsPaused: false,
  //   status: 'active',
  //   platform: 'Other',
  //   assets: ['WSG', 'BNB'],
  //   risks: [
  //     'COMPLEXITY_LOW',
  //     'BATTLE_TESTED',
  //     'IL_HIGH',
  //     'MCAP_MICRO',
  //     'AUDIT',
  //     'CONTRACTS_VERIFIED',
  //   ],
  //   stratType: 'StratLP',
  //   addLiquidityUrl:
  //     'https://pancakeswap.finance/add/0xA58950F05FeA2277d2608748412bf9F802eA4901/0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?inputCurrency=0xA58950F05FeA2277d2608748412bf9F802eA4901&outputCurrency=0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
  //   createdAt: 1635156130,
  // },
  // {
  //   id: 'mdex-bsc-bifi-usdt',
  //   logo: 'usdt-pairs/BIFI-USDT.png',
  //   name: 'BIFI-USDT LP',
  //   token: 'BIFI-USDT LP',
  //   tokenDescription: 'Mdex',
  //   tokenAddress: '0xDA4e9Ad1Db5546Ac5cF9BCDb6a0285b97b39f747',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooMdexBIFI-USDT',
  //   earnedTokenAddress: '0x78dB17F440A223464B15046A25A273De510A14Ce',
  //   earnContractAddress: '0x78dB17F440A223464B15046A25A273De510A14Ce',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'mdex-bsc-bifi-usdt',
  //   oraclePrice: 0,
  //   depositsPaused: false,
  //   status: 'active',
  //   platform: 'Mdex',
  //   assets: ['BIFI', 'USDT'],
  //   risks: [
  //     'COMPLEXITY_LOW',
  //     'BATTLE_TESTED',
  //     'IL_HIGH',
  //     'MCAP_MEDIUM',
  //     'AUDIT',
  //     'CONTRACTS_VERIFIED',
  //   ],
  //   stratType: 'StratLP',
  //   buyTokenUrl:
  //     'https://bsc.mdex.com/#/swap?inputCurrency=0x55d398326f99059fF775485246999027B3197955&outputCurrency=0xCa3F508B8e4Dd382eE878A314789373D80A5190A',
  //   addLiquidityUrl:
  //     'https://bsc.mdex.com/#/add/0xCa3F508B8e4Dd382eE878A314789373D80A5190A/0x55d398326f99059fF775485246999027B3197955',
  //   createdAt: 1620907472,
  // },
  // {
  //   id: 'banana-tusd-busd-eol',
  //   name: 'TUSD-BUSD LP',
  //   token: 'TUSD-BUSD BLP',
  //   tokenDescription: 'ApeSwap',
  //   tokenAddress: '0xeDbA62A5d133Fa340e4ef1D58D72F6e773554318',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooApeTUSD-BUSD',
  //   earnedTokenAddress: '0xB40a5A891CCadCA136cb8cdC9BAa72B3eF9C593a',
  //   earnContractAddress: '0xB40a5A891CCadCA136cb8cdC9BAa72B3eF9C593a',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'banana-tusd-busd',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   retireReason: 'rewards',
  //   platform: 'ApeSwap',
  //   assets: ['TUSD', 'BUSD'],
  //   risks: [
  //     'COMPLEXITY_LOW',
  //     'BATTLE_TESTED',
  //     'IL_NONE',
  //     'MCAP_LARGE',
  //     'AUDIT',
  //     'CONTRACTS_VERIFIED',
  //   ],
  //   stratType: 'StratLP',
  //   addLiquidityUrl:
  //     'https://dex.apeswap.finance/#/add/0x14016E85a25aeb13065688cAFB43044C2ef86784/0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
  //   buyTokenUrl:
  //     'https://dex.apeswap.finance/#/swap?inputCurrency=0x14016E85a25aeb13065688cAFB43044C2ef86784&outputCurrency=0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
  //   createdAt: 1635073833,
  // },
  // {
  //   id: 'banana-fil-wbnb',
  //   name: 'FIL-BNB LP',
  //   token: 'FIL-BNB BLP',
  //   tokenDescription: 'ApeSwap',
  //   tokenAddress: '0xcAEC8648dbaC41b6504A8E408892931796D67d87',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooApeFIL-BNB',
  //   earnedTokenAddress: '0xC9412193f775BadBCEfabE7F0350c83d4F66fEeb',
  //   earnContractAddress: '0xC9412193f775BadBCEfabE7F0350c83d4F66fEeb',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'banana-fil-wbnb',
  //   oraclePrice: 0,
  //   depositsPaused: false,
  //   status: 'active',
  //   platform: 'ApeSwap',
  //   assets: ['FIL', 'BNB'],
  //   risks: [
  //     'COMPLEXITY_LOW',
  //     'BATTLE_TESTED',
  //     'IL_LOW',
  //     'MCAP_LARGE',
  //     'AUDIT',
  //     'CONTRACTS_VERIFIED',
  //   ],
  //   stratType: 'StratLP',
  //   addLiquidityUrl:
  //     'https://dex.apeswap.finance/#/add/ETH/0x0D8Ce2A99Bb6e3B7Db580eD848240e4a0F9aE153',
  //   buyTokenUrl:
  //     'https://dex.apeswap.finance/#/swap?outputCurrency=0x0D8Ce2A99Bb6e3B7Db580eD848240e4a0F9aE153',
  //   createdAt: 1635072623,
  // },
  // {
  //   id: 'banana-wbnb-dep',
  //   name: 'DEP-BNB LP',
  //   token: 'DEP-BNB BLP',
  //   tokenDescription: 'ApeSwap',
  //   tokenAddress: '0x6518709dC42F70Dbf89934cA382F1d9dB26370e8',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooApeDEP-BNB',
  //   earnedTokenAddress: '0x62BA2D4A572664036c35f0D76cEe81cC8b77199b',
  //   earnContractAddress: '0x62BA2D4A572664036c35f0D76cEe81cC8b77199b',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'banana-wbnb-dep',
  //   oraclePrice: 0,
  //   depositsPaused: false,
  //   status: 'active',
  //   platform: 'ApeSwap',
  //   assets: ['DEP', 'BNB'],
  //   risks: [
  //     'COMPLEXITY_LOW',
  //     'BATTLE_TESTED',
  //     'IL_HIGH',
  //     'MCAP_MICRO',
  //     'AUDIT',
  //     'CONTRACTS_VERIFIED',
  //   ],
  //   stratType: 'StratLP',
  //   addLiquidityUrl:
  //     'https://dex.apeswap.finance/#/add/ETH/0xcaF5191fc480F43e4DF80106c7695ECA56E48B18',
  //   buyTokenUrl:
  //     'https://dex.apeswap.finance/#/swap?outputCurrency=0xcaF5191fc480F43e4DF80106c7695ECA56E48B18',
  //   createdAt: 1634545736,
  // },
  // {
  //   id: 'wex-wusd-dep-eol',
  //   name: 'DEP-WUSD WLP',
  //   token: 'DEP-WUSD WLP',
  //   tokenDescription: 'WaultFinance',
  //   tokenAddress: '0xb03b009117F8cbBD5A9381b621724E7921F53d73',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooWaultDEP-WUSD',
  //   earnedTokenAddress: '0xE1A80C5f37D25421511991a10986d9E82D000200',
  //   earnContractAddress: '0xE1A80C5f37D25421511991a10986d9E82D000200',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'wex-wusd-dep',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   retireReason: 'rewards',
  //   platform: 'Other',
  //   assets: ['DEP', 'WUSD'],
  //   risks: [
  //     'COMPLEXITY_LOW',
  //     'BATTLE_TESTED',
  //     'IL_HIGH',
  //     'MCAP_MICRO',
  //     'AUDIT',
  //     'CONTRACTS_VERIFIED',
  //   ],
  //   stratType: 'StratLP',
  //   addLiquidityUrl:
  //     'https://swap.wault.finance/bsc/#/add/0x3fF997eAeA488A082fb7Efc8e6B9951990D0c3aB/0xcaF5191fc480F43e4DF80106c7695ECA56E48B18',
  //   buyTokenUrl:
  //     'https://swap.wault.finance/bsc/#/swap?outputCurrency=0xcaF5191fc480F43e4DF80106c7695ECA56E48B18',
  //   createdAt: 1634568642,
  // },
  // {
  //   id: 'cakev2-rpg-busd-eol',
  //   name: 'RPG-BUSD LP',
  //   token: 'RPG-BUSD LP',
  //   tokenDescription: 'PancakeSwap',
  //   tokenAddress: '0x55cdb14855220b239Cf857A03849D96736b9103f',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooCakeRPG-BUSD',
  //   earnedTokenAddress: '0x330B1FD9cD7a8600f5527E7F662dE9994F20FB1b',
  //   earnContractAddress: '0x330B1FD9cD7a8600f5527E7F662dE9994F20FB1b',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'cakev2-rpg-busd',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   retireReason: 'rewards',
  //   platform: 'PancakeSwap',
  //   assets: ['RPG', 'BUSD'],
  //   risks: [
  //     'COMPLEXITY_LOW',
  //     'BATTLE_TESTED',
  //     'IL_HIGH',
  //     'MCAP_MICRO',
  //     'AUDIT',
  //     'CONTRACTS_VERIFIED',
  //   ],
  //   stratType: 'StratLP',
  //   addLiquidityUrl:
  //     'https://pancakeswap.finance/add/0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56/0xc2098a8938119A52B1F7661893c0153A6CB116d5',
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?outputCurrency=0xc2098a8938119A52B1F7661893c0153A6CB116d5',
  //   createdAt: 1634513749,
  // },
  // {
  //   id: 'cakev2-mcb-wbnb-eol',
  //   name: 'MCB-BNB LP',
  //   token: 'MCB-BNB LP',
  //   tokenDescription: 'PancakeSwap',
  //   tokenAddress: '0xE4077A90f8600d9599440cC6d9057785f43a6Ac9',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooCakeMCB-BNB',
  //   earnedTokenAddress: '0x2b0E71D3e8c0714b748F8e8865bCa9928eaaB75A',
  //   earnContractAddress: '0x2b0E71D3e8c0714b748F8e8865bCa9928eaaB75A',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'cakev2-mcb-wbnb',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   retireReason: 'rewards',
  //   platform: 'PancakeSwap',
  //   assets: ['MCB', 'BNB'],
  //   risks: [
  //     'COMPLEXITY_LOW',
  //     'BATTLE_TESTED',
  //     'IL_HIGH',
  //     'MCAP_MICRO',
  //     'AUDIT',
  //     'CONTRACTS_VERIFIED',
  //   ],
  //   stratType: 'StratLP',
  //   addLiquidityUrl:
  //     'https://pancakeswap.finance/add/BNB/0x5fE80d2CD054645b9419657d3d10d26391780A7B',
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?inputCurrency=0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56&outputCurrency=0x5fE80d2CD054645b9419657d3d10d26391780A7B',
  //   createdAt: 1634513560,
  // },
  // {
  //   id: 'baby-baby',
  //   logo: 'single-assets/BABY.svg',
  //   name: 'BABY',
  //   token: 'BABY',
  //   tokenDescription: 'BabySwap',
  //   tokenAddress: '0x53E562b9B7E5E94b81f10e96Ee70Ad06df3D2657',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooBaby',
  //   earnedTokenAddress: '0x87F9A89B51dA28CE8653A700d362CDa9b9bA7d88',
  //   earnContractAddress: '0x87F9A89B51dA28CE8653A700d362CDa9b9bA7d88',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'tokens',
  //   oracleId: 'BABY',
  //   oraclePrice: 0,
  //   depositsPaused: false,
  //   status: 'active',
  //   platform: 'Other',
  //   assets: ['BABY'],
  //   risks: [
  //     'COMPLEXITY_LOW',
  //     'BATTLE_TESTED',
  //     'IL_NONE',
  //     'MCAP_MICRO',
  //     'AUDIT',
  //     'CONTRACTS_VERIFIED',
  //   ],
  //   stratType: 'SingleStake',
  //   withdrawalFee: '0%',
  //   buyTokenUrl:
  //     'https://exchange.babyswap.finance/#/swap?outputCurrency=0x53E562b9B7E5E94b81f10e96Ee70Ad06df3D2657',
  //   createdAt: 1634030320,
  // },
  // {
  //   id: 'baby-baby-usdt',
  //   name: 'BABY-USDT LP',
  //   token: 'BABY-USDT LP',
  //   tokenDescription: 'BabySwap',
  //   tokenAddress: '0xE730C7B7470447AD4886c763247012DfD233bAfF',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooBabyBABY-USDT',
  //   earnedTokenAddress: '0xbFF84AC91c3d4123e5D5aAF32996c05A90c7CDAB',
  //   earnContractAddress: '0xbFF84AC91c3d4123e5D5aAF32996c05A90c7CDAB',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'baby-baby-usdt',
  //   oraclePrice: 0,
  //   depositsPaused: false,
  //   status: 'active',
  //   platform: 'Other',
  //   assets: ['BABY', 'USDT'],
  //   risks: [
  //     'COMPLEXITY_LOW',
  //     'BATTLE_TESTED',
  //     'IL_HIGH',
  //     'MCAP_MICRO',
  //     'AUDIT',
  //     'CONTRACTS_VERIFIED',
  //   ],
  //   stratType: 'StratLP',
  //   addLiquidityUrl:
  //     'https://exchange.babyswap.finance/#/add/0x53E562b9B7E5E94b81f10e96Ee70Ad06df3D2657/0x55d398326f99059fF775485246999027B3197955',
  //   buyTokenUrl:
  //     'https://exchange.babyswap.finance/#/swap?outputCurrency=0x53E562b9B7E5E94b81f10e96Ee70Ad06df3D2657',
  //   createdAt: 1634029618,
  // },
  // {
  //   id: 'baby-baby-bnb',
  //   name: 'BABY-BNB LP',
  //   token: 'BABY-BNB LP',
  //   tokenDescription: 'BabySwap',
  //   tokenAddress: '0x36aE10A4d16311959b607eE98Bc4a8A653A33b1F',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooBabyBABY-BNB',
  //   earnedTokenAddress: '0x5968bCf6fA9d697bc7EbbD925BdaD0b487fB02bC',
  //   earnContractAddress: '0x5968bCf6fA9d697bc7EbbD925BdaD0b487fB02bC',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'baby-baby-bnb',
  //   oraclePrice: 0,
  //   depositsPaused: false,
  //   status: 'active',
  //   platform: 'Other',
  //   assets: ['BABY', 'BNB'],
  //   risks: [
  //     'COMPLEXITY_LOW',
  //     'BATTLE_TESTED',
  //     'IL_HIGH',
  //     'MCAP_MICRO',
  //     'AUDIT',
  //     'CONTRACTS_VERIFIED',
  //   ],
  //   stratType: 'StratLP',
  //   addLiquidityUrl:
  //     'https://exchange.babyswap.finance/#/add/BNB/0x53E562b9B7E5E94b81f10e96Ee70Ad06df3D2657',
  //   buyTokenUrl:
  //     'https://exchange.babyswap.finance/#/swap?outputCurrency=0x53E562b9B7E5E94b81f10e96Ee70Ad06df3D2657',
  //   createdAt: 1634029066,
  // },
  // {
  //   id: 'cakev2-wbnb-beta',
  //   name: 'BETA-BNB LP',
  //   token: 'BETA-BNB LP',
  //   tokenDescription: 'PancakeSwap',
  //   tokenAddress: '0x88a02D94F437799f06f8c256ff07Aa397E6D0016',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooCakeBETA-BNB',
  //   earnedTokenAddress: '0x53BD8254BFe7D3615E6879D877522309DA7dD130',
  //   earnContractAddress: '0x53BD8254BFe7D3615E6879D877522309DA7dD130',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'cakev2-wbnb-beta',
  //   oraclePrice: 0,
  //   depositsPaused: false,
  //   status: 'active',
  //   platform: 'PancakeSwap',
  //   assets: ['BETA', 'BNB'],
  //   risks: [
  //     'COMPLEXITY_LOW',
  //     'BATTLE_TESTED',
  //     'IL_HIGH',
  //     'MCAP_MEDIUM',
  //     'AUDIT',
  //     'CONTRACTS_VERIFIED',
  //   ],
  //   stratType: 'StratLP',
  //   addLiquidityUrl:
  //     'https://pancakeswap.finance/add/BNB/0xBe1a001FE942f96Eea22bA08783140B9Dcc09D28',
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?outputCurrency=0xBe1a001FE942f96Eea22bA08783140B9Dcc09D28',
  //   createdAt: 1633872463,
  // },
  // {
  //   id: 'sing-sing-busd-eol',
  //   name: 'SING-BUSD LP',
  //   token: 'SING-BUSD LP',
  //   tokenDescription: 'ApeSwap (Singular)',
  //   tokenAddress: '0xdDdc245c62a0AA875893Ab53b52455aCdCd7f526',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooSingSING-BUSD',
  //   earnedTokenAddress: '0x70E397fE8C402F2993EB2AC54C522f5AE9F33eDb',
  //   earnContractAddress: '0x70E397fE8C402F2993EB2AC54C522f5AE9F33eDb',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'sing-sing-busd',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'Other',
  //   assets: ['SING', 'BUSD'],
  //   addLiquidityUrl:
  //     'https://app.apeswap.finance/add/0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56/0x23894C0ce2d79B79Ea33A4D02e67ae843Ef6e563',
  //   buyTokenUrl:
  //     'https://app.apeswap.finance/swap?inputCurrency=0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56&outputCurrency=0x23894C0ce2d79B79Ea33A4D02e67ae843Ef6e563',
  //   createdAt: 1633685032,
  //   retiredReason: 'tvl',
  // },
  // {
  //   id: 'cafev2-brew-bnb-eol',
  //   name: 'BREW-BNB LP',
  //   token: 'BREW-BNB LP2',
  //   tokenDescription: 'CafeSwap',
  //   tokenAddress: '0x4D1f8F8E579096097809D439d6707f2F5870652A',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooCafeSwapBREW-BNB',
  //   earnedTokenAddress: '0x6D23D73B219B73560E871021D1E1AC382793727a',
  //   earnContractAddress: '0x6D23D73B219B73560E871021D1E1AC382793727a',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'cafev2-brew-bnb',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'Other',
  //   assets: ['BREW', 'BNB'],
  //   addLiquidityUrl:
  //     'https://dex.cafeswap.finance/#/add/ETH/0x790Be81C3cA0e53974bE2688cDb954732C9862e1',
  //   buyTokenUrl:
  //     'https://dex.cafeswap.finance/#/swap?outputCurrency=0x790Be81C3cA0e53974bE2688cDb954732C9862e1',
  //   createdAt: 1633690726,
  //   retiredReason: 'tvl',
  // },
  // {
  //   id: 'cafev2-brew-busd-eol',
  //   name: 'BREW-BUSD LP',
  //   token: 'BREW-BUSD LP2',
  //   tokenDescription: 'CafeSwap',
  //   tokenAddress: '0xfd2A5F04368a18D3bD4204d7dEb250b1710Bbc15',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooCafeSwapBREW-BUSD',
  //   earnedTokenAddress: '0xa7f299F51143e91a411805a4648434092788a111',
  //   earnContractAddress: '0xa7f299F51143e91a411805a4648434092788a111',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'cafev2-brew-busd',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'Other',
  //   assets: ['BREW', 'BUSD'],
  //   addLiquidityUrl:
  //     'https://dex.cafeswap.finance/#/add/0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56/0x790Be81C3cA0e53974bE2688cDb954732C9862e1',
  //   buyTokenUrl:
  //     'https://dex.cafeswap.finance/#/swap?outputCurrency=0x790Be81C3cA0e53974bE2688cDb954732C9862e1',
  //   createdAt: 1633691257,
  //   retiredReason: 'tvl',
  // },
  // {
  //   id: 'cakev2-nft-wbnb-eol',
  //   name: 'NFT-BNB LP',
  //   token: 'NFT-BNB LP2',
  //   tokenDescription: 'PancakeSwap',
  //   tokenAddress: '0x0ecc84c9629317a494f12Bc56aA2522475bF32e8',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooCakeV2NFT-BNB',
  //   earnedTokenAddress: '0xC7847f03d87C046630482c9b8e557B89ae0672Cc',
  //   earnContractAddress: '0xC7847f03d87C046630482c9b8e557B89ae0672Cc',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'cakev2-nft-wbnb',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'PancakeSwap',
  //   assets: ['NFT', 'BNB'],
  //   addLiquidityUrl:
  //     'https://pancakeswap.finance/add/BNB/0x1fC9004eC7E5722891f5f38baE7678efCB11d34D',
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?outputCurrency=0x1fC9004eC7E5722891f5f38baE7678efCB11d34D',
  //   createdAt: 1632668528,
  //   retiredReason: 'tvl',
  // },
  // {
  //   id: 'banana-ong-bnb-eol',
  //   name: 'ONG-BNB ALP',
  //   token: 'ONG-BNB ALP',
  //   tokenDescription: 'ApeSwap',
  //   tokenAddress: '0xe569fDBE1EA6B7e49c3fB3AD7Fe3758CA701e90f',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooApeONG-BNB',
  //   earnedTokenAddress: '0xfea0827048B45818C53E3D0aCeC46A0041F095EA',
  //   earnContractAddress: '0xfea0827048B45818C53E3D0aCeC46A0041F095EA',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'banana-ong-bnb',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'ApeSwap',
  //   assets: ['ONG', 'BNB'],
  //   addLiquidityUrl:
  //     'https://app.apeswap.finance/add/ETH/0x308bfaeAaC8BDab6e9Fc5Ead8EdCb5f95b0599d9',
  //   buyTokenUrl:
  //     'https://app.apeswap.finance/swap?outputCurrency=0x308bfaeAaC8BDab6e9Fc5Ead8EdCb5f95b0599d9',
  //   createdAt: 1632595236,
  //   retiredReason: 'tvl',
  // },
  // {
  //   id: 'pacoca-pacoca-bnb',
  //   name: 'PACOCA-BNB LP',
  //   token: 'PACOCA-BNB LP',
  //   tokenDescription: 'Pacoca (ApeSwap)',
  //   tokenAddress: '0x0fee6E1E55fA772fAE71E6734a7F9E8622900D75',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooPacocaPACOCA-BNB',
  //   earnedTokenAddress: '0x8d64F5EcabbAf5e73a4c2dA9b9f15AA89cD13e87',
  //   earnContractAddress: '0x8d64F5EcabbAf5e73a4c2dA9b9f15AA89cD13e87',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'pacoca-pacoca-bnb',
  //   oraclePrice: 0,
  //   depositsPaused: false,
  //   status: 'active',
  //   platform: 'Other',
  //   assets: ['PACOCA', 'BNB'],
  //   risks: [
  //     'COMPLEXITY_LOW',
  //     'BATTLE_TESTED',
  //     'IL_HIGH',
  //     'MCAP_MICRO',
  //     'AUDIT',
  //     'CONTRACTS_VERIFIED',
  //   ],
  //   stratType: 'StratLP',
  //   addLiquidityUrl:
  //     'https://app.apeswap.finance/add/0x55671114d774ee99d653d6c12460c780a67f1d18/0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c',
  //   buyTokenUrl: 'https://pacoca.io/exchange',
  //   createdAt: 1632480278,
  // },
  // {
  //   id: 'pacoca-pacoca',
  //   logo: 'single-assets/PACOCA.svg',
  //   name: 'PACOCA',
  //   token: 'PACOCA',
  //   tokenDescription: 'Pacoca',
  //   tokenAddress: '0x55671114d774ee99D653D6C12460c780a67f1D18',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooPacoca',
  //   earnedTokenAddress: '0x21403739A1Dc0e3ceC70CBD5ceaE78fF25F102a4',
  //   earnContractAddress: '0x21403739A1Dc0e3ceC70CBD5ceaE78fF25F102a4',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'tokens',
  //   oracleId: 'PACOCA',
  //   oraclePrice: 0,
  //   depositsPaused: false,
  //   status: 'active',
  //   platform: 'Other',
  //   assets: ['PACOCA'],
  //   risks: [
  //     'COMPLEXITY_LOW',
  //     'BATTLE_TESTED',
  //     'IL_NONE',
  //     'MCAP_MICRO',
  //     'AUDIT',
  //     'CONTRACTS_VERIFIED',
  //   ],
  //   stratType: 'SingleStake',
  //   withdrawalFee: '0%',
  //   buyTokenUrl: 'https://pacoca.io/exchange',
  //   createdAt: 1632479930,
  // },
  // {
  //   id: 'annex-ann-busd-eol',
  //   name: 'ANN-BUSD LP',
  //   token: 'ANN-BUSD LP',
  //   tokenDescription: 'Annex',
  //   tokenAddress: '0xD4c4960a18A3eD79Ce1E9Ce6D2C50d2937d5f2f8',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooAnnexANN-BUSD',
  //   earnedTokenAddress: '0x6D56c98Df063EF0CB7431AAf87BA6c2f2Fe24611',
  //   earnContractAddress: '0x6D56c98Df063EF0CB7431AAf87BA6c2f2Fe24611',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'annex-ann-busd',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'Other',
  //   assets: ['ANN', 'BUSD'],
  //   retireReason: 'rewards',
  //   addLiquidityUrl:
  //     'https://app.annex.finance/trade/liquidity/add/0x98936Bde1CF1BFf1e7a8012Cee5e2583851f2067/0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
  //   buyTokenUrl:
  //     'https://app.annex.finance/trade/swap?inputCurrency=0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56&outputCurrency=0x98936Bde1CF1BFf1e7a8012Cee5e2583851f2067&exactAmount=1000',
  //   createdAt: 1632300410,
  // },
  // {
  //   id: 'annex-ann-eol',
  //   logo: 'single-assets/ANN.png',
  //   name: 'ANN',
  //   token: 'ANN',
  //   tokenDescription: 'Annex',
  //   tokenAddress: '0x98936Bde1CF1BFf1e7a8012Cee5e2583851f2067',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooAnnexAnn',
  //   earnedTokenAddress: '0xB3e80cf1e9A0478F917Ec81f6B223C495CA20a27',
  //   earnContractAddress: '0xB3e80cf1e9A0478F917Ec81f6B223C495CA20a27',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'tokens',
  //   oracleId: 'ANN',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'Other',
  //   assets: ['ANN'],
  //   retireReason: 'rewards',
  //   buyTokenUrl:
  //     'https://app.annex.finance/trade/swap?inputCurrency=0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56&outputCurrency=0x98936Bde1CF1BFf1e7a8012Cee5e2583851f2067&exactAmount=1000',
  //   createdAt: 1632300632,
  // },
  // {
  //   id: 'belt-4belt',
  //   logo: 'uncategorized/BELT-VENUSBLP.png',
  //   name: 'BUSD/USDT/USDC/DAI',
  //   token: '4BELT LP',
  //   tokenDescription: 'Belt',
  //   tokenAddress: '0x9cb73F20164e399958261c289Eb5F9846f4D1404',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooBelt4Belt',
  //   earnedTokenAddress: '0xc1fcf50ccaCd1583BD9d3b41657056878C94e592',
  //   earnContractAddress: '0xc1fcf50ccaCd1583BD9d3b41657056878C94e592',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'belt-4belt',
  //   oraclePrice: 0,
  //   depositsPaused: false,
  //   status: 'active',
  //   platform: 'Belt',
  //   assets: ['USDT', 'BUSD', 'USDC', 'DAI', '4BELT'],
  //   risks: [
  //     'COMPLEXITY_LOW',
  //     'BATTLE_TESTED',
  //     'IL_NONE',
  //     'MCAP_LARGE',
  //     'AUDIT',
  //     'CONTRACTS_VERIFIED',
  //     'OVER_COLLAT_ALGO_STABLECOIN',
  //   ],
  //   stratType: 'StratMultiLP',
  //   withdrawalFee: '0.01%',
  //   addLiquidityUrl: 'https://belt.fi/',
  //   createdAt: 1619502882,
  // },
  // {
  //   id: 'pearzap-pear-bnb-eol',
  //   name: 'PEAR-BNB LP',
  //   token: 'PEAR-BNB LP',
  //   tokenDescription: 'ApeSwap (PearZap)',
  //   tokenAddress: '0x36649058bCcF2B8855EEd1Ce44adb734028c6F8D',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooPearZapPEAR-BNB',
  //   earnedTokenAddress: '0xa2728d080650d3828e525881Dfd296481b273ab8',
  //   earnContractAddress: '0xa2728d080650d3828e525881Dfd296481b273ab8',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'pearzap-pear-bnb',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'Other',
  //   assets: ['PEAR', 'BNB'],
  //   retireReason: 'rewards',
  //   addLiquidityUrl:
  //     'https://app.apeswap.finance/add/ETH/0xdf7C18ED59EA738070E665Ac3F5c258dcc2FBad8',
  //   buyTokenUrl:
  //     'https://app.apeswap.finance/swap?outputCurrency=0xdf7C18ED59EA738070E665Ac3F5c258dcc2FBad8',
  //   createdAt: 1631698093,
  // },
  // {
  //   id: 'pearzap-pear-busd-eol',
  //   name: 'PEAR-BUSD LP',
  //   token: 'PEAR-BUSD LP',
  //   tokenDescription: 'ApeSwap (PearZap)',
  //   tokenAddress: '0x67Ce6Cac27E8BE8B8cdb5088806aAB13A12a5353',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooPearZapPEAR-BUSD',
  //   earnedTokenAddress: '0xa94f3a0cEA66F7141DC4a2039E65205c68Db83ab',
  //   earnContractAddress: '0xa94f3a0cEA66F7141DC4a2039E65205c68Db83ab',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'pearzap-pear-busd',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'Other',
  //   assets: ['PEAR', 'BUSD'],
  //   retireReason: 'rewards',
  //   addLiquidityUrl:
  //     'https://app.apeswap.finance/add/0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56/0xdf7C18ED59EA738070E665Ac3F5c258dcc2FBad8',
  //   buyTokenUrl:
  //     'https://app.apeswap.finance/swap?outputCurrency=0xdf7C18ED59EA738070E665Ac3F5c258dcc2FBad8',
  //   createdAt: 1631698681,
  // },
  // {
  //   id: 'czf-czf-bnb',
  //   name: 'CZF-BNB LP',
  //   token: 'CZF-BNB LP2',
  //   tokenDescription: 'PancakeSwap (CZodiac)',
  //   tokenAddress: '0xeF8e8CfADC0b634b6d0065080a69F139159a17dE',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooCZFCZF-BNB',
  //   earnedTokenAddress: '0x98CADFb805fAc92eF70e3Ed900c76a487cDb26a1',
  //   earnContractAddress: '0x98CADFb805fAc92eF70e3Ed900c76a487cDb26a1',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'czf-czf-bnb',
  //   oraclePrice: 0,
  //   depositsPaused: false,
  //   status: 'active',
  //   platform: 'Other',
  //   assets: ['CZF', 'BNB'],
  //   addLiquidityUrl:
  //     'https://pancakeswap.finance/add/0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c/0x7c1608C004F20c3520f70b924E2BfeF092dA0043',
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?outputCurrency=0x7c1608C004F20c3520f70b924E2BfeF092dA0043',
  //   createdAt: 1631694805,
  // },
  // {
  //   id: 'czf-czf-busd-eol',
  //   name: 'CZF-BUSD LP',
  //   token: 'CZF-BUSD LP2',
  //   tokenDescription: 'PancakeSwap (CZodiac)',
  //   tokenAddress: '0xAAC96d00C566571bafdfa3B8440Bdc3cDB223Ad0',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooCZFCZF-BUSD',
  //   earnedTokenAddress: '0x3c7f71a03B61EE15658f22f7fa8bd103811297f3',
  //   earnContractAddress: '0x3c7f71a03B61EE15658f22f7fa8bd103811297f3',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'czf-czf-busd',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'Other',
  //   assets: ['CZF', 'BUSD'],
  //   addLiquidityUrl:
  //     'https://pancakeswap.finance/add/0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56/0x7c1608C004F20c3520f70b924E2BfeF092dA0043',
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?outputCurrency=0x7c1608C004F20c3520f70b924E2BfeF092dA0043',
  //   createdAt: 1631691818,
  //   retiredReason: 'tvl',
  // },
  // {
  //   id: 'long-long-wbnb-eol',
  //   name: 'LONG-BNB LP',
  //   token: 'LONG-BNB LP2',
  //   tokenDescription: 'PancakeSwap (Longdrink)',
  //   tokenAddress: '0x5ab4dc6Ec350e546103F6891299b467293c36c3e',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooLongLONG-BNB',
  //   earnedTokenAddress: '0xeF20Daca8d81652774c8b9830b7865CF782CefF5',
  //   earnContractAddress: '0xeF20Daca8d81652774c8b9830b7865CF782CefF5',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'long-long-wbnb',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'Other',
  //   assets: ['LONG', 'BNB'],
  //   addLiquidityUrl:
  //     'https://pancakeswap.finance/add/0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c/0x5317fA16f8603bE9C461DeF5D5A1Bf28DfE42d55',
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?outputCurrency=0x5317fA16f8603bE9C461DeF5D5A1Bf28DfE42d55',
  //   createdAt: 1631531985,
  //   retiredReason: 'tvl',
  // },
  // {
  //   id: 'cakev2-dvi-wbnb-eol',
  //   name: 'DVI-BNB LP',
  //   token: 'DVI-BNB LP2',
  //   tokenDescription: 'PancakeSwap',
  //   tokenAddress: '0x89EBF9cD99864f6E51bd7a578965922029cAB977',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooCakeV2DVI-WBNB',
  //   earnedTokenAddress: '0x9D193d68249131A94EA0EA6d88db4171ad2C42b7',
  //   earnContractAddress: '0x9D193d68249131A94EA0EA6d88db4171ad2C42b7',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'cakev2-dvi-wbnb',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'PancakeSwap',
  //   assets: ['DVI', 'BNB'],
  //   addLiquidityUrl:
  //     'https://pancakeswap.finance/add/0x758FB037A375F17c7e195CC634D77dA4F554255B/0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?inputCurrency=0x758FB037A375F17c7e195CC634D77dA4F554255B&outputCurrency=0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
  //   createdAt: 1625611527,
  // },
  // {
  //   id: 'cakev2-mcrn-wbnb-eol',
  //   name: 'MCRN-BNB LP',
  //   token: 'MCRN-BNB LP2',
  //   tokenDescription: 'PancakeSwap',
  //   tokenAddress: '0xe8D5d81dac092Ae61d097f84EFE230759BF2e522',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooCakeV2MCRN-WBNB',
  //   earnedTokenAddress: '0x3da80295D96dcA4bc24c865F4548D23da4a44AE2',
  //   earnContractAddress: '0x3da80295D96dcA4bc24c865F4548D23da4a44AE2',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'cakev2-mcrn-wbnb',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   retireReason: 'rewards',
  //   platform: 'PancakeSwap',
  //   assets: ['MCRN', 'BNB'],
  //   addLiquidityUrl:
  //     'https://pancakeswap.finance/add/0xacb2d47827C9813AE26De80965845D80935afd0B/0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?inputCurrency=0xacb2d47827C9813AE26De80965845D80935afd0B&outputCurrency=0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
  //   createdAt: 1628027293,
  // },
  // {
  //   id: 'banana-pots-bnb',
  //   name: 'POTS-BNB ALP',
  //   token: 'POTS-BNB ALP',
  //   tokenDescription: 'ApeSwap',
  //   tokenAddress: '0xaa3FCBA2cf7c5f8c8f785A7180F5063144fE53c6',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooApePOTS-BNB',
  //   earnedTokenAddress: '0xbFa24f7C2376c28407504Fb8512797dD8D316aBf',
  //   earnContractAddress: '0xbFa24f7C2376c28407504Fb8512797dD8D316aBf',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'banana-pots-bnb',
  //   oraclePrice: 0,
  //   depositsPaused: false,
  //   status: 'active',
  //   risks: [
  //     'COMPLEXITY_LOW',
  //     'BATTLE_TESTED',
  //     'IL_HIGH',
  //     'MCAP_MICRO',
  //     'PLATFORM_ESTABLISHED',
  //     'AUDIT',
  //     'CONTRACTS_VERIFIED',
  //   ],
  //   stratType: 'StratLP',
  //   platform: 'ApeSwap',
  //   assets: ['POTS', 'BNB'],
  //   addLiquidityUrl:
  //     'https://app.apeswap.finance/add/ETH/0x3Fcca8648651E5b974DD6d3e50F61567779772A8',
  //   buyTokenUrl:
  //     'https://app.apeswap.finance/swap?outputCurrency=0x3Fcca8648651E5b974DD6d3e50F61567779772A8',
  //   createdAt: 1628065858,
  // },
  // {
  //   id: 'cakev2-pots-busd-eol',
  //   name: 'POTS-BUSD LP',
  //   token: 'POTS-BUSD LP2',
  //   tokenDescription: 'PancakeSwap',
  //   tokenAddress: '0xF90BAA331Cfd40F094476E752Bf272892170d399',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooCakeV2POTS-BUSD',
  //   earnedTokenAddress: '0x3106d9B3d5e04ff5D575212140FAb1Cf17C8933F',
  //   earnContractAddress: '0x3106d9B3d5e04ff5D575212140FAb1Cf17C8933F',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'cakev2-pots-busd',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   retireReason: 'rewards',
  //   platform: 'PancakeSwap',
  //   assets: ['POTS', 'BUSD'],
  //   risks: [
  //     'COMPLEXITY_LOW',
  //     'BATTLE_TESTED',
  //     'IL_HIGH',
  //     'MCAP_MICRO',
  //     'PLATFORM_ESTABLISHED',
  //     'AUDIT',
  //     'CONTRACTS_VERIFIED',
  //   ],
  //   stratType: 'StratLP',
  //   addLiquidityUrl:
  //     'https://pancakeswap.finance/add/0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56/0x3Fcca8648651E5b974DD6d3e50F61567779772A8',
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?outputCurrency=0x3Fcca8648651E5b974DD6d3e50F61567779772A8',
  //   createdAt: 1628533257,
  // },
  // {
  //   id: 'cakev2-tlos-wbnb-eol',
  //   name: 'TLOS-BNB LP',
  //   token: 'TLOS-BNB LP2',
  //   tokenDescription: 'PancakeSwap',
  //   tokenAddress: '0x3eDb06e2d182d133864fe7C0f9B4C204bBf61D4E',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooCakeV2TLOS-BNB',
  //   earnedTokenAddress: '0x985a438C320ab799E87F660A214c6052673A077A',
  //   earnContractAddress: '0x985a438C320ab799E87F660A214c6052673A077A',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'cakev2-tlos-wbnb',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   retireReason: 'rewards',
  //   platform: 'PancakeSwap',
  //   assets: ['TLOS', 'BNB'],
  //   addLiquidityUrl:
  //     'https://pancakeswap.finance/add/BNB/0xb6C53431608E626AC81a9776ac3e999c5556717c',
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?outputCurrency=0xb6C53431608E626AC81a9776ac3e999c5556717c',
  //   createdAt: 1631196240,
  // },
  // {
  //   id: 'honeyfarm-moon-bnb-eol',
  //   name: 'MOON-BNB LP',
  //   token: 'MOON-BNB LP',
  //   tokenDescription: 'PancakeSwap (HoneyFarm)',
  //   tokenAddress: '0x643D3f1C1fA1A7D5D6BDE50Bd3FDD1Cdd8A85692',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooHoneyFarmMOON-BNB',
  //   earnedTokenAddress: '0x6DA3C7518c86D4d6b1cD42E79990c08BCbAD509f',
  //   earnContractAddress: '0x6DA3C7518c86D4d6b1cD42E79990c08BCbAD509f',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'honeyfarm-moon-bnb',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'Other',
  //   assets: ['MOON', 'BNB'],
  //   addLiquidityUrl:
  //     'https://pancakeswap.finance/add/BNB/0xE8c93310af068aa50bd7bF0ebFa459Df2a02ceba',
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?outputCurrency=0xE8c93310af068aa50bd7bF0ebFa459Df2a02ceba',
  //   createdAt: 1631186662,
  // },
  // {
  //   id: 'honeyfarm-moon-busd-eol',
  //   name: 'MOON-BUSD LP',
  //   token: 'MOON-BUSD LP',
  //   tokenDescription: 'PancakeSwap (HoneyFarm)',
  //   tokenAddress: '0x1536d05Bebb05173a19c44aA1ec0a009544AaFC4',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooHoneyFarmMOON-BUSD',
  //   earnedTokenAddress: '0xF8Ee4Bf5deEC42E31cb6bD1a9B2E63aDE0E633CC',
  //   earnContractAddress: '0xF8Ee4Bf5deEC42E31cb6bD1a9B2E63aDE0E633CC',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'honeyfarm-moon-busd',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'Other',
  //   assets: ['MOON', 'BUSD'],
  //   addLiquidityUrl:
  //     'https://pancakeswap.finance/add/0xe9e7cea3dedca5984780bafc599bd69add087d56/0xE8c93310af068aa50bd7bF0ebFa459Df2a02ceba',
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?outputCurrency=0xE8c93310af068aa50bd7bF0ebFa459Df2a02ceba',
  //   createdAt: 1631187072,
  // },
  // {
  //   id: 'mdex-usdt-btcb',
  //   name: 'USDT-BTCB LP',
  //   token: 'USDT-BTCB LP',
  //   tokenDescription: 'Mdex',
  //   tokenAddress: '0xda28Eb7ABa389C1Ea226A420bCE04Cb565Aafb85',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooMdexUSDT-BTCB',
  //   earnedTokenAddress: '0x397967AE34c9e91c11e3DCb72e566c598e929754',
  //   earnContractAddress: '0x397967AE34c9e91c11e3DCb72e566c598e929754',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'mdex-usdt-btcb',
  //   oraclePrice: 0,
  //   depositsPaused: false,
  //   status: 'active',
  //   platform: 'Mdex',
  //   assets: ['USDT', 'BTCB'],
  //   addLiquidityUrl:
  //     'https://exchange.pancakeswap.finance/#/add/0x55d398326f99059fF775485246999027B3197955/0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c',
  //   buyTokenUrl:
  //     'https://exchange.pancakeswap.finance/#/swap?inputCurrency=0x55d398326f99059fF775485246999027B3197955&outputCurrency=0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c',
  //   createdAt: 1630682566,
  // },
  // {
  //   id: 'mdex-bsc-rabbit-busd-eol',
  //   name: 'RABBIT-BUSD LP',
  //   token: 'RABBIT-BUSD LP',
  //   tokenDescription: 'Mdex',
  //   tokenAddress: '0x0025D20D85788C2cAE2FEB9C298bdaFc93bF08Ce',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooMdexRABBIT-BUSD',
  //   earnedTokenAddress: '0xF191Ff7Ce1BdBaA9149f63eF857b877Dc60f1a56',
  //   earnContractAddress: '0xF191Ff7Ce1BdBaA9149f63eF857b877Dc60f1a56',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'mdex-rabbit-busd',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'Mdex',
  //   assets: ['RABBIT', 'BUSD'],
  //   retireReason: 'tvl',
  //   addLiquidityUrl:
  //     'https://exchange.pancakeswap.finance/#/add/0x95a1199EBA84ac5f19546519e287d43D2F0E1b41/0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
  //   buyTokenUrl:
  //     'https://exchange.pancakeswap.finance/#/swap?inputCurrency=0x95a1199EBA84ac5f19546519e287d43D2F0E1b41&outputCurrency=0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
  //   createdAt: 1630681248,
  // },
  // {
  //   id: 'cakev2-bscdefi-wbnb-eol',
  //   name: 'BSCDEFI-BNB LP',
  //   token: 'BSCDEFI-BNB LP2',
  //   tokenDescription: 'PancakeSwap',
  //   tokenAddress: '0x5B0A3b98C2f01741A11E57A9d0595B254E62F9F2',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooCakeV2BSCDEFI-BNB',
  //   earnedTokenAddress: '0x109272Cc6b89465AdEc21bFB98864b7640CE8A17',
  //   earnContractAddress: '0x109272Cc6b89465AdEc21bFB98864b7640CE8A17',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'cakev2-bscdefi-wbnb',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'PancakeSwap',
  //   assets: ['BSCDEFI', 'BNB'],
  //   retireReason: 'rewards',
  //   addLiquidityUrl:
  //     'https://pancakeswap.finance/add/BNB/0x40E46dE174dfB776BB89E04dF1C47d8a66855EB3',
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?outputCurrency=0x40E46dE174dfB776BB89E04dF1C47d8a66855EB3',
  //   createdAt: 1630671144,
  // },
  // {
  //   id: 'cakev2-bp-wbnb',
  //   name: 'BP-BNB LP',
  //   token: 'BP-BNB LP2',
  //   tokenDescription: 'PancakeSwap',
  //   tokenAddress: '0x2bF2dEB40639201C9A94c9e33b4852D9AEa5fd2D',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooCakeV2BP-BNB',
  //   earnedTokenAddress: '0x6709b27eDeD9c594C4490c98ba8efb3EEb0DE9f4',
  //   earnContractAddress: '0x6709b27eDeD9c594C4490c98ba8efb3EEb0DE9f4',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'cakev2-bp-wbnb',
  //   oraclePrice: 0,
  //   depositsPaused: false,
  //   status: 'active',
  //   platform: 'PancakeSwap',
  //   assets: ['BP', 'BNB'],
  //   addLiquidityUrl:
  //     'https://pancakeswap.finance/add/BNB/0xACB8f52DC63BB752a51186D1c55868ADbFfEe9C1',
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?outputCurrency=0xACB8f52DC63BB752a51186D1c55868ADbFfEe9C1',
  //   createdAt: 1629636078,
  // },
  // {
  //   id: 'cakev2-qbt-wbnb-eol',
  //   name: 'QBT-BNB LP',
  //   token: 'QBT-BNB LP2',
  //   tokenDescription: 'PancakeSwap',
  //   tokenAddress: '0x67EFeF66A55c4562144B9AcfCFbc62F9E4269b3e',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooCakeV2QBT-WBNB',
  //   earnedTokenAddress: '0x6Ab5c71b96dAC5E832fa9B8386D983a53D46ae2F',
  //   earnContractAddress: '0x6Ab5c71b96dAC5E832fa9B8386D983a53D46ae2F',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'cakev2-qbt-wbnb',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'PancakeSwap',
  //   assets: ['QBT', 'BNB'],
  //   retireReason: 'rewards',
  //   addLiquidityUrl:
  //     'https://pancakeswap.finance/add/BNB/0x17B7163cf1Dbd286E262ddc68b553D899B93f526',
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?inputCurrency=BNB&outputCurrency=0x17B7163cf1Dbd286E262ddc68b553D899B93f526',
  //   createdAt: 1630452711,
  // },
  // {
  //   id: 'auro-auro-busd-eol',
  //   name: 'AURO-BUSD LP',
  //   token: 'AURO-BUSD LP',
  //   tokenDescription: 'Viralata',
  //   tokenAddress: '0xBD28311f4AAF9bdCFB02554c8eD26e5dBe13884E',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooViralataAURO-BUSD',
  //   earnedTokenAddress: '0x02503B56D6c0861170E49d2E24cD6409660275BE',
  //   earnContractAddress: '0x02503B56D6c0861170E49d2E24cD6409660275BE',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'auro-auro-busd',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'Other',
  //   assets: ['AURO', 'BUSD'],
  //   addLiquidityUrl:
  //     'https://app.viralata.finance/exchange/add/0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56/0x8d9A79314c4e09A7c53C124195cAeB8B89F4879D',
  //   buyTokenUrl:
  //     'https://app.viralata.finance/exchange/swap?inputCurrency=0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56&outputCurrency=0x8d9A79314c4e09A7c53C124195cAeB8B89F4879D',
  //   createdAt: 1630068753,
  //   retiredReason: 'tvl',
  // },
  // {
  //   id: 'auro-auro-bnb-eol',
  //   name: 'AURO-BNB LP',
  //   token: 'AURO-BNB LP',
  //   tokenDescription: 'Viralata',
  //   tokenAddress: '0x202be643545e361b58bb8A2D8413cea0403C9eFa',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooViralataAURO-BNB',
  //   earnedTokenAddress: '0x7cBab71cB057b2167976546b6874ce0f0d09F7FA',
  //   earnContractAddress: '0x7cBab71cB057b2167976546b6874ce0f0d09F7FA',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'auro-auro-bnb',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'Other',
  //   assets: ['AURO', 'BNB'],
  //   addLiquidityUrl:
  //     'https://app.viralata.finance/exchange/add/ETH/0x8d9A79314c4e09A7c53C124195cAeB8B89F4879D',
  //   buyTokenUrl:
  //     'https://app.viralata.finance/exchange/swap?inputCurrency=0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56&outputCurrency=0x8d9A79314c4e09A7c53C124195cAeB8B89F4879D',
  //   createdAt: 1630069109,
  //   retiredReason: 'tvl',
  // },
  // {
  //   id: 'elk-elk-bnb-eol',
  //   name: 'ELK-BNB ELP',
  //   token: 'ELK-BNB ELP',
  //   tokenDescription: 'ElkFinance',
  //   tokenAddress: '0x6aDB3c5a9673ffFe6502e3bdEC465AdC91Ab3C01',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooElkELK-BNB',
  //   earnedTokenAddress: '0x69B2f487B2eDD9E272211f7CD8f57C1cddE0764E',
  //   earnContractAddress: '0x69B2f487B2eDD9E272211f7CD8f57C1cddE0764E',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'elk-elk-bnb',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'Other',
  //   assets: ['ELK', 'BNB'],
  //   retireReason: 'rewards',
  //   addLiquidityUrl: 'https://app.elk.finance/#/add/BNB/0xE1C110E1B1b4A1deD0cAf3E42BfBdbB7b5d7cE1C',
  //   buyTokenUrl:
  //     'https://app.elk.finance/#/swap?outputCurrency=0xE1C110E1B1b4A1deD0cAf3E42BfBdbB7b5d7cE1C',
  //   createdAt: 1630229724,
  // },
  // {
  //   id: 'elk-elk-dai-eol',
  //   name: 'ELK-DAI ELP',
  //   token: 'ELK-DAI ELP',
  //   tokenDescription: 'ElkFinance',
  //   tokenAddress: '0xFDB340B7902B5DC6A0F3434246767BFfA841b9c1',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooElkELK-DAI',
  //   earnedTokenAddress: '0x41a6CdA789316B2Fa5D824EF27E4223872E4cB34',
  //   earnContractAddress: '0x41a6CdA789316B2Fa5D824EF27E4223872E4cB34',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'elk-elk-dai',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'Other',
  //   assets: ['ELK', 'DAI'],
  //   retireReason: 'rewards',
  //   addLiquidityUrl:
  //     'https://app.elk.finance/#/add/0x1AF3F329e8BE154074D8769D1FFa4eE058B1DBc3/0xE1C110E1B1b4A1deD0cAf3E42BfBdbB7b5d7cE1C',
  //   buyTokenUrl:
  //     'https://app.elk.finance/#/swap?outputCurrency=0xE1C110E1B1b4A1deD0cAf3E42BfBdbB7b5d7cE1C',
  //   createdAt: 1630241116,
  // },
  // {
  //   id: 'wex-wusd-wex-eol',
  //   name: 'WUSD-WEX WLP',
  //   token: 'WUSD-WEX WLP',
  //   tokenDescription: 'WaultFinance',
  //   tokenAddress: '0xE5821F993A6578033c0bA38bc2B0F7bCF74F5Dc3',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooWaultWUSD-WEX',
  //   earnedTokenAddress: '0xa5Ba15875afA08e2C86F67AE2958B560A50a3823',
  //   earnContractAddress: '0xa5Ba15875afA08e2C86F67AE2958B560A50a3823',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'wex-wusd-wex',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   retireReason: 'rewards',
  //   platform: 'Other',
  //   assets: ['WUSD', 'WEX'],
  //   addLiquidityUrl:
  //     'https://swap.wault.finance/bsc/#/add/0x3fF997eAeA488A082fb7Efc8e6B9951990D0c3aB/0xa9c41A46a6B3531d28d5c32F6633dd2fF05dFB90',
  //   buyTokenUrl:
  //     'https://swap.wault.finance/bsc/#/swap?inputCurrency=0x3fF997eAeA488A082fb7Efc8e6B9951990D0c3aB&outputCurrency=0xa9c41A46a6B3531d28d5c32F6633dd2fF05dFB90',
  //   createdAt: 1630087549,
  // },
  // {
  //   id: 'cakev2-naos-wbnb-eol',
  //   name: 'NAOS-BNB LP',
  //   token: 'NAOS-BNB LP2',
  //   tokenDescription: 'PancakeSwap',
  //   tokenAddress: '0xCAA662aD41a662b81be2AEa5D59EC0697628665F',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooCakeV2NAOS-BNB',
  //   earnedTokenAddress: '0x1D13dF9D7012914f45B8BAD8A2a9b1E9886B2173',
  //   earnContractAddress: '0x1D13dF9D7012914f45B8BAD8A2a9b1E9886B2173',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'cakev2-naos-wbnb',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'PancakeSwap',
  //   assets: ['NAOS', 'BNB'],
  //   retireReason: 'rewards',
  //   addLiquidityUrl:
  //     'https://pancakeswap.finance/add/0x758d08864fB6cCE3062667225ca10b8F00496cc2/BNB',
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?outputCurrency=0x758d08864fB6cCE3062667225ca10b8F00496cc2',
  //   createdAt: 1630063783,
  // },
  // {
  //   id: 'cakev2-pha-busd-eol',
  //   name: 'PHA-BUSD LP',
  //   token: 'PHA-BUSD LP2',
  //   tokenDescription: 'PancakeSwap',
  //   tokenAddress: '0x4ddd56e2f34338839BB5953515833950eA680aFb',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooCakeV2PHA-BUSD',
  //   earnedTokenAddress: '0xb911A102D2db3216c800456930EA4B19beD78C6C',
  //   earnContractAddress: '0xb911A102D2db3216c800456930EA4B19beD78C6C',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'cakev2-pha-busd',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'PancakeSwap',
  //   assets: ['PHA', 'BUSD'],
  //   retireReason: 'rewards',
  //   addLiquidityUrl:
  //     'https://pancakeswap.finance/add/0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56/0x0112e557d400474717056C4e6D40eDD846F38351',
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?outputCurrency=0x0112e557d400474717056C4e6D40eDD846F38351',
  //   createdAt: 1630064017,
  // },
  // {
  //   id: 'omnifarm-usdo-busd-ot-eol',
  //   name: 'BUSD-USDO OCP LP',
  //   token: 'BUSD-USDO OCP LP',
  //   tokenDescription: 'Omnifarm (Omnitrade)',
  //   tokenAddress: '0x1597b1a1E4F387724FbF90D3F7Ff8c883f8e8dE1',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooOmnifarmOmnitradeBUSD-USDO',
  //   earnedTokenAddress: '0x73f9F03A7f639a1AC874445153c09f16cED9316b',
  //   earnContractAddress: '0x73f9F03A7f639a1AC874445153c09f16cED9316b',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'omnifarm-usdo-busd-ot',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   retireReason: 'rewards',
  //   platform: 'Other',
  //   assets: ['USDO', 'BUSD'],
  //   addLiquidityUrl: 'https://omnitrade.ocp.finance/#/pools',
  //   buyTokenUrl: 'https://omnitrade.ocp.finance/#/',
  //   createdAt: 1630278952,
  // },
  // {
  //   id: 'omnifarm-usdo-busd-eol',
  //   name: 'USDO-BUSD LP',
  //   token: 'USDO-BUSD LP2',
  //   tokenDescription: 'Omnifarm (PancakeSwap)',
  //   tokenAddress: '0x2220EcdD9ff26DfD2605D2b489E55B2056159853',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooOmnifarmUSDO-BUSD',
  //   earnedTokenAddress: '0x76adF521dBFA9822937eCB1C15ed60f95B519FF8',
  //   earnContractAddress: '0x76adF521dBFA9822937eCB1C15ed60f95B519FF8',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'omnifarm-usdo-busd',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   retireReason: 'rewards',
  //   platform: 'Other',
  //   assets: ['USDO', 'BUSD'],
  //   addLiquidityUrl:
  //     'https://pancakeswap.finance/add/0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56/0x5801D0e1C7D977D78E4890880B8E579eb4943276',
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?inputCurrency=0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56&outputCurrency=0x5801D0e1C7D977D78E4890880B8E579eb4943276',
  //   createdAt: 1629968065,
  // },
  // {
  //   id: 'omnifarm-usdo-wbnb-eol',
  //   name: 'USDO-BNB LP',
  //   token: 'USDO-BNB LP2',
  //   tokenDescription: 'Omnifarm (PancakeSwap)',
  //   tokenAddress: '0x8518D5906A6C72b0157d55caFf239dc43c19AbF6',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooOmnifarmUSDO-WBNB',
  //   earnedTokenAddress: '0x95b7Ac779Dc476fa4Fe62B8c97F7f71913DFfB76',
  //   earnContractAddress: '0x95b7Ac779Dc476fa4Fe62B8c97F7f71913DFfB76',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'omnifarm-usdo-wbnb',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   retireReason: 'rewards',
  //   platform: 'Other',
  //   assets: ['USDO', 'BNB'],
  //   addLiquidityUrl:
  //     'https://pancakeswap.finance/add/BNB/0x5801D0e1C7D977D78E4890880B8E579eb4943276',
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?outputCurrency=0x5801D0e1C7D977D78E4890880B8E579eb4943276',
  //   createdAt: 1629960094,
  // },
  // {
  //   id: 'omnifarm-usdo-bdp-eol',
  //   name: 'USDO-BDP LP',
  //   token: 'USDO-BDP LP2',
  //   tokenDescription: 'Omnifarm (PancakeSwap)',
  //   tokenAddress: '0xc5Ca9F1bb9a88Bac08368e7085984dB113daEc49',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooOmnifarmUSDO-BDP',
  //   earnedTokenAddress: '0x4D2a4dE1Add1931633188E1351d7cBfc2b8C377C',
  //   earnContractAddress: '0x4D2a4dE1Add1931633188E1351d7cBfc2b8C377C',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'omnifarm-usdo-bdp',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   retireReason: 'rewards',
  //   platform: 'Other',
  //   assets: ['BDP', 'USDO'],
  //   addLiquidityUrl:
  //     'https://pancakeswap.finance/add/0x685b63CFE0179b3EFb70A01dCb1D648549AA192d/0x5801D0e1C7D977D78E4890880B8E579eb4943276',
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?inputCurrency=0x685b63CFE0179b3EFb70A01dCb1D648549AA192d&outputCurrency=0x5801D0e1C7D977D78E4890880B8E579eb4943276',
  //   createdAt: 1629968965,
  // },
  // {
  //   id: 'omnifarm-usdo-popen-eol',
  //   name: 'USDO-pOPEN LP',
  //   token: 'USDO-pOPEN LP2',
  //   tokenDescription: 'Omnifarm (PancakeSwap)',
  //   tokenAddress: '0x07558dD9c7d478C67163760b5F80D19E6f5A1D5F',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooOmnifarmUSDO-pOPEN',
  //   earnedTokenAddress: '0xF09EB81f36E3e9De1339300d692f272Cc889A152',
  //   earnContractAddress: '0xF09EB81f36E3e9De1339300d692f272Cc889A152',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'omnifarm-usdo-popen',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'Other',
  //   assets: ['pOPEN', 'USDO'],
  //   addLiquidityUrl:
  //     'https://pancakeswap.finance/add/0xaBaE871B7E3b67aEeC6B46AE9FE1A91660AadAC5/0x5801D0e1C7D977D78E4890880B8E579eb4943276',
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?inputCurrency=0xaBaE871B7E3b67aEeC6B46AE9FE1A91660AadAC5&outputCurrency=0x5801D0e1C7D977D78E4890880B8E579eb4943276',
  //   createdAt: 1629971041,
  //   retiredReason: 'tvl',
  // },
  // {
  //   id: 'omnifarm-usdo-anymtlx-eol',
  //   name: 'USDO-anyMTLX LP',
  //   token: 'USDO-anyMTLX LP2',
  //   tokenDescription: 'Omnifarm (PancakeSwap)',
  //   tokenAddress: '0xa4c50baC074769449e46C889920A5aFd8E6ED9e0',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooOmnifarmUSDO-anyMTLX',
  //   earnedTokenAddress: '0xDeD7c3dED606b3a97B206C2a2d9f724Eb43Aa738',
  //   earnContractAddress: '0xDeD7c3dED606b3a97B206C2a2d9f724Eb43Aa738',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'omnifarm-usdo-anymtlx',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'Other',
  //   assets: ['anyMTLX', 'USDO'],
  //   addLiquidityUrl:
  //     'https://pancakeswap.finance/add/0x5921DEE8556c4593EeFCFad3CA5e2f618606483b/0x5801D0e1C7D977D78E4890880B8E579eb4943276',
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?inputCurrency=0x5921DEE8556c4593EeFCFad3CA5e2f618606483b&outputCurrency=0x5801D0e1C7D977D78E4890880B8E579eb4943276',
  //   createdAt: 1629973021,
  //   retiredReason: 'tvl',
  // },
  // {
  //   id: 'omnifarm-fet-usdo-eol',
  //   name: 'USDO-FET LP',
  //   token: 'USDO-FET LP2',
  //   tokenDescription: 'Omnifarm (PancakeSwap)',
  //   tokenAddress: '0x184d310749D4E86635b146F6F6E75900A22AF733',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooOmnifarmUSDO-FET',
  //   earnedTokenAddress: '0x88194Ca4c2372892C8E6eEaC7B33F713EECCd604',
  //   earnContractAddress: '0x88194Ca4c2372892C8E6eEaC7B33F713EECCd604',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'omnifarm-fet-usdo',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   retireReason: 'rewards',
  //   platform: 'Other',
  //   assets: ['FET', 'USDO'],
  //   addLiquidityUrl:
  //     'https://pancakeswap.finance/add/0x031b41e504677879370e9dbcf937283a8691fa7f/0x5801d0e1c7d977d78e4890880b8e579eb4943276',
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?inputCurrency=0x031b41e504677879370e9DBcF937283A8691Fa7f&outputCurrency=0x5801D0e1C7D977D78E4890880B8E579eb4943276',
  //   createdAt: 1629974875,
  // },
  // {
  //   id: 'omnifarm-ocp-usdo-eol',
  //   name: 'OCP-USDO LP',
  //   token: 'OCP-USDO LP2',
  //   tokenDescription: 'Omnifarm (PancakeSwap)',
  //   tokenAddress: '0xe595fF226bF6e30479ef8D7fa3DA4086194DB42f',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooOmnifarmOCP-USDO',
  //   earnedTokenAddress: '0xCB214C265e8Bc37B6d7df1601cB7dA35EC8F89c6',
  //   earnContractAddress: '0xCB214C265e8Bc37B6d7df1601cB7dA35EC8F89c6',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'omnifarm-ocp-usdo',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'Other',
  //   assets: ['OCP', 'USDO'],
  //   addLiquidityUrl:
  //     'https://pancakeswap.finance/add/0x3C70260eEe0a2bFc4b375feB810325801f289fBd/0x5801d0e1c7d977d78e4890880b8e579eb4943276',
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?inputCurrency=0x3C70260eEe0a2bFc4b375feB810325801f289fBd&outputCurrency=0x5801D0e1C7D977D78E4890880B8E579eb4943276',
  //   createdAt: 1629976449,
  //   retiredReason: 'tvl',
  // },
  // {
  //   id: 'tosdisbsc-dis-wbnb-eol',
  //   name: 'DIS-BNB LP',
  //   token: 'DIS-BNB LP2',
  //   tokenDescription: 'TosDis (PancakeSwap)',
  //   tokenAddress: '0x67F18f3784fEd30652eB724CB1C174A72FEB450B',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooTosDisDIS-BNB',
  //   earnedTokenAddress: '0x7FdC26B1381A53dBB6B65984857c55746f60d156',
  //   earnContractAddress: '0x7FdC26B1381A53dBB6B65984857c55746f60d156',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'tosdisbsc-dis-wbnb',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   retireReason: 'rewards',
  //   platform: 'Other',
  //   assets: ['DIS', 'BNB'],
  //   addLiquidityUrl:
  //     'https://pancakeswap.finance/add/BNB/0x57efFdE2759b68d86C544e88F7977e3314144859',
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?outputCurrency=0x57efFdE2759b68d86C544e88F7977e3314144859',
  //   createdAt: 1629835777,
  // },
  // {
  //   id: 'banana-ont-bnb',
  //   logo: 'bnb-pairs/ONT-BNB.svg',
  //   name: 'ONT-BNB LP',
  //   token: 'ONT-BNB LP',
  //   tokenDescription: 'ApeSwap',
  //   tokenAddress: '0x924D3f2F94618e8Ee837d4C2b8d703F0de12a57e',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooApeONT-BNB',
  //   earnedTokenAddress: '0xf8ED3D2E36eA4eA13eA4cCcaD5c72ac55B64e651',
  //   earnContractAddress: '0xf8ED3D2E36eA4eA13eA4cCcaD5c72ac55B64e651',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'banana-ont-bnb',
  //   oraclePrice: 0,
  //   depositsPaused: false,
  //   status: 'active',
  //   platform: 'ApeSwap',
  //   assets: ['ONT', 'BNB'],
  //   addLiquidityUrl:
  //     'https://dex.apeswap.finance/#/add/0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c/0xfd7b3a77848f1c2d67e05e54d78d174a0c850335',
  //   buyTokenUrl:
  //     'https://dex.apeswap.finance/#/swap?inputCurrency=0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c&outputCurrency=0xfd7b3a77848f1c2d67e05e54d78d174a0c850335',
  //   createdAt: 1619632017,
  // },
  // {
  //   id: 'wex-bnb-ont-eol',
  //   name: 'ONT-BNB WLP',
  //   token: 'ONT-BNB WLP',
  //   tokenDescription: 'WaultFinance',
  //   tokenAddress: '0x37d7317149a4B5Cf5B6b81B04A23DeD16f4e4d1e',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooWaultBNB-ONT',
  //   earnedTokenAddress: '0x9b188c2C76a01bb290A17FadB65BD400Dc3F53F0',
  //   earnContractAddress: '0x9b188c2C76a01bb290A17FadB65BD400Dc3F53F0',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'wex-bnb-ont',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   retireReason: 'rewards',
  //   status: 'eol',
  //   platform: 'Other',
  //   assets: ['ONT', 'BNB'],
  //   addLiquidityUrl:
  //     'https://swap.wault.finance/bsc/#/add/BNB/0xFd7B3A77848f1C2D67E05E54d78d174a0C850335',
  //   buyTokenUrl:
  //     'https://swap.wault.finance/bsc/#/swap?outputCurrency=0xFd7B3A77848f1C2D67E05E54d78d174a0C850335',
  //   createdAt: 1627400931,
  // },
  // {
  //   id: 'cake-cakev2-eol',
  //   logo: 'single-assets/CAKE.svg',
  //   name: 'CAKE',
  //   token: 'CAKE',
  //   tokenDescription: 'PancakeSwap',
  //   tokenAddress: '0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82',
  //   tokenDecimals: 18,
  //   earnedToken: 'mooCakeV2',
  //   earnedTokenAddress: '0x97e5d50Fe0632A95b9cf1853E744E02f7D816677',
  //   earnContractAddress: '0x97e5d50Fe0632A95b9cf1853E744E02f7D816677',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'tokens',
  //   oracleId: 'Cake',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   retireReason: 'rewards',
  //   platform: 'PancakeSwap',
  //   assets: ['CAKE'],
  //   withdrawalFee: '0%',
  //   risks: [
  //     'COMPLEXITY_LOW',
  //     'BATTLE_TESTED',
  //     'IL_NONE',
  //     'MCAP_LARGE',
  //     'AUDIT',
  //     'CONTRACTS_VERIFIED',
  //   ],
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?outputCurrency=0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82',
  //   createdAt: 1623414597,
  // },
  // {
  //   id: 'yel-yel',
  //   logo: 'single-assets/YEL.svg',
  //   name: 'YEL',
  //   token: 'YEL',
  //   tokenDescription: 'YEL',
  //   tokenAddress: '0xD3b71117E6C1558c1553305b44988cd944e97300',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooYel',
  //   earnedTokenAddress: '0xC5C11A531e60378c605383dd54ad1e4C598aD93A',
  //   earnContractAddress: '0xC5C11A531e60378c605383dd54ad1e4C598aD93A',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'tokens',
  //   oracleId: 'YEL',
  //   oraclePrice: 0,
  //   depositsPaused: false,
  //   status: 'active',
  //   platform: 'Other',
  //   assets: ['YEL'],
  //   withdrawalFee: '0%',
  //   buyTokenUrl:
  //     'https://app.apeswap.finance/swap?outputCurrency=0xD3b71117E6C1558c1553305b44988cd944e97300',
  //   createdAt: 1629797899,
  // },
  // {
  //   id: 'yel-yel-wbnb',
  //   name: 'YEL-BNB ALP',
  //   token: 'YEL-BNB ALP',
  //   tokenDescription: 'YEL (ApeSwap)',
  //   tokenAddress: '0xa5AC78A856345AB1FC8a4550208F42ca34B54e56',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooYelYEL-BNB',
  //   earnedTokenAddress: '0xE2f9EC1898F8C759cD1FeeC9c035CAD8071a5d4B',
  //   earnContractAddress: '0xE2f9EC1898F8C759cD1FeeC9c035CAD8071a5d4B',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'yel-yel-wbnb',
  //   oraclePrice: 0,
  //   depositsPaused: false,
  //   status: 'active',
  //   platform: 'Other',
  //   assets: ['YEL', 'BNB'],
  //   addLiquidityUrl:
  //     'https://app.apeswap.finance/add/ETH/0xD3b71117E6C1558c1553305b44988cd944e97300',
  //   buyTokenUrl:
  //     'https://app.apeswap.finance/swap?outputCurrency=0xD3b71117E6C1558c1553305b44988cd944e97300',
  //   createdAt: 1629802727,
  // },
  // {
  //   id: 'banana-bhc-bnb-eol',
  //   name: 'BHC-BNB LP',
  //   token: 'BHC-BNB ALP',
  //   tokenDescription: 'ApeSwap',
  //   tokenAddress: '0x59B2A38F67e312D312121614d1Daa7ad8c5773fC',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooApeBHC-BNB',
  //   earnedTokenAddress: '0x6023C372e5feeD42664986094914f08bBBa49f36',
  //   earnContractAddress: '0x6023C372e5feeD42664986094914f08bBBa49f36',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'banana-bhc-bnb',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   retireReason: 'rewards',
  //   platform: 'ApeSwap',
  //   assets: ['BHC', 'BNB'],
  //   addLiquidityUrl:
  //     'https://dex.apeswap.finance/#/add/ETH/0x6fd7c98458a943f469E1Cf4eA85B173f5Cd342F4',
  //   buyTokenUrl:
  //     'https://dex.apeswap.finance/#/swap?outputCurrency=0x6fd7c98458a943f469E1Cf4eA85B173f5Cd342F4',
  //   createdAt: 1629458274,
  // },
  // {
  //   id: 'banana-caps-bnb-eol',
  //   name: 'CAPS-BNB LP',
  //   token: 'CAPS-BNB ALP',
  //   tokenDescription: 'ApeSwap',
  //   tokenAddress: '0x6Dfbf17ac70CE03388B1f88cb3C97AD79120e7b1',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooApeCAPS-BNB',
  //   earnedTokenAddress: '0x9FF87464352156C7C9D700A40307121479A26754',
  //   earnContractAddress: '0x9FF87464352156C7C9D700A40307121479A26754',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'banana-caps-bnb',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   retireReason: 'rewards',
  //   platform: 'ApeSwap',
  //   assets: ['CAPS', 'BNB'],
  //   addLiquidityUrl:
  //     'https://dex.apeswap.finance/#/add/ETH/0xFfBa7529AC181c2Ee1844548e6D7061c9A597dF4',
  //   buyTokenUrl:
  //     'https://dex.apeswap.finance/#/swap?outputCurrency=0xFfBa7529AC181c2Ee1844548e6D7061c9A597dF4',
  //   createdAt: 1629370054,
  // },
  // {
  //   id: 'honeyfarm-bear-bnb-eol',
  //   name: 'BEAR-BNB LP',
  //   token: 'BEAR-BNB LP',
  //   tokenDescription: 'PancakeSwap (HoneyFarm)',
  //   tokenAddress: '0xC5E96F03414DA6E7eA6D2F051D23964C0B80739F',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooHoneyFarmBEAR-BNB',
  //   earnedTokenAddress: '0x49787E72B7dC4C0f5D705A3A86Fc7fc5DBC7e959',
  //   earnContractAddress: '0x49787E72B7dC4C0f5D705A3A86Fc7fc5DBC7e959',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'honeyfarm-bear-bnb',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'Other',
  //   assets: ['BEAR', 'BNB'],
  //   addLiquidityUrl:
  //     'https://pancakeswap.finance/add/BNB/0xc3EAE9b061Aa0e1B9BD3436080Dc57D2d63FEdc1',
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?outputCurrency=0xc3EAE9b061Aa0e1B9BD3436080Dc57D2d63FEdc1',
  //   createdAt: 1629121148,
  // },
  // {
  //   id: 'honeyfarm-bear-busd-eol',
  //   name: 'BEAR-BUSD LP',
  //   token: 'BEAR-BUSD LP',
  //   tokenDescription: 'PancakeSwap (HoneyFarm)',
  //   tokenAddress: '0x9DD10B5d23A05bBAC9E9Ae0C7ca9890791eABb25',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooHoneyFarmBEAR-BUSD',
  //   earnedTokenAddress: '0x04C549551F536ceB015e79F2B242024800eeC206',
  //   earnContractAddress: '0x04C549551F536ceB015e79F2B242024800eeC206',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'honeyfarm-bear-busd',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'Other',
  //   assets: ['BEAR', 'BUSD'],
  //   addLiquidityUrl:
  //     'https://pancakeswap.finance/add/0xe9e7cea3dedca5984780bafc599bd69add087d56/0xc3EAE9b061Aa0e1B9BD3436080Dc57D2d63FEdc1',
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?outputCurrency=0xc3EAE9b061Aa0e1B9BD3436080Dc57D2d63FEdc1',
  //   createdAt: 1629120594,
  // },
  // {
  //   id: 'honeyfarm-honey-bnb-eol2',
  //   name: 'HONEY-BNB LP',
  //   token: 'HONEY-BNB LP',
  //   tokenDescription: 'PancakeSwap (HoneyFarm)',
  //   tokenAddress: '0x27418bf2E38E613560D2281dB6590f3f6D446259',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooHoneyFarmHONEY-BNB',
  //   earnedTokenAddress: '0xA78B4895a1a5d8Dd960C436fe3798f6242C585EF',
  //   earnContractAddress: '0xA78B4895a1a5d8Dd960C436fe3798f6242C585EF',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'honeyfarm-honey-bnb',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'Other',
  //   assets: ['HONEY', 'BNB'],
  //   addLiquidityUrl:
  //     'https://pancakeswap.finance/add/BNB/0xFa363022816aBf82f18a9C2809dCd2BB393F6AC5',
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?outputCurrency=0xFa363022816aBf82f18a9C2809dCd2BB393F6AC5',
  //   createdAt: 1629120042,
  // },
  // {
  //   id: 'honeyfarm-honey-busd-eol2',
  //   name: 'HONEY-BUSD LP',
  //   token: 'HONEY-BUSD LP',
  //   tokenDescription: 'PancakeSwap (HoneyFarm)',
  //   tokenAddress: '0x2961aa26a1Cb3068E580099045cc79a5b7B9634c',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooHoneyFarmHONEY-BUSD',
  //   earnedTokenAddress: '0x1F40e7Cb04Ec64Ef82568E49Db60858188641Bb1',
  //   earnContractAddress: '0x1F40e7Cb04Ec64Ef82568E49Db60858188641Bb1',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'honeyfarm-honey-busd',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'Other',
  //   assets: ['HONEY', 'BUSD'],
  //   addLiquidityUrl:
  //     'https://pancakeswap.finance/add/0xe9e7cea3dedca5984780bafc599bd69add087d56/0xFa363022816aBf82f18a9C2809dCd2BB393F6AC5',
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?outputCurrency=0xFa363022816aBf82f18a9C2809dCd2BB393F6AC5',
  //   createdAt: 1629119706,
  // },
  // {
  //   id: 'banana-hotcross-busd-eol',
  //   name: 'HOTCROSS-BUSD LP',
  //   token: 'HOTCROSS-BUSD LP',
  //   tokenDescription: 'ApeSwap',
  //   tokenAddress: '0x3B9Aa711D1D90A4f8639f66C227881729a3317F2',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooBananaHOTCROSS-BUSD',
  //   earnedTokenAddress: '0xF3a599fe4E0f7ce7f5dDA19B1b54FDf82cdA7520',
  //   earnContractAddress: '0xF3a599fe4E0f7ce7f5dDA19B1b54FDf82cdA7520',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'banana-hotcross-busd',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   retireReason: 'tvl',
  //   platform: 'ApeSwap',
  //   assets: ['HOTCROSS', 'BUSD'],
  //   addLiquidityUrl:
  //     'https://app.apeswap.finance/add/0x4FA7163E153419E0E1064e418dd7A99314Ed27b6/0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
  //   buyTokenUrl:
  //     'https://dex.apeswap.finance/#/swap?inputCurrency=0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56c&outputCurrency=0x4FA7163E153419E0E1064e418dd7A99314Ed27b6',
  //   createdAt: 1629125037,
  // },
  // {
  //   id: 'banana-eth-bnb',
  //   name: 'ETH-BNB LP',
  //   token: 'ETH-BNB ALP',
  //   tokenDescription: 'ApeSwap',
  //   tokenAddress: '0xA0C3Ef24414ED9C9B456740128d8E63D016A9e11',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooApeETH-BNB',
  //   earnedTokenAddress: '0xc259D613510A70A92DcDd43F8ecED2F2143758cE',
  //   earnContractAddress: '0xc259D613510A70A92DcDd43F8ecED2F2143758cE',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'banana-eth-bnb',
  //   oraclePrice: 0,
  //   depositsPaused: false,
  //   status: 'active',
  //   platform: 'ApeSwap',
  //   assets: ['ETH', 'BNB'],
  //   addLiquidityUrl:
  //     'https://dex.apeswap.finance/#/add/ETH/0x2170Ed0880ac9A755fd29B2688956BD959F933F8',
  //   buyTokenUrl:
  //     'https://dex.apeswap.finance/#/swap?outputCurrency=0x2170Ed0880ac9A755fd29B2688956BD959F933F8',
  //   createdAt: 1629037614,
  // },
  // {
  //   id: 'banana-busd-bnb',
  //   name: 'BUSD-BNB LP',
  //   token: 'BUSD-BNB ALP',
  //   tokenDescription: 'ApeSwap',
  //   tokenAddress: '0x51e6D27FA57373d8d4C256231241053a70Cb1d93',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooApeBUSD-BNB',
  //   earnedTokenAddress: '0x6Dfc38DB4c92A1CBd9e4196F6c7741178B0fC2cd',
  //   earnContractAddress: '0x6Dfc38DB4c92A1CBd9e4196F6c7741178B0fC2cd',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'banana-busd-bnb',
  //   oraclePrice: 0,
  //   depositsPaused: false,
  //   status: 'active',
  //   platform: 'ApeSwap',
  //   assets: ['BUSD', 'BNB'],
  //   risks: [
  //     'COMPLEXITY_LOW',
  //     'BATTLE_TESTED',
  //     'IL_HIGH',
  //     'MCAP_LARGE',
  //     'PLATFORM_ESTABLISHED',
  //     'AUDIT',
  //     'CONTRACTS_VERIFIED',
  //   ],
  //   stratType: 'StratLP',
  //   addLiquidityUrl:
  //     'https://dex.apeswap.finance/#/add/ETH/0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
  //   buyTokenUrl:
  //     'https://dex.apeswap.finance/#/swap?outputCurrency=0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
  //   createdAt: 1629037860,
  // },
  // {
  //   id: 'banana-sxp-bnb',
  //   name: 'SXP-BNB LP',
  //   token: 'SXP-BNB ALP',
  //   tokenDescription: 'ApeSwap',
  //   tokenAddress: '0xF726b3e81FA7166B9C2cFd7eB7fe8CcBcb6B1355',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooApeSXP-BNB',
  //   earnedTokenAddress: '0xd7738105fd1990DFcaD848EF69eF88700a88507C',
  //   earnContractAddress: '0xd7738105fd1990DFcaD848EF69eF88700a88507C',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'banana-sxp-bnb',
  //   oraclePrice: 0,
  //   depositsPaused: false,
  //   status: 'active',
  //   platform: 'ApeSwap',
  //   assets: ['SXP', 'BNB'],
  //   addLiquidityUrl:
  //     'https://dex.apeswap.finance/#/add/ETH/0x47BEAd2563dCBf3bF2c9407fEa4dC236fAbA485A',
  //   buyTokenUrl:
  //     'https://dex.apeswap.finance/#/swap?outputCurrency=0x47BEAd2563dCBf3bF2c9407fEa4dC236fAbA485A',
  //   createdAt: 1629038022,
  // },
  // {
  //   id: 'banana-bake-bnb-eol',
  //   name: 'BAKE-BNB LP',
  //   token: 'BAKE-BNB ALP',
  //   tokenDescription: 'ApeSwap',
  //   tokenAddress: '0xc1C7a1D33B34019F82808F64bA07e77512a13d1A',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooApeBAKE-BNB',
  //   earnedTokenAddress: '0x4cE3d66dc02f72bFBF1785709ede69cBc1b6E86F',
  //   earnContractAddress: '0x4cE3d66dc02f72bFBF1785709ede69cBc1b6E86F',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'banana-bake-bnb',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   retireReason: 'rewards',
  //   platform: 'ApeSwap',
  //   assets: ['BAKE', 'BNB'],
  //   addLiquidityUrl:
  //     'https://dex.apeswap.finance/#/add/ETH/0xE02dF9e3e622DeBdD69fb838bB799E3F168902c5',
  //   buyTokenUrl:
  //     'https://dex.apeswap.finance/#/swap?outputCurrency=0xE02dF9e3e622DeBdD69fb838bB799E3F168902c5',
  //   createdAt: 1629038163,
  // },
  // {
  //   id: 'wex-wusd-busd-eol',
  //   name: 'WUSD-BUSD WLP',
  //   token: 'WUSD-BUSD WLP',
  //   tokenDescription: 'WaultFinance',
  //   tokenAddress: '0x6102D8A7C963F78D46a35a6218B0DB4845d1612F',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooWaultWUSD-BUSD',
  //   earnedTokenAddress: '0x3D1BF2D911a0068B5733E84228AA6eB9b908671f',
  //   earnContractAddress: '0x3D1BF2D911a0068B5733E84228AA6eB9b908671f',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'wex-wusd-busd',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   retireReason: 'rewards',
  //   platform: 'Other',
  //   assets: ['WUSD', 'BUSD'],
  //   addLiquidityUrl:
  //     'https://swap.wault.finance/bsc/#/add/0xe9e7cea3dedca5984780bafc599bd69add087d56/0x3fF997eAeA488A082fb7Efc8e6B9951990D0c3aB',
  //   buyTokenUrl:
  //     'https://swap.wault.finance/bsc/#/swap?inputCurrency=0xe9e7cea3dedca5984780bafc599bd69add087d56&outputCurrency=0x3fF997eAeA488A082fb7Efc8e6B9951990D0c3aB',
  //   createdAt: 1628010235,
  // },
  // {
  //   id: 'banana-cake-bnb',
  //   name: 'CAKE-BNB LP',
  //   token: 'CAKE-BNB ALP',
  //   tokenDescription: 'ApeSwap',
  //   tokenAddress: '0x60593Abea55e9Ea9d31c1b6473191cD2475a720D',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooApeCAKE-BNB',
  //   earnedTokenAddress: '0x2337AEc28Bd34639277E321C67571623940A5b1D',
  //   earnContractAddress: '0x2337AEc28Bd34639277E321C67571623940A5b1D',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'banana-cake-bnb',
  //   oraclePrice: 0,
  //   depositsPaused: false,
  //   status: 'active',
  //   platform: 'ApeSwap',
  //   assets: ['CAKE', 'BNB'],
  //   addLiquidityUrl:
  //     'https://dex.apeswap.finance/#/add/ETH/0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82',
  //   buyTokenUrl:
  //     'https://dex.apeswap.finance/#/swap?outputCurrency=0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82',
  //   createdAt: 1628871779,
  // },
  // {
  //   id: 'banana-btcb-bnb',
  //   name: 'BTCB-BNB LP',
  //   token: 'BTCB-BNB ALP',
  //   tokenDescription: 'ApeSwap',
  //   tokenAddress: '0x1E1aFE9D9c5f290d8F6996dDB190bd111908A43D',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooApeBTCB-BNB',
  //   earnedTokenAddress: '0x5B5da11711C1CaA47550137c481ee1516c136D4a',
  //   earnContractAddress: '0x5B5da11711C1CaA47550137c481ee1516c136D4a',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'banana-btcb-bnb',
  //   oraclePrice: 0,
  //   depositsPaused: false,
  //   status: 'active',
  //   platform: 'ApeSwap',
  //   risks: [
  //     'COMPLEXITY_LOW',
  //     'BATTLE_TESTED',
  //     'IL_HIGH',
  //     'MCAP_LARGE',
  //     'PLATFORM_ESTABLISHED',
  //     'AUDIT',
  //     'CONTRACTS_VERIFIED',
  //   ],
  //   stratType: 'StratLP',
  //   assets: ['BTCB', 'BNB'],
  //   addLiquidityUrl:
  //     'https://dex.apeswap.finance/#/add/ETH/0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c',
  //   buyTokenUrl:
  //     'https://dex.apeswap.finance/#/swap?outputCurrency=0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c',
  //   createdAt: 1628871569,
  // },
  // {
  //   id: 'banana-banana-eol',
  //   logo: 'degens/BANANA.svg',
  //   name: 'BANANA',
  //   token: 'BANANA',
  //   tokenDescription: 'ApeSwap',
  //   tokenAddress: '0x603c7f932ED1fc6575303D8Fb018fDCBb0f39a95',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooApeBANANA',
  //   earnedTokenAddress: '0xD307e7CC6a302046b7D91D83aa4B8324cFB7a786',
  //   earnContractAddress: '0xD307e7CC6a302046b7D91D83aa4B8324cFB7a786',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'tokens',
  //   oracleId: 'BANANA',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'ApeSwap',
  //   assets: ['BANANA'],
  //   buyTokenUrl:
  //     'https://dex.apeswap.finance/#/swap?inputCurrency=0x603c7f932ED1fc6575303D8Fb018fDCBb0f39a95&outputCurrency=0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c',
  //   createdAt: 1615196201,
  // },
  // {
  //   id: 'banana-guard-bnb-eol',
  //   logo: 'degens/BANANA-GUARD-BNB.svg',
  //   name: 'GUARD-BNB LP',
  //   token: 'GUARD-BNB LP',
  //   tokenDescription: 'ApeSwap',
  //   tokenAddress: '0xDd2B5E024942F9a83255F41144db5648b71f01c4',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooBananaGUARD-BNB',
  //   earnedTokenAddress: '0xB65381D73d96348D38C683f34998c0352F9c4EFF',
  //   earnContractAddress: '0xB65381D73d96348D38C683f34998c0352F9c4EFF',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'banana-guard-bnb',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   retireReason: 'rewards',
  //   platform: 'ApeSwap',
  //   assets: ['GUARD', 'BNB'],
  //   addLiquidityUrl:
  //     'https://dex.apeswap.finance/#/add/0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c/0xF606bd19b1E61574ED625d9ea96C841D4E247A32',
  //   buyTokenUrl:
  //     'https://dex.apeswap.finance/#/swap?inputCurrency=0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c&outputCurrency=0xF606bd19b1E61574ED625d9ea96C841D4E247A32',
  //   createdAt: 1628110811,
  // },
  // {
  //   id: 'banana-land-bnb-eol',
  //   name: 'LAND-BNB ALP',
  //   token: 'LAND-BNB ALP',
  //   tokenDescription: 'ApeSwap',
  //   tokenAddress: '0xB15f34082Baa4E3515A49E05D4d1D40cE933da0b',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooApeLAND-BNB',
  //   earnedTokenAddress: '0x7f7d3734903341464a685809405ff3b5463FF379',
  //   earnContractAddress: '0x7f7d3734903341464a685809405ff3b5463FF379',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'banana-land-bnb',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   retireReason: 'tvl',
  //   platform: 'ApeSwap',
  //   assets: ['LAND', 'BNB'],
  //   addLiquidityUrl:
  //     'https://app.apeswap.finance/add/ETH/0x9D986A3f147212327Dd658F712d5264a73a1fdB0',
  //   buyTokenUrl:
  //     'https://app.apeswap.finance/swap?outputCurrency=0x9D986A3f147212327Dd658F712d5264a73a1fdB0',
  //   createdAt: 1628412370,
  // },
  // {
  //   id: 'honeyfarm-honey-busd-eol',
  //   name: 'HONEY-BUSD LP',
  //   token: 'HONEY-BUSD LP',
  //   tokenDescription: 'PancakeSwap (HoneyFarm)',
  //   tokenAddress: '0x2961aa26a1Cb3068E580099045cc79a5b7B9634c',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooHoneyFarmHONEY-BUSDeol',
  //   earnedTokenAddress: '0x703Ab45f572db0f961520787f34F0E8bf7C2208B',
  //   earnContractAddress: '0x703Ab45f572db0f961520787f34F0E8bf7C2208B',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'honeyfarm-honey-busd',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'Other',
  //   assets: ['HONEY', 'BUSD'],
  //   withdrawalFee: '0%',
  //   addLiquidityUrl:
  //     'https://pancakeswap.finance/add/0xe9e7cea3dedca5984780bafc599bd69add087d56/0xFa363022816aBf82f18a9C2809dCd2BB393F6AC5',
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?outputCurrency=0xFa363022816aBf82f18a9C2809dCd2BB393F6AC5',
  //   createdAt: 1628251203,
  // },
  // {
  //   id: 'honeyfarm-honey-bnb-eol',
  //   name: 'HONEY-BNB LP',
  //   token: 'HONEY-BNB LP',
  //   tokenDescription: 'PancakeSwap (HoneyFarm)',
  //   tokenAddress: '0x27418bf2E38E613560D2281dB6590f3f6D446259',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooHoneyFarmHONEY-BNBeol',
  //   earnedTokenAddress: '0xE0e445357e86464C8DB8B4302e35852B3F92ac5d',
  //   earnContractAddress: '0xE0e445357e86464C8DB8B4302e35852B3F92ac5d',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'honeyfarm-honey-bnb',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'Other',
  //   assets: ['HONEY', 'BNB'],
  //   withdrawalFee: '0%',
  //   addLiquidityUrl:
  //     'https://pancakeswap.finance/add/BNB/0xFa363022816aBf82f18a9C2809dCd2BB393F6AC5',
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?outputCurrency=0xFa363022816aBf82f18a9C2809dCd2BB393F6AC5',
  //   createdAt: 1628251164,
  // },
  // {
  //   id: 'banana-cyt-bnb',
  //   name: 'CYT-BNB ALP',
  //   token: 'CYT-BNB ALP',
  //   tokenDescription: 'ApeSwap',
  //   tokenAddress: '0x5a55Fce6F6aCB2B1b20514964301Db4f37028C81',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooApeCYT-BNB',
  //   earnedTokenAddress: '0x673F5c29811107407744080F7403A4ABC43B646f',
  //   earnContractAddress: '0x673F5c29811107407744080F7403A4ABC43B646f',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'banana-cyt-bnb',
  //   oraclePrice: 0,
  //   depositsPaused: false,
  //   status: 'active',
  //   platform: 'ApeSwap',
  //   assets: ['CYT', 'BNB'],
  //   addLiquidityUrl:
  //     'https://app.apeswap.finance/add/ETH/0xd9025e25Bb6cF39f8c926A704039D2DD51088063',
  //   buyTokenUrl:
  //     'https://app.apeswap.finance/swap?outputCurrency=0xd9025e25Bb6cF39f8c926A704039D2DD51088063',
  //   createdAt: 1628432658,
  // },
  // {
  //   id: 'cakev2-btt-busd-eol',
  //   name: 'BTT-BUSD LP',
  //   token: 'BTT-BUSD LP2',
  //   tokenDescription: 'PancakeSwap',
  //   tokenAddress: '0xdcfbB12DED3FEa12D2A078Bc6324131cD14bF835',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooCakeV2BTT-BUSD',
  //   earnedTokenAddress: '0x0f10758Bf5A088471FbDeCbfd3051E4FAC927d1f',
  //   earnContractAddress: '0x0f10758Bf5A088471FbDeCbfd3051E4FAC927d1f',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'cakev2-btt-busd',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   retireReason: 'tvl',
  //   platform: 'PancakeSwap',
  //   assets: ['BTT', 'BUSD'],
  //   risks: [
  //     'COMPLEXITY_LOW',
  //     'BATTLE_TESTED',
  //     'IL_HIGH',
  //     'MCAP_MEDIUM',
  //     'PLATFORM_ESTABLISHED',
  //     'AUDIT',
  //     'CONTRACTS_VERIFIED',
  //   ],
  //   stratType: 'StratLP',
  //   addLiquidityUrl:
  //     'https://pancakeswap.finance/add/0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56/0x8595f9da7b868b1822194faed312235e43007b49',
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?outputCurrency=0x8595f9da7b868b1822194faed312235e43007b49',
  //   createdAt: 1628160572,
  // },
  // {
  //   id: 'cakev2-trx-busd',
  //   name: 'TRX-BUSD LP',
  //   token: 'TRX-BUSD LP2',
  //   tokenDescription: 'PancakeSwap',
  //   tokenAddress: '0xb5D108578Be3750209d1b3A8f45FFee8C5a75146',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooCakeV2TRX-BUSD',
  //   earnedTokenAddress: '0x5689Bdbc6BF06d5aF997a399F5abE858E60ae0fF',
  //   earnContractAddress: '0x5689Bdbc6BF06d5aF997a399F5abE858E60ae0fF',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'cakev2-trx-busd',
  //   oraclePrice: 0,
  //   depositsPaused: false,
  //   status: 'active',
  //   platform: 'PancakeSwap',
  //   assets: ['TRX', 'BUSD'],
  //   risks: [
  //     'COMPLEXITY_LOW',
  //     'BATTLE_TESTED',
  //     'IL_HIGH',
  //     'MCAP_LARGE',
  //     'PLATFORM_ESTABLISHED',
  //     'AUDIT',
  //     'CONTRACTS_VERIFIED',
  //   ],
  //   stratType: 'StratLP',
  //   addLiquidityUrl:
  //     'https://pancakeswap.finance/add/0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56/0x85eac5ac2f758618dfa09bdbe0cf174e7d574d5b',
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?outputCurrency=0x85eac5ac2f758618dfa09bdbe0cf174e7d574d5b',
  //   createdAt: 1628160485,
  // },
  // {
  //   id: 'cakev2-win-busd',
  //   name: 'WIN-BUSD LP',
  //   token: 'WIN-BUSD LP2',
  //   tokenDescription: 'PancakeSwap',
  //   tokenAddress: '0x6a445cEB72c8B1751755386c3990055ff92e14A0',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooCakeV2WIN-BUSD',
  //   earnedTokenAddress: '0x5E21De9266820e00039Ccc4211eE135114944b5b',
  //   earnContractAddress: '0x5E21De9266820e00039Ccc4211eE135114944b5b',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'cakev2-win-busd',
  //   oraclePrice: 0,
  //   depositsPaused: false,
  //   status: 'active',
  //   platform: 'PancakeSwap',
  //   assets: ['WIN', 'BUSD'],
  //   addLiquidityUrl:
  //     'https://pancakeswap.finance/add/0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56/0xaef0d72a118ce24fee3cd1d43d383897d05b4e99',
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?outputCurrency=0xaef0d72a118ce24fee3cd1d43d383897d05b4e99',
  //   createdAt: 1628160374,
  // },
  // {
  //   id: 'cakev2-revv-bnb-eol',
  //   name: 'REVV-BNB LP',
  //   token: 'REVV-BNB LP',
  //   tokenDescription: 'PancakeSwap',
  //   tokenAddress: '0x1Cc18962B919ef90085a8b21f8dDC95824Fbad9E',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooCakeV2REVV-BNB',
  //   earnedTokenAddress: '0xe0F782FFAeC27DC2377b8b656B828820dDB7BEd4',
  //   earnContractAddress: '0xe0F782FFAeC27DC2377b8b656B828820dDB7BEd4',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'cakev2-revv-bnb',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'PancakeSwap',
  //   assets: ['REVV', 'BNB'],
  //   withdrawalFee: '0%',
  //   retireReason: 'rewards',
  //   removeLiquidityUrl:
  //     'https://pancakeswap.finance/remove/0x833F307aC507D47309fD8CDD1F835BeF8D702a93/0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?inputCurrency=0x833F307aC507D47309fD8CDD1F835BeF8D702a93&outputCurrency=0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
  //   createdAt: 1627923279,
  // },
  // {
  //   id: 'cakev2-wsg-wbnb-eol',
  //   name: 'WSG-BNB LP',
  //   token: 'WSG-BNB LP2',
  //   tokenDescription: 'PancakeSwap',
  //   tokenAddress: '0x37Ff7D4459ad96E0B01275E5efffe091f33c2CAD',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooCakeV2WSG-WBNB',
  //   earnedTokenAddress: '0x57Fd8B7c7f7520281a472f54275C47CfE7E28716',
  //   earnContractAddress: '0x57Fd8B7c7f7520281a472f54275C47CfE7E28716',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'cakev2-wsg-wbnb',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   retireReason: 'rewards',
  //   platform: 'PancakeSwap',
  //   assets: ['WSG', 'BNB'],
  //   addLiquidityUrl:
  //     'https://pancakeswap.finance/add/0xA58950F05FeA2277d2608748412bf9F802eA4901/0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?inputCurrency=0xA58950F05FeA2277d2608748412bf9F802eA4901&outputCurrency=0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
  //   createdAt: 1628027137,
  // },
  // {
  //   id: 'banana-bnb-sista-eol',
  //   name: 'SISTA-BNB LP',
  //   token: 'SISTA-BNB LP',
  //   tokenDescription: 'ApeSwap',
  //   tokenAddress: '0x45546310FDA2fbDB7Ee26EA0A5b6F82D075254bC',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooBananaSISTA-BNB',
  //   earnedTokenAddress: '0x7ba299C3681aFEE3d91f7d0685451A7c1C45Cd00',
  //   earnContractAddress: '0x7ba299C3681aFEE3d91f7d0685451A7c1C45Cd00',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'banana-bnb-sista',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'ApeSwap',
  //   assets: ['SISTA', 'BNB'],
  //   addLiquidityUrl:
  //     'https://dex.apeswap.finance/#/add/0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c/0xCA6d25C10dad43ae8Be0bc2af4D3CD1114583C08',
  //   buyTokenUrl:
  //     'https://dex.apeswap.finance/#/swap?inputCurrency=0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c&outputCurrency=0xCA6d25C10dad43ae8Be0bc2af4D3CD1114583C08',
  //   createdAt: 1627915331,
  //   retiredReason: 'tvl',
  // },
  // {
  //   id: 'wex-bnb-dep-eol',
  //   logo: 'bnb-pairs/DEP-BNB.png',
  //   name: 'DEP-BNB WLP',
  //   token: 'DEP-BNB WLP',
  //   tokenDescription: 'WaultFinance',
  //   tokenAddress: '0x45cfEC682817f62fCD11816ed41900E160E17F2d',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooWaultBNB-DEP',
  //   earnedTokenAddress: '0xAf36873ecC5C8BC18173a16a862CD53f117Bf0f7',
  //   earnContractAddress: '0xAf36873ecC5C8BC18173a16a862CD53f117Bf0f7',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'wex-bnb-dep',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   retireReason: 'rewards',
  //   platform: 'Other',
  //   assets: ['DEP', 'BNB'],
  //   addLiquidityUrl:
  //     'https://swap.wault.finance/bsc/#/add/BNB/0xcaF5191fc480F43e4DF80106c7695ECA56E48B18',
  //   buyTokenUrl:
  //     'https://swap.wault.finance/bsc/#/swap?outputCurrency=0xcaF5191fc480F43e4DF80106c7695ECA56E48B18',
  //   createdAt: 1627665592,
  // },
  // {
  //   id: 'jetswap-btcb-bnb',
  //   name: 'BTCB-BNB LP',
  //   token: 'BTCB-BNB LP',
  //   tokenDescription: 'JetSwap',
  //   tokenAddress: '0xc3C16CaE0cf35615b8716a9A8BB0b1257CdbCA53',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooJetswapBTCB-BNB',
  //   earnedTokenAddress: '0x1301Bfedaec0592e7915012A0f1AE2aCBaFbA4c3',
  //   earnContractAddress: '0x1301Bfedaec0592e7915012A0f1AE2aCBaFbA4c3',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'jetswap-btcb-bnb',
  //   oraclePrice: 0,
  //   depositsPaused: false,
  //   status: 'active',
  //   platform: 'JetSwap',
  //   assets: ['BTCB', 'BNB'],
  //   addLiquidityUrl:
  //     'https://exchange.jetswap.finance/#/add/0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c/0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
  //   buyTokenUrl:
  //     'https://exchange.jetswap.finance/#/swap?inputCurrency=0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c&outputCurrency=0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
  //   createdAt: 1627911930,
  // },
  // {
  //   id: 'pera-pera-bnb-eol',
  //   name: 'PERA-BNB LP',
  //   token: 'PERA-BNB LP',
  //   tokenDescription: 'PancakeSwap (Pera)',
  //   tokenAddress: '0x59eA11cfe863A6D680F4D14Bb24343e852ABBbbb',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooPeraPERA-BNB',
  //   earnedTokenAddress: '0xE48888E2d11307c467AB364FeaD6d8C214210D2B',
  //   earnContractAddress: '0xE48888E2d11307c467AB364FeaD6d8C214210D2B',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'pera-pera-bnb',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   retireReason: 'rewards',
  //   platform: 'Other',
  //   assets: ['PERA', 'BNB'],
  //   addLiquidityUrl:
  //     'https://pancakeswap.finance/add/BNB/0xb9D8592E16A9c1a3AE6021CDDb324EaC1Cbc70d6',
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?outputCurrency=0xb9D8592E16A9c1a3AE6021CDDb324EaC1Cbc70d6',
  //   createdAt: 1627490578,
  // },
  // {
  //   id: 'banana-tape-bnb-eol',
  //   name: 'TAPE-BNB LP',
  //   token: 'TAPE-BNB LP',
  //   tokenDescription: 'ApeSwap',
  //   tokenAddress: '0x756d4406169273d99aAc8366cf5eAf7865d6a9b9',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooApeTAPE-BNB',
  //   earnedTokenAddress: '0x8444E699466BeaE79436c782aC7Fe6308E92dDcF',
  //   earnContractAddress: '0x8444E699466BeaE79436c782aC7Fe6308E92dDcF',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'banana-tape-bnb',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   retireReason: 'rewards',
  //   platform: 'ApeSwap',
  //   assets: ['TAPE', 'BNB'],
  //   addLiquidityUrl:
  //     'https://dex.apeswap.finance/#/add/ETH/0xF63400ee0420ce5b1Ebdee0C942D7dE1C734a41f',
  //   buyTokenUrl:
  //     'https://dex.apeswap.finance/#/swap?outputCurrency=0xF63400ee0420ce5b1Ebdee0C942D7dE1C734a41f',
  //   createdAt: 1627650183,
  // },
  // {
  //   id: 'banana-axs-bnb',
  //   name: 'AXS-BNB LP',
  //   token: 'AXS-BNB ALP',
  //   tokenDescription: 'ApeSwap',
  //   tokenAddress: '0x8b85A4228400fa9b2fB5bD47dB8f05b7f8Bb7F5c',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooApeAXS-BNB',
  //   earnedTokenAddress: '0x69DDD966e3FAaB30c09D7A2C40609Ea859F4107E',
  //   earnContractAddress: '0x69DDD966e3FAaB30c09D7A2C40609Ea859F4107E',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'banana-axs-bnb',
  //   oraclePrice: 0,
  //   depositsPaused: false,
  //   status: 'active',
  //   platform: 'ApeSwap',
  //   assets: ['AXS', 'BNB'],
  //   addLiquidityUrl:
  //     'https://dex.apeswap.finance/#/add/ETH/0x715D400F88C167884bbCc41C5FeA407ed4D2f8A0',
  //   buyTokenUrl:
  //     'https://dex.apeswap.finance/#/swap?outputCurrency=0x715D400F88C167884bbCc41C5FeA407ed4D2f8A0',
  //   createdAt: 1627574550,
  // },
  // {
  //   id: 'cakev2-if-busd-eol',
  //   name: 'IF-BUSD LP',
  //   token: 'IF-BUSD LP2',
  //   tokenDescription: 'PancakeSwap',
  //   tokenAddress: '0x7b4682D2B3f8670b125aF6AEA8d7eD2Daa43Bdc1',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooCakeV2IF-BUSD',
  //   earnedTokenAddress: '0x448dCDE29b236eF0e72C1f896b8Ed5674789E809',
  //   earnContractAddress: '0x448dCDE29b236eF0e72C1f896b8Ed5674789E809',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'cakev2-if-busd',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'PancakeSwap',
  //   assets: ['IF', 'BUSD'],
  //   withdrawalFee: '0%',
  //   retireReason: 'rewards',
  //   removeLiquidityUrl:
  //     'https://pancakeswap.finance/remove/0xB0e1fc65C1a741b4662B813eB787d369b8614Af1/0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?inputCurrency=0xB0e1fc65C1a741b4662B813eB787d369b8614Af1&outputCurrency=0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
  //   createdAt: 1627539012,
  // },
  // {
  //   id: 'cakev2-skill-wbnb-eol',
  //   name: 'SKILL-BNB LP',
  //   token: 'SKILL-BNB LP2',
  //   tokenDescription: 'PancakeSwap',
  //   tokenAddress: '0xC19dfd34D3ba5816dF9CBDaa02D32A9F8dc6F6fC',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooCakeV2SKILL-WBNB',
  //   earnedTokenAddress: '0x4FA0345d6f683dfb90f8935c2fEeBBe03085bdDD',
  //   earnContractAddress: '0x4FA0345d6f683dfb90f8935c2fEeBBe03085bdDD',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'cakev2-skill-wbnb',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'PancakeSwap',
  //   assets: ['SKILL', 'BNB'],
  //   withdrawalFee: '0%',
  //   retireReason: 'rewards',
  //   removeLiquidityUrl:
  //     'https://pancakeswap.finance/remove/0x154A9F9cbd3449AD22FDaE23044319D6eF2a1Fab/0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?inputCurrency=0x154A9F9cbd3449AD22FDaE23044319D6eF2a1Fab&outputCurrency=0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
  //   createdAt: 1627627366,
  // },
  // {
  //   id: 'farmhero-pancake-hero-wbnb-eol',
  //   name: 'HERO-BNB LP',
  //   token: 'HERO-BNB LP',
  //   tokenDescription: 'FarmHero (Pancakeswap)',
  //   tokenAddress: '0x35BC504e05fa91f17552A9c3a6A2E7E9651A0622',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooFarmHeroPancakeHERO-WBNB',
  //   earnedTokenAddress: '0x1D8Ed2D5d2f5C0e8d9919532C2D47D97ae3447ad',
  //   earnContractAddress: '0x1D8Ed2D5d2f5C0e8d9919532C2D47D97ae3447ad',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'farmhero-pancake-hero-wbnb',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'Other',
  //   assets: ['HERO', 'BNB'],
  //   addLiquidityUrl:
  //     'https://pancakeswap.finance/add/0x9B26e16377ad29A6CCC01770bcfB56DE3A36d8b2/0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?inputCurrency=0x9B26e16377ad29A6CCC01770bcfB56DE3A36d8b2&outputCurrency=0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
  //   createdAt: 1627448553,
  //   retiredReason: 'tvl',
  // },
  // {
  //   id: 'farmhero-pancake-hero-busd-eol',
  //   name: 'HERO-BUSD LP',
  //   token: 'HERO-BUSD LP',
  //   tokenDescription: 'FarmHero (Pancakeswap)',
  //   tokenAddress: '0x4BcaA3bd33fF733a581dF027aAd1EBDbDDb55CC2',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooFarmHeroPancakeHERO-BUSD',
  //   earnedTokenAddress: '0x8D37dB34d1f69CD2591ba9EFFCFda568eCe4b16B',
  //   earnContractAddress: '0x8D37dB34d1f69CD2591ba9EFFCFda568eCe4b16B',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'farmhero-pancake-hero-busd',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'Other',
  //   assets: ['HERO', 'BUSD'],
  //   addLiquidityUrl:
  //     'https://pancakeswap.finance/add/0x9B26e16377ad29A6CCC01770bcfB56DE3A36d8b2/0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?inputCurrency=0x9B26e16377ad29A6CCC01770bcfB56DE3A36d8b2&outputCurrency=0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
  //   createdAt: 1627448941,
  //   retiredReason: 'tvl',
  // },
  // {
  //   id: 'cakev2-sps-wbnb-eol',
  //   name: 'SPS-BNB LP',
  //   token: 'SPS-BNB LP2',
  //   tokenDescription: 'PancakeSwap',
  //   tokenAddress: '0xFdFde3aF740A22648B9dD66D05698e5095940850',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooCakeV2SPS-WBNB',
  //   earnedTokenAddress: '0x8fBc0609Ca09De7CA68E9E36bEf5b51bA60b8Ff8',
  //   earnContractAddress: '0x8fBc0609Ca09De7CA68E9E36bEf5b51bA60b8Ff8',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'cakev2-sps-wbnb',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   retireReason: 'tvl',
  //   platform: 'PancakeSwap',
  //   assets: ['SPS', 'BNB'],
  //   addLiquidityUrl:
  //     'https://pancakeswap.finance/add/0x1633b7157e7638C4d6593436111Bf125Ee74703F/0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?inputCurrency=0x1633b7157e7638C4d6593436111Bf125Ee74703F&outputCurrency=0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
  //   createdAt: 1627425423,
  // },
  // {
  //   id: 'cakev2-c98-wbnb',
  //   logo: 'bnb-pairs/C98-BNB.svg',
  //   name: 'C98-BNB LP',
  //   token: 'C98-BNB LP2',
  //   tokenDescription: 'PancakeSwap',
  //   tokenAddress: '0x92247860A03F48d5c6425c7CA35CDcFCB1013AA1',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooCakeV2C98-WBNB',
  //   earnedTokenAddress: '0x13071d48A5FDe2735102657e15D1132F92eE8C83',
  //   earnContractAddress: '0x13071d48A5FDe2735102657e15D1132F92eE8C83',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'cakev2-c98-wbnb',
  //   oraclePrice: 0,
  //   depositsPaused: false,
  //   status: 'active',
  //   platform: 'PancakeSwap',
  //   assets: ['C98', 'BNB'],
  //   addLiquidityUrl:
  //     'https://pancakeswap.finance/add/0xaEC945e04baF28b135Fa7c640f624f8D90F1C3a6/0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?inputCurrency=0xaEC945e04baF28b135Fa7c640f624f8D90F1C3a6&outputCurrency=0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
  //   createdAt: 1627056633,
  // },
  // {
  //   id: 'mdex-bsc-mdx',
  //   logo: 'single-assets/MDX.png',
  //   name: 'MDX',
  //   token: 'MDX',
  //   tokenDescription: 'Mdex',
  //   tokenAddress: '0x9C65AB58d8d978DB963e63f2bfB7121627e3a739',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooMdexMDX',
  //   earnedTokenAddress: '0xc34C7923B73eB68E363AaEB2b7fbBeC11Cf90884',
  //   earnContractAddress: '0xc34C7923B73eB68E363AaEB2b7fbBeC11Cf90884',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'tokens',
  //   oracleId: 'MDX',
  //   oraclePrice: 0,
  //   depositsPaused: false,
  //   status: 'active',
  //   platform: 'Mdex',
  //   assets: ['MDX'],
  //   withdrawalFee: '0%',
  //   buyTokenUrl:
  //     'https://bsc.mdex.com/#/swap?inputCurrency=WBNB&outputCurrency=0x9c65ab58d8d978db963e63f2bfb7121627e3a739',
  //   createdAt: 1621519770,
  // },
  // {
  //   id: 'wex-tusd-usdt-eol',
  //   logo: 'usdt-pairs/TUSD-USDT.png',
  //   name: 'TUSD-USDT WLP',
  //   token: 'TUSD-USDT WLP',
  //   tokenDescription: 'WaultFinance',
  //   tokenAddress: '0xBE66b44015734D881ACcfBa478d957885612A902',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooWaultTUSD-USDT',
  //   earnedTokenAddress: '0x54be68B8330D49e454DF63A9F74Baa62B90fB768',
  //   earnContractAddress: '0x54be68B8330D49e454DF63A9F74Baa62B90fB768',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'wex-tusd-usdt',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   retireReason: 'rewards',
  //   platform: 'Other',
  //   assets: ['TUSD', 'USDT'],
  //   addLiquidityUrl:
  //     'https://swap.wault.finance/bsc/#/add/0x55d398326f99059fF775485246999027B3197955/0x14016E85a25aeb13065688cAFB43044C2ef86784',
  //   buyTokenUrl:
  //     'https://swap.wault.finance/bsc/#/swap?inputCurrency=0x55d398326f99059fF775485246999027B3197955&outputCurrency=0x14016E85a25aeb13065688cAFB43044C2ef86784',
  //   createdAt: 1627238978,
  // },
  // {
  //   id: 'cakev2-axs-wbnb',
  //   name: 'AXS-BNB LP',
  //   token: 'AXS-BNB LP2',
  //   tokenDescription: 'PancakeSwap',
  //   tokenAddress: '0xC2d00De94795e60FB76Bc37d899170996cBdA436',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooCakeV2AXS-WBNB',
  //   earnedTokenAddress: '0x97579c19A91c9e174D1DF4782739A2ec0971c0D8',
  //   earnContractAddress: '0x97579c19A91c9e174D1DF4782739A2ec0971c0D8',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'cakev2-axs-wbnb',
  //   oraclePrice: 0,
  //   depositsPaused: false,
  //   status: 'active',
  //   platform: 'PancakeSwap',
  //   assets: ['AXS', 'BNB'],
  //   addLiquidityUrl:
  //     'https://pancakeswap.finance/add/0x715D400F88C167884bbCc41C5FeA407ed4D2f8A0/0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?inputCurrency=0x715D400F88C167884bbCc41C5FeA407ed4D2f8A0&outputCurrency=0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
  //   createdAt: 1626948737,
  // },
  // {
  //   id: 'ellipsis-usdn-eol',
  //   logo: 'uncategorized/epsUSDN.png',
  //   name: 'BUSD/USDT/USDC/USDN',
  //   token: 'usdn3EPS',
  //   tokenDescription: 'Ellipsis',
  //   tokenAddress: '0x8339156534e2d7354Eed097A6FB4A5820d8d0d27',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooEllipsisUSDN',
  //   earnedTokenAddress: '0x1DB9162039F834F02f095395B334d514f894b696',
  //   earnContractAddress: '0x1DB9162039F834F02f095395B334d514f894b696',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'ellipsis-usdn',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'Ellipsis',
  //   assets: ['USDN', 'USDC', 'USDT', 'BUSD'],
  //   addLiquidityUrl: 'https://ellipsis.finance/pool',
  //   createdAt: 1626784750,
  //   retiredReason: 'tvl',
  // },
  // {
  //   id: 'fruit-fruit-cake-eol',
  //   name: 'FRUIT-CAKE LP',
  //   token: 'FRUIT-CAKE LP',
  //   tokenDescription: 'ApeSwap (Fruit)',
  //   tokenAddress: '0x16F77B8f15Ec2c98069e0E34FDa78E3d57Ca501B',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooFruitFRUIT-CAKE',
  //   earnedTokenAddress: '0xe7D4C514621C2687391cee9e3a150a738EbF2C92',
  //   earnContractAddress: '0xe7D4C514621C2687391cee9e3a150a738EbF2C92',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'fruit-fruit-cake',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'Other',
  //   assets: ['FRUIT', 'CAKE'],
  //   retireReason: 'tvl',
  //   addLiquidityUrl:
  //     'https://dex.apeswap.finance/#/add/0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82/0x4ECfb95896660aa7F54003e967E7b283441a2b0A',
  //   buyTokenUrl:
  //     'https://dex.apeswap.finance/#/swap?outputCurrency=0x4ECfb95896660aa7F54003e967E7b283441a2b0A',
  //   createdAt: 1626774100,
  // },
  // {
  //   id: 'fruit-fruit-xvs-eol',
  //   name: 'FRUIT-XVS LP',
  //   token: 'FRUIT-XVS LP',
  //   tokenDescription: 'ApeSwap (Fruit)',
  //   tokenAddress: '0xcD727738F97cE36462b846CE52aBd2D4f5fea75a',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooFruitFRUIT-XVS',
  //   earnedTokenAddress: '0x76BB40099580dD8987Fd9B860272B7EA387155a8',
  //   earnContractAddress: '0x76BB40099580dD8987Fd9B860272B7EA387155a8',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'fruit-fruit-xvs',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'Other',
  //   assets: ['FRUIT', 'XVS'],
  //   retireReason: 'tvl',
  //   addLiquidityUrl:
  //     'https://dex.apeswap.finance/#/add/0xcf6bb5389c92bdda8a3747ddb454cb7a64626c63/0x4ECfb95896660aa7F54003e967E7b283441a2b0A',
  //   buyTokenUrl:
  //     'https://dex.apeswap.finance/#/swap?outputCurrency=0x4ECfb95896660aa7F54003e967E7b283441a2b0A',
  //   createdAt: 1626774505,
  // },
  // {
  //   id: 'banana-fruit-bnb-eol',
  //   name: 'FRUIT-BNB LP',
  //   token: 'FRUIT-BNB LP',
  //   tokenDescription: 'ApeSwap',
  //   tokenAddress: '0x0bE55fd1Fdc7134Ff8412e8BAaC63CBb691B1d64',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooApeFRUIT-BNB',
  //   earnedTokenAddress: '0xf33352769cF8A1fC840FC573A9B4Bf92e398311f',
  //   earnContractAddress: '0xf33352769cF8A1fC840FC573A9B4Bf92e398311f',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'banana-fruit-bnb',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'ApeSwap',
  //   assets: ['FRUIT', 'BNB'],
  //   retireReason: 'rewards',
  //   addLiquidityUrl:
  //     'https://dex.apeswap.finance/#/add/ETH/0x4ECfb95896660aa7F54003e967E7b283441a2b0A',
  //   buyTokenUrl:
  //     'https://dex.apeswap.finance/#/swap?outputCurrency=0x4ECfb95896660aa7F54003e967E7b283441a2b0A',
  //   createdAt: 1626697848,
  // },
  // {
  //   id: 'stablequant-quant-busd-eol',
  //   logo: 'degens/quant-busd.png',
  //   name: 'QUANT-BUSD LP',
  //   token: 'QUANT-BUSD LP',
  //   tokenDescription: 'PancakeSwap (QUANT)',
  //   tokenAddress: '0x8828B94d62d43d8415968666D221Bf3C0fcaf58f',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooStableQuantQUANT-BUSD',
  //   earnedTokenAddress: '0xa4918a9B3CE89c3A179Ea873A45a901a4535eC65',
  //   earnContractAddress: '0xa4918a9B3CE89c3A179Ea873A45a901a4535eC65',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'stablequant-quant-busd',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'Other',
  //   assets: ['QUANT', 'BUSD'],
  //   retireReason: 'tvl',
  //   addLiquidityUrl:
  //     'https://pancakeswap.finance/add/0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56/0xBCA627FEd3b6E8F414C745E12B2b89371497779D',
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?inputCurrency=0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56&outputCurrency=0xBCA627FEd3b6E8F414C745E12B2b89371497779D',
  //   createdAt: 1627849743,
  // },
  // {
  //   id: 'rabbit-carrot-busd',
  //   name: 'CARROT-BUSD LP',
  //   token: 'CARROT-BUSD LP',
  //   tokenDescription: 'Mdex (RabbitFinance)',
  //   tokenAddress: '0xFa3De14B75832dF680D6cae6C2555d7ff5F80C00',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooRabbitCARROT-BUSD',
  //   earnedTokenAddress: '0xAfa633818a93D456F4f17225F307cA9B0DB9927F',
  //   earnContractAddress: '0xAfa633818a93D456F4f17225F307cA9B0DB9927F',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'rabbit-carrot-busd',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   retireReason: 'rewards',
  //   platform: 'Other',
  //   assets: ['CARROT', 'BUSD'],
  //   addLiquidityUrl:
  //     'https://bsc.mdex.me/#/add/0xe9e7cea3dedca5984780bafc599bd69add087d56/0xA5043373edC09f3f7d87Fe35CA933e0a7B59D005',
  //   buyTokenUrl:
  //     'https://bsc.mdex.me/#/swap?outputCurrency=0xA5043373edC09f3f7d87Fe35CA933e0a7B59D005',
  //   createdAt: 1626436039,
  // },
  // {
  //   id: 'kingdefi-krown-bnb-eol',
  //   name: 'KRW-BNB LP',
  //   token: 'KRW-BNB LP',
  //   tokenDescription: 'PancakeSwap (KingDefi)',
  //   tokenAddress: '0x23DCDDfa33c3b79192f4D9c39de0b2a98B5c0390',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooKingDefiKROWN-BNB',
  //   earnedTokenAddress: '0x7436E25d56b22646122448BbFD05F732984B4f19',
  //   earnContractAddress: '0x7436E25d56b22646122448BbFD05F732984B4f19',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'kingdefi-krown-bnb',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   retireReason: 'tvl',
  //   platform: 'Other',
  //   assets: ['KRW', 'BNB'],
  //   addLiquidityUrl:
  //     'https://pancakeswap.finance/add/BNB/0x1446f3CEdf4d86a9399E49f7937766E6De2A3AAB',
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?outputCurrency=0x1446f3cedf4d86a9399e49f7937766e6de2a3aab',
  //   createdAt: 1626276700,
  // },
  // {
  //   id: 'kingdefi-krown-eol',
  //   logo: 'single-assets/KRW.png',
  //   name: 'KRW',
  //   token: 'KRW',
  //   tokenDescription: 'KingDefi',
  //   tokenAddress: '0x1446f3CEdf4d86a9399E49f7937766E6De2A3AAB',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooKingDefiKROWN',
  //   earnedTokenAddress: '0xEb4804389A843676686715785C8Df27EA6E43F9b',
  //   earnContractAddress: '0xEb4804389A843676686715785C8Df27EA6E43F9b',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'tokens',
  //   oracleId: 'KRW',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   retireReason: 'rewards',
  //   platform: 'Other',
  //   assets: ['KRW'],
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?outputCurrency=0x1446f3cedf4d86a9399e49f7937766e6de2a3aab',
  //   createdAt: 1626280033,
  // },
  // {
  //   id: 'ooe-ooe-bnb',
  //   name: 'OOE-BNB LP',
  //   token: 'OOE-BNB LP',
  //   tokenDescription: 'OpenOcean',
  //   tokenAddress: '0xa0B80538Fe40660921cE9327879bD1156726D13D',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooOoeOOE-BNB',
  //   earnedTokenAddress: '0xfA9f791Ff43128bbEB8B89Ee452Ba31eEFDd52f0',
  //   earnContractAddress: '0xfA9f791Ff43128bbEB8B89Ee452Ba31eEFDd52f0',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'ooe-ooe-bnb',
  //   oraclePrice: 0,
  //   depositsPaused: false,
  //   status: 'active',
  //   platform: 'Other',
  //   assets: ['OOE', 'BNB'],
  //   addLiquidityUrl:
  //     'https://openocean.finance/pools/?t=1631247635777#/add/BNB/0x9029FdFAe9A03135846381c7cE16595C3554e10A',
  //   buyTokenUrl: 'https://openocean.finance/classic',
  //   createdAt: 1626108849,
  // },
  // {
  //   id: 'ooe-ooe-busd',
  //   name: 'OOE-BUSD LP',
  //   token: 'OOE-BUSD LP',
  //   tokenDescription: 'OpenOcean',
  //   tokenAddress: '0x70F591e38AD52A1F99C1e055759F1F31Bcf3732e',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooOoeOOE-BUSD',
  //   earnedTokenAddress: '0xC7981CD58062B6C2c782418ddb235d06A3AB80a9',
  //   earnContractAddress: '0xC7981CD58062B6C2c782418ddb235d06A3AB80a9',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'ooe-ooe-busd',
  //   oraclePrice: 0,
  //   depositsPaused: false,
  //   status: 'active',
  //   platform: 'Other',
  //   assets: ['OOE', 'BUSD'],
  //   addLiquidityUrl:
  //     'https://openocean.finance/pools/?t=1631247635777#/add/0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56/0x9029FdFAe9A03135846381c7cE16595C3554e10A',
  //   buyTokenUrl: 'https://openocean.finance/classic',
  //   createdAt: 1626107634,
  // },
  // {
  //   id: 'banana-yfi-bnb',
  //   name: 'YFI-BNB LP',
  //   token: 'YFI-BNB ApeLP',
  //   tokenDescription: 'ApeSwap',
  //   tokenAddress: '0xA3421Bd2B3B1578FF43Ab95C10f667e5A3bbCeF7',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooApeYFI-BNB',
  //   earnedTokenAddress: '0x4A1f31E1014087B9F9e023147Cf319fF1534B209',
  //   earnContractAddress: '0x4A1f31E1014087B9F9e023147Cf319fF1534B209',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'banana-yfi-bnb',
  //   oraclePrice: 0,
  //   depositsPaused: false,
  //   status: 'active',
  //   platform: 'ApeSwap',
  //   assets: ['YFI', 'BNB'],
  //   addLiquidityUrl:
  //     'https://dex.apeswap.finance/#/add/ETH/0x88f1A5ae2A3BF98AEAF342D26B30a79438c9142e',
  //   buyTokenUrl:
  //     'https://dex.apeswap.finance/#/swap?outputCurrency=0x88f1A5ae2A3BF98AEAF342D26B30a79438c9142e',
  //   createdAt: 1626024399,
  // },
  // {
  //   id: 'wex-wex-btcb-eol',
  //   name: 'WEX-BTCB WLP',
  //   token: 'WEX-BTCB WLP',
  //   tokenDescription: 'WaultFinance',
  //   tokenAddress: '0xB621C33747D508CC16E83214823D644713DCA02e',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooWaultWEX-BTCB',
  //   earnedTokenAddress: '0xe4D876d745aA10f6a8EEd066E1aeA379C5c6852D',
  //   earnContractAddress: '0xe4D876d745aA10f6a8EEd066E1aeA379C5c6852D',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'wex-wex-btcb',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   retireReason: 'rewards',
  //   platform: 'Other',
  //   assets: ['WEX', 'BTCB'],
  //   addLiquidityUrl:
  //     'https://swap.wault.finance/bsc/#/add/0xa9c41a46a6b3531d28d5c32f6633dd2ff05dfb90/0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c',
  //   buyTokenUrl:
  //     'https://swap.wault.finance/bsc/#/swap?inputCurrency=0xa9c41a46a6b3531d28d5c32f6633dd2ff05dfb90&outputCurrency=0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c',
  //   createdAt: 1625847079,
  // },
  // {
  //   id: 'cakev2-chess-usdc',
  //   name: 'CHESS-USDC LP',
  //   token: 'CHESS-USDC LP2',
  //   tokenDescription: 'PancakeSwap',
  //   tokenAddress: '0x1472976E0B97F5B2fC93f1FFF14e2b5C4447b64F',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooCakeV2CHESS-USDC',
  //   earnedTokenAddress: '0x44B59f89cD4EAbCb08d6cD97B8998A96400E8c6F',
  //   earnContractAddress: '0x44B59f89cD4EAbCb08d6cD97B8998A96400E8c6F',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'cakev2-chess-usdc',
  //   oraclePrice: 0,
  //   depositsPaused: false,
  //   status: 'active',
  //   platform: 'PancakeSwap',
  //   assets: ['CHESS', 'USDC'],
  //   addLiquidityUrl:
  //     'https://pancakeswap.finance/add/0x20de22029ab63cf9A7Cf5fEB2b737Ca1eE4c82A6/0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d',
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?inputCurrency=0x20de22029ab63cf9A7Cf5fEB2b737Ca1eE4c82A6&outputCurrency=0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d',
  //   createdAt: 1626076601,
  // },
  // {
  //   id: 'cakev2-mask-wbnb-eol',
  //   name: 'MASK-BNB LP',
  //   token: 'MASK-BNB LP2',
  //   tokenDescription: 'PancakeSwap',
  //   tokenAddress: '0x46c6bA71af7648cD7f67D0AD4d16f75bE251ed12',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooCakeV2MASK-WBNB',
  //   earnedTokenAddress: '0x7C86bF8e0Db2D327EF1b0D616C5617E9733CdDf6',
  //   earnContractAddress: '0x7C86bF8e0Db2D327EF1b0D616C5617E9733CdDf6',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'cakev2-mask-wbnb',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   retireReason: 'rewards',
  //   platform: 'PancakeSwap',
  //   assets: ['MASK', 'BNB'],
  //   addLiquidityUrl:
  //     'https://pancakeswap.finance/add/0x2eD9a5C8C13b93955103B9a7C167B67Ef4d568a3/0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?inputCurrency=0x2eD9a5C8C13b93955103B9a7C167B67Ef4d568a3&outputCurrency=0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
  //   createdAt: 1625695424,
  // },
  // {
  //   id: 'ellipsis-pbtc-eol',
  //   logo: 'uncategorized/epsPBTC.png',
  //   name: 'pBTC/renBTC/BTCB',
  //   token: 'pbtcEPS',
  //   tokenDescription: 'Ellipsis',
  //   tokenAddress: '0xA2520F3596449105bfe899f3D573C4353917EAf8',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooEllipsisPBTC',
  //   earnedTokenAddress: '0x8Fa781Fe4995cB9F7de6BD46042A7034c920824B',
  //   earnContractAddress: '0x8Fa781Fe4995cB9F7de6BD46042A7034c920824B',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'ellipsis-pbtc',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'Ellipsis',
  //   assets: ['pBTC', 'renBTC', 'BTCB'],
  //   retireReason: 'exploit',
  //   addLiquidityUrl: 'https://ellipsis.finance/pool',
  //   createdAt: 1625591426,
  // },
  // {
  //   id: 'banana-sfp-bnb',
  //   logo: 'degens/BANANA-SFP-BNB.svg',
  //   name: 'SFP-BNB LP',
  //   token: 'SFP-BNB ApeLP',
  //   tokenDescription: 'ApeSwap',
  //   tokenAddress: '0x6411A2240c8cD1dd48718eEE1ae4a10E71123Fd3',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooApeSFP-BNB',
  //   earnedTokenAddress: '0x57aB8a1F94FED0EE20431C6687211aaf2B7e73E5',
  //   earnContractAddress: '0x57aB8a1F94FED0EE20431C6687211aaf2B7e73E5',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'banana-sfp-bnb',
  //   oraclePrice: 0,
  //   depositsPaused: false,
  //   status: 'active',
  //   platform: 'ApeSwap',
  //   assets: ['SFP', 'BNB'],
  //   addLiquidityUrl:
  //     'https://dex.apeswap.finance/#/add/ETH/0xD41FDb03Ba84762dD66a0af1a6C8540FF1ba5dfb',
  //   buyTokenUrl:
  //     'https://dex.apeswap.finance/#/swap?outputCurrency=0xD41FDb03Ba84762dD66a0af1a6C8540FF1ba5dfb',
  //   createdAt: 1625587277,
  // },
  // {
  //   id: 'banana-eps-bnb-eol',
  //   logo: 'degens/BANANA-EPS-BNB.svg',
  //   name: 'EPS-BNB LP',
  //   token: 'EPS-BNB ApeLP',
  //   tokenDescription: 'ApeSwap',
  //   tokenAddress: '0x97c4C531e739E870d958940E8688017894084003',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooApeEPS-BNB',
  //   earnedTokenAddress: '0xd9CDe586500163d8D48866949cB18F46Ffe07995',
  //   earnContractAddress: '0xd9CDe586500163d8D48866949cB18F46Ffe07995',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'banana-eps-bnb',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   retireReason: 'rewards',
  //   platform: 'ApeSwap',
  //   assets: ['EPS', 'BNB'],
  //   addLiquidityUrl:
  //     'https://dex.apeswap.finance/#/add/ETH/0xA7f552078dcC247C2684336020c03648500C6d9F',
  //   buyTokenUrl:
  //     'https://dex.apeswap.finance/#/swap?outputCurrency=0xA7f552078dcC247C2684336020c03648500C6d9F',
  //   createdAt: 1625587502,
  // },
  // {
  //   id: 'banana-xvs-bnb',
  //   logo: 'degens/BANANA-XVS-BNB.svg',
  //   name: 'XVS-BNB LP',
  //   token: 'XVS-BNB ApeLP',
  //   tokenDescription: 'ApeSwap',
  //   tokenAddress: '0x9e199da6f87E09a290724EbA866eEdae2e971A0b',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooApeXVS-BNB',
  //   earnedTokenAddress: '0xaC541bC3434f72AA18d124E5dcb13014aa66eBa7',
  //   earnContractAddress: '0xaC541bC3434f72AA18d124E5dcb13014aa66eBa7',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'banana-xvs-bnb',
  //   oraclePrice: 0,
  //   depositsPaused: false,
  //   status: 'active',
  //   platform: 'ApeSwap',
  //   assets: ['XVS', 'BNB'],
  //   addLiquidityUrl:
  //     'https://dex.apeswap.finance/#/add/ETH/0xcF6BB5389c92Bdda8a3747Ddb454cB7a64626C63',
  //   buyTokenUrl:
  //     'https://dex.apeswap.finance/#/swap?outputCurrency=0xcF6BB5389c92Bdda8a3747Ddb454cB7a64626C63',
  //   createdAt: 1625587685,
  // },
  // {
  //   id: 'banana-twt-bnb',
  //   logo: 'degens/BANANA-TWT-BNB.svg',
  //   name: 'TWT-BNB LP',
  //   token: 'TWT-BNB ApeLP',
  //   tokenDescription: 'ApeSwap',
  //   tokenAddress: '0x4c48D692e3de076C7b844B956b28cdd1DD5C0945',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooApeTWT-BNB',
  //   earnedTokenAddress: '0x2EA33e6312010C502E0D3DbDC635C3aF2776C44b',
  //   earnContractAddress: '0x2EA33e6312010C502E0D3DbDC635C3aF2776C44b',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'banana-twt-bnb',
  //   oraclePrice: 0,
  //   depositsPaused: false,
  //   status: 'active',
  //   platform: 'ApeSwap',
  //   assets: ['TWT', 'BNB'],
  //   addLiquidityUrl:
  //     'https://dex.apeswap.finance/#/add/ETH/0x4B0F1812e5Df2A09796481Ff14017e6005508003',
  //   buyTokenUrl:
  //     'https://dex.apeswap.finance/#/swap?outputCurrency=0x4090e535f2e251f5f88518998b18b54d26b3b07c',
  //   createdAt: 1625587895,
  // },
  // {
  //   id: 'cakev2-titan-busd-eol',
  //   name: 'TITAN-BUSD LP',
  //   token: 'TITAN-BUSD LP2',
  //   tokenDescription: 'PancakeSwap',
  //   tokenAddress: '0x9392a1f471d9Aa14c0B8EB28bD7a3F4A814727bE',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooCakeV2TITAN-BUSD',
  //   earnedTokenAddress: '0xab25e8a555ec105BF7835349139808B1d1A979c8',
  //   earnContractAddress: '0xab25e8a555ec105BF7835349139808B1d1A979c8',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'cakev2-titan-busd',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'PancakeSwap',
  //   assets: ['TITAN', 'BUSD'],
  //   withdrawalFee: '0%',
  //   retireReason: 'rewards',
  //   removeLiquidityUrl:
  //     'https://pancakeswap.finance/remove/0xe898EDc43920F357A93083F1d4460437dE6dAeC2/0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?inputCurrency=0xe898EDc43920F357A93083F1d4460437dE6dAeC2&outputCurrency=0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
  //   createdAt: 1625829534,
  // },
  // {
  //   id: 'cakev2-one-wbnb',
  //   name: 'ONE-BNB LP',
  //   token: 'ONE-BNB LP2',
  //   tokenDescription: 'PancakeSwap',
  //   tokenAddress: '0x9D2296e2Fe3CdBf2EB3e3e2CA8811BaFA42eeDFF',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooCakeV2ONE-WBNB',
  //   earnedTokenAddress: '0xd1fa26820d7c549979790595F5ad9f5D8D16f55f',
  //   earnContractAddress: '0xd1fa26820d7c549979790595F5ad9f5D8D16f55f',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'cakev2-one-wbnb',
  //   oraclePrice: 0,
  //   depositsPaused: false,
  //   status: 'active',
  //   platform: 'PancakeSwap',
  //   assets: ['ONE', 'BNB'],
  //   addLiquidityUrl:
  //     'https://pancakeswap.finance/add/0x03fF0ff224f904be3118461335064bB48Df47938/0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?inputCurrency=0x03fF0ff224f904be3118461335064bB48Df47938&outputCurrency=0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
  //   createdAt: 1625827584,
  // },
  // {
  //   id: 'wex-watch-bnb-eol',
  //   name: 'WATCH-BNB WLP',
  //   token: 'WATCH-BNB WLP',
  //   tokenDescription: 'WaultFinance',
  //   tokenAddress: '0xd10382b532B75bCf58fa5bd5Af2470Bd29031337',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooWaultWATCH-BNB',
  //   earnedTokenAddress: '0xcaB54b92D6Bb43B7d8f6aE3C69C4D032882e7Eac',
  //   earnContractAddress: '0xcaB54b92D6Bb43B7d8f6aE3C69C4D032882e7Eac',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'wex-watch-bnb',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'Other',
  //   assets: ['WATCH', 'BNB'],
  //   withdrawalFee: '0%',
  //   retireReason: 'rewards',
  //   removeLiquidityUrl:
  //     'https://swap.wault.finance/bsc/#/remove/BNB/0x7A9f28EB62C791422Aa23CeAE1dA9C847cBeC9b0',
  //   buyTokenUrl:
  //     'https://swap.wault.finance/bsc/#/swap?outputCurrency=0x7A9f28EB62C791422Aa23CeAE1dA9C847cBeC9b0',
  //   createdAt: 1624974847,
  // },
  // {
  //   id: 'cakev2-form-busd-eol',
  //   name: 'FORM-BUSD LP',
  //   token: 'FORM-BUSD LP2',
  //   tokenDescription: 'PancakeSwap',
  //   tokenAddress: '0x3E19C18Fe3458A6065D8F0844cB7Eae52C9DAE07',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooCakeV2FORM-BUSD',
  //   earnedTokenAddress: '0xc093298526c7F477dd9D6Fc49455e5b7a19737df',
  //   earnContractAddress: '0xc093298526c7F477dd9D6Fc49455e5b7a19737df',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'cakev2-form-busd',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'PancakeSwap',
  //   assets: ['FORM', 'BUSD'],
  //   retireReason: 'rewards',
  //   addLiquidityUrl:
  //     'https://pancakeswap.finance/add/0x25A528af62e56512A19ce8c3cAB427807c28CC19/0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?inputCurrency=0x25A528af62e56512A19ce8c3cAB427807c28CC19&outputCurrency=0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
  //   createdAt: 1625632018,
  // },
  // {
  //   id: 'cakev2-busd-orbs-eol',
  //   name: 'BUSD-ORBS LP',
  //   token: 'BUSD-ORBS LP2',
  //   tokenDescription: 'PancakeSwap',
  //   tokenAddress: '0xB87b857670A44356f2b70337E0F218713D2378e8',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooCakeV2BUSD-ORBS',
  //   earnedTokenAddress: '0x37f9560A30C7A603dcAec4EFdcDBc3FfC9E55DF1',
  //   earnContractAddress: '0x37f9560A30C7A603dcAec4EFdcDBc3FfC9E55DF1',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'cakev2-busd-orbs',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'PancakeSwap',
  //   assets: ['BUSD', 'ORBS'],
  //   retireReason: 'rewards',
  //   addLiquidityUrl:
  //     'https://pancakeswap.finance/add/0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56/0xeBd49b26169e1b52c04cFd19FCf289405dF55F80',
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?inputCurrency=0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56&outputCurrency=0xeBd49b26169e1b52c04cFd19FCf289405dF55F80',
  //   createdAt: 1625604344,
  // },
  // {
  //   id: 'cakev2-bscpad-wbnb',
  //   name: 'BSCPAD-BNB LP',
  //   token: 'BSCPAD-BNB LP2',
  //   tokenDescription: 'PancakeSwap',
  //   tokenAddress: '0xba01662E978DE7d67F8FfC937726215eb8995d17',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooCakeV2BSCPAD-WBNB',
  //   earnedTokenAddress: '0xf2204ADf10864f29d9D52bDAc43880C0CA6C1C2a',
  //   earnContractAddress: '0xf2204ADf10864f29d9D52bDAc43880C0CA6C1C2a',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'cakev2-bscpad-wbnb',
  //   oraclePrice: 0,
  //   depositsPaused: false,
  //   status: 'active',
  //   platform: 'PancakeSwap',
  //   assets: ['BSCPAD', 'BNB'],
  //   addLiquidityUrl:
  //     'https://pancakeswap.finance/add/0x5A3010d4d8D3B5fB49f8B6E57FB9E48063f16700/0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?inputCurrency=0x5A3010d4d8D3B5fB49f8B6E57FB9E48063f16700&outputCurrency=0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
  //   createdAt: 1625523432,
  // },
  // {
  //   id: 'cakev2-adx-busd-eol',
  //   name: 'ADX-BUSD LP',
  //   token: 'ADX-BUSD LP2',
  //   tokenDescription: 'PancakeSwap',
  //   tokenAddress: '0x0648ff5de80Adf54aAc07EcE2490f50a418Dde23',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooCakeV2ADX-BUSD',
  //   earnedTokenAddress: '0x36134fa942FEbCF2C7052804f6c2697828D47e51',
  //   earnContractAddress: '0x36134fa942FEbCF2C7052804f6c2697828D47e51',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'cakev2-adx-busd',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'PancakeSwap',
  //   assets: ['ADX', 'BUSD'],
  //   retireReason: 'rewards',
  //   addLiquidityUrl:
  //     'https://pancakeswap.finance/add/0x6bfF4Fb161347ad7de4A625AE5aa3A1CA7077819/0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?inputCurrency=0x6bfF4Fb161347ad7de4A625AE5aa3A1CA7077819&outputCurrency=0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
  //   createdAt: 1625089092,
  // },
  // {
  //   id: 'cakev2-usdt-usdc',
  //   name: 'USDT-USDC LP',
  //   token: 'USDT-USDC LP2',
  //   tokenDescription: 'PancakeSwap',
  //   tokenAddress: '0xEc6557348085Aa57C72514D67070dC863C0a5A8c',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooCakeV2USDT-USDC',
  //   earnedTokenAddress: '0x094259b4A2Bb8fb65e55cD3744ff832a84cdCF37',
  //   earnContractAddress: '0x094259b4A2Bb8fb65e55cD3744ff832a84cdCF37',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'cakev2-usdt-usdc',
  //   oraclePrice: 0,
  //   depositsPaused: false,
  //   status: 'active',
  //   platform: 'PancakeSwap',
  //   assets: ['USDT', 'USDC'],
  //   addLiquidityUrl:
  //     'https://pancakeswap.finance/add/0x55d398326f99059fF775485246999027B3197955/0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d',
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?inputCurrency=0x55d398326f99059fF775485246999027B3197955&outputCurrency=0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d',
  //   createdAt: 1624921866,
  // },
  // {
  //   id: 'cakev2-cake-usdt',
  //   name: 'CAKE-USDT LP',
  //   token: 'CAKE-USDT LP2',
  //   tokenDescription: 'PancakeSwap',
  //   tokenAddress: '0xA39Af17CE4a8eb807E076805Da1e2B8EA7D0755b',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooCakeV2CAKE-USDT',
  //   earnedTokenAddress: '0x969b3fb717C432735088e9e7A7F261F37fb2e526',
  //   earnContractAddress: '0x969b3fb717C432735088e9e7A7F261F37fb2e526',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'cakev2-cake-usdt',
  //   oraclePrice: 0,
  //   depositsPaused: false,
  //   status: 'active',
  //   platform: 'PancakeSwap',
  //   assets: ['CAKE', 'USDT'],
  //   addLiquidityUrl:
  //     'https://pancakeswap.finance/add/0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82/0x55d398326f99059fF775485246999027B3197955',
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?inputCurrency=0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82&outputCurrency=0x55d398326f99059fF775485246999027B3197955',
  //   createdAt: 1624921366,
  // },
  // {
  //   id: 'merlin-merlin-eol',
  //   logo: 'single-assets/MERL.svg',
  //   name: 'MERL',
  //   token: 'MERL',
  //   tokenDescription: 'Merlin',
  //   tokenAddress: '0xDA360309C59CB8C434b28A91b823344a96444278',
  //   tokenDecimals: 18,
  //   earnedToken: 'mooMerlinMERL',
  //   earnedTokenAddress: '0x6261d793BdAe82842461A72B746bc18a5B7D2Bc4',
  //   earnContractAddress: '0x6261d793BdAe82842461A72B746bc18a5B7D2Bc4',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'tokens',
  //   oracleId: 'MERL',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'Other',
  //   assets: ['MERL'],
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?outputCurrency=0xDA360309C59CB8C434b28A91b823344a96444278',
  //   createdAt: 1623845585,
  // },
  // {
  //   id: 'cakev2-rabbit-wbnb-eol',
  //   name: 'RABBIT-BNB LP',
  //   token: 'RABBIT-BNB LP',
  //   tokenDescription: 'PancakeSwap',
  //   tokenAddress: '0x04b56A5B3f45CFeaFbfDCFc999c14be5434f2146',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooCakeV2RABBIT-WBNB',
  //   earnedTokenAddress: '0x034B9C71B18ad97dD6731419D9C830EA97aB41d9',
  //   earnContractAddress: '0x034B9C71B18ad97dD6731419D9C830EA97aB41d9',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'cakev2-rabbit-wbnb',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'PancakeSwap',
  //   assets: ['RABBIT', 'BNB'],
  //   withdrawalFee: '0%',
  //   retireReason: 'rewards',
  //   removeLiquidityUrl:
  //     'https://pancakeswap.finance/remove/BNB/0x95a1199EBA84ac5f19546519e287d43D2F0E1b41',
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?outputCurrency=0x95a1199EBA84ac5f19546519e287d43D2F0E1b41',
  //   createdAt: 1624570674,
  // },
  // {
  //   id: 'cakev2-htb-bnb-eol',
  //   name: 'HTB-BNB LP',
  //   token: 'HTB-BNB LP',
  //   tokenDescription: 'PancakeSwap',
  //   tokenAddress: '0x2a995d355D5df641e878C0F366685741Fd18D004',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooCakeV2HTB-BNB',
  //   earnedTokenAddress: '0x71dE1F33Cda6a1E7eAA3e939566c911B90aA2f05',
  //   earnContractAddress: '0x71dE1F33Cda6a1E7eAA3e939566c911B90aA2f05',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'cakev2-htb-bnb',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'PancakeSwap',
  //   assets: ['HTB', 'BNB'],
  //   retireReason: 'rewards',
  //   addLiquidityUrl:
  //     'https://pancakeswap.finance/add/BNB/0x4e840AADD28DA189B9906674B4Afcb77C128d9ea',
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?outputCurrency=0x4e840AADD28DA189B9906674B4Afcb77C128d9ea',
  //   createdAt: 1624290799,
  // },
  // {
  //   id: 'cakev2-wex-wbnb-eol',
  //   name: 'WEX-BNB LP',
  //   token: 'WEX-BNB LP',
  //   tokenDescription: 'PancakeSwap',
  //   tokenAddress: '0x547A355E70cd1F8CAF531B950905aF751dBEF5E6',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooCakeV2WEX-WBNB',
  //   earnedTokenAddress: '0xC7822D2152B2aa54FbF316C4dADFD2D899F47AD1',
  //   earnContractAddress: '0xC7822D2152B2aa54FbF316C4dADFD2D899F47AD1',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'cakev2-wex-wbnb',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'PancakeSwap',
  //   assets: ['WEX', 'BNB'],
  //   withdrawalFee: '0%',
  //   retireReason: 'rewards',
  //   removeLiquidityUrl:
  //     'https://pancakeswap.finance/remove/BNB/0xa9c41A46a6B3531d28d5c32F6633dd2fF05dFB90',
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?outputCurrency=0xa9c41A46a6B3531d28d5c32F6633dd2fF05dFB90',
  //   createdAt: 1624565664,
  // },
  // {
  //   id: 'cakev2-waultx-wbnb-eol',
  //   name: 'WAULTx-BNB LP',
  //   token: 'WAULTx-BNB LP',
  //   tokenDescription: 'PancakeSwap',
  //   tokenAddress: '0x3e4370204f598205998143F07ebCC486E441b456',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooCakeV2WAULTx-WBNB',
  //   earnedTokenAddress: '0x4e1B2951CC4C36D0997B7e2B67Ff957a2D53c17c',
  //   earnContractAddress: '0x4e1B2951CC4C36D0997B7e2B67Ff957a2D53c17c',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'cakev2-waultx-wbnb',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'PancakeSwap',
  //   assets: ['WAULTx', 'BNB'],
  //   retireReason: 'rewards',
  //   addLiquidityUrl:
  //     'https://pancakeswap.finance/add/BNB/0xB64E638E60D154B43f660a6BF8fD8a3b249a6a21',
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?outputCurrency=0xB64E638E60D154B43f660a6BF8fD8a3b249a6a21',
  //   createdAt: 1624565511,
  // },
  // {
  //   id: 'cakev2-woo-bnb',
  //   name: 'WOO-BNB LP',
  //   token: 'WOO-BNB LP',
  //   tokenDescription: 'PancakeSwap',
  //   tokenAddress: '0x89eE0491CE55d2f7472A97602a95426216167189',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooCakeV2WOO-BNB',
  //   earnedTokenAddress: '0x3a9CE43546c2D67e18d0699f8B5e9B75292e1806',
  //   earnContractAddress: '0x3a9CE43546c2D67e18d0699f8B5e9B75292e1806',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'cakev2-woo-bnb',
  //   oraclePrice: 0,
  //   depositsPaused: false,
  //   status: 'active',
  //   platform: 'PancakeSwap',
  //   assets: ['WOO', 'BNB'],
  //   addLiquidityUrl:
  //     'https://pancakeswap.finance/add/BNB/0x4691937a7508860F876c9c0a2a617E7d9E945D4B',
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?outputCurrency=0x4691937a7508860F876c9c0a2a617E7d9E945D4B',
  //   createdAt: 1624290817,
  // },
  // {
  //   id: 'ica-zbtc-eol',
  //   logo: 'single-assets/ZBTC.svg',
  //   name: 'ZBTC',
  //   token: 'ZBTC',
  //   tokenDescription: 'Icarus',
  //   tokenAddress: '0xD0dff49De3E314FDFD3f93c5EEee7D5d2F5515cD',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooIcarusZBTC',
  //   earnedTokenAddress: '0xd98625cc5d60afBce022a70eEAFa9811F47d6d63',
  //   earnContractAddress: '0xd98625cc5d60afBce022a70eEAFa9811F47d6d63',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'tokens',
  //   oracleId: 'ZBTC',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'Other',
  //   assets: ['ZBTC'],
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?outputCurrency=0xD0dff49De3E314FDFD3f93c5EEee7D5d2F5515cD',
  //   createdAt: 1624093024,
  //   retiredReason: 'tvl',
  // },
  // {
  //   id: 'ica-zeth-eol',
  //   logo: 'single-assets/ZETH.svg',
  //   name: 'ZETH',
  //   token: 'ZETH',
  //   tokenDescription: 'Icarus',
  //   tokenAddress: '0xdbEb98858f5d4Dca13EA0272B2b786E9415d3992',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooIcarusZETH',
  //   earnedTokenAddress: '0xE1e15BaF9Adb643269b97baDB7fB5fEE4aDC0705',
  //   earnContractAddress: '0xE1e15BaF9Adb643269b97baDB7fB5fEE4aDC0705',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'tokens',
  //   oracleId: 'ZETH',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'Other',
  //   assets: ['ZETH'],
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?outputCurrency=0xdbEb98858f5d4Dca13EA0272B2b786E9415d3992',
  //   createdAt: 1624022921,
  //   retiredReason: 'tvl',
  // },
  // {
  //   id: 'cakev2-boring-bn-eol',
  //   name: 'BORING-BNB LP',
  //   token: 'BORING-BNB LP',
  //   tokenDescription: 'PancakeSwap',
  //   tokenAddress: '0xDfA808Da5CFB9ABA5Fb3748FF85888F79174F378',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooCakeV2BORING-BNB',
  //   earnedTokenAddress: '0x4E59eD7b9A88720c7635E26970982bE77ED9cA9B',
  //   earnContractAddress: '0x4E59eD7b9A88720c7635E26970982bE77ED9cA9B',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'cakev2-boring-bnb',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   retireReason: 'rewards',
  //   platform: 'PancakeSwap',
  //   assets: ['BORING', 'BNB'],
  //   addLiquidityUrl:
  //     'https://pancakeswap.finance/add/BNB/0xffEecbf8D7267757c2dc3d13D730E97E15BfdF7F',
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?outputCurrency=0xffEecbf8D7267757c2dc3d13D730E97E15BfdF7F',
  //   createdAt: 1623960439,
  // },
  // {
  //   id: 'cakev2-marsh-bnb-eol',
  //   name: 'MARSH-BNB LP',
  //   token: 'MARSH-BNB LP',
  //   tokenDescription: 'PancakeSwap',
  //   tokenAddress: '0x222F93187f15F354D41Ff6a7703eF7e18cdD5103',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooCakeV2MARSH-BNB',
  //   earnedTokenAddress: '0x0d7Cc12D33Fc60C59308201f277a88e7Ff8Df681',
  //   earnContractAddress: '0x0d7Cc12D33Fc60C59308201f277a88e7Ff8Df681',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'cakev2-marsh-bnb',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'PancakeSwap',
  //   assets: ['MARSH', 'BNB'],
  //   retireReason: 'rewards',
  //   addLiquidityUrl:
  //     'https://pancakeswap.finance/add/BNB/0x2FA5dAF6Fe0708fBD63b1A7D1592577284f52256',
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?outputCurrency=0x2FA5dAF6Fe0708fBD63b1A7D1592577284f52256',
  //   createdAt: 1623960469,
  // },
  // {
  //   id: 'cakev2-hai-bnb-eol',
  //   name: 'HAI-BNB LP',
  //   token: 'HAI-BNB LP',
  //   tokenDescription: 'PancakeSwap',
  //   tokenAddress: '0x289841bFb694767bCb56fBc7B741aB4B4D97D490',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooCakeV2HAI-BNB',
  //   earnedTokenAddress: '0x36591BB1f679e5deE9f454A7909CDaC84cc6828e',
  //   earnContractAddress: '0x36591BB1f679e5deE9f454A7909CDaC84cc6828e',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'cakev2-hai-bnb',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'PancakeSwap',
  //   assets: ['HAI', 'BNB'],
  //   retireReason: 'rewards',
  //   addLiquidityUrl:
  //     'https://pancakeswap.finance/add/BNB/0xaA9E582e5751d703F85912903bacADdFed26484C',
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?outputCurrency=0xaA9E582e5751d703F85912903bacADdFed26484C',
  //   createdAt: 1623960421,
  // },
  // {
  //   id: 'cakev2-o3-busd-eol',
  //   name: 'O3-BUSD LP',
  //   token: 'O3-BUSD LP2',
  //   tokenDescription: 'PancakeSwap',
  //   tokenAddress: '0x7759283571Da8c0928786A96AE601944E10461Ff',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooCakeV2O3-BUSD',
  //   earnedTokenAddress: '0xfF36953Cc5B159FE31FBf5E68a3B4BD6010cdeed',
  //   earnContractAddress: '0xfF36953Cc5B159FE31FBf5E68a3B4BD6010cdeed',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'cakev2-o3-busd',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   retireReason: 'rewards',
  //   platform: 'PancakeSwap',
  //   assets: ['O3', 'BUSD'],
  //   addLiquidityUrl:
  //     'https://pancakeswap.finance/add/0xEe9801669C6138E84bD50dEB500827b776777d28/0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?inputCurrency=0xEe9801669C6138E84bD50dEB500827b776777d28&outputCurrency=0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
  //   createdAt: 1623960608,
  // },
  // {
  //   id: 'wex-eth-beth-eol',
  //   logo: 'eth-pairs/BETH-ETH.svg',
  //   name: 'ETH-BETH WLP',
  //   token: 'ETH-BETH WLP',
  //   tokenDescription: 'WaultFinance',
  //   tokenAddress: '0x11040f3c467993556B19813d4A18b684598Ba4BD',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooWaultETH-BETH',
  //   earnedTokenAddress: '0x71f20694f66432f08A0D0B71a07A7CBDeA40971E',
  //   earnContractAddress: '0x71f20694f66432f08A0D0B71a07A7CBDeA40971E',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'wex-eth-beth',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   retireReason: 'rewards',
  //   platform: 'Other',
  //   assets: ['ETH', 'BETH'],
  //   addLiquidityUrl:
  //     'https://swap.wault.finance/bsc/#/add/0x2170Ed0880ac9A755fd29B2688956BD959F933F8/0x250632378E573c6Be1AC2f97Fcdf00515d0Aa91B',
  //   buyTokenUrl:
  //     'https://swap.wault.finance/bsc/#/swap?inputCurrency=0x2170Ed0880ac9A755fd29B2688956BD959F933F8&outputCurrency=0x250632378E573c6Be1AC2f97Fcdf00515d0Aa91B',
  //   createdAt: 1623755000,
  // },
  // {
  //   id: 'iron-steel-iron',
  //   logo: 'degens/IRON.png',
  //   name: 'STEEL-IRON LP',
  //   token: 'STEEL-IRON LP',
  //   tokenDescription: 'PancakeSwap (Iron)',
  //   tokenAddress: '0x453a688c33696c42a73230B0b3AC1e4676D32aA6',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooIronSTEEL-IRON',
  //   earnedTokenAddress: '0x1384Ed18E881C0CC9027DC04ab88bFBF641c6106',
  //   earnContractAddress: '0x1384Ed18E881C0CC9027DC04ab88bFBF641c6106',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'iron-steel-iron',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'Other',
  //   assets: ['STEEL', 'IRON'],
  //   addLiquidityUrl:
  //     'https://pancakeswap.finance/add/0x7b65B489fE53fCE1F6548Db886C08aD73111DDd8/0x9001eE054F1692feF3A48330cB543b6FEc6287eb',
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?outputCurrency=0x9001eE054F1692feF3A48330cB543b6FEc6287eb',
  //   createdAt: 1620995742,
  // },
  // {
  //   id: 'iron-steel',
  //   logo: 'single-assets/STEEL.png',
  //   name: 'STEEL',
  //   token: 'STEEL',
  //   tokenDescription: 'Iron Finance',
  //   tokenAddress: '0x9001eE054F1692feF3A48330cB543b6FEc6287eb',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooIronSTEEL',
  //   earnedTokenAddress: '0xb20D8dA939089ebCaCd8C8b0b5c868Fb2d349733',
  //   earnContractAddress: '0xb20D8dA939089ebCaCd8C8b0b5c868Fb2d349733',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'tokens',
  //   oracleId: 'STEEL',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'Other',
  //   assets: ['STEEL'],
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?outputCurrency=0x9001eE054F1692feF3A48330cB543b6FEc6287eb',
  //   createdAt: 1623689407,
  // },
  // {
  //   id: 'memefarmv2-ape-bnb-eol',
  //   logo: 'degens/ape-bnb.png',
  //   name: 'APE-BNB LP',
  //   token: 'APE-BNB LP2',
  //   tokenDescription: 'PancakeSwap (MemeFarm)',
  //   tokenAddress: '0x08a2749863D8353dcEe9869131192784E783F48a',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooMemefarmV2APE-BNB',
  //   earnedTokenAddress: '0x1eda2b152742A9965e4692eDA509802755303373',
  //   earnContractAddress: '0x1eda2b152742A9965e4692eDA509802755303373',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'memefarmv2-ape-bnb',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'Other',
  //   assets: ['APE', 'BNB'],
  //   addLiquidityUrl:
  //     'https://pancakeswap.finance/add/BNB/0xa9eA4b786ee5b7A733c035564Bfd9341A4c9FC1e',
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?outputCurrency=0xa9eA4b786ee5b7A733c035564Bfd9341A4c9FC1e',
  //   createdAt: 1623756710,
  // },
  // {
  //   id: 'cakev2-ampl-bsc-mp-busd-eol',
  //   logo: 'cake-pairs/ampl-bsc-mp-busd.svg',
  //   name: 'AMPL-BSC-mp-BUSD LP',
  //   token: 'AMPL-BSC-mp-BUSD LP2',
  //   tokenDescription: 'PancakeSwap',
  //   tokenAddress: '0x6E98Beb694Ff1CDb1eE130eDD2b21b0298683d58',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooCakeV2AMPL-BSC-MP-BUSD',
  //   earnedTokenAddress: '0x6F327421a2B4c96C3E6e7e9588423F02A5A0165B',
  //   earnContractAddress: '0x6F327421a2B4c96C3E6e7e9588423F02A5A0165B',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'cakev2-ampl-bsc-mp-busd',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'PancakeSwap',
  //   assets: ['AMPL-BSC-mp', 'BUSD'],
  //   retireReason: 'rewards',
  //   addLiquidityUrl:
  //     'https://pancakeswap.finance/add/0xDB021b1B247fe2F1fa57e0A87C748Cc1E321F07F/0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?inputCurrency=0xDB021b1B247fe2F1fa57e0A87C748Cc1E321F07F&outputCurrency=0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
  //   createdAt: 1623667082,
  // },
  // {
  //   id: 'wex-eth-bnb-eol',
  //   logo: 'eth-pairs/ETH-BNB.png',
  //   name: 'ETH-BNB WLP',
  //   token: 'ETH-BNB WLP',
  //   tokenDescription: 'WaultFinance',
  //   tokenAddress: '0x04253aB3ff54D2E03b717BF6810a0a2Fd228365a',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooWaultETH-BNB',
  //   earnedTokenAddress: '0x8B238319E06009F5A8d89BC4667727f9624E52E1',
  //   earnContractAddress: '0x8B238319E06009F5A8d89BC4667727f9624E52E1',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'wex-eth-bnb',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   retireReason: 'rewards',
  //   platform: 'Other',
  //   assets: ['ETH', 'BNB'],
  //   addLiquidityUrl:
  //     'https://swap.wault.finance/bsc/#/add/0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c/0x2170ed0880ac9a755fd29b2688956bd959f933f8',
  //   buyTokenUrl:
  //     'https://swap.wault.finance/bsc/#/swap?inputCurrency=0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c&outputCurrency=0x2170ed0880ac9a755fd29b2688956bd959f933f8',
  //   createdAt: 1623254324,
  // },
  // {
  //   id: 'wex-usdt-alpaca-eol',
  //   logo: 'usdt-pairs/ALPACA-USDT.png',
  //   name: 'USDT-ALPACA WLP',
  //   token: 'USDT-ALPACA WLP',
  //   tokenDescription: 'WaultFinance',
  //   tokenAddress: '0xCb1b3C77606c61C9C2eCA86f554f5806343757AD',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooWaultUSDT-ALPACA',
  //   earnedTokenAddress: '0x68f967F903Fb99D6e00d825ebaE482bE031584dA',
  //   earnContractAddress: '0x68f967F903Fb99D6e00d825ebaE482bE031584dA',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'wex-usdt-alpaca',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   retireReason: 'rewards',
  //   platform: 'Other',
  //   assets: ['USDT', 'ALPACA'],
  //   addLiquidityUrl:
  //     'https://swap.wault.finance/bsc/#/add/0x55d398326f99059ff775485246999027b3197955/0x8f0528ce5ef7b51152a59745befdd91d97091d2f',
  //   buyTokenUrl:
  //     'https://swap.wault.finance/bsc/#/swap?inputCurrency=0x55d398326f99059ff775485246999027b3197955&outputCurrency=0x8f0528ce5ef7b51152a59745befdd91d97091d2f',
  //   createdAt: 1623252458,
  // },
  // {
  //   id: 'wex-usdt-matic-eol',
  //   logo: 'usdt-pairs/MATIC-USDT.png',
  //   name: 'USDT-MATIC WLP',
  //   token: 'USDT-MATIC WLP',
  //   tokenDescription: 'WaultFinance',
  //   tokenAddress: '0x4aeA71115DAB37e79C0005a042d6c3985255f3CB',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooWaultUSDT-MATIC',
  //   earnedTokenAddress: '0x6Bc93525A78c151c6392b93A3f1E2DB7313fc2c7',
  //   earnContractAddress: '0x6Bc93525A78c151c6392b93A3f1E2DB7313fc2c7',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'wex-usdt-matic',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   retireReason: 'rewards',
  //   platform: 'Other',
  //   assets: ['USDT', 'MATIC'],
  //   addLiquidityUrl:
  //     'https://swap.wault.finance/bsc/#/add/0x55d398326f99059ff775485246999027b3197955/0xcc42724c6683b7e57334c4e856f4c9965ed682bd',
  //   buyTokenUrl:
  //     'https://swap.wault.finance/bsc/#/swap?inputCurrency=0x55d398326f99059ff775485246999027b3197955&outputCurrency=0xcc42724c6683b7e57334c4e856f4c9965ed682bd',
  //   createdAt: 1623252284,
  // },
  // {
  //   id: 'wex-ada-busd-eol',
  //   logo: 'busd-pairs/ADA-BUSD.png',
  //   name: 'ADA-BUSD WLP',
  //   token: 'ADA-BUSD WLP',
  //   tokenDescription: 'WaultFinance',
  //   tokenAddress: '0xB24a49d02380fCDFbc320c25B0dCa997d2ed435D',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooWaultADA-BUSD',
  //   earnedTokenAddress: '0x847bA37DeE3d8757cB2493e14715ba079afe6865',
  //   earnContractAddress: '0x847bA37DeE3d8757cB2493e14715ba079afe6865',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'wex-ada-busd',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   retireReason: 'rewards',
  //   platform: 'Other',
  //   assets: ['ADA', 'BUSD'],
  //   addLiquidityUrl:
  //     'https://swap.wault.finance/bsc/#/add/0xe9e7cea3dedca5984780bafc599bd69add087d56/0x3ee2200efb3400fabb9aacf31297cbdd1d435d47',
  //   buyTokenUrl:
  //     'https://swap.wault.finance/bsc/#/swap?inputCurrency=0xe9e7cea3dedca5984780bafc599bd69add087d56&outputCurrency=0x3ee2200efb3400fabb9aacf31297cbdd1d435d47',
  //   createdAt: 1623451205,
  // },
  // {
  //   id: 'wex-xrp-busd-eol',
  //   logo: 'busd-pairs/XRP-BUSD.png',
  //   name: 'XRP-BUSD WLP',
  //   token: 'XRP-BUSD WLP',
  //   tokenDescription: 'WaultFinance',
  //   tokenAddress: '0x0e3ccA3D602eBEA46CF349B991Ee0d857A096d3D',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooWaultXRP-BUSD',
  //   earnedTokenAddress: '0x3b82B5740505908E181c9449E23f89Dc9F19B7a4',
  //   earnContractAddress: '0x3b82B5740505908E181c9449E23f89Dc9F19B7a4',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'wex-xrp-busd',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'Other',
  //   assets: ['XRP', 'BUSD'],
  //   withdrawalFee: '0%',
  //   retireReason: 'rewards',
  //   removeLiquidityUrl:
  //     'https://swap.wault.finance/bsc/#/remove/0xe9e7cea3dedca5984780bafc599bd69add087d56/0x1d2f0da169ceb9fc7b3144628db156f3f6c60dbe',
  //   buyTokenUrl:
  //     'https://swap.wault.finance/bsc/#/swap?inputCurrency=0xe9e7cea3dedca5984780bafc599bd69add087d56&outputCurrency=0x1d2f0da169ceb9fc7b3144628db156f3f6c60dbe',
  //   createdAt: 1623449312,
  // },
  // {
  //   id: 'wex-alpaca-bnb-eol',
  //   logo: 'bnb-pairs/ALPACA-BNB.png',
  //   name: 'ALPACA-BNB WLP',
  //   token: 'ALPACA-BNB WLP',
  //   tokenDescription: 'WaultFinance',
  //   tokenAddress: '0xC70fE75DAE1d69245F315Cec8BF4aebe41893807',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooWaultALPACA-BNB',
  //   earnedTokenAddress: '0xD2111F59eaE79B7066D2B5970D6755d2e3bEa358',
  //   earnContractAddress: '0xD2111F59eaE79B7066D2B5970D6755d2e3bEa358',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'wex-alpaca-bnb',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   retireReason: 'rewards',
  //   platform: 'Other',
  //   assets: ['ALPACA', 'BNB'],
  //   addLiquidityUrl:
  //     'https://swap.wault.finance/bsc/#/add/BNB/0x8f0528ce5ef7b51152a59745befdd91d97091d2f',
  //   buyTokenUrl:
  //     'https://swap.wault.finance/bsc/#/swap?outputCurrency=0x8f0528ce5ef7b51152a59745befdd91d97091d2f',
  //   createdAt: 1623240769,
  // },
  // {
  //   id: 'wex-usdt-wex-eol',
  //   logo: 'usdt-pairs/WEX-USDT.png',
  //   name: 'USDT-WEX WLP',
  //   token: 'USDT-WEX WLP',
  //   tokenDescription: 'WaultFinance',
  //   tokenAddress: '0x50e8D9Aa83eBDe9608074eC1faaDfD2E792D9B81',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooWaultUSDT-WEX',
  //   earnedTokenAddress: '0xdC048A42a6a57483EDC40C549b58d3A5fE5E5218',
  //   earnContractAddress: '0xdC048A42a6a57483EDC40C549b58d3A5fE5E5218',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'wex-usdt-wex',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   retireReason: 'rewards',
  //   platform: 'Other',
  //   assets: ['USDT', 'WEX'],
  //   addLiquidityUrl:
  //     'https://swap.wault.finance/bsc/#/add/0x55d398326f99059ff775485246999027b3197955/0xa9c41a46a6b3531d28d5c32f6633dd2ff05dfb90',
  //   buyTokenUrl:
  //     'https://swap.wault.finance/bsc/#/swap?inputCurrency=0x55d398326f99059ff775485246999027b3197955&outputCurrency=0xa9c41a46a6b3531d28d5c32f6633dd2ff05dfb90',
  //   createdAt: 1623240504,
  // },
  // {
  //   id: 'wex-wex-bnb-eol',
  //   logo: 'bnb-pairs/WEX-BNB.png',
  //   name: 'WEX-BNB WLP',
  //   token: 'WEX-BNB WLP',
  //   tokenDescription: 'WaultFinance',
  //   tokenAddress: '0xb4814f1ca673dBE9a2637C5dd4e94A4a0ED834C6',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooWaultWEX-BNB',
  //   earnedTokenAddress: '0x8988EE959828A41872734D1408d7dC612f085872',
  //   earnContractAddress: '0x8988EE959828A41872734D1408d7dC612f085872',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'wex-wex-bnb',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   retireReason: 'rewards',
  //   platform: 'Other',
  //   assets: ['WEX', 'BNB'],
  //   addLiquidityUrl:
  //     'https://swap.wault.finance/bsc/#/add/0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c/0xa9c41a46a6b3531d28d5c32f6633dd2ff05dfb90',
  //   buyTokenUrl:
  //     'https://swap.wault.finance/bsc/#/swap?inputCurrency=0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c&outputCurrency=0xa9c41a46a6b3531d28d5c32f6633dd2ff05dfb90',
  //   createdAt: 1623254549,
  // },
  // {
  //   id: 'cakev2-btcb-eth',
  //   logo: 'cake-pairs/btcb-eth.svg',
  //   name: 'BTCB-ETH LP',
  //   token: 'BTCB-ETH LP2',
  //   tokenDescription: 'PancakeSwap',
  //   tokenAddress: '0xD171B26E4484402de70e3Ea256bE5A2630d7e88D',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooCakeV2BTCB-ETH',
  //   earnedTokenAddress: '0xEf43E54Bb4221106953951238FC301a1f8939490',
  //   earnContractAddress: '0xEf43E54Bb4221106953951238FC301a1f8939490',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'cakev2-btcb-eth',
  //   oraclePrice: 0,
  //   depositsPaused: false,
  //   status: 'active',
  //   platform: 'PancakeSwap',
  //   assets: ['ETH', 'BTCB'],
  //   addLiquidityUrl:
  //     'https://pancakeswap.finance/add/0x2170Ed0880ac9A755fd29B2688956BD959F933F8/0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c',
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?inputCurrency=0x2170Ed0880ac9A755fd29B2688956BD959F933F8&outputCurrency=0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c',
  //   createdAt: 1623360754,
  // },
  // {
  //   id: 'cakev2-eth-usdc',
  //   logo: 'cake-pairs/eth-usdc.svg',
  //   name: 'ETH-USDC LP',
  //   token: 'ETH-USDC LP2',
  //   tokenDescription: 'PancakeSwap',
  //   tokenAddress: '0xEa26B78255Df2bBC31C1eBf60010D78670185bD0',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooCakeV2ETH-USDC',
  //   earnedTokenAddress: '0x26107644A6dbC38385F4B7263d9bA96D829eC090',
  //   earnContractAddress: '0x26107644A6dbC38385F4B7263d9bA96D829eC090',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'cakev2-eth-usdc',
  //   oraclePrice: 0,
  //   depositsPaused: false,
  //   status: 'active',
  //   platform: 'PancakeSwap',
  //   assets: ['ETH', 'USDC'],
  //   addLiquidityUrl:
  //     'https://pancakeswap.finance/add/0x2170Ed0880ac9A755fd29B2688956BD959F933F8/0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d',
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?inputCurrency=0x2170Ed0880ac9A755fd29B2688956BD959F933F8&outputCurrency=0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d',
  //   createdAt: 1623366577,
  // },
  // {
  //   id: 'banana-typh-bnb-eol',
  //   logo: 'bnb-pairs/typh-bnb.svg',
  //   name: 'TYPH-BNB LP',
  //   token: 'TYPH-BNB ApeLP',
  //   tokenDescription: 'ApeSwap',
  //   tokenAddress: '0xFEaf192c2662E5700bDa860c58d2686d9cc12Ec8',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooApeTYPH-BNB',
  //   earnedTokenAddress: '0xdAfE6095F8e2509CfF05E489eE971dB0EdED8d6f',
  //   earnContractAddress: '0xdAfE6095F8e2509CfF05E489eE971dB0EdED8d6f',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'banana-typh-bnb',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   retireReason: 'rewards',
  //   platform: 'ApeSwap',
  //   assets: ['TYPH', 'BNB'],
  //   addLiquidityUrl:
  //     'https://dex.apeswap.finance/#/add/ETH/0x4090e535f2e251f5f88518998b18b54d26b3b07c',
  //   buyTokenUrl:
  //     'https://dex.apeswap.finance/#/swap?outputCurrency=0x4090e535f2e251f5f88518998b18b54d26b3b07c',
  //   createdAt: 1623306007,
  // },
  // {
  //   id: 'tenfi-tenfi-bnb',
  //   logo: 'single-assets/TENFI.png',
  //   name: 'TENFI-BNB LP',
  //   token: 'TENFI-BNB LP',
  //   tokenDescription: 'PancakeSwap (Ten)',
  //   tokenAddress: '0x09F39f9B7d6395155396Fed7347620dD54Da1dc6',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooTenTEN-BNB',
  //   earnedTokenAddress: '0x0Ce6FeC7521b7d054b5B0a5F43D49120f9d6CFbc',
  //   earnContractAddress: '0x0Ce6FeC7521b7d054b5B0a5F43D49120f9d6CFbc',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'tenfi-tenfi-bnb',
  //   oraclePrice: 0,
  //   depositsPaused: false,
  //   status: 'active',
  //   platform: 'Other',
  //   assets: ['TENFI', 'BNB'],
  //   addLiquidityUrl:
  //     'https://pancakeswap.finance/add/BNB/0xd15c444f1199ae72795eba15e8c1db44e47abf62',
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?outputCurrency=0xd15c444f1199ae72795eba15e8c1db44e47abf62',
  //   createdAt: 1623240043,
  // },
  // {
  //   id: 'tenfi-tenfi-busd',
  //   logo: 'single-assets/TENFI.png',
  //   name: 'TENFI-BUSD LP',
  //   token: 'TENFI-BUSD LP',
  //   tokenDescription: 'PancakeSwap (Ten)',
  //   tokenAddress: '0xac6EE351e2E9108f03c7F5c49296505B8F336Be3',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooTenTEN-BUSD',
  //   earnedTokenAddress: '0x00F204F524a433f45a6278c2C0E10F5292b3C5B9',
  //   earnContractAddress: '0x00F204F524a433f45a6278c2C0E10F5292b3C5B9',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'tenfi-tenfi-busd',
  //   oraclePrice: 0,
  //   depositsPaused: false,
  //   status: 'active',
  //   platform: 'Other',
  //   assets: ['TENFI', 'BUSD'],
  //   addLiquidityUrl:
  //     'https://pancakeswap.finance/add/0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56/0xd15c444f1199ae72795eba15e8c1db44e47abf62',
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?outputCurrency=0xd15c444f1199ae72795eba15e8c1db44e47abf62',
  //   createdAt: 1623240420,
  // },
  // {
  //   id: 'cakev2-mbox-bnb-eol',
  //   logo: 'bnb-pairs/MBOX-BNB.svg',
  //   name: 'MBOX-BNB LP',
  //   token: 'MBOX-BNB LP',
  //   tokenDescription: 'PancakeSwap',
  //   tokenAddress: '0x8FA59693458289914dB0097F5F366d771B7a7C3F',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooCakeV2MBOX-BNB',
  //   earnedTokenAddress: '0x2Dd2a81d1B44723f6106f621d0D64848fbdbCc77',
  //   earnContractAddress: '0x2Dd2a81d1B44723f6106f621d0D64848fbdbCc77',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'cakev2-mbox-bnb',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'PancakeSwap',
  //   assets: ['MBOX', 'BNB'],
  //   addLiquidityUrl:
  //     'https://pancakeswap.finance/add/BNB/0x3203c9e46ca618c8c1ce5dc67e7e9d75f5da2377',
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?outputCurrency=0x3203c9e46ca618c8c1ce5dc67e7e9d75f5da2377',
  //   createdAt: 1623165437,
  //   retiredReason: 'tvl',
  // },
  // {
  //   id: 'banana-celr-bnb',
  //   logo: 'bnb-pairs/CELR-BNB.svg',
  //   name: 'CELR-BNB LP',
  //   token: 'CELR-BNB LP',
  //   tokenDescription: 'ApeSwap',
  //   tokenAddress: '0xB7F42e58Cf2364ac994F93f7afF3b158CFA3dC76',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooApeCELR-BNB',
  //   earnedTokenAddress: '0x04E56F75EDD898BEe4A136bDBAb2b47Be59109c8',
  //   earnContractAddress: '0x04E56F75EDD898BEe4A136bDBAb2b47Be59109c8',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'banana-celr-bnb',
  //   oraclePrice: 0,
  //   depositsPaused: false,
  //   status: 'active',
  //   platform: 'ApeSwap',
  //   assets: ['CELR', 'BNB'],
  //   addLiquidityUrl:
  //     'https://dex.apeswap.finance/#/add/ETH/0x1f9f6a696C6Fd109cD3956F45dC709d2b3902163',
  //   buyTokenUrl:
  //     'https://dex.apeswap.finance/#/swap?outputCurrency=0x1f9f6a696C6Fd109cD3956F45dC709d2b3902163',
  //   createdAt: 1623097537,
  // },
  // {
  //   id: 'banana-shib-bnb',
  //   logo: 'bnb-pairs/SHIB-BNB.svg',
  //   name: 'SHIB-BNB LP',
  //   token: 'SHIB-BNB LP',
  //   tokenDescription: 'ApeSwap',
  //   tokenAddress: '0xC0AFB6078981629F7eAe4f2ae93b6DBEA9D7a7e9',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooApeSHIB-BNB',
  //   earnedTokenAddress: '0x25699A5Bdcb524e3030a89c2f5088a076DC562bC',
  //   earnContractAddress: '0x25699A5Bdcb524e3030a89c2f5088a076DC562bC',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'banana-shib-bnb',
  //   oraclePrice: 0,
  //   depositsPaused: false,
  //   status: 'active',
  //   platform: 'ApeSwap',
  //   assets: ['SHIB', 'BNB'],
  //   addLiquidityUrl:
  //     'https://dex.apeswap.finance/#/add/ETH/0x2859e4544C4bB03966803b044A93563Bd2D0DD4D',
  //   buyTokenUrl:
  //     'https://dex.apeswap.finance/#/swap?outputCurrency=0x2859e4544C4bB03966803b044A93563Bd2D0DD4D',
  //   createdAt: 1623096325,
  // },
  // {
  //   id: 'cakev2-ata-bnb-eol',
  //   logo: 'bnb-pairs/ATA-BNB.svg',
  //   name: 'ATA-BNB LP',
  //   token: 'ATA-BNB LP',
  //   tokenDescription: 'PancakeSwap',
  //   tokenAddress: '0xeF7767677867552CFA699148b96a03358A9be779',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooCakeV2ATA-BNB',
  //   earnedTokenAddress: '0x76746cDb7C1DDA92c6211D5Cc4bC03859c5Ee8a1',
  //   earnContractAddress: '0x76746cDb7C1DDA92c6211D5Cc4bC03859c5Ee8a1',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'cakev2-ata-bnb',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'PancakeSwap',
  //   assets: ['ATA', 'BNB'],
  //   retireReason: 'rewards',
  //   addLiquidityUrl:
  //     'https://pancakeswap.finance/add/BNB/0xa2120b9e674d3fc3875f415a7df52e382f141225',
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?outputCurrency=0xa2120b9e674d3fc3875f415a7df52e382f141225',
  //   createdAt: 1623064219,
  // },
  // {
  //   id: 'panther-panther-busd-eol',
  //   logo: 'degens/panther-busd.png',
  //   name: 'PANTHER-BUSD LP',
  //   token: 'PANTHER-BUSD LP',
  //   tokenDescription: 'PantherSwap',
  //   tokenAddress: '0x9287F5Ad55D7eE8EAE90B865718EB9A7cF3fb71A',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooPantherPANTHER-BUSD',
  //   earnedTokenAddress: '0x3f8664Bc085aCceb2bC311C0F56bAA44F9C17204',
  //   earnContractAddress: '0x3f8664Bc085aCceb2bC311C0F56bAA44F9C17204',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'panther-panther-busd',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   retireReason: 'rewards',
  //   platform: 'Other',
  //   assets: ['PANTHER', 'BUSD'],
  //   addLiquidityUrl:
  //     'https://dex.pantherswap.com/#/add/0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56/0x1f546aD641B56b86fD9dCEAc473d1C7a357276B7',
  //   buyTokenUrl:
  //     'https://dex.pantherswap.com/#/swap?outputCurrency=0x1f546ad641b56b86fd9dceac473d1c7a357276b7',
  //   createdAt: 1622797586,
  // },
  // {
  //   id: 'panther-panther-bnb-eol',
  //   logo: 'degens/panther-bnb.png',
  //   name: 'PANTHER-BNB LP',
  //   token: 'PANTHER-BNB LP',
  //   tokenDescription: 'PantherSwap',
  //   tokenAddress: '0xC24AD5197DaeFD97DF28C70AcbDF17d9fF92a49B',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooPantherPANTHER-BNB',
  //   earnedTokenAddress: '0xF2E57C6156aE3bFD107AE6187D53dd621D508D02',
  //   earnContractAddress: '0xF2E57C6156aE3bFD107AE6187D53dd621D508D02',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'panther-panther-bnb',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   retireReason: 'rewards',
  //   platform: 'Other',
  //   assets: ['PANTHER', 'BNB'],
  //   addLiquidityUrl:
  //     'https://dex.pantherswap.com/#/add/ETH/0x1f546aD641B56b86fD9dCEAc473d1C7a357276B7',
  //   buyTokenUrl:
  //     'https://dex.pantherswap.com/#/swap?outputCurrency=0x1f546ad641b56b86fd9dceac473d1c7a357276b7',
  //   createdAt: 1622799050,
  // },
  // {
  //   id: 'garuda-garuda-bnb',
  //   logo: 'degens/garuda-bnb.png',
  //   name: 'GARUDA-BNB LP',
  //   token: 'GARUDA-BNB LP',
  //   tokenDescription: 'PancakeSwap',
  //   tokenAddress: '0x1AB609b5d930c64Be12A05D040A9822b0Da84A8F',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooGarudaGARUDA-BNB',
  //   earnedTokenAddress: '0xeC16330D826459C8497Cb7B5e44f8Aa88f28470B',
  //   earnContractAddress: '0xeC16330D826459C8497Cb7B5e44f8Aa88f28470B',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'garuda-garuda-bnb',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'Other',
  //   assets: ['GARUDA', 'BNB'],
  //   addLiquidityUrl:
  //     'https://pancakeswap.finance/add/BNB/0x854086dC841e1bfae50Cb615bF41f55BF432a90b',
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?outputCurrency=0x854086dC841e1bfae50Cb615bF41f55BF432a90b',
  //   createdAt: 1620992847,
  // },
  // {
  //   id: 'cakev2-mtrg-busd-eol',
  //   logo: 'busd-pairs/MTRG-BUSD.svg',
  //   name: 'MTRG-BUSD LP',
  //   token: 'MTRG-BUSD LP',
  //   tokenDescription: 'PancakeSwap',
  //   tokenAddress: '0x4dcA4D427511bC327639b222DA18FA5e334F686F',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooCakeV2MTRG-BUSD',
  //   earnedTokenAddress: '0xD1A43172f05D0D9a3DD1B9c861332378FdaDB88c',
  //   earnContractAddress: '0xD1A43172f05D0D9a3DD1B9c861332378FdaDB88c',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'cakev2-mtrg-busd',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   retireReason: 'rewards',
  //   platform: 'PancakeSwap',
  //   assets: ['MTRG', 'BUSD'],
  //   addLiquidityUrl:
  //     'https://pancakeswap.finance/add/0xBd2949F67DcdC549c6Ebe98696449Fa79D988A9F/0xe9e7cea3dedca5984780bafc599bd69add087d56',
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?inputCurrency=0xe9e7cea3dedca5984780bafc599bd69add087d56&outputCurrency=0xBd2949F67DcdC549c6Ebe98696449Fa79D988A9F',
  //   createdAt: 1622808356,
  // },
  // {
  //   id: 'cakev2-qkc-busd-eol',
  //   logo: 'busd-pairs/QKC-BUSD.svg',
  //   name: 'QKC-BUSD LP',
  //   token: 'QKC-BUSD LP',
  //   tokenDescription: 'PancakeSwap',
  //   tokenAddress: '0x8853E3309a31583eA438F7704681F46F0D4D909b',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooCakeV2QKC-BUSD',
  //   earnedTokenAddress: '0xE61b206339EA172824fBF334cADFe2A6b0dEbAeD',
  //   earnContractAddress: '0xE61b206339EA172824fBF334cADFe2A6b0dEbAeD',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'cakev2-qkc-busd',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'PancakeSwap',
  //   assets: ['QKC', 'BUSD'],
  //   retireReason: 'rewards',
  //   addLiquidityUrl:
  //     'https://pancakeswap.finance/add/0xa1434f1fc3f437fa33f7a781e041961c0205b5da/0xe9e7cea3dedca5984780bafc599bd69add087d56',
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?inputCurrency=0xe9e7cea3dedca5984780bafc599bd69add087d56&outputCurrency=0xa1434f1fc3f437fa33f7a781e041961c0205b5da',
  //   createdAt: 1622808107,
  // },
  // {
  //   id: 'cakev2-mx-busd-eol',
  //   logo: 'busd-pairs/MX-BUSD.svg',
  //   name: 'MX-BUSD LP',
  //   token: 'MX-BUSD LP',
  //   tokenDescription: 'PancakeSwap',
  //   tokenAddress: '0x41F049D990D38305504631C9835f6F856Bf1BA67',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooCakeV2MX-BUSD',
  //   earnedTokenAddress: '0x7e7CEbCC006c74a406138BF94CA3815CE04461e0',
  //   earnContractAddress: '0x7e7CEbCC006c74a406138BF94CA3815CE04461e0',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'cakev2-mx-busd',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'PancakeSwap',
  //   assets: ['MX', 'BUSD'],
  //   retireReason: 'rewards',
  //   addLiquidityUrl:
  //     'https://pancakeswap.finance/add/0x9f882567a62a5560d147d64871776eea72df41d3/0xe9e7cea3dedca5984780bafc599bd69add087d56',
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?inputCurrency=0xe9e7cea3dedca5984780bafc599bd69add087d56&outputCurrency=0x9f882567a62a5560d147d64871776eea72df41d3',
  //   createdAt: 1622801174,
  // },
  // {
  //   id: 'cakev2-ktn-bnb-eol',
  //   logo: 'bnb-pairs/KTN-BNB.svg',
  //   name: 'KTN-BNB LP',
  //   token: 'KTN-BNB LP2',
  //   tokenDescription: 'PancakeSwap',
  //   tokenAddress: '0x48028De4A9b0d3D91180333d796021ec7757bA1B',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooCakeV2KTN-BNB',
  //   earnedTokenAddress: '0xBD322911Bd9E944743CDb88Cb5f9da33B5CD55BE',
  //   earnContractAddress: '0xBD322911Bd9E944743CDb88Cb5f9da33B5CD55BE',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'cakev2-ktn-bnb',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   retireReason: 'rewards',
  //   platform: 'PancakeSwap',
  //   assets: ['KTN', 'BNB'],
  //   addLiquidityUrl:
  //     'https://pancakeswap.finance/add/BNB/0xDAe6c2A48BFAA66b43815c5548b10800919c993E',
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?outputCurrency=0xDAe6c2A48BFAA66b43815c5548b10800919c993E',
  //   createdAt: 1622633223,
  // },
  // {
  //   id: 'ellipsis-eps-eol',
  //   logo: 'single-assets/EPS.png',
  //   name: 'EPS',
  //   token: 'EPS',
  //   tokenDescription: 'Ellipsis',
  //   tokenAddress: '0xA7f552078dcC247C2684336020c03648500C6d9F',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooEllipsisEPS',
  //   earnedTokenAddress: '0x0CDAf84f90F0BBFF225A82D7165967Fff93A7bea',
  //   earnContractAddress: '0x0CDAf84f90F0BBFF225A82D7165967Fff93A7bea',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'tokens',
  //   oracleId: 'EPS',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'Ellipsis',
  //   assets: ['EPS'],
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?outputCurrency=0xA7f552078dcC247C2684336020c03648500C6d9F',
  //   createdAt: 1622492691,
  //   retiredReason: 'tvl',
  // },
  // {
  //   id: 'dopple-dop-lp',
  //   logo: 'uncategorized/DOPPLE-DOP-LP.svg',
  //   name: 'BUSD/USDT/USDC/DAI',
  //   token: 'BUSD/USDT/USDC/DAI',
  //   tokenDescription: 'Dopple',
  //   tokenAddress: '0x9116F04092828390799514Bac9986529d70c3791',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooDoppleDopLP',
  //   earnedTokenAddress: '0x8464365600d6A1b86A7f7c80DCD63AFa861069dB',
  //   earnContractAddress: '0x8464365600d6A1b86A7f7c80DCD63AFa861069dB',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'dopple-dop-lp',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   assets: ['BUSD', 'USDT', 'USDC', 'DAI'],
  //   platform: 'Other',
  //   withdrawalFee: '0%',
  //   addLiquidityUrl: 'https://dopple.finance/Deposit/dop-lps',
  //   createdAt: 1621853505,
  // },
  // {
  //   id: 'cakev2-tusd-busd-eol',
  //   logo: 'busd-pairs/TUSD-BUSD.svg',
  //   name: 'TUSD-BUSD LP',
  //   token: 'TUSD-BUSD LP2',
  //   tokenDescription: 'PancakeSwap',
  //   tokenAddress: '0x2E28b9B74D6d99D4697e913b82B41ef1CAC51c6C',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooCakeV2TUSD-BUSD',
  //   earnedTokenAddress: '0x62Cce60Bca43926e062ca2A4e2DcdAbEA5D2EAD2',
  //   earnContractAddress: '0x62Cce60Bca43926e062ca2A4e2DcdAbEA5D2EAD2',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'cakev2-tusd-busd',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'PancakeSwap',
  //   assets: ['TUSD', 'BUSD'],
  //   addLiquidityUrl:
  //     'https://pancakeswap.finance/add/0xe9e7cea3dedca5984780bafc599bd69add087d56/0x14016e85a25aeb13065688cafb43044c2ef86784',
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?inputCurrency=0xe9e7cea3dedca5984780bafc599bd69add087d56&outputCurrency=0x14016e85a25aeb13065688cafb43044c2ef86784',
  //   createdAt: 1622579735,
  //   retiredReason: 'tvl',
  // },
  // {
  //   id: 'mdex-bsc-bnb-usdt-eol',
  //   logo: 'usdt-pairs/BNB-USDT.png',
  //   name: 'BNB-USDT LP',
  //   token: 'BNB-USDT LP',
  //   tokenDescription: 'Mdex',
  //   tokenAddress: '0x09CB618bf5eF305FadfD2C8fc0C26EeCf8c6D5fd',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooMdexBNB-USDT',
  //   earnedTokenAddress: '0x7638db36fc21E030D5F8158B27181c3b27418bE2',
  //   earnContractAddress: '0x7638db36fc21E030D5F8158B27181c3b27418bE2',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'mdex-bsc-bnb-usdt',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'Mdex',
  //   assets: ['BNB', 'USDT'],
  //   buyTokenUrl:
  //     'https://bsc.mdex.com/#/swap?outputCurrency=0x55d398326f99059fF775485246999027B3197955',
  //   addLiquidityUrl: 'https://bsc.mdex.com/#/add/BNB/0x55d398326f99059fF775485246999027B3197955',
  //   createdAt: 1622469917,
  //   retiredReason: 'tvl',
  // },
  // {
  //   id: 'mdex-bsc-fil-usdt-eol',
  //   logo: 'usdt-pairs/FIL-USDT.png',
  //   name: 'FIL-USDT LP',
  //   token: 'FIL-USDT LP',
  //   tokenDescription: 'Mdex',
  //   tokenAddress: '0xCAABda10a3ac99Fc15f5B636Aa18E6B4Fd8db16D',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooMdexFIL-USDT',
  //   earnedTokenAddress: '0xE4E5795596E153e8f39D35Ff8559ae4D7bd03622',
  //   earnContractAddress: '0xE4E5795596E153e8f39D35Ff8559ae4D7bd03622',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'mdex-bsc-fil-usdt',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   retireReason: 'tvl',
  //   platform: 'Mdex',
  //   assets: ['FIL', 'USDT'],
  //   buyTokenUrl:
  //     'https://bsc.mdex.com/#/swap?inputCurrency=0x55d398326f99059ff775485246999027b3197955&outputCurrency=0x0d8ce2a99bb6e3b7db580ed848240e4a0f9ae153',
  //   addLiquidityUrl:
  //     'https://bsc.mdex.com/#/add/0x0d8ce2a99bb6e3b7db580ed848240e4a0f9ae153/0x55d398326f99059fF775485246999027B3197955',
  //   createdAt: 1622290867,
  // },
  // {
  //   id: 'cakev2-vrt-bnb-eol',
  //   logo: 'bnb-pairs/VRT-BNB.svg',
  //   name: 'VRT-BNB LP',
  //   token: 'VRT-BNB LP2',
  //   tokenDescription: 'PancakeSwap',
  //   tokenAddress: '0xEf5212aDa83EC2cc105C409DF10b8806D20E3b35',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooCakeV2VRT-BNB',
  //   earnedTokenAddress: '0xA8cC688E9B387b0cBe41D0CcF55875CE2318695A',
  //   earnContractAddress: '0xA8cC688E9B387b0cBe41D0CcF55875CE2318695A',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'cakev2-vrt-bnb',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'PancakeSwap',
  //   assets: ['VRT', 'BNB'],
  //   addLiquidityUrl:
  //     'https://pancakeswap.finance/add/BNB/0x5f84ce30dc3cf7909101c69086c50de191895883',
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?outputCurrency=0x5f84ce30dc3cf7909101c69086c50de191895883',
  //   createdAt: 1622289628,
  // },
  // {
  //   id: 'cakev2-ez-bnb-eol',
  //   logo: 'bnb-pairs/EZ-BNB.svg',
  //   name: 'EZ-BNB LP',
  //   token: 'EZ-BNB LP2',
  //   tokenDescription: 'PancakeSwap',
  //   tokenAddress: '0x51BF99bBae59b67E5cE2fA9c17b683384773F8b3',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooCakeV2EZ-BNB',
  //   earnedTokenAddress: '0x3Ee4b86Bf6BbceBf46695C0F3703F87Ff18a776b',
  //   earnContractAddress: '0x3Ee4b86Bf6BbceBf46695C0F3703F87Ff18a776b',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'cakev2-ez-bnb',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'PancakeSwap',
  //   assets: ['EZ', 'BNB'],
  //   retireReason: 'rewards',
  //   addLiquidityUrl:
  //     'https://pancakeswap.finance/add/BNB/0x5512014efa6cd57764fa743756f7a6ce3358cc83',
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?outputCurrency=0x5512014efa6cd57764fa743756f7a6ce3358cc83',
  //   createdAt: 1622289457,
  // },
  // {
  //   id: 'cakev2-kalm-bnb-eol',
  //   logo: 'bnb-pairs/KALM-BNB.svg',
  //   name: 'KALM-BNB LP',
  //   token: 'KALM-BNB LP2',
  //   tokenDescription: 'PancakeSwap',
  //   tokenAddress: '0xc74f7243766269DeC5b85b0eF4aF186e909c1b06',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooCakeV2KALM-BNB',
  //   earnedTokenAddress: '0x487D8014F8803757e0542e127854DEBd213A39e5',
  //   earnContractAddress: '0x487D8014F8803757e0542e127854DEBd213A39e5',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'cakev2-kalm-bnb',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'PancakeSwap',
  //   assets: ['KALM', 'BNB'],
  //   withdrawalFee: '0%',
  //   retireReason: 'rewards',
  //   removeLiquidityUrl:
  //     'https://pancakeswap.finance/remove/BNB/0x4ba0057f784858a48fe351445c672ff2a3d43515',
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?outputCurrency=0x4ba0057f784858a48fe351445c672ff2a3d43515',
  //   createdAt: 1622289670,
  // },
  // {
  //   id: 'jetswap-wings-eol',
  //   logo: 'degens/WINGS.svg',
  //   name: 'WINGS',
  //   token: 'WINGS',
  //   tokenDescription: 'JetSwap',
  //   tokenAddress: '0x0487b824c8261462F88940f97053E65bDb498446',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooJetswapWINGS',
  //   earnedTokenAddress: '0x5e784882E8D2ac69777713f0e71100741b8aC2F6',
  //   earnContractAddress: '0x5e784882E8D2ac69777713f0e71100741b8aC2F6',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'tokens',
  //   oracleId: 'WINGS',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'JetSwap',
  //   assets: ['WINGS'],
  //   buyTokenUrl:
  //     'https://exchange.jetswap.finance/#/swap?outputCurrency=0x0487b824c8261462f88940f97053e65bdb498446',
  //   createdAt: 1621591563,
  //   retiredReason: 'tvl',
  // },
  // {
  //   id: 'dopple-ust-lp',
  //   logo: 'uncategorized/DOPPLE-UST-LP.png',
  //   name: 'UST/BUSD/USDT',
  //   token: 'UST/BUSD/USDT',
  //   tokenDescription: 'Dopple',
  //   tokenAddress: '0x7Edcdc8cD062948CE9A9bc38c477E6aa244dD545',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooDoppleUstLP',
  //   earnedTokenAddress: '0x4Af8ED7c9f5D0C672F71a82c827FC3A02485791F',
  //   earnContractAddress: '0x4Af8ED7c9f5D0C672F71a82c827FC3A02485791F',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'dopple-ust-lp',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   assets: ['BUSD', 'USDT', 'UST'],
  //   platform: 'Other',
  //   withdrawalFee: '0%',
  //   addLiquidityUrl: 'https://dopple.finance/Deposit/ust-pools-lps',
  //   createdAt: 1622189304,
  // },
  // {
  //   id: 'cakev2-bifi-bnb',
  //   logo: 'bnb-pairs/BIFI-BNB.svg',
  //   name: 'BIFI-BNB LP',
  //   token: 'BIFI-BNB LP2',
  //   tokenDescription: 'PancakeSwap',
  //   tokenAddress: '0x3f1A9f3D9aaD8bD339eD4853F345d2eF89fbfE0c',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooCakeV2BIFI-BNB',
  //   earnedTokenAddress: '0x59960d624600aE1669584Bb504a5f4F2e90EbF46',
  //   earnContractAddress: '0x59960d624600aE1669584Bb504a5f4F2e90EbF46',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'cakev2-bifi-bnb',
  //   oraclePrice: 0,
  //   depositsPaused: false,
  //   status: 'active',
  //   platform: 'PancakeSwap',
  //   assets: ['BIFI', 'BNB'],
  //   risks: [
  //     'COMPLEXITY_LOW',
  //     'BATTLE_TESTED',
  //     'IL_LOW',
  //     'MCAP_MEDIUM',
  //     'AUDIT',
  //     'CONTRACTS_VERIFIED',
  //   ],
  //   stratType: 'StratLP',
  //   addLiquidityUrl:
  //     'https://pancakeswap.finance/add/BNB/0xCa3F508B8e4Dd382eE878A314789373D80A5190A',
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?outputCurrency=0xCa3F508B8e4Dd382eE878A314789373D80A5190A',
  //   createdAt: 1619335376,
  // },
  // {
  //   id: 'banana-sushi-bnb',
  //   logo: 'degens/BANANA-SUSHI-BNB.svg',
  //   name: 'SUSHI-BNB LP',
  //   token: 'SUSHI-BNB LP',
  //   tokenDescription: 'ApeSwap',
  //   tokenAddress: '0x1D0C3044eBf055986c52D38b19B5369374E6Bc6A',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooApeSUSHI-BNB',
  //   earnedTokenAddress: '0x0DC52004341103FF00C65b9c1370c2c2d125CBc5',
  //   earnContractAddress: '0x0DC52004341103FF00C65b9c1370c2c2d125CBc5',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'banana-sushi-bnb',
  //   oraclePrice: 0,
  //   depositsPaused: false,
  //   status: 'active',
  //   platform: 'ApeSwap',
  //   assets: ['SUSHI', 'BNB'],
  //   addLiquidityUrl:
  //     'https://dex.apeswap.finance/#/add/ETH/0x947950bcc74888a40ffa2593c5798f11fc9124c4',
  //   buyTokenUrl:
  //     'https://dex.apeswap.finance/#/swap?outputCurrency=0x947950bcc74888a40ffa2593c5798f11fc9124c4',
  //   createdAt: 1622138632,
  // },
  // {
  //   id: 'banana-dot-bnb',
  //   logo: 'degens/BANANA-DOT-BNB.svg',
  //   name: 'DOT-BNB LP',
  //   token: 'DOT-BNB LP',
  //   tokenDescription: 'ApeSwap',
  //   tokenAddress: '0x21CBb561c5d7D70e9E6Cc42136CB22F07552aEef',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooApeDOT-BNB',
  //   earnedTokenAddress: '0x036D059Dbf58CB624CfdFDaAbCa6D3f80c2f8b3C',
  //   earnContractAddress: '0x036D059Dbf58CB624CfdFDaAbCa6D3f80c2f8b3C',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'banana-dot-bnb',
  //   oraclePrice: 0,
  //   depositsPaused: false,
  //   status: 'active',
  //   platform: 'ApeSwap',
  //   assets: ['DOT', 'BNB'],
  //   addLiquidityUrl:
  //     'https://dex.apeswap.finance/#/add/ETH/0x7083609fce4d1d8dc0c979aab8c869ea2c873402',
  //   buyTokenUrl:
  //     'https://dex.apeswap.finance/#/swap?outputCurrency=0x7083609fce4d1d8dc0c979aab8c869ea2c873402',
  //   createdAt: 1622137330,
  // },
  // {
  //   id: 'banana-xrp-bnb',
  //   logo: 'degens/BANANA-XRP-BNB.svg',
  //   name: 'XRP-BNB LP',
  //   token: 'XRP-BNB LP',
  //   tokenDescription: 'ApeSwap',
  //   tokenAddress: '0x6f0f18f5fcC1466ec41c8106689e10BeFE68D1c0',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooApeXRP-BNB',
  //   earnedTokenAddress: '0xb8624845cfaC1B65Da120bD25d0C1a921f8B178d',
  //   earnContractAddress: '0xb8624845cfaC1B65Da120bD25d0C1a921f8B178d',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'banana-xrp-bnb',
  //   oraclePrice: 0,
  //   depositsPaused: false,
  //   status: 'active',
  //   platform: 'ApeSwap',
  //   assets: ['XRP', 'BNB'],
  //   addLiquidityUrl:
  //     'https://dex.apeswap.finance/#/add/ETH/0x1d2f0da169ceb9fc7b3144628db156f3f6c60dbe',
  //   buyTokenUrl:
  //     'https://dex.apeswap.finance/#/swap?outputCurrency=0x1d2f0da169ceb9fc7b3144628db156f3f6c60dbe',
  //   createdAt: 1622134868,
  // },
  // {
  //   id: 'banana-snx-bnb',
  //   logo: 'bnb-pairs/SNX-BNB.svg',
  //   name: 'SNX-BNB LP',
  //   token: 'SNX-BNB LP',
  //   tokenDescription: 'ApeSwap',
  //   tokenAddress: '0x8b1F1F28a8CcbaA8a8Bc1582921ECe97Ce99d9e1',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooApeSNX-BNB',
  //   earnedTokenAddress: '0x0e4FCD49Bd0A50D31f321509C8B2e7f21Fb87D26',
  //   earnContractAddress: '0x0e4FCD49Bd0A50D31f321509C8B2e7f21Fb87D26',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'banana-snx-bnb',
  //   oraclePrice: 0,
  //   depositsPaused: false,
  //   status: 'active',
  //   platform: 'ApeSwap',
  //   assets: ['SNX', 'BNB'],
  //   addLiquidityUrl:
  //     'https://dex.apeswap.finance/#/add/0x9Ac983826058b8a9C7Aa1C9171441191232E8404/0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c',
  //   buyTokenUrl:
  //     'https://dex.apeswap.finance/#/swap?outputCurrency=0x9Ac983826058b8a9C7Aa1C9171441191232E8404',
  //   createdAt: 1622126575,
  // },
  // {
  //   id: 'banana-blz-bnb-eol',
  //   logo: 'bnb-pairs/BLZ-BNB.svg',
  //   name: 'BLZ-BNB LP',
  //   token: 'BLZ-BNB LP',
  //   tokenDescription: 'ApeSwap',
  //   tokenAddress: '0x015f807d0186f7e62810D0C597A23CB19Ff57E4d',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooApeBLZ-BNB',
  //   earnedTokenAddress: '0x6081601e62F51d9e956aa704B506E4c202E0E016',
  //   earnContractAddress: '0x6081601e62F51d9e956aa704B506E4c202E0E016',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'banana-blz-bnb',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   retireReason: 'rewards',
  //   platform: 'ApeSwap',
  //   assets: ['BLZ', 'BNB'],
  //   addLiquidityUrl:
  //     'https://dex.apeswap.finance/#/add/0x935a544bf5816e3a7c13db2efe3009ffda0acda2/0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c',
  //   buyTokenUrl:
  //     'https://dex.apeswap.finance/#/swap?outputCurrency=0x935a544bf5816e3a7c13db2efe3009ffda0acda2',
  //   createdAt: 1622133953,
  // },
  // {
  //   id: 'banana-ltc-bnb',
  //   logo: 'degens/banana-ltc-bnb.svg',
  //   name: 'LTC-BNB LP',
  //   token: 'LTC-BNB LP',
  //   tokenDescription: 'ApeSwap',
  //   tokenAddress: '0x0F12362c017Fe5101c7bBa09390f1CB729f5B318',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooApeLTC-BNB',
  //   earnedTokenAddress: '0xB25e82640c062365B973E9eB55EEF77C47E81296',
  //   earnContractAddress: '0xB25e82640c062365B973E9eB55EEF77C47E81296',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'banana-ltc-bnb',
  //   oraclePrice: 0,
  //   depositsPaused: false,
  //   status: 'active',
  //   platform: 'ApeSwap',
  //   assets: ['LTC', 'BNB'],
  //   addLiquidityUrl:
  //     'https://dex.apeswap.finance/#/add/ETH/0x4338665cbb7b2485a8855a139b75d5e34ab0db94',
  //   buyTokenUrl:
  //     'https://dex.apeswap.finance/#/swap?outputCurrency=0x4338665cbb7b2485a8855a139b75d5e34ab0db94',
  //   createdAt: 1621941740,
  // },
  // {
  //   id: 'cakev2-cake-busd',
  //   name: 'CAKE-BUSD LP',
  //   token: 'CAKE-BUSD LP2',
  //   tokenDescription: 'PancakeSwap',
  //   tokenAddress: '0x804678fa97d91B974ec2af3c843270886528a9E6',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooCakeV2CAKE-BUSD',
  //   earnedTokenAddress: '0x1542885D6EeD4EE3AC1a110d3f159003a0774101',
  //   earnContractAddress: '0x1542885D6EeD4EE3AC1a110d3f159003a0774101',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'cakev2-cake-busd',
  //   oraclePrice: 0,
  //   depositsPaused: false,
  //   status: 'active',
  //   platform: 'PancakeSwap',
  //   assets: ['CAKE', 'BUSD'],
  //   addLiquidityUrl:
  //     'https://pancakeswap.finance/add/0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82/0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?inputCurrency=0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82&outputCurrency=0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
  //   createdAt: 1621860750,
  // },
  // {
  //   id: 'cakev2-chr-busd',
  //   logo: 'cake-pairs/chr-busd.svg',
  //   name: 'CHR-BUSD LP',
  //   token: 'CHR-BUSD LP2',
  //   tokenDescription: 'PancakeSwap',
  //   tokenAddress: '0x6045931E511EF7e53A4A817F971e0CA28c758809',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooCakeV2CHR-BUSD',
  //   earnedTokenAddress: '0x324888479Bfe3903cB405959Bc9Cd644000e495A',
  //   earnContractAddress: '0x324888479Bfe3903cB405959Bc9Cd644000e495A',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'cakev2-chr-busd',
  //   oraclePrice: 0,
  //   depositsPaused: false,
  //   status: 'active',
  //   platform: 'PancakeSwap',
  //   assets: ['CHR', 'BUSD'],
  //   addLiquidityUrl:
  //     'https://pancakeswap.finance/add/0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56/0xf9CeC8d50f6c8ad3Fb6dcCEC577e05aA32B224FE',
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?inputCurrency=0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56&outputCurrency=0xf9CeC8d50f6c8ad3Fb6dcCEC577e05aA32B224FE',
  //   createdAt: 1621996349,
  // },
  // {
  //   id: 'cakev2-deri-busd-eol',
  //   logo: 'cake-pairs/deri-busd.svg',
  //   name: 'DERI-BUSD LP',
  //   token: 'DERI-BUSD LP2',
  //   tokenDescription: 'PancakeSwap',
  //   tokenAddress: '0xDc7188AC11e124B1fA650b73BA88Bf615Ef15256',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooCakeV2DERI-BUSD',
  //   earnedTokenAddress: '0x6D02b2d38902689aF2c18eafb8B8c84161c6341b',
  //   earnContractAddress: '0x6D02b2d38902689aF2c18eafb8B8c84161c6341b',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'cakev2-deri-busd',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'PancakeSwap',
  //   assets: ['DERI', 'BUSD'],
  //   retireReason: 'rewards',
  //   addLiquidityUrl:
  //     'https://pancakeswap.finance/add/0xe60eaf5A997DFAe83739e035b005A33AfdCc6df5/0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?inputCurrency=0xe60eaf5A997DFAe83739e035b005A33AfdCc6df5&outputCurrency=0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
  //   createdAt: 1621991325,
  // },
  // {
  //   id: 'hps-hps',
  //   logo: 'single-assets/HPS.png',
  //   name: 'HPS',
  //   token: 'HPS',
  //   tokenDescription: 'BillionHappiness',
  //   tokenAddress: '0xeDa21B525Ac789EaB1a08ef2404dd8505FfB973D',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooBillionHPS',
  //   earnedTokenAddress: '0xAC8E64BE515fAe8c6026fb22954d4D1375938Bb5',
  //   earnContractAddress: '0xAC8E64BE515fAe8c6026fb22954d4D1375938Bb5',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'tokens',
  //   oracleId: 'HPS',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'Other',
  //   assets: ['HPS'],
  //   buyTokenUrl:
  //     'https://julswap.com/#/swap?inputCurrency=BNB&outputCurrency=0xeDa21B525Ac789EaB1a08ef2404dd8505FfB973D',
  //   createdAt: 1617016075,
  // },
  // {
  //   id: 'dopple-dolly-lp',
  //   logo: 'uncategorized/DOPPLE-DOLLY-LP.svg',
  //   name: 'DOLLY/BUSD/USDT',
  //   token: 'DOLLY/BUSD/USDT',
  //   tokenDescription: 'Dopple',
  //   tokenAddress: '0xAA5509Ce0ecEA324bff504A46Fc61EB75Cb68B0c',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooDoppleDollyLP',
  //   earnedTokenAddress: '0xCcb7A9E2e4F08407065f51d32C365304fbF2FD01',
  //   earnContractAddress: '0xCcb7A9E2e4F08407065f51d32C365304fbF2FD01',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'dopple-dolly-lp',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   assets: ['DOLLY', 'USDT', 'BUSD'],
  //   platform: 'Other',
  //   withdrawalFee: '0%',
  //   addLiquidityUrl: 'https://dopple.finance/Deposit/dolly-lps',
  //   createdAt: 1621851120,
  // },
  // {
  //   id: 'cakev2-xend-bnb-eol',
  //   logo: 'bnb-pairs/XEND-BNB.svg',
  //   name: 'XEND-BNB LP',
  //   token: 'XEND-BNB LP2',
  //   tokenDescription: 'PancakeSwap',
  //   tokenAddress: '0xcECfC2789af72ed151589a96a59f3A1ABC65c3b5',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooCakeV2XEND-BNB',
  //   earnedTokenAddress: '0x6F3ED82fbE292055D509D0200f02d8F9fE62E5D3',
  //   earnContractAddress: '0x6F3ED82fbE292055D509D0200f02d8F9fE62E5D3',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'cakev2-xend-bnb',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'PancakeSwap',
  //   assets: ['XEND', 'BNB'],
  //   retireReason: 'rewards',
  //   addLiquidityUrl:
  //     'https://pancakeswap.finance/add/BNB/0x4a080377f83D669D7bB83B3184a8A5E61B500608',
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?outputCurrency=0x4a080377f83D669D7bB83B3184a8A5E61B500608',
  //   createdAt: 1621868570,
  // },
  // {
  //   id: 'cakev2-cyc-bnb-eol',
  //   logo: 'bnb-pairs/CYC-BNB.svg',
  //   name: 'CYC-BNB LP',
  //   token: 'CYC-BNB LP2',
  //   tokenDescription: 'PancakeSwap',
  //   tokenAddress: '0xECf30FbecfA642012f54212a3bE92eEF1e48eDaC',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooCakeV2CYC-BNB',
  //   earnedTokenAddress: '0xe4e94eAD9D926443C02c11E6453D31f8810D5186',
  //   earnContractAddress: '0xe4e94eAD9D926443C02c11E6453D31f8810D5186',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'cakev2-cyc-bnb',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'PancakeSwap',
  //   assets: ['CYC', 'BNB'],
  //   retireReason: 'rewards',
  //   addLiquidityUrl:
  //     'https://pancakeswap.finance/add/BNB/0x810EE35443639348aDbbC467b33310d2AB43c168',
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?outputCurrency=0x810EE35443639348aDbbC467b33310d2AB43c168',
  //   createdAt: 1621868549,
  // },
  // {
  //   id: 'banana-near-bnb',
  //   logo: 'bnb-pairs/NEAR-BNB.svg',
  //   name: 'NEAR-BNB LP',
  //   token: 'NEAR-BNB ALP',
  //   tokenDescription: 'ApeSwap',
  //   tokenAddress: '0xB75724635A6cDa850f08b578F23a568CeDba099D',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooApeNEAR-BNB',
  //   earnedTokenAddress: '0x37E7A54830877Ec8c163aAD72A4aa7B0dA2Ad6cb',
  //   earnContractAddress: '0x37E7A54830877Ec8c163aAD72A4aa7B0dA2Ad6cb',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'banana-near-bnb',
  //   oraclePrice: 0,
  //   depositsPaused: false,
  //   status: 'active',
  //   platform: 'ApeSwap',
  //   assets: ['NEAR', 'BNB'],
  //   addLiquidityUrl:
  //     'https://dex.apeswap.finance/#/add/0x1fa4a73a3f0133f0025378af00236f3abdee5d63/0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c',
  //   buyTokenUrl:
  //     'https://dex.apeswap.finance/#/swap?outputCurrency=0x1fa4a73a3f0133f0025378af00236f3abdee5d63',
  //   createdAt: 1621868213,
  // },
  // {
  //   id: 'banana-coti-bnb',
  //   logo: 'bnb-pairs/COTI-BNB.svg',
  //   name: 'COTI-BNB LP',
  //   token: 'COTI-BNB ALP',
  //   tokenDescription: 'ApeSwap',
  //   tokenAddress: '0xaCfDCF0486ADC2421aAc3FFc0923b9c56FAEBC47',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooApeCOTI-BNB',
  //   earnedTokenAddress: '0x0dd3afc66F1250eC402E703CA093EE17537478CB',
  //   earnContractAddress: '0x0dd3afc66F1250eC402E703CA093EE17537478CB',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'banana-coti-bnb',
  //   oraclePrice: 0,
  //   depositsPaused: false,
  //   status: 'active',
  //   platform: 'ApeSwap',
  //   assets: ['COTI', 'BNB'],
  //   addLiquidityUrl:
  //     'https://dex.apeswap.finance/#/add/0xAdBAF88B39D37Dc68775eD1541F1bf83A5A45feB/0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c',
  //   buyTokenUrl:
  //     'https://dex.apeswap.finance/#/swap?outputCurrency=0xAdBAF88B39D37Dc68775eD1541F1bf83A5A45feB',
  //   createdAt: 1621868192,
  // },
  // {
  //   id: 'banana-etc-bnb-eol',
  //   logo: 'bnb-pairs/ETC-BNB.svg',
  //   name: 'ETC-BNB LP',
  //   token: 'ETC-BNB ALP',
  //   tokenDescription: 'ApeSwap',
  //   tokenAddress: '0xDd6C7A955C72B3FFD546d8dadBf7669528d8F785',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooApeETC-BNB',
  //   earnedTokenAddress: '0x3D64f2950412a7eed6B59704D18B07B3C22411F5',
  //   earnContractAddress: '0x3D64f2950412a7eed6B59704D18B07B3C22411F5',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'banana-etc-bnb',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   retireReason: 'rewards',
  //   platform: 'ApeSwap',
  //   assets: ['ETC', 'BNB'],
  //   addLiquidityUrl:
  //     'https://dex.apeswap.finance/#/add/0x3d6545b08693daE087E957cb1180ee38B9e3c25E/0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c',
  //   buyTokenUrl:
  //     'https://dex.apeswap.finance/#/swap?outputCurrency=0x3d6545b08693daE087E957cb1180ee38B9e3c25E',
  //   createdAt: 1621868168,
  // },
  // {
  //   id: 'banana-zec-bnb',
  //   logo: 'bnb-pairs/ZEC-BNB.svg',
  //   name: 'ZEC-BNB LP',
  //   token: 'ZEC-BNB ALP',
  //   tokenDescription: 'ApeSwap',
  //   tokenAddress: '0x2B2C771e44aF4C6f858598308e05FB89b23f11cE',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooApeZEC-BNB',
  //   earnedTokenAddress: '0xCe3870506C57bd6A891aDc8d35D51557A5B55769',
  //   earnContractAddress: '0xCe3870506C57bd6A891aDc8d35D51557A5B55769',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'banana-zec-bnb',
  //   oraclePrice: 0,
  //   depositsPaused: false,
  //   status: 'active',
  //   platform: 'ApeSwap',
  //   assets: ['ZEC', 'BNB'],
  //   addLiquidityUrl:
  //     'https://dex.apeswap.finance/#/add/0x1Ba42e5193dfA8B03D15dd1B86a3113bbBEF8Eeb/0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c',
  //   buyTokenUrl:
  //     'https://dex.apeswap.finance/#/swap?outputCurrency=0x1Ba42e5193dfA8B03D15dd1B86a3113bbBEF8Eeb',
  //   createdAt: 1621868153,
  // },
  // {
  //   id: 'garuda-garuda-usdc',
  //   logo: 'degens/garuda-usdc.png',
  //   name: 'GARUDA-USDC LP',
  //   token: 'GARUDA-USDC LP',
  //   tokenDescription: 'PancakeSwap (Garuda)',
  //   tokenAddress: '0xeD90DEEc60a9b03844aDE0e4b086BAdA0aCC5B8D',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooGarudaGARUDA-USDC',
  //   earnedTokenAddress: '0xE783464c205Ddf7240D5A86C62AD31DdfEdFCfde',
  //   earnContractAddress: '0xE783464c205Ddf7240D5A86C62AD31DdfEdFCfde',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'garuda-garuda-usdc',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'Other',
  //   assets: ['GARUDA', 'USDC'],
  //   addLiquidityUrl:
  //     'https://pancakeswap.finance/add/0x854086dC841e1bfae50Cb615bF41f55BF432a90b/0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d',
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?outputCurrency=0x854086dC841e1bfae50Cb615bF41f55BF432a90b',
  //   createdAt: 1621839042,
  // },
  // {
  //   id: 'jetswap-wings-bnb',
  //   logo: 'degens/WINGS-BNB.svg',
  //   name: 'WINGS-BNB LP',
  //   token: 'WINGS-BNB LP',
  //   tokenDescription: 'JetSwap',
  //   tokenAddress: '0x1CD0fe829d83Fb49c8831Ae860d19c6062adA6e9',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooJetswapWINGS-BNB',
  //   earnedTokenAddress: '0x2dEF6c368248d665082Db40a7Dc9F772CAD1DE75',
  //   earnContractAddress: '0x2dEF6c368248d665082Db40a7Dc9F772CAD1DE75',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'jetswap-wings-bnb',
  //   oraclePrice: 0,
  //   depositsPaused: false,
  //   status: 'active',
  //   platform: 'JetSwap',
  //   assets: ['WINGS', 'BNB'],
  //   addLiquidityUrl:
  //     'https://exchange.jetswap.finance/#/add/BNB/0x0487b824c8261462f88940f97053e65bdb498446',
  //   buyTokenUrl:
  //     'https://exchange.jetswap.finance/#/swap?outputCurrency=0x0487b824c8261462f88940f97053e65bdb498446',
  //   createdAt: 1621578983,
  // },
  // {
  //   id: 'jetswap-wings-busd-eol',
  //   logo: 'degens/WINGS-BUSD.svg',
  //   name: 'WINGS-BUSD LP',
  //   token: 'WINGS-BUSD LP',
  //   tokenDescription: 'JetSwap',
  //   tokenAddress: '0xFBa740304f3fc39d0e79703a5D7788E13f877DC0',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooJetswapWINGS-BUSD',
  //   earnedTokenAddress: '0xE16f790B414C235Ada8cBC419C5C1Ae61ff49A84',
  //   earnContractAddress: '0xE16f790B414C235Ada8cBC419C5C1Ae61ff49A84',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'jetswap-wings-busd',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'JetSwap',
  //   assets: ['WINGS', 'BUSD'],
  //   addLiquidityUrl:
  //     'https://exchange.jetswap.finance/#/add/0xe9e7cea3dedca5984780bafc599bd69add087d56/0x0487b824c8261462f88940f97053e65bdb498446',
  //   buyTokenUrl:
  //     'https://exchange.jetswap.finance/#/swap?outputCurrency=0x0487b824c8261462f88940f97053e65bdb498446',
  //   createdAt: 1621582618,
  //   retiredReason: 'tvl',
  // },
  // {
  //   id: 'jetswap-busd-bnb',
  //   logo: 'degens/WINGS-BNB-BUSD.svg',
  //   name: 'BUSD-BNB LP',
  //   token: 'BUSD-BNB JLP',
  //   tokenDescription: 'JetSwap',
  //   tokenAddress: '0xc53eE6446101F2128f887b8c75083E74dca3e973',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooJetswapBUSD-BNB',
  //   earnedTokenAddress: '0xDb207D797cA68112f5AA2677ee01301cF91c3c6E',
  //   earnContractAddress: '0xDb207D797cA68112f5AA2677ee01301cF91c3c6E',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'jetswap-busd-bnb',
  //   oraclePrice: 0,
  //   depositsPaused: false,
  //   status: 'active',
  //   platform: 'JetSwap',
  //   assets: ['BUSD', 'BNB'],
  //   addLiquidityUrl:
  //     'https://exchange.jetswap.finance/#/add/BNB/0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
  //   createdAt: 1621583380,
  // },
  // {
  //   id: 'grand-grand-eol',
  //   logo: 'degens/grand.png',
  //   name: 'GRAND',
  //   token: 'GRAND',
  //   tokenDescription: 'Grand',
  //   tokenAddress: '0xeE814F5B2bF700D2e843Dc56835D28d095161dd9',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooGrandGRAND',
  //   earnedTokenAddress: '0x547c889C676fe449018A3987866cFC674518b700',
  //   earnContractAddress: '0x547c889C676fe449018A3987866cFC674518b700',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'tokens',
  //   oracleId: 'GRAND',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'Other',
  //   assets: ['GRAND'],
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?outputCurrency=0xeE814F5B2bF700D2e843Dc56835D28d095161dd9',
  //   createdAt: 1621599740,
  // },
  // {
  //   id: 'grand-grand-bnb-eol',
  //   logo: 'degens/grand-bnb.png',
  //   name: 'GRAND-BNB LP',
  //   token: 'GRAND-BNB LP',
  //   tokenDescription: 'PancakeSwap (Grand)',
  //   tokenAddress: '0x7BA02AFCB8112648D27772f0d73c759C6963a666',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooGrandGRAND-BNB',
  //   earnedTokenAddress: '0x40dA18484963Fb672c3D2FE4942ddF363265b410',
  //   earnContractAddress: '0x40dA18484963Fb672c3D2FE4942ddF363265b410',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'grand-grand-bnb',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'Other',
  //   assets: ['GRAND', 'BNB'],
  //   addLiquidityUrl:
  //     'https://pancakeswap.finance/add/BNB/0xeE814F5B2bF700D2e843Dc56835D28d095161dd9',
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?outputCurrency=0xeE814F5B2bF700D2e843Dc56835D28d095161dd9',
  //   createdAt: 1621596545,
  // },
  // {
  //   id: 'dumpling-sdump-bnb-eol',
  //   logo: 'degens/sdump-bnb.png',
  //   name: 'SDUMP-BNB LP',
  //   token: 'SDUMP-BNB LP',
  //   tokenDescription: 'PancakeSwap (Dumpling)',
  //   tokenAddress: '0x8B3C67ca3dA1B389A1252AF79F284d2FaAD07358',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooDumplingSDUMP-BNB',
  //   earnedTokenAddress: '0x0396F46444D9256753E36408FC89D829d1f12A09',
  //   earnContractAddress: '0x0396F46444D9256753E36408FC89D829d1f12A09',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'dumpling-sdump-bnb',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'Other',
  //   assets: ['SDUMP', 'BNB'],
  //   addLiquidityUrl:
  //     'https://pancakeswap.finance/add/BNB/0x13F6751ba11337BC67aBBdAd638a56194ee133B8',
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?outputCurrency=0x13F6751ba11337BC67aBBdAd638a56194ee133B8',
  //   createdAt: 1621586017,
  // },
  // {
  //   id: 'dumpling-sdump-busd-eol',
  //   logo: 'degens/sdump-bnb.png',
  //   name: 'SDUMP-BUSD LP',
  //   token: 'SDUMP-BUSD LP',
  //   tokenDescription: 'PancakeSwap (Dumpling)',
  //   tokenAddress: '0x8B19Bb58DbdA8B5303aBc96Bb418E9bF4100F34b',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooDumplingSDUMP-BUSD',
  //   earnedTokenAddress: '0xAF142b8678917C57703604fD4A4de2600f9e7551',
  //   earnContractAddress: '0xAF142b8678917C57703604fD4A4de2600f9e7551',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'dumpling-sdump-busd',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'Other',
  //   assets: ['SDUMP', 'BUSD'],
  //   addLiquidityUrl:
  //     'https://pancakeswap.finance/add/0xe9e7cea3dedca5984780bafc599bd69add087d56/0x13F6751ba11337BC67aBBdAd638a56194ee133B8',
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?outputCurrency=0x13F6751ba11337BC67aBBdAd638a56194ee133B8',
  //   createdAt: 1621586353,
  // },
  // {
  //   id: 'satis-xsat-busd-eol',
  //   logo: 'degens/SAT-BUSD.png',
  //   name: 'xSAT-BUSD LP',
  //   token: 'xSAT-BUSD LP',
  //   tokenDescription: 'PancakeSwap (Satis)',
  //   tokenAddress: '0x3357387B7341c341Ffac18ada7cF2C242BC82b84',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooSatisXSAT-BUSD',
  //   earnedTokenAddress: '0x538c84e85e71F775Df1A896927008ecE3e14f47e',
  //   earnContractAddress: '0x538c84e85e71F775Df1A896927008ecE3e14f47e',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'satis-xsat-busd',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'Other',
  //   assets: ['SAT', 'BUSD'],
  //   addLiquidityUrl:
  //     'https://pancakeswap.finance/add/0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56/0x8fda94079913CB921D065Ed9c004Afb43e1f900e',
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?outputCurrency=0x8fda94079913CB921D065Ed9c004Afb43e1f900e',
  //   createdAt: 1621510653,
  // },
  // {
  //   id: 'satis-xsat-bnb-eol',
  //   logo: 'degens/SAT-BNB.png',
  //   name: 'xSAT-BNB LP',
  //   token: 'xSAT-BNB LP',
  //   tokenDescription: 'PancakeSwap (Satis)',
  //   tokenAddress: '0x15a24f7850bCe4818617c1efA2D24132E65809dc',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooSatisXSAT-BNB',
  //   earnedTokenAddress: '0x0F2A749bD7C64BC7E4f9cAE7C54eAEFD72E27193',
  //   earnContractAddress: '0x0F2A749bD7C64BC7E4f9cAE7C54eAEFD72E27193',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'satis-xsat-bnb',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'Other',
  //   assets: ['SAT', 'BNB'],
  //   addLiquidityUrl:
  //     'https://pancakeswap.finance/add/BNB/0x8fda94079913CB921D065Ed9c004Afb43e1f900e',
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?outputCurrency=0x8fda94079913CB921D065Ed9c004Afb43e1f900e',
  //   createdAt: 1621511403,
  // },
  // {
  //   id: 'iron-dnd',
  //   logo: 'degens/DND.png',
  //   name: 'DND',
  //   token: 'DND',
  //   tokenDescription: 'Iron Finance',
  //   tokenAddress: '0x34EA3F7162E6f6Ed16bD171267eC180fD5c848da',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooIronDND',
  //   earnedTokenAddress: '0xc2B68b16ABA7444D07258CACA55cD3fD3a3C5268',
  //   earnContractAddress: '0xc2B68b16ABA7444D07258CACA55cD3fD3a3C5268',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'tokens',
  //   oracleId: 'DND',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'Other',
  //   assets: ['DND'],
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?outputCurrency=0x34EA3F7162E6f6Ed16bD171267eC180fD5c848da',
  //   createdAt: 1621422974,
  // },
  // {
  //   id: 'iron-dnd-bnb',
  //   logo: 'degens/DND.png',
  //   name: 'DND-BNB LP',
  //   token: 'DND-BNB LP2',
  //   tokenDescription: 'PancakeSwap (Iron)',
  //   tokenAddress: '0xAcC75DaDB2Df216528F6Cb5E545e1284Ca258B1C',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooIronDND-BNB',
  //   earnedTokenAddress: '0x0a4f69E053EE16fA127De5EA5d23EAd075e429D3',
  //   earnContractAddress: '0x0a4f69E053EE16fA127De5EA5d23EAd075e429D3',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'iron-dnd-bnb',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'Other',
  //   assets: ['DND', 'BNB'],
  //   addLiquidityUrl:
  //     'https://pancakeswap.finance/add/BNB/0x34EA3F7162E6f6Ed16bD171267eC180fD5c848da',
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?outputCurrency=0x34EA3F7162E6f6Ed16bD171267eC180fD5c848da',
  //   createdAt: 1621421804,
  // },
  // {
  //   id: 'cakev2-hotcross-bnb',
  //   logo: 'cake-pairs/hotcross-bnb.svg',
  //   name: 'HOTCROSS-BNB LP',
  //   token: 'HOTCROSS-BNB LP2',
  //   tokenDescription: 'PancakeSwap',
  //   tokenAddress: '0xF23BAD605E94dE0e3B60c9718a43A94A5aF43915',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooCakeV2HOTCROSS-BNB',
  //   earnedTokenAddress: '0xfA4f79Cf4Eb504eD42fd77a931057815c1E41EC0',
  //   earnContractAddress: '0xfA4f79Cf4Eb504eD42fd77a931057815c1E41EC0',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'cakev2-hotcross-bnb',
  //   oraclePrice: 0,
  //   depositsPaused: false,
  //   status: 'active',
  //   platform: 'PancakeSwap',
  //   assets: ['HOTCROSS', 'BNB'],
  //   addLiquidityUrl:
  //     'https://pancakeswap.finance/add/BNB/0x4FA7163E153419E0E1064e418dd7A99314Ed27b6',
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?outputCurrency=0x4FA7163E153419E0E1064e418dd7A99314Ed27b6',
  //   createdAt: 1621554511,
  // },
  // {
  //   id: 'cakev2-rfox-bnb-eol',
  //   logo: 'cake-pairs/rfox-bnb.svg',
  //   name: 'RFOX-BNB LP',
  //   token: 'RFOX-BNB LP2',
  //   tokenDescription: 'PancakeSwap',
  //   tokenAddress: '0x8E04b3972b5C25766c681dFD30a8A1cBf6dcc8c1',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooCakeV2RFOX-BNB',
  //   earnedTokenAddress: '0x070Dcc132461571676eF5e3F64b0D730136C0898',
  //   earnContractAddress: '0x070Dcc132461571676eF5e3F64b0D730136C0898',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'cakev2-rfox-bnb',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'PancakeSwap',
  //   assets: ['RFOX', 'BNB'],
  //   withdrawalFee: '0%',
  //   retireReason: 'rewards',
  //   removeLiquidityUrl:
  //     'https://pancakeswap.finance/remove/BNB/0x0a3a21356793b49154fd3bbe91cbc2a16c0457f5',
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?outputCurrency=0x0a3a21356793b49154fd3bbe91cbc2a16c0457f5',
  //   createdAt: 1621562741,
  // },
  // {
  //   id: 'cakev2-wmass-busd-eol',
  //   logo: 'busd-pairs/WMASS-BUSD.svg',
  //   name: 'WMASS-BUSD LP',
  //   token: 'WMASS-BUSD LP2',
  //   tokenDescription: 'PancakeSwap',
  //   tokenAddress: '0xeC95ff6281c3aD8E27372fA6675EB337640b8E5E',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooCakeV2WMASS-BUSD',
  //   earnedTokenAddress: '0x84D4B046c060730b0A2fd65703F20c4A61EaAff7',
  //   earnContractAddress: '0x84D4B046c060730b0A2fd65703F20c4A61EaAff7',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'cakev2-wmass-busd',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'PancakeSwap',
  //   assets: ['WMASS', 'BUSD'],
  //   retireReason: 'rewards',
  //   addLiquidityUrl:
  //     'https://pancakeswap.finance/add/0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56/0x7e396bfc8a2f84748701167c2d622f041a1d7a17',
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?outputCurrency=0x7e396bfc8a2f84748701167c2d622f041a1d7a17',
  //   createdAt: 1621416130,
  // },
  // {
  //   id: 'cakev2-ubxt-busd-eol',
  //   logo: 'busd-pairs/UBXT-BUSD.svg',
  //   name: 'UBXT-BUSD LP',
  //   token: 'UBXT-BUSD LP2',
  //   tokenDescription: 'PancakeSwap',
  //   tokenAddress: '0x8D3FF27D2aD6a9556B7C4F82F4d602D20114bC90',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooCakeV2UBXT-BUSD',
  //   earnedTokenAddress: '0x173BCBDd7E5f8AadC1e9dEC2f030c9567230b253',
  //   earnContractAddress: '0x173BCBDd7E5f8AadC1e9dEC2f030c9567230b253',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'cakev2-ubxt-busd',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'PancakeSwap',
  //   assets: ['UBXT', 'BUSD'],
  //   retireReason: 'rewards',
  //   addLiquidityUrl:
  //     'https://pancakeswap.finance/add/0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56/0xbbeb90cfb6fafa1f69aa130b7341089abeef5811',
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?outputCurrency=0xbbeb90cfb6fafa1f69aa130b7341089abeef5811',
  //   createdAt: 1621416163,
  // },
  // {
  //   id: 'cakev2-btr-busd-eol',
  //   logo: 'busd-pairs/BTR-BUSD.svg',
  //   name: 'BTR-BUSD LP',
  //   token: 'BTR-BUSD LP2',
  //   tokenDescription: 'PancakeSwap',
  //   tokenAddress: '0xBC7AC609Fa730239190A70952e64eE1DFC2530AC',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooCakeV2BTR-BUSD',
  //   earnedTokenAddress: '0x6051785dBEA79BDF2e0B278a5cb4741Fe1a2Fd12',
  //   earnContractAddress: '0x6051785dBEA79BDF2e0B278a5cb4741Fe1a2Fd12',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'cakev2-btr-busd',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   retireReason: 'rewards',
  //   platform: 'PancakeSwap',
  //   assets: ['BTR', 'BUSD'],
  //   addLiquidityUrl:
  //     'https://pancakeswap.finance/add/0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56/0x5a16e8ce8ca316407c6e6307095dc9540a8d62b3',
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?outputCurrency=0x5a16e8ce8ca316407c6e6307095dc9540a8d62b3',
  //   createdAt: 1621416199,
  // },
  // {
  //   id: 'cakev2-pmon-busd-eol',
  //   logo: 'busd-pairs/PMON-BUSD.svg',
  //   name: 'PMON-BUSD LP',
  //   token: 'PMON-BUSD LP2',
  //   tokenDescription: 'PancakeSwap',
  //   tokenAddress: '0xcdb0016d97FD0E7EC2C3B78aA4786Cbd8e19C14C',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooCakeV2PMON-BUSD',
  //   earnedTokenAddress: '0x82eaf01Bc5947d23Ca140221F2cd3f27250DF5Ac',
  //   earnContractAddress: '0x82eaf01Bc5947d23Ca140221F2cd3f27250DF5Ac',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'cakev2-pmon-busd',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   retireReason: 'rewards',
  //   platform: 'PancakeSwap',
  //   assets: ['PMON', 'BUSD'],
  //   addLiquidityUrl:
  //     'https://pancakeswap.finance/add/0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56/0x1796ae0b0fa4862485106a0de9b654efe301d0b2',
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?outputCurrency=0x1796ae0b0fa4862485106a0de9b654efe301d0b2',
  //   createdAt: 1621416238,
  // },
  // {
  //   id: 'cakev2-one-busd-eol',
  //   logo: 'busd-pairs/ONE-BUSD.svg',
  //   name: 'ONE-BUSD LP',
  //   token: 'ONE-BUSD LP2',
  //   tokenDescription: 'PancakeSwap',
  //   tokenAddress: '0x4d057F769D930eaFD597B49d6Fb2e1009A73a702',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooCakeV2ONE-BUSD',
  //   earnedTokenAddress: '0x94A901E76140e27dD72Ba9a22988419e95324826',
  //   earnContractAddress: '0x94A901E76140e27dD72Ba9a22988419e95324826',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'cakev2-one-busd',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'PancakeSwap',
  //   assets: ['BigONE', 'BUSD'],
  //   retireReason: 'rewards',
  //   addLiquidityUrl:
  //     'https://pancakeswap.finance/add/0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56/0x04baf95fd4c52fd09a56d840baee0ab8d7357bf0',
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?outputCurrency=0x04baf95fd4c52fd09a56d840baee0ab8d7357bf0',
  //   createdAt: 1621416271,
  // },
  // {
  //   id: 'cakev2-oin-busd-eol',
  //   logo: 'busd-pairs/OIN-BUSD.svg',
  //   name: 'OIN-BUSD LP',
  //   token: 'OIN-BUSD LP2',
  //   tokenDescription: 'PancakeSwap',
  //   tokenAddress: '0x6a00E41561ac36A78dbA1D09091b0F00C4E53724',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooCakeV2OIN-BUSD',
  //   earnedTokenAddress: '0x50bFd46216afcCA24890Ebd6eC67a0Cb92ce9aED',
  //   earnContractAddress: '0x50bFd46216afcCA24890Ebd6eC67a0Cb92ce9aED',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'cakev2-oin-busd',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'PancakeSwap',
  //   assets: ['OIN', 'BUSD'],
  //   retireReason: 'rewards',
  //   addLiquidityUrl:
  //     'https://pancakeswap.finance/add/0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56/0x658e64ffcf40d240a43d52ca9342140316ae44fa',
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?outputCurrency=0x658e64ffcf40d240a43d52ca9342140316ae44fa',
  //   createdAt: 1621416307,
  // },
  // {
  //   id: 'cakev2-tdoge-bnb-eol',
  //   logo: 'bnb-pairs/TDOGE-BNB.svg',
  //   name: 'tDOGE-BNB LP',
  //   token: 'tDOGE-BNB LP2',
  //   tokenDescription: 'PancakeSwap',
  //   tokenAddress: '0x2030845Ce7d4224523fd2F03Ca20Afe4aAD1D890',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooCakeV2TDOGE-BNB',
  //   earnedTokenAddress: '0x6AF821Aaf981D54aD37d58bFbA7fD2E2c41b623d',
  //   earnContractAddress: '0x6AF821Aaf981D54aD37d58bFbA7fD2E2c41b623d',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'cakev2-tdoge-bnb',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'PancakeSwap',
  //   assets: ['tDOGE', 'BNB'],
  //   addLiquidityUrl:
  //     'https://pancakeswap.finance/add/BNB/0xe550a593d09fbc8dcd557b5c88cea6946a8b404a',
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?outputCurrency=0xe550a593d09fbc8dcd557b5c88cea6946a8b404a',
  //   createdAt: 1621416442,
  // },
  // {
  //   id: 'cakev2-fine-bnb-eol',
  //   logo: 'bnb-pairs/FINE-BNB.svg',
  //   name: 'FINE-BNB LP',
  //   token: 'FINE-BNB LP2',
  //   tokenDescription: 'PancakeSwap',
  //   tokenAddress: '0xC309a6d2F1537922E06f15aA2eb21CaA1b2eEDb6',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooCakeV2FINE-BNB',
  //   earnedTokenAddress: '0xDCbf0c895d641FB67FB993456b6E99E478c7B087',
  //   earnContractAddress: '0xDCbf0c895d641FB67FB993456b6E99E478c7B087',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'cakev2-fine-bnb',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'PancakeSwap',
  //   assets: ['FINE', 'BNB'],
  //   withdrawalFee: '0%',
  //   retireReason: 'rewards',
  //   removeLiquidityUrl:
  //     'https://pancakeswap.finance/remove/BNB/0x4e6415a5727ea08aae4580057187923aec331227',
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?outputCurrency=0x4e6415a5727ea08aae4580057187923aec331227',
  //   createdAt: 1621416478,
  // },
  // {
  //   id: 'cakev2-doge-bnb',
  //   logo: 'bnb-pairs/DOGE-BNB-PCS.svg',
  //   name: 'DOGE-BNB LP',
  //   token: 'DOGE-BNB LP2',
  //   tokenDescription: 'PancakeSwap',
  //   tokenAddress: '0xac109C8025F272414fd9e2faA805a583708A017f',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooCakeV2DOGE-BNB',
  //   earnedTokenAddress: '0x3b3bc8AE6dcAcCeaaC3C19E196ebD3341Cfe9c4e',
  //   earnContractAddress: '0x3b3bc8AE6dcAcCeaaC3C19E196ebD3341Cfe9c4e',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'cakev2-doge-bnb',
  //   oraclePrice: 0,
  //   depositsPaused: false,
  //   status: 'active',
  //   platform: 'PancakeSwap',
  //   assets: ['DOGE', 'BNB'],
  //   risks: [
  //     'COMPLEXITY_LOW',
  //     'BATTLE_TESTED',
  //     'IL_HIGH',
  //     'MCAP_LARGE',
  //     'AUDIT',
  //     'CONTRACTS_VERIFIED',
  //   ],
  //   stratType: 'StratLP',
  //   addLiquidityUrl:
  //     'https://pancakeswap.finance/add/BNB/0xba2ae424d960c26247dd6c32edc70b295c744c43',
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?outputCurrency=0xba2ae424d960c26247dd6c32edc70b295c744c43',
  //   createdAt: 1621416514,
  // },
  // {
  //   id: 'cakev2-hyfi-bnb-eol',
  //   logo: 'bnb-pairs/HYFI-BNB.svg',
  //   name: 'HYFI-BNB LP',
  //   token: 'HYFI-BNB LP2',
  //   tokenDescription: 'PancakeSwap',
  //   tokenAddress: '0x0716725d78081A9E0E1Ff81516f5415b399e274D',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooCakeV2HYFI-BNB',
  //   earnedTokenAddress: '0xfe9bB1AFF4D1A2928F0aFEcaBF7d96479ece7eF4',
  //   earnContractAddress: '0xfe9bB1AFF4D1A2928F0aFEcaBF7d96479ece7eF4',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'cakev2-hyfi-bnb',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'PancakeSwap',
  //   assets: ['HYFI', 'BNB'],
  //   retireReason: 'rewards',
  //   addLiquidityUrl:
  //     'https://pancakeswap.finance/add/BNB/0x9a319b959e33369C5eaA494a770117eE3e585318',
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?outputCurrency=0x9a319b959e33369C5eaA494a770117eE3e585318',
  //   createdAt: 1621416553,
  // },
  // {
  //   id: 'banana-avax-bnb',
  //   logo: 'bnb-pairs/AVAX-BNB.svg',
  //   name: 'AVAX-BNB LP',
  //   token: 'AVAX-BNB ALP',
  //   tokenDescription: 'ApeSwap',
  //   tokenAddress: '0x40aFc7CBd0Dc2bE5860F0035b717d20Afb4827b2',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooApeAVAX-BNB',
  //   earnedTokenAddress: '0x97697d89e490196eE7dbF6660424B80D276ae7d9',
  //   earnContractAddress: '0x97697d89e490196eE7dbF6660424B80D276ae7d9',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'banana-avax-bnb',
  //   oraclePrice: 0,
  //   depositsPaused: false,
  //   status: 'active',
  //   platform: 'ApeSwap',
  //   assets: ['AVAX', 'BNB'],
  //   addLiquidityUrl:
  //     'https://dex.apeswap.finance/#/add/ETH/0x1ce0c2827e2ef14d5c4f29a091d735a204794041',
  //   buyTokenUrl:
  //     'https://dex.apeswap.finance/#/swap?outputCurrency=0x1ce0c2827e2ef14d5c4f29a091d735a204794041',
  //   createdAt: 1621357766,
  // },
  // {
  //   id: 'garuda-garuda-usdt',
  //   logo: 'degens/garuda-usdt.png',
  //   name: 'GARUDA-USDT LP',
  //   token: 'GARUDA-USDT LP',
  //   tokenDescription: 'PancakeSwap (Garuda)',
  //   tokenAddress: '0x7F6369476756B06D2b70BD77917fbc35A7801EFD',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooGarudaGARUDA-USDT',
  //   earnedTokenAddress: '0x1e8Cf92A803781b4ecB9102031fc5506A476E2A4',
  //   earnContractAddress: '0x1e8Cf92A803781b4ecB9102031fc5506A476E2A4',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'garuda-garuda-usdt',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'Other',
  //   assets: ['GARUDA', 'USDT'],
  //   addLiquidityUrl:
  //     'https://pancakeswap.finance/add/0x854086dC841e1bfae50Cb615bF41f55BF432a90b/0x55d398326f99059fF775485246999027B3197955',
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?outputCurrency=0x854086dC841e1bfae50Cb615bF41f55BF432a90b',
  //   createdAt: 1621163837,
  // },
  // {
  //   id: 'banana-ftm-bnb',
  //   logo: 'bnb-pairs/FTM-BNB.svg',
  //   name: 'FTM-BNB LP',
  //   token: 'FTM-BNB ALP',
  //   tokenDescription: 'ApeSwap',
  //   tokenAddress: '0x47A0B7bA18Bb80E4888ca2576c2d34BE290772a6',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooApeFTM-BNB',
  //   earnedTokenAddress: '0xb61fa9e4548f0D3E1405cfbA7a12de8fF11C0E4B',
  //   earnContractAddress: '0xb61fa9e4548f0D3E1405cfbA7a12de8fF11C0E4B',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'banana-ftm-bnb',
  //   oraclePrice: 0,
  //   depositsPaused: false,
  //   status: 'active',
  //   platform: 'ApeSwap',
  //   assets: ['FTM', 'BNB'],
  //   addLiquidityUrl:
  //     'https://dex.apeswap.finance/#/add/ETH/0xad29abb318791d579433d831ed122afeaf29dcfe',
  //   buyTokenUrl:
  //     'https://dex.apeswap.finance/#/swap?outputCurrency=0xad29abb318791d579433d831ed122afeaf29dcfe',
  //   createdAt: 1621084510,
  // },
  // {
  //   id: 'banana-comp-bnb',
  //   logo: 'bnb-pairs/COMP-BNB.svg',
  //   name: 'COMP-BNB LP',
  //   token: 'COMP-BNB ALP',
  //   tokenDescription: 'ApeSwap',
  //   tokenAddress: '0xb4c0c621B2eDfE6C22585ebAC56b0e634907B8A7',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooApeCOMP-BNB',
  //   earnedTokenAddress: '0x117298872069E8e3BcB5E02727852f9Ca94930Cb',
  //   earnContractAddress: '0x117298872069E8e3BcB5E02727852f9Ca94930Cb',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'banana-comp-bnb',
  //   oraclePrice: 0,
  //   depositsPaused: false,
  //   status: 'active',
  //   platform: 'ApeSwap',
  //   assets: ['COMP', 'BNB'],
  //   addLiquidityUrl:
  //     'https://dex.apeswap.finance/#/add/ETH/0x52ce071bd9b1c4b00a0b92d298c512478cad67e8',
  //   buyTokenUrl:
  //     'https://dex.apeswap.finance/#/swap?outputCurrency=0x52ce071bd9b1c4b00a0b92d298c512478cad67e8',
  //   createdAt: 1621075587,
  // },
  // {
  //   id: 'banana-aave-bnb',
  //   logo: 'bnb-pairs/AAVE-BNB.svg',
  //   name: 'AAVE-BNB LP',
  //   token: 'AAVE-BNB ALP',
  //   tokenDescription: 'ApeSwap',
  //   tokenAddress: '0xf13e007e181A8F57eD3a4D4CcE0A9ff9E7241CEf',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooApeAAVE-BNB',
  //   earnedTokenAddress: '0x2EF1B47174d9f9bC6e9a37E863928C0E6100324d',
  //   earnContractAddress: '0x2EF1B47174d9f9bC6e9a37E863928C0E6100324d',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'banana-aave-bnb',
  //   oraclePrice: 0,
  //   depositsPaused: false,
  //   status: 'active',
  //   platform: 'ApeSwap',
  //   assets: ['AAVE', 'BNB'],
  //   addLiquidityUrl:
  //     'https://dex.apeswap.finance/#/add/ETH/0xfb6115445bff7b52feb98650c87f44907e58f802',
  //   buyTokenUrl:
  //     'https://dex.apeswap.finance/#/swap?outputCurrency=0xfb6115445bff7b52feb98650c87f44907e58f802',
  //   createdAt: 1621073733,
  // },
  // {
  //   id: 'banana-link-bnb',
  //   logo: 'degens/LINK-BNB.svg',
  //   name: 'LINK-BNB LP',
  //   token: 'LINK-BNB ALP',
  //   tokenDescription: 'ApeSwap',
  //   tokenAddress: '0x092ADA3818DB7FBb8e0A2124Ff218C5125C1CcE6',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooApeLINK-BNB',
  //   earnedTokenAddress: '0xfb04ba534ED800d9bFFce22B5F86eDaA4fc76D50',
  //   earnContractAddress: '0xfb04ba534ED800d9bFFce22B5F86eDaA4fc76D50',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'banana-link-bnb',
  //   oraclePrice: 0,
  //   depositsPaused: false,
  //   status: 'active',
  //   platform: 'ApeSwap',
  //   assets: ['LINK', 'BNB'],
  //   risks: [
  //     'COMPLEXITY_LOW',
  //     'BATTLE_TESTED',
  //     'IL_LOW',
  //     'MCAP_LARGE',
  //     'AUDIT',
  //     'CONTRACTS_VERIFIED',
  //   ],
  //   addLiquidityUrl:
  //     'https://dex.apeswap.finance/#/add/ETH/0xf8a0bf9cf54bb92f17374d9e9a321e6a111a51bd',
  //   buyTokenUrl:
  //     'https://dex.apeswap.finance/#/swap?outputCurrency=0xf8a0bf9cf54bb92f17374d9e9a321e6a111a51bd',
  //   createdAt: 1621068362,
  // },
  // {
  //   id: 'banana-ada-bnb',
  //   logo: 'degens/ADA-BNB.svg',
  //   name: 'ADA-BNB LP',
  //   token: 'ADA-BNB ALP',
  //   tokenDescription: 'ApeSwap',
  //   tokenAddress: '0x40d4543887E4170A1A40Cd8dB15A6b297c812Cb1',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooApeADA-BNB',
  //   earnedTokenAddress: '0xb8E6a5EDbC8ae71f74Fd02f65E95Ba0a8733c5a1',
  //   earnContractAddress: '0xb8E6a5EDbC8ae71f74Fd02f65E95Ba0a8733c5a1',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'banana-ada-bnb',
  //   oraclePrice: 0,
  //   depositsPaused: false,
  //   status: 'active',
  //   platform: 'ApeSwap',
  //   assets: ['ADA', 'BNB'],
  //   addLiquidityUrl:
  //     'https://dex.apeswap.finance/#/add/ETH/0x3EE2200Efb3400fAbB9AacF31297cBdD1d435D47',
  //   buyTokenUrl:
  //     'https://dex.apeswap.finance/#/swap?outputCurrency=0x3EE2200Efb3400fAbB9AacF31297cBdD1d435D47',
  //   createdAt: 1621017761,
  // },
  // {
  //   id: 'iron-iron-busd',
  //   logo: 'degens/IRON.png',
  //   name: 'IRON-BUSD LP',
  //   token: 'IRON-BUSD LP',
  //   tokenDescription: 'PancakeSwap (Iron)',
  //   tokenAddress: '0x26e49b5aE7e9b6c8C7C9b8506DA6b3b321c7431c',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooIronIRON-BUSD',
  //   earnedTokenAddress: '0x4b1dF138339627416a8b991a75769919F65cCD8d',
  //   earnContractAddress: '0x4b1dF138339627416a8b991a75769919F65cCD8d',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'iron-iron-busd',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'Other',
  //   assets: ['BUSD', 'IRON'],
  //   addLiquidityUrl:
  //     'https://pancakeswap.finance/add/0x7b65B489fE53fCE1F6548Db886C08aD73111DDd8/0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?outputCurrency=0x7b65B489fE53fCE1F6548Db886C08aD73111DDd8',
  //   createdAt: 1620995315,
  // },
  // {
  //   id: 'iron-steel-bnb',
  //   logo: 'degens/STEEL.png',
  //   name: 'STEEL-BNB LP',
  //   token: 'STEEL-BNB LP',
  //   tokenDescription: 'PancakeSwap (Iron)',
  //   tokenAddress: '0xcB7aA7F125DD9c237f6Df8f6D149DbBE779692E5',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooIronSTEEL-BNB',
  //   earnedTokenAddress: '0xCB01313B8Cf1d2742D0bc7209B2E5d1D0d172b36',
  //   earnContractAddress: '0xCB01313B8Cf1d2742D0bc7209B2E5d1D0d172b36',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'iron-steel-bnb',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'Other',
  //   assets: ['STEEL', 'BNB'],
  //   addLiquidityUrl:
  //     'https://pancakeswap.finance/add/0x9001eE054F1692feF3A48330cB543b6FEc6287eb/BNB',
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?outputCurrency=0x9001eE054F1692feF3A48330cB543b6FEc6287eb',
  //   createdAt: 1620995657,
  // },
  // {
  //   id: 'bhc-bhc-eol',
  //   logo: 'single-assets/BHC.png',
  //   name: 'BHC',
  //   token: 'BHC',
  //   tokenDescription: 'BillionHappiness',
  //   tokenAddress: '0x6fd7c98458a943f469E1Cf4eA85B173f5Cd342F4',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooBHC',
  //   earnedTokenAddress: '0x56E899E56F6B8635B4A01b724db23674a64A7231',
  //   earnContractAddress: '0x56E899E56F6B8635B4A01b724db23674a64A7231',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'tokens',
  //   oracleId: 'BHC',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'Other',
  //   assets: ['BHC'],
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?outputCurrency=0x6fd7c98458a943f469E1Cf4eA85B173f5Cd342F4',
  //   createdAt: 1611121393,
  // },
  // {
  //   id: 'banana-matic-bnb',
  //   logo: 'bnb-pairs/MATIC-BNB.svg',
  //   name: 'MATIC-BNB LP',
  //   token: 'MATIC-BNB ALP',
  //   tokenDescription: 'ApeSwap',
  //   tokenAddress: '0x29A4A3D77c010CE100A45831BF7e798f0f0B325D',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooApeMATIC-BNB',
  //   earnedTokenAddress: '0xB9dA6EdEeF7e3db24265EcFBeBF4e06B7c7A455f',
  //   earnContractAddress: '0xB9dA6EdEeF7e3db24265EcFBeBF4e06B7c7A455f',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'banana-matic-bnb',
  //   oraclePrice: 0,
  //   depositsPaused: false,
  //   status: 'active',
  //   platform: 'ApeSwap',
  //   assets: ['MATIC', 'BNB'],
  //   addLiquidityUrl:
  //     'https://dex.apeswap.finance/#/add/ETH/0xcc42724c6683b7e57334c4e856f4c9965ed682bd',
  //   buyTokenUrl:
  //     'https://dex.apeswap.finance/#/swap?outputCurrency=0xcc42724c6683b7e57334c4e856f4c9965ed682bd',
  //   createdAt: 1620832822,
  // },
  // {
  //   id: 'bunny-bunny-v2-eol',
  //   logo: 'single-assets/BUNNY.png',
  //   name: 'BUNNY',
  //   token: 'BUNNY',
  //   tokenDescription: 'Pancakebunny',
  //   tokenAddress: '0xC9849E6fdB743d08fAeE3E34dd2D1bc69EA11a51',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooBunnyV2',
  //   earnedTokenAddress: '0x2288dC6e2D2FaBd94a0ab63DD558AE149Bbc9eD9',
  //   earnContractAddress: '0x2288dC6e2D2FaBd94a0ab63DD558AE149Bbc9eD9',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'tokens',
  //   oracleId: 'BUNNY',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   retireReason: 'rewards',
  //   platform: 'Other',
  //   assets: ['BUNNY'],
  //   buyTokenUrl: 'https://app.1inch.io/#/56/swap/BNB/BUNNY',
  //   createdAt: 1620828423,
  // },
  // {
  //   id: 'tofy-tofy-eol',
  //   logo: 'degens/tofy.png',
  //   name: 'TOFY',
  //   token: 'TOFY',
  //   tokenDescription: 'Marshmallow',
  //   tokenAddress: '0xE1F2d89a6c79b4242F300f880e490A70083E9A1c',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooTofyTOFY',
  //   earnedTokenAddress: '0xE9F13aBE42A29BA302E7d57171f57430A2a2f8d0',
  //   earnContractAddress: '0xE9F13aBE42A29BA302E7d57171f57430A2a2f8d0',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'tokens',
  //   oracleId: 'TOFY',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'Other',
  //   assets: ['TOFY'],
  //   retireReason: 'rewards',
  //   buyTokenUrl:
  //     'https://exchange.marshmallowdefi.com/#/swap?outputCurrency=0xE1F2d89a6c79b4242F300f880e490A70083E9A1c',
  //   createdAt: 1620827357,
  // },
  // {
  //   id: 'tofy-tofy-mash-eol',
  //   logo: 'degens/tofy-mash.png',
  //   name: 'TOFY-MASH LP',
  //   token: 'TOFY-MASH LP',
  //   tokenDescription: 'Marshmallow',
  //   tokenAddress: '0xc36e93F6D92be42E2eAFB009dF74a0eBFeD5c0C2',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooTofyTOFY-MASH',
  //   earnedTokenAddress: '0xceCa548809FBb923974609EF3a2013134815741A',
  //   earnContractAddress: '0xceCa548809FBb923974609EF3a2013134815741A',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'tofy-tofy-mash',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'Other',
  //   assets: ['TOFY', 'MASH'],
  //   addLiquidityUrl:
  //     'https://exchange.marshmallowdefi.com/#/add/ETH/0xE1F2d89a6c79b4242F300f880e490A70083E9A1c',
  //   buyTokenUrl:
  //     'https://exchange.marshmallowdefi.com/#/swap?outputCurrency=0xE1F2d89a6c79b4242F300f880e490A70083E9A1c',
  //   createdAt: 1620825118,
  // },
  // {
  //   id: 'tofy-tofy-bnb-eol',
  //   logo: 'degens/tofy-bnb.png',
  //   name: 'TOFY-BNB LP',
  //   token: 'TOFY-BNB LP',
  //   tokenDescription: 'Marshmallow',
  //   tokenAddress: '0x753f40F5CdeB085AD4540Dd02a1c0c712EaF51F6',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooTofyTOFY-BNB',
  //   earnedTokenAddress: '0x67C079A0bd936270981A8399f5d38c39f00941F4',
  //   earnContractAddress: '0x67C079A0bd936270981A8399f5d38c39f00941F4',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'tofy-tofy-bnb',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'Other',
  //   assets: ['TOFY', 'BNB'],
  //   addLiquidityUrl:
  //     'https://exchange.marshmallowdefi.com/#/add/ETH/0xE1F2d89a6c79b4242F300f880e490A70083E9A1c',
  //   buyTokenUrl:
  //     'https://exchange.marshmallowdefi.com/#/swap?outputCurrency=0xE1F2d89a6c79b4242F300f880e490A70083E9A1c',
  //   createdAt: 1620824133,
  // },
  // {
  //   id: 'goal-goal-busd-eol',
  //   logo: 'degens/GOAL-BUSD.png',
  //   name: 'GOAL-BUSD LP',
  //   token: 'GOAL-BUSD LP2',
  //   tokenDescription: 'PancakeSwap',
  //   tokenAddress: '0xE4305690e9B765bb7B85a48BBe6b16b82e12A3cC',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooGoalGOAL-BUSD',
  //   earnedTokenAddress: '0xF18784Ba03fA5017E1ae4D82c4a3e00F5E8fF16f',
  //   earnContractAddress: '0xF18784Ba03fA5017E1ae4D82c4a3e00F5E8fF16f',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'goal-goal-busd',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'PancakeSwap',
  //   assets: ['GOAL', 'BUSD'],
  //   addLiquidityUrl:
  //     'https://pancakeswap.finance/add/0xE5b57E6e1b945B91FEE368aC108d2ebCcA78Aa8F/0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?outputCurrency=0xE5b57E6e1b945B91FEE368aC108d2ebCcA78Aa8F',
  //   createdAt: 1620725403,
  //   retiredReason: 'tvl',
  // },
  // {
  //   id: 'cakev2-kun-busd-eol',
  //   logo: 'busd-pairs/KUN-BUSD.svg',
  //   name: 'KUN-BUSD LP',
  //   token: 'KUN-BUSD LP2',
  //   tokenDescription: 'PancakeSwap',
  //   tokenAddress: '0xeA61020E5a128D2BEC67d48F7CFbe3408dB7E391',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooCakeV2KUN-BUSD',
  //   earnedTokenAddress: '0x52823D1A0F3745D9481557eDF3ACff04857D28B6',
  //   earnContractAddress: '0x52823D1A0F3745D9481557eDF3ACff04857D28B6',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'cakev2-kun-busd',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'PancakeSwap',
  //   assets: ['KUN', 'BUSD'],
  //   retireReason: 'rewards',
  //   addLiquidityUrl:
  //     'https://pancakeswap.finance/add/0x1a2fb0af670d0234c2857fad35b789f8cb725584/0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?outputCurrency=0x1a2fb0af670d0234c2857fad35b789f8cb725584',
  //   createdAt: 1620722886,
  // },
  // {
  //   id: 'cakev2-mcoin-ust-eol',
  //   logo: 'ust-pairs/MCOIN-UST.svg',
  //   name: 'mCOIN-UST LP',
  //   token: 'mCOIN-UST LP2',
  //   tokenDescription: 'PancakeSwap',
  //   tokenAddress: '0xBCf01a42f6BC42F3Cfe81B05519565044d65D22a',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooCakeV2MCOIN-UST',
  //   earnedTokenAddress: '0xF803F277D5aAe38ac5CBfe8BA7B2e265983cc2a6',
  //   earnContractAddress: '0xF803F277D5aAe38ac5CBfe8BA7B2e265983cc2a6',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'cakev2-mcoin-ust',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   retireReason: 'rewards',
  //   platform: 'PancakeSwap',
  //   assets: ['mCOIN', 'UST'],
  //   addLiquidityUrl:
  //     'https://pancakeswap.finance/add/0x49022089e78a8D46Ec87A3AF86a1Db6c189aFA6f/0x23396cF899Ca06c4472205fC903bDB4de249D6fC',
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?outputCurrency=0x49022089e78a8D46Ec87A3AF86a1Db6c189aFA6f',
  //   createdAt: 1620623730,
  // },
  // {
  //   id: 'banana-watch-bnb',
  //   logo: 'bnb-pairs/WATCH-BNB-APE.svg',
  //   name: 'WATCH-BNB LP',
  //   token: 'WATCH-BNB ALP',
  //   tokenDescription: 'ApeSwap',
  //   tokenAddress: '0xa00A91fBB39051e2a6368424A93895c3f1F2290b',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooApeWATCH-BNB',
  //   earnedTokenAddress: '0xc795E63738d6207dFE4450D280013aCFdb1Cc526',
  //   earnContractAddress: '0xc795E63738d6207dFE4450D280013aCFdb1Cc526',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'banana-watch-bnb',
  //   oraclePrice: 0,
  //   depositsPaused: false,
  //   status: 'active',
  //   platform: 'ApeSwap',
  //   assets: ['WATCH', 'BNB'],
  //   addLiquidityUrl:
  //     'https://dex.apeswap.finance/#/add/ETH/0x7A9f28EB62C791422Aa23CeAE1dA9C847cBeC9b0',
  //   buyTokenUrl:
  //     'https://dex.apeswap.finance/#/swap?outputCurrency=0x7A9f28EB62C791422Aa23CeAE1dA9C847cBeC9b0',
  //   createdAt: 1620550835,
  // },
  // {
  //   id: 'jetfuel-fts-bnb-eol',
  //   logo: 'bnb-pairs/FTS-BNB.svg',
  //   name: 'FTS-BNB LP',
  //   token: 'FTS-BNB LP',
  //   tokenDescription: 'PancakeSwap',
  //   tokenAddress: '0xc69f2139a6Ce6912703AC10e5e74ee26Af1b4a7e',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooJetfuelFTS-BNB',
  //   earnedTokenAddress: '0xcE7cC761217CEaBE2eC7dD56a13Ff0056c36Aa79',
  //   earnContractAddress: '0xcE7cC761217CEaBE2eC7dD56a13Ff0056c36Aa79',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'jetfuel-fts-bnb',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'Other',
  //   assets: ['FTS', 'BNB'],
  //   addLiquidityUrl:
  //     'https://pancakeswap.finance/add/BNB/0x4437743ac02957068995c48e08465e0ee1769fbe',
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?outputCurrency=0x4437743ac02957068995c48e08465e0ee1769fbe',
  //   createdAt: 1620547906,
  // },
  // {
  //   id: 'banana-bxbtc-bnb-eol',
  //   logo: 'bnb-pairs/BXBTC-BNB.svg',
  //   name: 'BXBTC-BNB LP',
  //   token: 'BXBTC-BNB LP',
  //   tokenDescription: 'ApeSwap',
  //   tokenAddress: '0xc2feF4BEC915315beF9f6E8a06b2516E64D29D06',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooApeBXBTC-BNB',
  //   earnedTokenAddress: '0x8D112FcdF377A2C4cb41b60AAe32199F939a866C',
  //   earnContractAddress: '0x8D112FcdF377A2C4cb41b60AAe32199F939a866C',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'banana-bxbtc-bnb',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'ApeSwap',
  //   assets: ['BXBTC', 'BNB'],
  //   addLiquidityUrl:
  //     'https://dex.apeswap.finance/#/add/ETH/0xab111D5948470Ba73d98D66BBdf2798FBE093546',
  //   buyTokenUrl:
  //     'https://dex.apeswap.finance/#/swap?outputCurrency=0xab111D5948470Ba73d98D66BBdf2798FBE093546',
  //   createdAt: 1620370116,
  // },
  // {
  //   id: 'cakev2-btt-bnb',
  //   logo: 'bnb-pairs/BTT-BNB.svg',
  //   name: 'BTT-BNB LP',
  //   token: 'BTT-BNB LP2',
  //   tokenDescription: 'PancakeSwap',
  //   tokenAddress: '0x946696344e7d4346b223e1Cf77035a76690d6A73',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooCakeV2BTT-BNB',
  //   earnedTokenAddress: '0x561860359244877A6dE987165a5A89Ef288C178D',
  //   earnContractAddress: '0x561860359244877A6dE987165a5A89Ef288C178D',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'cakev2-btt-bnb',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'PancakeSwap',
  //   assets: ['BTT', 'BNB'],
  //   addLiquidityUrl:
  //     'https://pancakeswap.finance/add/BNB/0x8595f9da7b868b1822194faed312235e43007b49',
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?outputCurrency=0x8595f9da7b868b1822194faed312235e43007b49',
  //   createdAt: 1620373799,
  // },
  // {
  //   id: 'cakev2-trx-bnb',
  //   logo: 'bnb-pairs/TRX-BNB.svg',
  //   name: 'TRX-BNB LP',
  //   token: 'TRX-BNB LP2',
  //   tokenDescription: 'PancakeSwap',
  //   tokenAddress: '0x3cd338c3BB249B6b3C55799F85a589FEbBBFf9Dd',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooCakeV2TRX-BNB',
  //   earnedTokenAddress: '0xa60D572AAfC217a76F73475644716cA63a4E7528',
  //   earnContractAddress: '0xa60D572AAfC217a76F73475644716cA63a4E7528',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'cakev2-trx-bnb',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'PancakeSwap',
  //   assets: ['TRX', 'BNB'],
  //   addLiquidityUrl:
  //     'https://pancakeswap.finance/add/BNB/0x85eac5ac2f758618dfa09bdbe0cf174e7d574d5b',
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?outputCurrency=0x85eac5ac2f758618dfa09bdbe0cf174e7d574d5b',
  //   createdAt: 1620373832,
  // },
  // {
  //   id: 'cakev2-win-bnb',
  //   logo: 'bnb-pairs/WIN-BNB.svg',
  //   name: 'WIN-BNB LP',
  //   token: 'WIN-BNB LP2',
  //   tokenDescription: 'PancakeSwap',
  //   tokenAddress: '0x894bd57afD8EFc93D9171cb585d11d0977557425',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooCakeV2WIN-BNB',
  //   earnedTokenAddress: '0x76Fcc8e2566ff7b60D11A9d5D76de12eC16cbf15',
  //   earnContractAddress: '0x76Fcc8e2566ff7b60D11A9d5D76de12eC16cbf15',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'cakev2-win-bnb',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'PancakeSwap',
  //   assets: ['WIN', 'BNB'],
  //   addLiquidityUrl:
  //     'https://pancakeswap.finance/add/BNB/0xaef0d72a118ce24fee3cd1d43d383897d05b4e99',
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?outputCurrency=0xaef0d72a118ce24fee3cd1d43d383897d05b4e99',
  //   createdAt: 1620373868,
  // },
  // {
  //   id: 'banana-naut-bnb-eol',
  //   logo: 'bnb-pairs/NAUT-BNB.svg',
  //   name: 'NAUT-BNB LP',
  //   token: 'NAUT-BNB LP',
  //   tokenDescription: 'ApeSwap',
  //   tokenAddress: '0x288EA5437c7aaD045a393cee2F41E548df24d1C8',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooApeNAUT-BNB',
  //   earnedTokenAddress: '0x9f670c6CdB8A6133016eC7C8552Dd42624FaC53F',
  //   earnContractAddress: '0x9f670c6CdB8A6133016eC7C8552Dd42624FaC53F',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'banana-naut-bnb',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   retireReason: 'tvl',
  //   platform: 'ApeSwap',
  //   assets: ['NAUT', 'BNB'],
  //   addLiquidityUrl:
  //     'https://dex.apeswap.finance/#/add/ETH/0x05B339B0A346bF01f851ddE47a5d485c34FE220c',
  //   buyTokenUrl:
  //     'https://dex.apeswap.finance/#/swap?outputCurrency=0x05b339b0a346bf01f851dde47a5d485c34fe220c',
  //   createdAt: 1620370071,
  // },
  // {
  //   id: 'cakev2-pnt-pbtc-eol',
  //   logo: 'uncategorized/PNT-PBTC.svg',
  //   name: 'PNT-pBTC LP',
  //   token: 'PNT-pBTC LP',
  //   tokenDescription: 'PancakeSwap',
  //   tokenAddress: '0xDAA89d335926628367b47852989bb22EE62CA5DE',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooCakeV2PNT-PBTC',
  //   earnedTokenAddress: '0xd6d2e8c9f9a276811A8994Bf7610807f4116115f',
  //   earnContractAddress: '0xd6d2e8c9f9a276811A8994Bf7610807f4116115f',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'cakev2-pnt-pbtc',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   retireReason: 'rewards',
  //   platform: 'PancakeSwap',
  //   assets: ['PNT', 'pBTC'],
  //   addLiquidityUrl:
  //     'https://pancakeswap.finance/add/0xeD28A457A5A76596ac48d87C0f577020F6Ea1c4C/0xdaacB0Ab6Fb34d24E8a67BfA14BF4D95D4C7aF92',
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?outputCurrency=0xeD28A457A5A76596ac48d87C0f577020F6Ea1c4C',
  //   createdAt: 1620292758,
  // },
  // {
  //   id: 'ica-ica-btcb-eol',
  //   logo: 'single-assets/ICA.svg',
  //   name: 'ICA-BTCB LP',
  //   token: 'ICA-BTCB LP',
  //   tokenDescription: 'PancakeSwap (Icarus)',
  //   tokenAddress: '0xA2e5A7Bf668ECb74186e0E4BFBA8a0C58BCc760d',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooIcarusICA-BTCB',
  //   earnedTokenAddress: '0xbf29b8AdaE718A3A372ba90040A9acBD8A5794f4',
  //   earnContractAddress: '0xbf29b8AdaE718A3A372ba90040A9acBD8A5794f4',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'ica-ica-btcb',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'Other',
  //   assets: ['ICA', 'BTCB'],
  //   addLiquidityUrl:
  //     'https://pancakeswap.finance/add/0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c/0x95111f630aC215Eb74599ED42C67E2c2790d69e2',
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?outputCurrency=0x95111f630ac215eb74599ed42c67e2c2790d69e2',
  //   createdAt: 1620204194,
  // },
  // {
  //   id: 'ica-ica-eth-eol',
  //   logo: 'single-assets/ICA.svg',
  //   name: 'ICA-ETH LP',
  //   token: 'ICA-ETH LP',
  //   tokenDescription: 'PancakeSwap (Icarus)',
  //   tokenAddress: '0x2cb2C54795b6714dA829FfA580B9692A261F8Bdd',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooIcarusICA-ETH',
  //   earnedTokenAddress: '0x389e5F8e23e88cA2D971a69b2AAADE264A9DE5Fb',
  //   earnContractAddress: '0x389e5F8e23e88cA2D971a69b2AAADE264A9DE5Fb',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'ica-ica-eth',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'Other',
  //   assets: ['ICA', 'ETH'],
  //   addLiquidityUrl:
  //     'https://pancakeswap.finance/add/0x2170Ed0880ac9A755fd29B2688956BD959F933F8/0x95111f630aC215Eb74599ED42C67E2c2790d69e2',
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?outputCurrency=0x95111f630ac215eb74599ed42c67e2c2790d69e2',
  //   createdAt: 1620203510,
  // },
  // {
  //   id: 'zefiv2-zefi-bnb-eol',
  //   logo: 'degens/ZEFI-BNB.svg',
  //   name: 'ZEFI-BNB LP',
  //   token: 'ZEFI-BNB LP2',
  //   tokenDescription: 'PancakeSwap (ZCore)',
  //   tokenAddress: '0x7C020B60fBDeB668834BE0367CfBA395CaAE5dFc',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooZefiV2ZEFI-BNB',
  //   earnedTokenAddress: '0xB62eE36A03ab043Eb5b57E1eE88dEFbe9959d4C3',
  //   earnContractAddress: '0xB62eE36A03ab043Eb5b57E1eE88dEFbe9959d4C3',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'zefiv2-zefi-bnb',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'Other',
  //   assets: ['ZEFI', 'BNB'],
  //   addLiquidityUrl:
  //     'https://pancakeswap.finance/add/BNB/0x0288D3E353fE2299F11eA2c2e1696b4A648eCC07',
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?outputCurrency=0x0288D3E353fE2299F11eA2c2e1696b4A648eCC07',
  //   createdAt: 1620143785,
  //   retiredReason: 'tvl',
  // },
  // {
  //   id: 'zefiv2-zefi-busd-eol',
  //   logo: 'degens/ZEFI-BUSD.svg',
  //   name: 'ZEFI-BUSD LP',
  //   token: 'ZEFI-BUSD LP2',
  //   tokenDescription: 'PancakeSwap (ZCore)',
  //   tokenAddress: '0x09B0123e36A2b0A5b95474455E437e8861a6C61E',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooZefiV2ZEFI-BUSD',
  //   earnedTokenAddress: '0x6b57697eb320F40d5F386547DB3d468111fF0B92',
  //   earnContractAddress: '0x6b57697eb320F40d5F386547DB3d468111fF0B92',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'zefiv2-zefi-busd',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'Other',
  //   assets: ['ZEFI', 'BUSD'],
  //   addLiquidityUrl:
  //     'https://pancakeswap.finance/add/0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56/0x0288D3E353fE2299F11eA2c2e1696b4A648eCC07',
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?outputCurrency=0x0288D3E353fE2299F11eA2c2e1696b4A648eCC07',
  //   createdAt: 1620143500,
  //   retiredReason: 'tvl',
  // },
  // {
  //   id: 'satis-sat-bnb-eol',
  //   logo: 'degens/SAT-BNB.png',
  //   name: 'SAT-BNB LP',
  //   token: 'SAT-BNB LP',
  //   tokenDescription: 'PancakeSwap (Satis)',
  //   tokenAddress: '0x0AB22917fA941edCa92A5b7E030C59fbC7D9019a',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooSatisSAT-BNB',
  //   earnedTokenAddress: '0x81a00F9789041E8CE270fCdd98dB65D8504C990B',
  //   earnContractAddress: '0x81a00F9789041E8CE270fCdd98dB65D8504C990B',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'satis-sat-bnb',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'Other',
  //   assets: ['SAT', 'BNB'],
  //   removeLiquidityUrl:
  //     'https://pancakeswap.finance/remove/BNB/0xA1928c0D8F83C0bFB7ebE51B412b1FD29A277893',
  //   createdAt: 1620046260,
  // },
  // {
  //   id: 'satis-sat-busd-eol',
  //   logo: 'degens/SAT-BUSD.png',
  //   name: 'SAT-BUSD LP',
  //   token: 'SAT-BUSD LP',
  //   tokenDescription: 'PancakeSwap (Satis)',
  //   tokenAddress: '0x020DB9ff8648AcFc9C590eafb8f6bcd8f254D248',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooSatisSAT-BUSD',
  //   earnedTokenAddress: '0xF2ce03745790fCE2F707157B008f981caA7c00b2',
  //   earnContractAddress: '0xF2ce03745790fCE2F707157B008f981caA7c00b2',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'satis-sat-busd',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'Other',
  //   assets: ['SAT', 'BUSD'],
  //   removeLiquidityUrl:
  //     'https://pancakeswap.finance/remove/0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56/0xA1928c0D8F83C0bFB7ebE51B412b1FD29A277893',
  //   createdAt: 1620045924,
  // },
  // {
  //   id: 'banana-busd-usdc',
  //   logo: 'busd-pairs/BUSD-USDC.svg',
  //   name: 'BUSD-USDC LP',
  //   token: 'BUSD-USDC LP',
  //   tokenDescription: 'ApeSwap',
  //   tokenAddress: '0xC087C78AbaC4A0E900a327444193dBF9BA69058E',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooApeBUSD-USDC',
  //   earnedTokenAddress: '0xc6631af32c5Bd444626274C9FD1dc8A759534222',
  //   earnContractAddress: '0xc6631af32c5Bd444626274C9FD1dc8A759534222',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'banana-busd-usdc',
  //   oraclePrice: 0,
  //   depositsPaused: false,
  //   status: 'active',
  //   platform: 'ApeSwap',
  //   assets: ['BUSD', 'USDC'],
  //   addLiquidityUrl:
  //     'https://dex.apeswap.finance/#/add/0x8ac76a51cc950d9822d68b83fe1ad97b32cd580d/0xe9e7cea3dedca5984780bafc599bd69add087d56',
  //   buyTokenUrl:
  //     'https://dex.apeswap.finance/#/swap?inputCurrency=0x8ac76a51cc950d9822d68b83fe1ad97b32cd580d&outputCurrency=0xe9e7cea3dedca5984780bafc599bd69add087d56',
  //   createdAt: 1620059978,
  // },
  // {
  //   id: 'banana-doge-bnb',
  //   logo: 'bnb-pairs/DOGE-BNB.svg',
  //   name: 'DOGE-BNB LP',
  //   token: 'DOGE-BNB LP',
  //   tokenDescription: 'ApeSwap',
  //   tokenAddress: '0xfd1ef328A17A8e8Eeaf7e4Ea1ed8a108E1F2d096',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooApeDOGE-BNB',
  //   earnedTokenAddress: '0x6c978fe7E78c52a4c40107f081bA92b5d3daEfBF',
  //   earnContractAddress: '0x6c978fe7E78c52a4c40107f081bA92b5d3daEfBF',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'banana-doge-bnb',
  //   oraclePrice: 0,
  //   depositsPaused: false,
  //   status: 'active',
  //   platform: 'ApeSwap',
  //   assets: ['DOGE', 'BNB'],
  //   risks: [
  //     'COMPLEXITY_LOW',
  //     'BATTLE_TESTED',
  //     'IL_HIGH',
  //     'MCAP_LARGE',
  //     'AUDIT',
  //     'CONTRACTS_VERIFIED',
  //   ],
  //   stratType: 'StratLP',
  //   addLiquidityUrl:
  //     'https://dex.apeswap.finance/#/add/ETH/0xbA2aE424d960c26247Dd6c32edC70B295c744C43',
  //   buyTokenUrl:
  //     'https://dex.apeswap.finance/#/swap?outputCurrency=0xbA2aE424d960c26247Dd6c32edC70B295c744C43',
  //   createdAt: 1619783884,
  // },
  // {
  //   id: 'cakev2-btcb-busd',
  //   logo: 'busd-pairs/BTCB-BUSD.svg',
  //   name: 'BTCB-BUSD LP',
  //   token: 'BTCB-BUSD LP2',
  //   tokenDescription: 'PancakeSwap',
  //   tokenAddress: '0xF45cd219aEF8618A92BAa7aD848364a158a24F33',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooCakeV2BTCB-BUSD',
  //   earnedTokenAddress: '0x40D21bCd71B0Be8864ff3Dd62373CFFA4E151d6F',
  //   earnContractAddress: '0x40D21bCd71B0Be8864ff3Dd62373CFFA4E151d6F',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'cakev2-btcb-busd',
  //   oraclePrice: 0,
  //   depositsPaused: false,
  //   status: 'active',
  //   platform: 'PancakeSwap',
  //   assets: ['BTCB', 'BUSD'],
  //   addLiquidityUrl:
  //     'https://pancakeswap.finance/add/0x7130d2a12b9bcbfae4f2634d864a1ee1ce3ead9c/0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
  //   createdAt: 1619713472,
  // },
  // {
  //   id: 'banana-keyfi-bnb-eol',
  //   logo: 'bnb-pairs/KEYFI-BNB.svg',
  //   name: 'KEYFI-BNB LP',
  //   token: 'KEYFI-BNB LP',
  //   tokenDescription: 'ApeSwap',
  //   tokenAddress: '0x7A8ACAEAfC4Fa051De4EAbff8D1abdD0388aE08a',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooApeKEYFI-BNB',
  //   earnedTokenAddress: '0x4A62e870EdF51EdAe9Cc9a4BF5D17919c7759AF3',
  //   earnContractAddress: '0x4A62e870EdF51EdAe9Cc9a4BF5D17919c7759AF3',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'banana-keyfi-bnb',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'ApeSwap',
  //   assets: ['KEYFI', 'BNB'],
  //   retireReason: 'rewards',
  //   addLiquidityUrl:
  //     'https://dex.apeswap.finance/#/add/0x4b6000f9163de2e3f0a01ec37e06e1469dbbce9d/0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c',
  //   buyTokenUrl:
  //     'https://dex.apeswap.finance/#/swap?inputCurrency=0x4b6000f9163de2e3f0a01ec37e06e1469dbbce9d&outputCurrency=0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c',
  //   createdAt: 1619632061,
  // },
  // {
  //   id: 'cakev2-lmt-bnb-eol',
  //   logo: 'bnb-pairs/LMT-BNB.svg',
  //   name: 'LMT-BNB LP',
  //   token: 'LMT-BNB LP2',
  //   tokenDescription: 'PancakeSwap',
  //   tokenAddress: '0x8271d7eAfeEb8F24d7C9fE1AccE2AE20611972E5',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooCakeV2LMT-BNB',
  //   earnedTokenAddress: '0x6261443B70d045f02B20Cc33e0faB096718563DF',
  //   earnContractAddress: '0x6261443B70d045f02B20Cc33e0faB096718563DF',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'cakev2-lmt-bnb',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'PancakeSwap',
  //   assets: ['LMT', 'BNB'],
  //   retireReason: 'rewards',
  //   addLiquidityUrl:
  //     'https://pancakeswap.finance/add/BNB/0x9617857e191354dbea0b714d78bc59e57c411087',
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?outputCurrency=0x9617857e191354dbea0b714d78bc59e57c411087',
  //   createdAt: 1619596631,
  // },
  // {
  //   id: 'swampv2-cake-bnb-eol',
  //   name: 'CAKE-BNB LP',
  //   token: 'CAKE-BNB LP2',
  //   tokenDescription: 'PancakeSwap (Swamp)',
  //   tokenAddress: '0x0eD7e52944161450477ee417DE9Cd3a859b14fD0',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooSwampV2CAKE-BNB',
  //   earnedTokenAddress: '0xe503B4c52a9220E20fe5Cf499D551977A6A12796',
  //   earnContractAddress: '0xe503B4c52a9220E20fe5Cf499D551977A6A12796',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'cakev2-cake-bnb',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'Other',
  //   assets: ['CAKE', 'BNB'],
  //   depositFee: '<0.1%',
  //   addLiquidityUrl:
  //     'https://pancakeswap.finance/add/BNB/0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82',
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?outputCurrency=0x0e09fabb73bd3ade0a17ecc321fd13a19e81ce82',
  //   createdAt: 1619446464,
  // },
  // {
  //   id: 'beltv2-belt-bnb',
  //   logo: 'bnb-pairs/BELT-BNB-BELT.png',
  //   name: 'BELT-BNB LP',
  //   token: 'BELT-BNB LP2',
  //   tokenDescription: 'PancakeSwap (Belt)',
  //   tokenAddress: '0xF3Bc6FC080ffCC30d93dF48BFA2aA14b869554bb',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooBeltV2BeltBNB',
  //   earnedTokenAddress: '0x190DD361Ee7EDB1801d66e9E957C5CDF1e7Be75b',
  //   earnContractAddress: '0x190DD361Ee7EDB1801d66e9E957C5CDF1e7Be75b',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'cakev2-belt-bnb',
  //   oraclePrice: 0,
  //   depositsPaused: false,
  //   status: 'active',
  //   platform: 'Belt',
  //   assets: ['BELT', 'BNB'],
  //   addLiquidityUrl:
  //     'https://pancakeswap.finance/add/BNB/0xE0e514c71282b6f4e823703a39374Cf58dc3eA4f',
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?outputCurrency=0xE0e514c71282b6f4e823703a39374Cf58dc3eA4f',
  //   createdAt: 1619499140,
  // },
  // {
  //   id: 'cakev2-dfd-busd-eol',
  //   logo: 'busd-pairs/DFD-BUSD.svg',
  //   name: 'DFD-BUSD LP',
  //   token: 'DFD-BUSD LP2',
  //   tokenDescription: 'PancakeSwap',
  //   tokenAddress: '0x029D66f9C0469450B7b4834B8dDC6a1118CeC3e1',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooCakeV2DFD-BUSD',
  //   earnedTokenAddress: '0xd80d646A28d60494A22Df83AA6b629df4F52DAeb',
  //   earnContractAddress: '0xd80d646A28d60494A22Df83AA6b629df4F52DAeb',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'cakev2-dfd-busd',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   retireReason: 'rewards',
  //   platform: 'PancakeSwap',
  //   assets: ['DFD', 'BUSD'],
  //   addLiquidityUrl:
  //     'https://pancakeswap.finance/add/0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56/0x9899a98b222fcb2f3dbee7df45d943093a4ff9ff',
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?outputCurrency=0x9899a98b222fcb2f3dbee7df45d943093a4ff9ff',
  //   createdAt: 1619513456,
  // },
  // {
  //   id: 'cakev2-alpaca-busd',
  //   logo: 'busd-pairs/ALPACA-BUSD.svg',
  //   name: 'ALPACA-BUSD LP',
  //   token: 'ALPACA-BUSD LP2',
  //   tokenDescription: 'PancakeSwap',
  //   tokenAddress: '0x7752e1FA9F3a2e860856458517008558DEb989e3',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooCakeV2ALPACA-BUSD',
  //   earnedTokenAddress: '0x8d89BF6A2CbEAD5577399EAc9e76222b4c41eD69',
  //   earnContractAddress: '0x8d89BF6A2CbEAD5577399EAc9e76222b4c41eD69',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'cakev2-alpaca-busd',
  //   oraclePrice: 0,
  //   depositsPaused: false,
  //   status: 'active',
  //   platform: 'PancakeSwap',
  //   assets: ['ALPACA', 'BUSD'],
  //   addLiquidityUrl:
  //     'https://pancakeswap.finance/add/0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56/0x8f0528ce5ef7b51152a59745befdd91d97091d2f',
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?outputCurrency=0x8f0528ce5ef7b51152a59745befdd91d97091d2f',
  //   createdAt: 1619497427,
  // },
  // {
  //   id: 'swampv2-swamp-bnb-eol',
  //   logo: 'bnb-pairs/SWAMP-BNB.svg',
  //   name: 'SWAMP-BNB LP',
  //   token: 'SWAMP-BNB LP2',
  //   tokenDescription: 'PancakeSwap (Swamp)',
  //   tokenAddress: '0x59Fe1dcdCD8918fe7B4e76E21f27599d5a1eAADb',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooSwampV2SWAMP-BNB',
  //   earnedTokenAddress: '0xe77Ec021543985E914C54F81938039FF58e5B0b1',
  //   earnContractAddress: '0xe77Ec021543985E914C54F81938039FF58e5B0b1',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'swampv2-swamp-bnb',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'Other',
  //   assets: ['SWAMP', 'BNB'],
  //   depositFee: '<0.1%',
  //   addLiquidityUrl: 'https://pancakeswap.finance/add/0xc5A49b4CBe004b6FD55B30Ba1dE6AC360FF9765d',
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?outputCurrency=0xc5A49b4CBe004b6FD55B30Ba1dE6AC360FF9765d',
  //   createdAt: 1619446410,
  //   retiredReason: 'tvl',
  // },
  // {
  //   id: 'swampv2-swamp-busd-eol',
  //   logo: 'busd-pairs/SWAMP-BUSD.svg',
  //   name: 'SWAMP-BUSD LP',
  //   token: 'SWAMP-BUSD LP2',
  //   tokenDescription: 'PancakeSwap (Swamp)',
  //   tokenAddress: '0x90946d2f9fB68ecc40ad8Bc0C1cB15D6cCbC4022',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooSwampV2SWAMP-BUSD',
  //   earnedTokenAddress: '0xF5898cB211fAE8538253F08930e6b350cEe16444',
  //   earnContractAddress: '0xF5898cB211fAE8538253F08930e6b350cEe16444',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'swampv2-swamp-busd',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'Other',
  //   assets: ['SWAMP', 'BUSD'],
  //   depositFee: '<0.1%',
  //   addLiquidityUrl:
  //     'https://pancakeswap.finance/add/0xc5A49b4CBe004b6FD55B30Ba1dE6AC360FF9765d/0xe9e7cea3dedca5984780bafc599bd69add087d56',
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?outputCurrency=0xc5A49b4CBe004b6FD55B30Ba1dE6AC360FF9765d',
  //   createdAt: 1619446431,
  //   retiredReason: 'tvl',
  // },
  // {
  //   id: 'cakev2-bunny-bnb-eol',
  //   logo: 'bnb-pairs/BUNNY-BNB.svg',
  //   name: 'BUNNY-BNB LP',
  //   token: 'BUNNY-BNB LP2',
  //   tokenDescription: 'PancakeSwap',
  //   tokenAddress: '0x5aFEf8567414F29f0f927A0F2787b188624c10E2',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooCakeV2BUNNY-BNB',
  //   earnedTokenAddress: '0x5b1c50a1B0FfFB762b0431D6b0E1309a48Ac95a9',
  //   earnContractAddress: '0x5b1c50a1B0FfFB762b0431D6b0E1309a48Ac95a9',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'cakev2-bunny-bnb',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'PancakeSwap',
  //   assets: ['BUNNY', 'BNB'],
  //   addLiquidityUrl:
  //     'https://pancakeswap.finance/add/BNB/0xc9849e6fdb743d08faee3e34dd2d1bc69ea11a51',
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?outputCurrency=0xc9849e6fdb743d08faee3e34dd2d1bc69ea11a51',
  //   createdAt: 1619437257,
  //   retiredReason: 'tvl',
  // },
  // {
  //   id: 'cakev2-eos-bnb-eol',
  //   logo: 'bnb-pairs/EOS-BNB.svg',
  //   name: 'EOS-BNB LP',
  //   token: 'EOS-BNB LP2',
  //   tokenDescription: 'PancakeSwap',
  //   tokenAddress: '0xB6e34b5C65Eda51bb1BD4ea5F79d385Fb94b9504',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooCakeV2EOS-BNB',
  //   earnedTokenAddress: '0x23ca638D912C98870bCd03CB3a7D4084072f4474',
  //   earnContractAddress: '0x23ca638D912C98870bCd03CB3a7D4084072f4474',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'cakev2-eos-bnb',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   retireReason: 'rewards',
  //   platform: 'PancakeSwap',
  //   assets: ['EOS', 'BNB'],
  //   addLiquidityUrl:
  //     'https://pancakeswap.finance/add/BNB/0x56b6fB708fC5732DEC1Afc8D8556423A2EDcCbD6',
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?outputCurrency=0x56b6fB708fC5732DEC1Afc8D8556423A2EDcCbD6',
  //   createdAt: 1619423558,
  // },
  // {
  //   id: 'cakev2-xrp-bnb',
  //   logo: 'bnb-pairs/XRP-BNB.svg',
  //   name: 'XRP-BNB LP',
  //   token: 'XRP-BNB LP2',
  //   tokenDescription: 'PancakeSwap',
  //   tokenAddress: '0x03F18135c44C64ebFdCBad8297fe5bDafdBbdd86',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooCakeV2XRP-BNB',
  //   earnedTokenAddress: '0x1a39846045288d55f48Bc6AB2b77A03d93aB5e05',
  //   earnContractAddress: '0x1a39846045288d55f48Bc6AB2b77A03d93aB5e05',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'cakev2-xrp-bnb',
  //   oraclePrice: 0,
  //   depositsPaused: false,
  //   status: 'active',
  //   platform: 'PancakeSwap',
  //   assets: ['XRP', 'BNB'],
  //   addLiquidityUrl:
  //     'https://pancakeswap.finance/add/BNB/0x1D2F0da169ceB9fC7B3144628dB156f3F6c60dBE',
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?outputCurrency=0x1D2F0da169ceB9fC7B3144628dB156f3F6c60dBE',
  //   createdAt: 1619423603,
  // },
  // {
  //   id: 'cakev2-uni-bnb',
  //   logo: 'bnb-pairs/UNI-BNB.svg',
  //   name: 'UNI-BNB LP',
  //   token: 'UNI-BNB LP2',
  //   tokenDescription: 'PancakeSwap',
  //   tokenAddress: '0x014608E87AF97a054C9a49f81E1473076D51d9a3',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooCakeV2UNI-BNB',
  //   earnedTokenAddress: '0xb3127Fe584E0A522857D1dAfb8ef0b8CE7E9A9c2',
  //   earnContractAddress: '0xb3127Fe584E0A522857D1dAfb8ef0b8CE7E9A9c2',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'cakev2-uni-bnb',
  //   oraclePrice: 0,
  //   depositsPaused: false,
  //   status: 'active',
  //   platform: 'PancakeSwap',
  //   assets: ['UNI', 'BNB'],
  //   addLiquidityUrl:
  //     'https://pancakeswap.finance/add/BNB/0xBf5140A22578168FD562DCcF235E5D43A02ce9B1',
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?outputCurrency=0xBf5140A22578168FD562DCcF235E5D43A02ce9B1',
  //   createdAt: 1619423639,
  // },
  // {
  //   id: 'cakev2-sxp-bnb',
  //   logo: 'bnb-pairs/SXP-BNB.svg',
  //   name: 'SXP-BNB LP',
  //   token: 'SXP-BNB LP2',
  //   tokenDescription: 'PancakeSwap',
  //   tokenAddress: '0xD8E2F8b6Db204c405543953Ef6359912FE3A88d6',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooCakeV2SXP-BNB',
  //   earnedTokenAddress: '0x4d92dfd7975910d63D0bfBc2A02b4BFad6E1FFC4',
  //   earnContractAddress: '0x4d92dfd7975910d63D0bfBc2A02b4BFad6E1FFC4',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'cakev2-sxp-bnb',
  //   oraclePrice: 0,
  //   depositsPaused: false,
  //   status: 'active',
  //   platform: 'PancakeSwap',
  //   assets: ['SXP', 'BNB'],
  //   addLiquidityUrl:
  //     'https://pancakeswap.finance/add/BNB/0x47BEAd2563dCBf3bF2c9407fEa4dC236fAbA485A',
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?outputCurrency=0x47BEAd2563dCBf3bF2c9407fEa4dC236fAbA485A',
  //   createdAt: 1619423687,
  // },
  // {
  //   id: 'cakev2-blk-bnb-eol',
  //   logo: 'bnb-pairs/BLK-BNB.svg',
  //   name: 'BLK-BNB LP',
  //   token: 'BLK-BNB LP2',
  //   tokenDescription: 'PancakeSwap',
  //   tokenAddress: '0x356Dd24BfF8e23BdE0430f00ad0C290E33438bD7',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooCakeV2BLK-BNB',
  //   earnedTokenAddress: '0x9Cfd2F621546575721d1f59D0075D31ed6F8b5d7',
  //   earnContractAddress: '0x9Cfd2F621546575721d1f59D0075D31ed6F8b5d7',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'cakev2-blk-bnb',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'PancakeSwap',
  //   assets: ['BLK', 'BNB'],
  //   addLiquidityUrl:
  //     'https://pancakeswap.finance/add/BNB/0x63870A18B6e42b01Ef1Ad8A2302ef50B7132054F',
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?outputCurrency=0x63870A18B6e42b01Ef1Ad8A2302ef50B7132054F',
  //   createdAt: 1619423720,
  // },
  // {
  //   id: 'cakev2-jgn-bnb-eol',
  //   logo: 'bnb-pairs/JGN-BNB.svg',
  //   name: 'JGN-BNB LP',
  //   token: 'JGN-BNB LP2',
  //   tokenDescription: 'PancakeSwap',
  //   tokenAddress: '0x7275278C94b5e20708380561C4Af98F38dDC6374',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooCakeV2JGN-BNB',
  //   earnedTokenAddress: '0x211A8D59Cc44d86e30F5b5F298253B0EB4F9bc13',
  //   earnContractAddress: '0x211A8D59Cc44d86e30F5b5F298253B0EB4F9bc13',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'cakev2-jgn-bnb',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'PancakeSwap',
  //   assets: ['JGN', 'BNB'],
  //   retireReason: 'rewards',
  //   addLiquidityUrl:
  //     'https://pancakeswap.finance/add/BNB/0xC13B7a43223BB9Bf4B69BD68Ab20ca1B79d81C75',
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?outputCurrency=0xC13B7a43223BB9Bf4B69BD68Ab20ca1B79d81C75',
  //   createdAt: 1619419712,
  // },
  // {
  //   id: 'cakev2-eps-bnb',
  //   logo: 'bnb-pairs/EPS-BNB.svg',
  //   name: 'EPS-BNB LP',
  //   token: 'EPS-BNB LP2',
  //   tokenDescription: 'PancakeSwap',
  //   tokenAddress: '0xddE420cbB3794ebD8FFC3Ac69F9c78e5d1411870',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooCakeV2EPS-BNB',
  //   earnedTokenAddress: '0xDdfE5F59C735977d33f36f9B8D06E6A212CB9094',
  //   earnContractAddress: '0xDdfE5F59C735977d33f36f9B8D06E6A212CB9094',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'cakev2-eps-bnb',
  //   oraclePrice: 0,
  //   depositsPaused: false,
  //   status: 'active',
  //   platform: 'PancakeSwap',
  //   assets: ['EPS', 'BNB'],
  //   addLiquidityUrl:
  //     'https://pancakeswap.finance/add/BNB/0xA7f552078dcC247C2684336020c03648500C6d9F',
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?outputCurrency=0xA7f552078dcC247C2684336020c03648500C6d9F',
  //   createdAt: 1619419751,
  // },
  // {
  //   id: 'cakev2-nrv-bnb-eol',
  //   logo: 'bnb-pairs/NRV-BNB.svg',
  //   name: 'NRV-BNB LP',
  //   token: 'NRV-BNB LP2',
  //   tokenDescription: 'PancakeSwap',
  //   tokenAddress: '0xE482249Cd295C0d1e9D2baAEE71e66de21024C68',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooCakeV2NRV-BNB',
  //   earnedTokenAddress: '0xD059034677f2E57489beF03f942CE6ffAccBEdD2',
  //   earnContractAddress: '0xD059034677f2E57489beF03f942CE6ffAccBEdD2',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'cakev2-nrv-bnb',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'PancakeSwap',
  //   assets: ['NRV', 'BNB'],
  //   retireReason: 'rewards',
  //   addLiquidityUrl:
  //     'https://pancakeswap.finance/add/BNB/0x42F6f551ae042cBe50C739158b4f0CAC0Edb9096',
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?outputCurrency=0x42F6f551ae042cBe50C739158b4f0CAC0Edb9096',
  //   createdAt: 1619419784,
  // },
  // {
  //   id: 'cakev2-bfi-bnb',
  //   logo: 'bnb-pairs/BFI-BNB.svg',
  //   name: 'BFI-BNB LP',
  //   token: 'BFI-BNB LP2',
  //   tokenDescription: 'PancakeSwap',
  //   tokenAddress: '0x153Ad7d25B0b810497483d0cEE8AF42Fc533FeC8',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooCakeV2BFI-BNB',
  //   earnedTokenAddress: '0x2a6163c0C608e8C2F90E94ae0D4A0df9ABc4A818',
  //   earnContractAddress: '0x2a6163c0C608e8C2F90E94ae0D4A0df9ABc4A818',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'cakev2-bfi-bnb',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'PancakeSwap',
  //   assets: ['BFI', 'BNB'],
  //   addLiquidityUrl:
  //     'https://pancakeswap.finance/add/BNB/0x81859801b01764D4f0Fa5E64729f5a6C3b91435b',
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?outputCurrency=0x81859801b01764D4f0Fa5E64729f5a6C3b91435b',
  //   createdAt: 1619419856,
  // },
  // {
  //   id: 'cakev2-bdo-bnb-eol',
  //   logo: 'bnb-pairs/BDO-BNB.svg',
  //   name: 'BDO-BNB LP',
  //   token: 'BDO-BNB LP2',
  //   tokenDescription: 'PancakeSwap',
  //   tokenAddress: '0x4288706624e3dD839b069216eB03B8B9819C10d2',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooCakeV2BDO-BNB',
  //   earnedTokenAddress: '0x0dc321E38BfF5693e6c003Ab71eD85693919B04e',
  //   earnContractAddress: '0x0dc321E38BfF5693e6c003Ab71eD85693919B04e',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'cakev2-bdo-bnb',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'PancakeSwap',
  //   assets: ['BDO', 'BNB'],
  //   addLiquidityUrl:
  //     'https://pancakeswap.finance/add/BNB/0x190b589cf9fb8ddeabbfeae36a813ffb2a702454',
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?outputCurrency=0x190b589cf9fb8ddeabbfeae36a813ffb2a702454',
  //   createdAt: 1619419892,
  // },
  // {
  //   id: 'cakev2-for-busd-eol',
  //   logo: 'busd-pairs/FOR-BUSD.svg',
  //   name: 'FOR-BUSD LP',
  //   token: 'FOR-BUSD LP2',
  //   tokenDescription: 'PancakeSwap',
  //   tokenAddress: '0xE60B4e87645093A42fa9dcC5d0C8Df6E67f1f9d2',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooCakeV2FOR-BUSD',
  //   earnedTokenAddress: '0x010ef61eb2240154C8779201be0914a8AaDcA828',
  //   earnContractAddress: '0x010ef61eb2240154C8779201be0914a8AaDcA828',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'cakev2-for-busd',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   retireReason: 'rewards',
  //   platform: 'PancakeSwap',
  //   assets: ['FOR', 'BUSD'],
  //   addLiquidityUrl:
  //     'https://pancakeswap.finance/add/0x658A109C5900BC6d2357c87549B651670E5b0539/0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?outputCurrency=0x658A109C5900BC6d2357c87549B651670E5b0539',
  //   createdAt: 1619418449,
  // },
  // {
  //   id: 'cakev2-vai-busd-eol',
  //   logo: 'busd-pairs/VAI-BUSD.svg',
  //   name: 'VAI-BUSD LP',
  //   token: 'VAI-BUSD LP2',
  //   tokenDescription: 'PancakeSwap',
  //   tokenAddress: '0x133ee93FE93320e1182923E1a640912eDE17C90C',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooCakeV2VAI-BUSD',
  //   earnedTokenAddress: '0xf5eA38B6b9644224dA1aECbC1219e8543c0689b2',
  //   earnContractAddress: '0xf5eA38B6b9644224dA1aECbC1219e8543c0689b2',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'cakev2-vai-busd',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'PancakeSwap',
  //   assets: ['VAI', 'BUSD'],
  //   addLiquidityUrl:
  //     'https://pancakeswap.finance/add/0x4bd17003473389a42daf6a0a729f6fdb328bbbd7/0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?inputCurrency=0x4bd17003473389a42daf6a0a729f6fdb328bbbd7&outputCurrency=0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
  //   createdAt: 1619418482,
  //   retiredReason: 'tvl',
  // },
  // {
  //   id: 'cakev2-usdt-busd',
  //   logo: 'busd-pairs/USDT-BUSD.svg',
  //   name: 'USDT-BUSD LP',
  //   token: 'USDT-BUSD LP2',
  //   tokenDescription: 'PancakeSwap',
  //   tokenAddress: '0x7EFaEf62fDdCCa950418312c6C91Aef321375A00',
  //   tokenDecimals: 18,
  //   earnedToken: 'mooCakeV2USDT-BUSD',
  //   earnedTokenAddress: '0x4c66ef364F54FCdD540ad29E075bc23cFe9F9FF6',
  //   earnContractAddress: '0x4c66ef364F54FCdD540ad29E075bc23cFe9F9FF6',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'cakev2-usdt-busd',
  //   oraclePrice: 0,
  //   depositsPaused: false,
  //   status: 'active',
  //   platform: 'PancakeSwap',
  //   assets: ['USDT', 'BUSD'],
  //   addLiquidityUrl:
  //     'https://pancakeswap.finance/add/0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56/0x55d398326f99059ff775485246999027b3197955',
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?outputCurrency=0x55d398326f99059ff775485246999027b3197955',
  //   createdAt: 1619426388,
  // },
  // {
  //   id: 'cakev2-alpha-bnb',
  //   logo: 'bnb-pairs/ALPHA-BNB.svg',
  //   name: 'ALPHA-BNB LP',
  //   token: 'ALPHA-BNB LP2',
  //   tokenDescription: 'PancakeSwap',
  //   tokenAddress: '0xACF47CBEaab5c8A6Ee99263cfE43995f89fB3206',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooCakeV2ALPHA-BNB',
  //   earnedTokenAddress: '0xfcA433b1c071737F92B76234984aBA10D04De57E',
  //   earnContractAddress: '0xfcA433b1c071737F92B76234984aBA10D04De57E',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'cakev2-alpha-bnb',
  //   oraclePrice: 0,
  //   depositsPaused: false,
  //   status: 'active',
  //   platform: 'PancakeSwap',
  //   assets: ['ALPHA', 'BNB'],
  //   addLiquidityUrl:
  //     'https://pancakeswap.finance/add/BNB/0xa1faa113cbe53436df28ff0aee54275c13b40975',
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?outputCurrency=0xa1faa113cbe53436df28ff0aee54275c13b40975',
  //   createdAt: 1619415302,
  // },
  // {
  //   id: 'cakev2-inj-bnb',
  //   logo: 'bnb-pairs/INJ-BNB.svg',
  //   name: 'INJ-BNB LP',
  //   token: 'INJ-BNB LP2',
  //   tokenDescription: 'PancakeSwap',
  //   tokenAddress: '0x1BdCebcA3b93af70b58C41272AEa2231754B23ca',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooCakeV2INJ-BNB',
  //   earnedTokenAddress: '0xb0602F26Fb2b7F8bca2d9aDB3FC3EF8619502097',
  //   earnContractAddress: '0xb0602F26Fb2b7F8bca2d9aDB3FC3EF8619502097',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'cakev2-inj-bnb',
  //   oraclePrice: 0,
  //   depositsPaused: false,
  //   status: 'active',
  //   platform: 'PancakeSwap',
  //   assets: ['INJ', 'BNB'],
  //   addLiquidityUrl:
  //     'https://pancakeswap.finance/add/BNB/0xa2B726B1145A4773F68593CF171187d8EBe4d495',
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?outputCurrency=0xa2B726B1145A4773F68593CF171187d8EBe4d495',
  //   createdAt: 1619415335,
  // },
  // {
  //   id: 'cakev2-twt-bnb',
  //   logo: 'bnb-pairs/TWT-BNB.svg',
  //   name: 'TWT-BNB LP',
  //   token: 'TWT-BNB LP2',
  //   tokenDescription: 'PancakeSwap',
  //   tokenAddress: '0x3DcB1787a95D2ea0Eb7d00887704EeBF0D79bb13',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooCakeV2TWT-BNB',
  //   earnedTokenAddress: '0x9151B2E9ad4B4222d67b8467BBcf1f37597b9E7B',
  //   earnContractAddress: '0x9151B2E9ad4B4222d67b8467BBcf1f37597b9E7B',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'cakev2-twt-bnb',
  //   oraclePrice: 0,
  //   depositsPaused: false,
  //   status: 'active',
  //   platform: 'PancakeSwap',
  //   assets: ['TWT', 'BNB'],
  //   addLiquidityUrl:
  //     'https://pancakeswap.finance/add/BNB/0x4b0f1812e5df2a09796481ff14017e6005508003',
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?outputCurrency=0x4b0f1812e5df2a09796481ff14017e6005508003',
  //   createdAt: 1619415368,
  // },
  // {
  //   id: 'cakev2-xvs-bnb',
  //   logo: 'bnb-pairs/XVS-BNB.svg',
  //   name: 'XVS-BNB LP',
  //   token: 'XVS-BNB LP2',
  //   tokenDescription: 'PancakeSwap',
  //   tokenAddress: '0x7EB5D86FD78f3852a3e0e064f2842d45a3dB6EA2',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooCakeV2XVS-BNB',
  //   earnedTokenAddress: '0xa2f05EA4Af928BA34d66E6f69343a6703744Caba',
  //   earnContractAddress: '0xa2f05EA4Af928BA34d66E6f69343a6703744Caba',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'cakev2-xvs-bnb',
  //   oraclePrice: 0,
  //   depositsPaused: false,
  //   status: 'active',
  //   platform: 'PancakeSwap',
  //   assets: ['XVS', 'BNB'],
  //   addLiquidityUrl:
  //     'https://pancakeswap.finance/add/BNB/0xcf6bb5389c92bdda8a3747ddb454cb7a64626c63',
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?outputCurrency=0xcf6bb5389c92bdda8a3747ddb454cb7a64626c63',
  //   createdAt: 1619415401,
  // },
  // {
  //   id: 'cakev2-reef-bnb-eol',
  //   logo: 'bnb-pairs/REEF-BNB.svg',
  //   name: 'REEF-BNB LP',
  //   token: 'REEF-BNB LP2',
  //   tokenDescription: 'PancakeSwap',
  //   tokenAddress: '0xd63b5CecB1f40d626307B92706Df357709D05827',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooCakeV2REEF-BNB',
  //   earnedTokenAddress: '0x8ec78a292e5E1Bce2ed917eD8b02cf9E93a78CdE',
  //   earnContractAddress: '0x8ec78a292e5E1Bce2ed917eD8b02cf9E93a78CdE',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'cakev2-reef-bnb',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'PancakeSwap',
  //   assets: ['REEF', 'BNB'],
  //   retireReason: 'rewards',
  //   addLiquidityUrl:
  //     'https://pancakeswap.finance/add/BNB/0xf21768ccbc73ea5b6fd3c687208a7c2def2d966e',
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?outputCurrency=0xf21768ccbc73ea5b6fd3c687208a7c2def2d966e',
  //   createdAt: 1619415119,
  // },
  // {
  //   id: 'cakev2-band-bnb-eol',
  //   logo: 'bnb-pairs/BAND-BNB.svg',
  //   name: 'BAND-BNB LP',
  //   token: 'BAND-BNB LP2',
  //   tokenDescription: 'PancakeSwap',
  //   tokenAddress: '0x168B273278F3A8d302De5E879aA30690B7E6c28f',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooCakeV2BAND-BNB',
  //   earnedTokenAddress: '0x9e6E0D9c43dDaC58710BeE30B01a5117f8Ba69bC',
  //   earnContractAddress: '0x9e6E0D9c43dDaC58710BeE30B01a5117f8Ba69bC',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'cakev2-band-bnb',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'PancakeSwap',
  //   assets: ['BAND', 'BNB'],
  //   retireReason: 'rewards',
  //   addLiquidityUrl:
  //     'https://pancakeswap.finance/add/BNB/0xad6caeb32cd2c308980a548bd0bc5aa4306c6c18',
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?outputCurrency=0xad6caeb32cd2c308980a548bd0bc5aa4306c6c18',
  //   createdAt: 1619415218,
  // },
  // {
  //   id: 'cakev2-atom-bnb-eol',
  //   logo: 'bnb-pairs/ATOM-BNB.svg',
  //   name: 'ATOM-BNB LP',
  //   token: 'ATOM-BNB LP2',
  //   tokenDescription: 'PancakeSwap',
  //   tokenAddress: '0x468b2DC8DC75990eE3E9dc0648965Ad6294E7914',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooCakeV2ATOM-BNB',
  //   earnedTokenAddress: '0x8815d484A3bf89b7e996f846ddAb6DAE1C4BEA3A',
  //   earnContractAddress: '0x8815d484A3bf89b7e996f846ddAb6DAE1C4BEA3A',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'cakev2-atom-bnb',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'PancakeSwap',
  //   assets: ['ATOM', 'BNB'],
  //   retireReason: 'rewards',
  //   addLiquidityUrl:
  //     'https://pancakeswap.finance/add/BNB/0x0eb3a705fc54725037cc9e008bdede697f62f335',
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?outputCurrency=0x0eb3a705fc54725037cc9e008bdede697f62f335',
  //   createdAt: 1619415266,
  // },
  // {
  //   id: 'cakev2-hget-bnb-eol',
  //   logo: 'bnb-pairs/HGET-BNB.svg',
  //   name: 'HGET-BNB LP',
  //   token: 'HGET-BNB LP2',
  //   tokenDescription: 'PancakeSwap',
  //   tokenAddress: '0xF74ee1e10e097dc326a2ad004F9Cc95CB71088d3',
  //   tokenDecimals: 18,
  //   earnedToken: 'mooCakeV2HGET-BNB',
  //   earnedTokenAddress: '0xdA7439450510FB56052681eb1A3FBb9C9880f80d',
  //   earnContractAddress: '0xdA7439450510FB56052681eb1A3FBb9C9880f80d',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'cakev2-hget-bnb',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   retireReason: 'rewards',
  //   platform: 'PancakeSwap',
  //   assets: ['HGET', 'BNB'],
  //   addLiquidityUrl:
  //     'https://pancakeswap.finance/add/BNB/0xc7d8d35eba58a0935ff2d5a33df105dd9f071731',
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?outputCurrency=0xc7d8d35eba58a0935ff2d5a33df105dd9f071731',
  //   createdAt: 1619388632,
  // },
  // {
  //   id: 'cakev2-cgg-bnb-eol',
  //   logo: 'bnb-pairs/CGG-BNB.svg',
  //   name: 'CGG-BNB LP',
  //   token: 'CGG-BNB LP2',
  //   tokenDescription: 'PancakeSwap',
  //   tokenAddress: '0x0604471c532F9fEBAD3E37190B667f44BD0894b3',
  //   tokenDecimals: 18,
  //   earnedToken: 'mooCakeV2CGG-BNB',
  //   earnedTokenAddress: '0x7697B2F43E73bC3292c82d1087BB7c6aEC931fED',
  //   earnContractAddress: '0x7697B2F43E73bC3292c82d1087BB7c6aEC931fED',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'cakev2-cgg-bnb',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   retireReason: 'rewards',
  //   platform: 'PancakeSwap',
  //   assets: ['CGG', 'BNB'],
  //   addLiquidityUrl:
  //     'https://pancakeswap.finance/add/BNB/0x1613957159e9b0ac6c80e824f7eea748a32a0ae2',
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?outputCurrency=0x1613957159e9b0ac6c80e824f7eea748a32a0ae2',
  //   createdAt: 1619388224,
  // },
  // {
  //   id: 'cakev2-xed-bnb-eol',
  //   logo: 'bnb-pairs/XED-BNB.svg',
  //   name: 'XED-BNB LP',
  //   token: 'XED-BNB LP2',
  //   tokenDescription: 'PancakeSwap',
  //   tokenAddress: '0xa7A0b605343dF36B748FF4B5f7578b3F2D0651CE',
  //   tokenDecimals: 18,
  //   earnedToken: 'mooCakeV2XED-BNB',
  //   earnedTokenAddress: '0xDAa93B916ca2A3608B912F8514c38678d7f9E568',
  //   earnContractAddress: '0xDAa93B916ca2A3608B912F8514c38678d7f9E568',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'cakev2-xed-bnb',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   retireReason: 'rewards',
  //   platform: 'PancakeSwap',
  //   assets: ['XED', 'BNB'],
  //   addLiquidityUrl:
  //     'https://pancakeswap.finance/add/BNB/0x5621b5a3f4a8008c4ccdd1b942b121c8b1944f1f',
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?outputCurrency=0x5621b5a3f4a8008c4ccdd1b942b121c8b1944f1f',
  //   createdAt: 1619388191,
  // },
  // {
  //   id: 'cakev2-link-bnb',
  //   logo: 'bnb-pairs/LINK-BNB.svg',
  //   name: 'LINK-BNB LP',
  //   token: 'LINK-BNB LP2',
  //   tokenDescription: 'PancakeSwap',
  //   tokenAddress: '0x824eb9faDFb377394430d2744fa7C42916DE3eCe',
  //   tokenDecimals: 18,
  //   earnedToken: 'mooCakeV2LINK-BNB',
  //   earnedTokenAddress: '0xaB5EA0Fee3AEdE95D9747f41109f0b9F3b8f07E7',
  //   earnContractAddress: '0xaB5EA0Fee3AEdE95D9747f41109f0b9F3b8f07E7',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'cakev2-link-bnb',
  //   oraclePrice: 0,
  //   depositsPaused: false,
  //   status: 'active',
  //   platform: 'PancakeSwap',
  //   assets: ['LINK', 'BNB'],
  //   addLiquidityUrl:
  //     'https://pancakeswap.finance/add/BNB/0xf8a0bf9cf54bb92f17374d9e9a321e6a111a51bd',
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?outputCurrency=0xf8a0bf9cf54bb92f17374d9e9a321e6a111a51bd',
  //   createdAt: 1619388074,
  // },
  // {
  //   id: 'cakev2-usdt-bnb',
  //   logo: 'bnb-pairs/USDT-BNB.svg',
  //   name: 'USDT-BNB LP',
  //   token: 'USDT-BNB LP2',
  //   tokenDescription: 'PancakeSwap',
  //   tokenAddress: '0x16b9a82891338f9bA80E2D6970FddA79D1eb0daE',
  //   tokenDecimals: 18,
  //   earnedToken: 'mooCakeV2USDT-BNB',
  //   earnedTokenAddress: '0x8d98855595E7971abE44bAdf1574fd126B286de4',
  //   earnContractAddress: '0x8d98855595E7971abE44bAdf1574fd126B286de4',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'cakev2-usdt-bnb',
  //   oraclePrice: 0,
  //   depositsPaused: false,
  //   status: 'active',
  //   platform: 'PancakeSwap',
  //   assets: ['USDT', 'BNB'],
  //   addLiquidityUrl:
  //     'https://pancakeswap.finance/add/BNB/0x55d398326f99059ff775485246999027b3197955',
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?outputCurrency=0x55d398326f99059ff775485246999027b3197955',
  //   createdAt: 1619388089,
  // },
  // {
  //   id: 'cakev2-busd-bnb',
  //   logo: 'bnb-pairs/BUSD-BNB.svg',
  //   name: 'BUSD-BNB LP',
  //   token: 'BUSD-BNB LP2',
  //   tokenDescription: 'PancakeSwap',
  //   tokenAddress: '0x58F876857a02D6762E0101bb5C46A8c1ED44Dc16',
  //   tokenDecimals: 18,
  //   earnedToken: 'mooCakeV2BNB-BUSD',
  //   earnedTokenAddress: '0xAd61143796D90FD5A61d89D63a546C7dB0a70475',
  //   earnContractAddress: '0xAd61143796D90FD5A61d89D63a546C7dB0a70475',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'cakev2-busd-bnb',
  //   oraclePrice: 0,
  //   depositsPaused: false,
  //   status: 'active',
  //   platform: 'PancakeSwap',
  //   assets: ['BUSD', 'BNB'],
  //   addLiquidityUrl:
  //     'https://pancakeswap.finance/add/BNB/0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?inputCurrency=0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
  //   createdAt: 1619388116,
  // },
  // {
  //   id: 'cakev2-eth-bnb',
  //   logo: 'bnb-pairs/ETH-BNB.svg',
  //   name: 'ETH-BNB LP',
  //   token: 'ETH-BNB LP2',
  //   tokenDescription: 'PancakeSwap',
  //   tokenAddress: '0x74E4716E431f45807DCF19f284c7aA99F18a4fbc',
  //   tokenDecimals: 18,
  //   earnedToken: 'mooCakeV2ETH-BNB',
  //   earnedTokenAddress: '0x0eb78598851D08218d54fCe965ee2bf29C288fac',
  //   earnContractAddress: '0x0eb78598851D08218d54fCe965ee2bf29C288fac',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'cakev2-eth-bnb',
  //   oraclePrice: 0,
  //   depositsPaused: false,
  //   status: 'active',
  //   platform: 'PancakeSwap',
  //   assets: ['ETH', 'BNB'],
  //   addLiquidityUrl:
  //     'https://pancakeswap.finance/add/BNB/0x2170ed0880ac9a755fd29b2688956bd959f933f8',
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?outputCurrency=0x2170ed0880ac9a755fd29b2688956bd959f933f8',
  //   createdAt: 1619388146,
  // },
  // {
  //   id: 'cakev2-btcb-bnb',
  //   logo: 'bnb-pairs/BTCB-BNB.svg',
  //   name: 'BTCB-BNB LP',
  //   token: 'BTCB-BNB LP2',
  //   tokenDescription: 'PancakeSwap',
  //   tokenAddress: '0x61EB789d75A95CAa3fF50ed7E47b96c132fEc082',
  //   tokenDecimals: 18,
  //   earnedToken: 'mooCakeV2BNB-BTCB',
  //   earnedTokenAddress: '0xafE4f29578FbfE7Be32B836CBEb81daB6574cC70',
  //   earnContractAddress: '0xafE4f29578FbfE7Be32B836CBEb81daB6574cC70',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'cakev2-btcb-bnb',
  //   oraclePrice: 0,
  //   depositsPaused: false,
  //   status: 'active',
  //   platform: 'PancakeSwap',
  //   assets: ['BTCB', 'BNB'],
  //   addLiquidityUrl:
  //     'https://pancakeswap.finance/add/BNB/0x7130d2a12b9bcbfae4f2634d864a1ee1ce3ead9c',
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?outputCurrency=0x7130d2a12b9bcbfae4f2634d864a1ee1ce3ead9c',
  //   createdAt: 1619388167,
  // },
  // {
  //   id: 'cakev2-hakka-busd-eol',
  //   logo: 'busd-pairs/HAKKA-BUSD.svg',
  //   name: 'HAKKA-BUSD LP',
  //   token: 'HAKKA-BUSD LP2',
  //   tokenDescription: 'PancakeSwap',
  //   tokenAddress: '0x2C624C9Ecf16Cb81aB85cC2C0B0c5e12A09AFDa6',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooCakeV2HAKKA-BUSD',
  //   earnedTokenAddress: '0xF79BF908d0e6d8E7054375CD80dD33424B1980bf',
  //   earnContractAddress: '0xF79BF908d0e6d8E7054375CD80dD33424B1980bf',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'cakev2-hakka-busd',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   retireReason: 'rewards',
  //   platform: 'PancakeSwap',
  //   assets: ['HAKKA', 'BUSD'],
  //   addLiquidityUrl:
  //     'https://pancakeswap.finance/add/0xe9e7cea3dedca5984780bafc599bd69add087d56/0x1d1eb8e8293222e1a29d2c0e4ce6c0acfd89aaac',
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?inputCurrency=0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56&outputCurrency=0x1d1eb8e8293222e1a29d2c0e4ce6c0acfd89aaac',
  //   createdAt: 1619369612,
  // },
  // {
  //   id: 'cakev2-hoo-busd-eol',
  //   logo: 'busd-pairs/HOO-BUSD.svg',
  //   name: 'HOO-BUSD LP',
  //   token: 'HOO-BUSD LP2',
  //   tokenDescription: 'PancakeSwap',
  //   tokenAddress: '0x3e42C1f7239231E3752B507764445dd8e6A570d5',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooCakeV2HOO-BUSD',
  //   earnedTokenAddress: '0x36eE7e0AA45eb94bb0B0FC9B9f5bdc66819761A5',
  //   earnContractAddress: '0x36eE7e0AA45eb94bb0B0FC9B9f5bdc66819761A5',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'cakev2-hoo-busd',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   retireReason: 'rewards',
  //   platform: 'PancakeSwap',
  //   assets: ['HOO', 'BUSD'],
  //   addLiquidityUrl:
  //     'https://pancakeswap.finance/add/0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56/0xE1d1F66215998786110Ba0102ef558b22224C016',
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?inputCurrency=0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56&outputCurrency=0xE1d1F66215998786110Ba0102ef558b22224C016',
  //   createdAt: 1619369504,
  // },
  // {
  //   id: 'cakev2-txl-busd',
  //   logo: 'busd-pairs/TXL-BUSD.svg',
  //   name: 'TXL-BUSD LP',
  //   token: 'TXL-BUSD LP2',
  //   tokenDescription: 'PancakeSwap',
  //   tokenAddress: '0x1434BB50196A0C7eA825940b1DFd8aAd25d79817',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooCakeV2TXL-BUSD',
  //   earnedTokenAddress: '0x9Bc299505Ce14FBa3bA3F1357CF3AA48ea01A2b9',
  //   earnContractAddress: '0x9Bc299505Ce14FBa3bA3F1357CF3AA48ea01A2b9',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'cakev2-txl-busd',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'PancakeSwap',
  //   assets: ['TXL', 'BUSD'],
  //   addLiquidityUrl:
  //     'https://pancakeswap.finance/add/0xe9e7cea3dedca5984780bafc599bd69add087d56/0x1ffd0b47127fdd4097e54521c9e2c7f0d66aafc5',
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?inputCurrency=0x1ffd0b47127fdd4097e54521c9e2c7f0d66aafc5&outputCurrency=0xe9e7cea3dedca5984780bafc599bd69add087d56',
  //   createdAt: 1619369534,
  // },
  // {
  //   id: 'cakev2-ust-busd',
  //   logo: 'busd-pairs/UST-BUSD.svg',
  //   name: 'UST-BUSD LP',
  //   token: 'UST-BUSD LP2',
  //   tokenDescription: 'PancakeSwap',
  //   tokenAddress: '0x05faf555522Fa3F93959F86B41A3808666093210',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooCakeV2UST-BUSD',
  //   earnedTokenAddress: '0x76c8b991F99a77B270D5f6529855f3612891e70f',
  //   earnContractAddress: '0x76c8b991F99a77B270D5f6529855f3612891e70f',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'cakev2-ust-busd',
  //   oraclePrice: 0,
  //   depositsPaused: false,
  //   status: 'active',
  //   platform: 'PancakeSwap',
  //   assets: ['UST', 'BUSD'],
  //   risks: [
  //     'COMPLEXITY_LOW',
  //     'BATTLE_TESTED',
  //     'MCAP_LARGE',
  //     'AUDIT',
  //     'CONTRACTS_VERIFIED',
  //     'ALGO_STABLE',
  //   ],
  //   addLiquidityUrl:
  //     'https://pancakeswap.finance/add/0x23396cF899Ca06c4472205fC903bDB4de249D6fC/0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?inputCurrency=0x23396cF899Ca06c4472205fC903bDB4de249D6fC&outputCurrency=0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
  //   createdAt: 1619369585,
  // },
  // {
  //   id: 'cakev2-mnflx-ust-eol',
  //   logo: 'ust-pairs/MNFLX-UST.svg',
  //   name: 'MNFLX-UST LP',
  //   token: 'MNFLX-UST LP2',
  //   tokenDescription: 'PancakeSwap',
  //   tokenAddress: '0x91417426C3FEaA3Ca795921eB9FdD9715ad92537',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooCakeV2mNFLX-UST',
  //   earnedTokenAddress: '0xE2409314Df4708230999F4bE4dE4Ac3cF3d0B08c',
  //   earnContractAddress: '0xE2409314Df4708230999F4bE4dE4Ac3cF3d0B08c',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'cakev2-mnflx-ust',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'PancakeSwap',
  //   assets: ['MNFLX', 'UST'],
  //   removeLiquidityUrl:
  //     'https://pancakeswap.finance/remove/0x23396cf899ca06c4472205fc903bdb4de249d6fc/0xa04F060077D90Fe2647B61e4dA4aD1F97d6649dc',
  //   createdAt: 1619393616,
  // },
  // {
  //   id: 'cakev2-mir-ust-eol',
  //   logo: 'ust-pairs/MIR-UST.svg',
  //   name: 'MIR-UST LP',
  //   token: 'MIR-UST LP2',
  //   tokenDescription: 'PancakeSwap',
  //   tokenAddress: '0x89666d026696660e93Bf6edf57B71A68615768B7',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooCakeV2MIR-UST',
  //   earnedTokenAddress: '0x268e83D800c22ED8F00C4a8eFb9c3d44Ad61CB2e',
  //   earnContractAddress: '0x268e83D800c22ED8F00C4a8eFb9c3d44Ad61CB2e',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'cakev2-mir-ust',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   retireReason: 'rewards',
  //   platform: 'PancakeSwap',
  //   assets: ['MIR', 'UST'],
  //   addLiquidityUrl:
  //     'https://pancakeswap.finance/add/0x23396cF899Ca06c4472205fC903bDB4de249D6fC/0x5B6DcF557E2aBE2323c48445E8CC948910d8c2c9',
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?outputCurrency=0x5B6DcF557E2aBE2323c48445E8CC948910d8c2c9',
  //   createdAt: 1619367089,
  // },
  // {
  //   id: 'cakev2-tbtc-btcb-eol',
  //   logo: 'uncategorized/TBTC-BTCB.svg',
  //   name: 'tBTC-BTCB LP',
  //   token: 'tBTC-BTCB LP2',
  //   tokenDescription: 'PancakeSwap',
  //   tokenAddress: '0x8046fa66753928F35f7Db23ae0188ee6743C2FBA',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooCakeV2tBTC-BTCB',
  //   earnedTokenAddress: '0xf03B5870bb8EFdcabAd45f2283D64A7fbCdb2B0E',
  //   earnContractAddress: '0xf03B5870bb8EFdcabAd45f2283D64A7fbCdb2B0E',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'cakev2-tbtc-btcb',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'PancakeSwap',
  //   assets: ['tBTC', 'BTCB'],
  //   addLiquidityUrl:
  //     'https://pancakeswap.finance/add/0x7130d2a12b9bcbfae4f2634d864a1ee1ce3ead9c/0x2cD1075682b0FCCaADd0Ca629e138E64015Ba11c',
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?inputCurrency=0x2cD1075682b0FCCaADd0Ca629e138E64015Ba11c&outputCurrency=0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9',
  //   createdAt: 1619361616,
  // },
  // {
  //   id: 'cakev2-bbadger-btcb-eol',
  //   logo: 'uncategorized/BADGER-BTCB.svg',
  //   name: 'bBADGER-BTCB LP',
  //   token: 'bBADGER-BTCB LP2',
  //   tokenDescription: 'PancakeSwap',
  //   tokenAddress: '0x5A58609dA96469E9dEf3fE344bC39B00d18eb9A5',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooCakeV2bBADGER-BTCB',
  //   earnedTokenAddress: '0xC4fAB5E28C9f71D59B9d08BCA51FBe283bf4542F',
  //   earnContractAddress: '0xC4fAB5E28C9f71D59B9d08BCA51FBe283bf4542F',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'cakev2-bbadger-btcb',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   retireReason: 'rewards',
  //   platform: 'PancakeSwap',
  //   assets: ['bBADGER', 'BTCB'],
  //   addLiquidityUrl:
  //     'https://pancakeswap.finance/add/0x7130d2a12b9bcbfae4f2634d864a1ee1ce3ead9c/0x1f7216fdb338247512ec99715587bb97bbf96eae',
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?inputCurrency=0x1f7216fdb338247512ec99715587bb97bbf96eae&outputCurrency=0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9',
  //   createdAt: 1619361721,
  // },
  // {
  //   id: 'cakev2-bdigg-btcb',
  //   logo: 'uncategorized/BDIGG-BTCB.svg',
  //   name: 'bDIGG-BTCB LP',
  //   token: 'bDIGG-BTCB LP2',
  //   tokenDescription: 'PancakeSwap',
  //   tokenAddress: '0x81d776C90c89B8d51E9497D58338933127e2fA80',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooCakeV2bDIGG-BTCB',
  //   earnedTokenAddress: '0xE32e76e79E94f4C9091F9a4120A72888d0DFf3C6',
  //   earnContractAddress: '0xE32e76e79E94f4C9091F9a4120A72888d0DFf3C6',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'cakev2-bdigg-btcb',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'PancakeSwap',
  //   assets: ['bDIGG', 'BTCB'],
  //   addLiquidityUrl:
  //     'https://pancakeswap.finance/add/0x5986D5c77c65e5801a5cAa4fAE80089f870A71dA/0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c',
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?inputCurrency=0x1f7216fdb338247512ec99715587bb97bbf96eae&outputCurrency=0x5986d5c77c65e5801a5caa4fae80089f870a71da',
  //   createdAt: 1619361670,
  // },
  // {
  //   id: 'cakev2-ramp-busd',
  //   logo: 'busd-pairs/RAMP-BUSD.svg',
  //   name: 'RAMP-BUSD LP',
  //   token: 'RAMP-BUSD LP2',
  //   tokenDescription: 'PancakeSwap',
  //   tokenAddress: '0xE834bf723f5bDff34a5D1129F3c31Ea4787Bc76a',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooCakeV2RAMP-BUSD',
  //   earnedTokenAddress: '0x37b67318d7746faD405AC139847b96718D37b8C4',
  //   earnContractAddress: '0x37b67318d7746faD405AC139847b96718D37b8C4',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'cakev2-ramp-busd',
  //   oraclePrice: 0,
  //   depositsPaused: false,
  //   status: 'active',
  //   platform: 'PancakeSwap',
  //   assets: ['RAMP', 'BUSD'],
  //   addLiquidityUrl:
  //     'https://pancakeswap.finance/add/0xe9e7cea3dedca5984780bafc599bd69add087d56/0x8519ea49c997f50ceffa444d240fb655e89248aa',
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?inputCurrency=0x8519ea49c997f50ceffa444d240fb655e89248aa&outputCurrency=0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
  //   createdAt: 1619356981,
  // },
  // {
  //   id: 'cakev2-dexe-busd-eol',
  //   logo: 'busd-pairs/DEXE-BUSD.svg',
  //   name: 'DEXE-BUSD LP',
  //   token: 'DEXE-BUSD LP2',
  //   tokenDescription: 'PancakeSwap',
  //   tokenAddress: '0x3578B1f9BCE98D2F4D293b422d8850fdf48B1f21',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooCakeV2DEXE-BUSD',
  //   earnedTokenAddress: '0x257d62DB67418735f7239c6F27f4d7b426067205',
  //   earnContractAddress: '0x257d62DB67418735f7239c6F27f4d7b426067205',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'cakev2-dexe-busd',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'PancakeSwap',
  //   assets: ['DEXE', 'BUSD'],
  //   retireReason: 'rewards',
  //   addLiquidityUrl:
  //     'https://pancakeswap.finance/add/0xe9e7cea3dedca5984780bafc599bd69add087d56/0x039cb485212f996a9dbb85a9a75d898f94d38da6',
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?inputCurrency=0x039cb485212f996a9dbb85a9a75d898f94d38da6&outputCurrency=0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
  //   createdAt: 1619356942,
  // },
  // {
  //   id: 'cakev2-tpt-busd-eol',
  //   logo: 'busd-pairs/TPT-BUSD.svg',
  //   name: 'TPT-BUSD LP',
  //   token: 'TPT-BUSD LP2',
  //   tokenDescription: 'PancakeSwap',
  //   tokenAddress: '0x6D0c831254221ba121fB53fb44Df289A6558867d',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooCakeV2TPT-BUSD',
  //   earnedTokenAddress: '0xd6D3FEACC85E5708e6344548a3415A5f484889c5',
  //   earnContractAddress: '0xd6D3FEACC85E5708e6344548a3415A5f484889c5',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'cakev2-tpt-busd',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   retireReason: 'rewards',
  //   platform: 'PancakeSwap',
  //   assets: ['TPT', 'BUSD'],
  //   addLiquidityUrl:
  //     'https://pancakeswap.finance/add/0xe9e7cea3dedca5984780bafc599bd69add087d56/0xeca41281c24451168a37211f0bc2b8645af45092',
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?inputCurrency=0xeca41281c24451168a37211f0bc2b8645af45092&outputCurrency=0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
  //   createdAt: 1619356909,
  // },
  // {
  //   id: 'cakev2-xmark-busd-eol',
  //   logo: 'busd-pairs/XMARK-BUSD.svg',
  //   name: 'XMARK-BUSD LP',
  //   token: 'XMARK-BUSD LP2',
  //   tokenDescription: 'PancakeSwap',
  //   tokenAddress: '0xAa40f1AC20AAFcFEE8595Da606D78C503C7e70A3',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooCakeV2XMARK-BUSD',
  //   earnedTokenAddress: '0xEEB6F2857746a50554a58E00D55b4E54657aba83',
  //   earnContractAddress: '0xEEB6F2857746a50554a58E00D55b4E54657aba83',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'cakev2-xmark-busd',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   retireReason: 'rewards',
  //   platform: 'PancakeSwap',
  //   assets: ['XMARK', 'BUSD'],
  //   addLiquidityUrl:
  //     'https://pancakeswap.finance/add/0xe9e7cea3dedca5984780bafc599bd69add087d56/0x26a5dfab467d4f58fb266648cae769503cec9580',
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?inputCurrency=0x26a5dfab467d4f58fb266648cae769503cec9580&outputCurrency=0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
  //   createdAt: 1619356876,
  // },
  // {
  //   id: 'cakev2-iotx-busd',
  //   logo: 'busd-pairs/IOTX-BUSD.svg',
  //   name: 'IOTX-BUSD LP',
  //   token: 'IOTX-BUSD LP2',
  //   tokenDescription: 'PancakeSwap',
  //   tokenAddress: '0xc13aA76AAc067c86aE38028019F414D731b3D86A',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooCakeV2IOTX-BUSD',
  //   earnedTokenAddress: '0x07fFC2258c99e6667235fEAa90De35A0a50CFBFd',
  //   earnContractAddress: '0x07fFC2258c99e6667235fEAa90De35A0a50CFBFd',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'cakev2-iotx-busd',
  //   oraclePrice: 0,
  //   depositsPaused: false,
  //   status: 'active',
  //   platform: 'PancakeSwap',
  //   assets: ['IOTX', 'BUSD'],
  //   addLiquidityUrl:
  //     'https://pancakeswap.finance/add/0xe9e7cea3dedca5984780bafc599bd69add087d56/0x9678e42cebeb63f23197d726b29b1cb20d0064e5',
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?inputCurrency=0x9678e42cebeb63f23197d726b29b1cb20d0064e5&outputCurrency=0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
  //   createdAt: 1619356846,
  // },
  // {
  //   id: 'cakev2-lina-busd-eol',
  //   logo: 'busd-pairs/LINA-BUSD.svg',
  //   name: 'LINA-BUSD LP',
  //   token: 'LINA-BUSD LP2',
  //   tokenDescription: 'PancakeSwap',
  //   tokenAddress: '0xC5768c5371568Cf1114cddD52CAeD163A42626Ed',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooCakeV2LINA-BUSD',
  //   earnedTokenAddress: '0x40d29c06EFB7Da61008fB65CB113786f076058a4',
  //   earnContractAddress: '0x40d29c06EFB7Da61008fB65CB113786f076058a4',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'cakev2-lina-busd',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   retireReason: 'tvl',
  //   platform: 'PancakeSwap',
  //   assets: ['LINA', 'BUSD'],
  //   addLiquidityUrl:
  //     'https://pancakeswap.finance/add/0xe9e7cea3dedca5984780bafc599bd69add087d56/0x762539b45a1dcce3d36d080f74d1aed37844b878',
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?inputCurrency=0x762539b45a1dcce3d36d080f74d1aed37844b878&outputCurrency=0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
  //   createdAt: 1619356816,
  // },
  // {
  //   id: 'cakev2-usdc-busd',
  //   logo: 'busd-pairs/USDC-BUSD.svg',
  //   name: 'USDC-BUSD LP',
  //   token: 'USDC-BUSD LP2',
  //   tokenDescription: 'PancakeSwap',
  //   tokenAddress: '0x2354ef4DF11afacb85a5C7f98B624072ECcddbB1',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooCakeV2USDC-BUSD',
  //   earnedTokenAddress: '0x9260c62866f36638964551A8f480C3aAAa4693fd',
  //   earnContractAddress: '0x9260c62866f36638964551A8f480C3aAAa4693fd',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'cakev2-usdc-busd',
  //   oraclePrice: 0,
  //   depositsPaused: false,
  //   status: 'active',
  //   platform: 'PancakeSwap',
  //   assets: ['USDC', 'BUSD'],
  //   risks: [
  //     'COMPLEXITY_LOW',
  //     'BATTLE_TESTED',
  //     'IL_NONE',
  //     'MCAP_LARGE',
  //     'AUDIT',
  //     'CONTRACTS_VERIFIED',
  //   ],
  //   addLiquidityUrl:
  //     'https://pancakeswap.finance/add/0xe9e7cea3dedca5984780bafc599bd69add087d56/0x8ac76a51cc950d9822d68b83fe1ad97b32cd580d',
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?outputCurrency=0x8ac76a51cc950d9822d68b83fe1ad97b32cd580d&outputCurrency=0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
  //   createdAt: 1619356783,
  // },
  // {
  //   id: 'cakev2-dai-busd',
  //   logo: 'busd-pairs/DAI-BUSD.svg',
  //   name: 'DAI-BUSD LP',
  //   token: 'DAI-BUSD LP2',
  //   tokenDescription: 'PancakeSwap',
  //   tokenAddress: '0x66FDB2eCCfB58cF098eaa419e5EfDe841368e489',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooCakeV2DAI-BUSD',
  //   earnedTokenAddress: '0x6eb020663898c72F8E25958F5f7bcDC732279201',
  //   earnContractAddress: '0x6eb020663898c72F8E25958F5f7bcDC732279201',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'cakev2-dai-busd',
  //   oraclePrice: 0,
  //   depositsPaused: false,
  //   status: 'active',
  //   platform: 'PancakeSwap',
  //   assets: ['DAI', 'BUSD'],
  //   addLiquidityUrl:
  //     'https://pancakeswap.finance/add/0xe9e7cea3dedca5984780bafc599bd69add087d56/0x1af3f329e8be154074d8769d1ffa4ee058b1dbc3',
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?outputCurrency=0x1af3f329e8be154074d8769d1ffa4ee058b1dbc3&outputCurrency=0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
  //   createdAt: 1619354488,
  // },
  // {
  //   id: 'cakev2-sushi-eth-eol',
  //   logo: 'uncategorized/SUSHI-ETH.svg',
  //   name: 'SUSHI-ETH LP',
  //   token: 'SUSHI-ETH LP2',
  //   tokenDescription: 'PancakeSwap',
  //   tokenAddress: '0x16aFc4F2Ad82986bbE2a4525601F8199AB9c832D',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooCakeV2SUSHI-ETH',
  //   earnedTokenAddress: '0x517b331B00dF201B660FfE74874Ed491D8047879',
  //   earnContractAddress: '0x517b331B00dF201B660FfE74874Ed491D8047879',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'cakev2-sushi-eth',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'PancakeSwap',
  //   assets: ['SUSHI', 'ETH'],
  //   addLiquidityUrl:
  //     'https://pancakeswap.finance/add/0x2170ed0880ac9a755fd29b2688956bd959f933f8/0x947950bcc74888a40ffa2593c5798f11fc9124c4',
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?inputCurrency=0x2170ed0880ac9a755fd29b2688956bd959f933f8&outputCurrency=0x947950bcc74888a40ffa2593c5798f11fc9124c4',
  //   createdAt: 1619350804,
  //   retiredReason: 'tvl',
  // },
  // {
  //   id: 'cakev2-dego-bnb-eol',
  //   logo: 'bnb-pairs/DEGO-BNB.svg',
  //   name: 'DEGO-BNB LP',
  //   token: 'DEGO-BNB LP2',
  //   tokenDescription: 'PancakeSwap',
  //   tokenAddress: '0xF1Ec67fA1881796BFf63Db3E1A301cE9cb787Fad',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooCakeV2DEGO-BNB',
  //   earnedTokenAddress: '0x98a8Fd9cA5B556dc387820CAC39f0584E4f87E51',
  //   earnContractAddress: '0x98a8Fd9cA5B556dc387820CAC39f0584E4f87E51',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'cakev2-dego-bnb',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'PancakeSwap',
  //   assets: ['DEGO', 'BNB'],
  //   retireReason: 'rewards',
  //   addLiquidityUrl:
  //     'https://pancakeswap.finance/add/BNB/0x3FdA9383A84C05eC8f7630Fe10AdF1fAC13241CC',
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?outputCurrency=0x3FdA9383A84C05eC8f7630Fe10AdF1fAC13241CC',
  //   createdAt: 1619335502,
  // },
  // {
  //   id: 'cakev2-gum-bnb-eol',
  //   logo: 'bnb-pairs/GUM-BNB.svg',
  //   name: 'GUM-BNB LP',
  //   token: 'GUM-BNB LP2',
  //   tokenDescription: 'PancakeSwap',
  //   tokenAddress: '0x28Ea5894D4DBbE90bB58eE3BAB2869387d711c87',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooCakeV2GUM-BNB',
  //   earnedTokenAddress: '0x04dc8B92192183a1e9186Df0CC27024Dfc83a7f1',
  //   earnContractAddress: '0x04dc8B92192183a1e9186Df0CC27024Dfc83a7f1',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'cakev2-gum-bnb',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'PancakeSwap',
  //   assets: ['GUM', 'BNB'],
  //   retireReason: 'rewards',
  //   addLiquidityUrl:
  //     'https://pancakeswap.finance/add/BNB/0xc53708664b99DF348dd27C3Ac0759d2DA9c40462',
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?outputCurrency=0xc53708664b99DF348dd27C3Ac0759d2DA9c40462',
  //   createdAt: 1619335460,
  // },
  // {
  //   id: 'cakev2-pbtc-bnb-eol',
  //   logo: 'bnb-pairs/PBTC-BNB.svg',
  //   name: 'pBTC-BNB LP',
  //   token: 'pBTC-BNB LP2',
  //   tokenDescription: 'PancakeSwap',
  //   tokenAddress: '0x0362ba706DFE8ED12Ec1470aB171d8Dcb1C72B8D',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooCakeV2PBTC-BNB',
  //   earnedTokenAddress: '0x2D8653881D8216AF7E5de31D5321FC21f45845FE',
  //   earnContractAddress: '0x2D8653881D8216AF7E5de31D5321FC21f45845FE',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'cakev2-pbtc-bnb',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'PancakeSwap',
  //   assets: ['pBTC', 'BNB'],
  //   retireReason: 'rewards',
  //   addLiquidityUrl:
  //     'https://pancakeswap.finance/add/BNB/0xeD28A457A5A76596ac48d87C0f577020F6Ea1c4C',
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?outputCurrency=0xeD28A457A5A76596ac48d87C0f577020F6Ea1c4C',
  //   createdAt: 1619335412,
  // },
  // {
  //   id: 'cakev2-belt-bnb-eol',
  //   logo: 'bnb-pairs/BELT-BNB.svg',
  //   name: 'BELT-BNB LP',
  //   token: 'BELT-BNB LP2',
  //   tokenDescription: 'PancakeSwap',
  //   tokenAddress: '0xF3Bc6FC080ffCC30d93dF48BFA2aA14b869554bb',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooCakeV2BELT-BNB',
  //   earnedTokenAddress: '0x3F0f1a8E2DCe5dA86Cad9b5914D8018c021E08b1',
  //   earnContractAddress: '0x3F0f1a8E2DCe5dA86Cad9b5914D8018c021E08b1',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'cakev2-belt-bnb',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'PancakeSwap',
  //   assets: ['BELT', 'BNB'],
  //   addLiquidityUrl:
  //     'https://pancakeswap.finance/add/BNB/0xE0e514c71282b6f4e823703a39374Cf58dc3eA4f',
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?outputCurrency=0xE0e514c71282b6f4e823703a39374Cf58dc3eA4f',
  //   createdAt: 1619335340,
  //   retiredReason: 'tvl',
  // },
  // {
  //   id: 'cakev2-bel-bnb-eol',
  //   logo: 'bnb-pairs/BEL-BNB.svg',
  //   name: 'BEL-BNB LP',
  //   token: 'BEL-BNB LP2',
  //   tokenDescription: 'PancakeSwap',
  //   tokenAddress: '0x69DEE989c30b5fFe40867f5FC14F00E4bCE7B681',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooCakeV2BEL-BNB',
  //   earnedTokenAddress: '0xE5FF1e597AF772BE9c6d95B9Be2193FC6b917539',
  //   earnContractAddress: '0xE5FF1e597AF772BE9c6d95B9Be2193FC6b917539',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'cakev2-bel-bnb',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'PancakeSwap',
  //   assets: ['BEL', 'BNB'],
  //   retireReason: 'rewards',
  //   addLiquidityUrl:
  //     'https://pancakeswap.finance/add/BNB/0x8443f091997f06a61670b735ed92734f5628692f',
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?outputCurrency=0x8443f091997f06a61670b735ed92734f5628692f',
  //   createdAt: 1619335307,
  // },
  // {
  //   id: 'cakev2-bmxx-bnb-eol',
  //   logo: 'bnb-pairs/BMXX-BNB.svg',
  //   name: 'BMXX-BNB LP',
  //   token: 'BMXX-BNB LP2',
  //   tokenDescription: 'PancakeSwap',
  //   tokenAddress: '0xc20A92a1424b29b78DFaF92FD35D4cf8A06419B4',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooCakeV2BMXX-BNB',
  //   earnedTokenAddress: '0xC83cB476c772622196a56A3B51F625ac48Ff9746',
  //   earnContractAddress: '0xC83cB476c772622196a56A3B51F625ac48Ff9746',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'cakev2-bmxx-bnb',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'PancakeSwap',
  //   assets: ['BMXX', 'BNB'],
  //   retireReason: 'rewards',
  //   addLiquidityUrl:
  //     'https://pancakeswap.finance/add/BNB/0x4131b87f74415190425ccd873048c708f8005823',
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?outputCurrency=0x4131b87f74415190425ccd873048c708f8005823',
  //   createdAt: 1619335277,
  // },
  // {
  //   id: 'cakev2-swgb-bnb-eol',
  //   logo: 'bnb-pairs/SWGB-BNB.svg',
  //   name: 'SWGB-BNB LP',
  //   token: 'SWGB-BNB LP2',
  //   tokenDescription: 'PancakeSwap',
  //   tokenAddress: '0x856f9AD94cA8680B899214Bb1EB3d235a3C33Afe',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooCakeV2SWGB-BNB',
  //   earnedTokenAddress: '0x891ac86d46c97b7cf330ba4f5a905930Ac331858',
  //   earnContractAddress: '0x891ac86d46c97b7cf330ba4f5a905930Ac331858',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'cakev2-swgb-bnb',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'PancakeSwap',
  //   assets: ['SWGB', 'BNB'],
  //   addLiquidityUrl:
  //     'https://pancakeswap.finance/add/BNB/0xe40255c5d7fa7ceec5120408c78c787cecb4cfdb',
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?outputCurrency=0xe40255c5d7fa7ceec5120408c78c787cecb4cfdb',
  //   createdAt: 1619331241,
  // },
  // {
  //   id: 'cakev2-lit-bnb-eol',
  //   logo: 'bnb-pairs/LIT-BNB.svg',
  //   name: 'LIT-BNB LP',
  //   token: 'LIT-BNB LP2',
  //   tokenDescription: 'PancakeSwap',
  //   tokenAddress: '0x1F37d4226d23d09044B8005c127C0517BD7e94fD',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooCakeV2LIT-BNB',
  //   earnedTokenAddress: '0x2257EFfa0Be8A43cDED6E339991844Dd6277f8e6',
  //   earnContractAddress: '0x2257EFfa0Be8A43cDED6E339991844Dd6277f8e6',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'cakev2-lit-bnb',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'PancakeSwap',
  //   assets: ['LIT', 'BNB'],
  //   retireReason: 'rewards',
  //   addLiquidityUrl:
  //     'https://pancakeswap.finance/add/BNB/0xb59490aB09A0f526Cc7305822aC65f2Ab12f9723',
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?outputCurrency=0xb59490aB09A0f526Cc7305822aC65f2Ab12f9723',
  //   createdAt: 1619331169,
  // },
  // {
  //   id: 'cakev2-ditto-bnb',
  //   logo: 'bnb-pairs/DITTO-BNB.svg',
  //   name: 'DITTO-BNB LP',
  //   token: 'DITTO-BNB LP2',
  //   tokenDescription: 'PancakeSwap',
  //   tokenAddress: '0x8645148dE4E339964bA480AE3478653b5bc6E211',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooCakeV2DITTO-BNB',
  //   earnedTokenAddress: '0x19D62B080Df4dfb9ca2c8Aa56721d06860EcD533',
  //   earnContractAddress: '0x19D62B080Df4dfb9ca2c8Aa56721d06860EcD533',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'cakev2-ditto-bnb',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'PancakeSwap',
  //   assets: ['DITTO', 'BNB'],
  //   addLiquidityUrl:
  //     'https://pancakeswap.finance/add/BNB/0x233d91a0713155003fc4dce0afa871b508b3b715',
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?outputCurrency=0x233d91a0713155003fc4dce0afa871b508b3b715',
  //   createdAt: 1619331040,
  // },
  // {
  //   id: 'cakev2-sfp-bnb',
  //   logo: 'bnb-pairs/SFP-BNB.svg',
  //   name: 'SFP-BNB LP',
  //   token: 'SFP-BNB LP2',
  //   tokenDescription: 'PancakeSwap',
  //   tokenAddress: '0x942b294e59a8c47a0F7F20DF105B082710F7C305',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooCakeV2SFP-BNB',
  //   earnedTokenAddress: '0xb5b88135d363A99bF4664C4a1A51dF4ec3cC154A',
  //   earnContractAddress: '0xb5b88135d363A99bF4664C4a1A51dF4ec3cC154A',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'cakev2-sfp-bnb',
  //   oraclePrice: 0,
  //   depositsPaused: false,
  //   status: 'active',
  //   platform: 'PancakeSwap',
  //   assets: ['SFP', 'BNB'],
  //   addLiquidityUrl:
  //     'https://pancakeswap.finance/add/BNB/0xd41fdb03ba84762dd66a0af1a6c8540ff1ba5dfb',
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?outputCurrency=0xd41fdb03ba84762dd66a0af1a6c8540ff1ba5dfb',
  //   createdAt: 1619330965,
  // },
  // {
  //   id: 'cakev2-wsote-bn-eolb',
  //   logo: 'bnb-pairs/WSOTE-BNB.svg',
  //   name: 'WSOTE-BNB LP',
  //   token: 'WSOTE-BNB LP2',
  //   tokenDescription: 'PancakeSwap',
  //   tokenAddress: '0x7653D2c31440f04d2c6520D482dC5DbD7650f70a',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooCakev2WSOTE-BNB',
  //   earnedTokenAddress: '0x51a159496328F5cCec192748F135eC57E326A10B',
  //   earnContractAddress: '0x51a159496328F5cCec192748F135eC57E326A10B',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'cakev2-wsote-bnb',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'PancakeSwap',
  //   assets: ['WSOTE', 'BNB'],
  //   addLiquidityUrl:
  //     'https://pancakeswap.finance/add/BNB/0x541e619858737031a1244a5d0cd47e5ef480342c',
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?outputCurrency=0x541e619858737031a1244a5d0cd47e5ef480342c',
  //   createdAt: 1619285608,
  // },
  // {
  //   id: 'cakev2-front-bnb-eol',
  //   logo: 'bnb-pairs/FRONT-BNB.svg',
  //   name: 'FRONT-BNB LP',
  //   token: 'FRONT-BNB LP2',
  //   tokenDescription: 'PancakeSwap',
  //   tokenAddress: '0xC6b668548aA4A56792e8002A920d3159728121D5',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooCakev2FRONT-BNB',
  //   earnedTokenAddress: '0x5C2C814107E6c0C664951bd6CF209A26eB10a98A',
  //   earnContractAddress: '0x5C2C814107E6c0C664951bd6CF209A26eB10a98A',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'cakev2-front-bnb',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'PancakeSwap',
  //   assets: ['FRONT', 'BNB'],
  //   retireReason: 'rewards',
  //   addLiquidityUrl:
  //     'https://pancakeswap.finance/add/BNB/0x928e55dab735aa8260af3cedada18b5f70c72f1b',
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?outputCurrency=0x928e55dab735aa8260af3cedada18b5f70c72f1b',
  //   createdAt: 1619285563,
  // },
  // {
  //   id: 'cakev2-btcst-bnb',
  //   logo: 'bnb-pairs/BTCST-BNB.svg',
  //   name: 'BTCST-BNB LP',
  //   token: 'BTCST-BNB LP2',
  //   tokenDescription: 'PancakeSwap',
  //   tokenAddress: '0xB2678C414ebC63c9CC6d1a0fC45f43E249B50fdE',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooCakev2BTCST-BNB',
  //   earnedTokenAddress: '0x7ffb0415df0f77a6536aE228622e077D0D5D3de8',
  //   earnContractAddress: '0x7ffb0415df0f77a6536aE228622e077D0D5D3de8',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'cakev2-btcst-bnb',
  //   oraclePrice: 0,
  //   depositsPaused: false,
  //   status: 'active',
  //   platform: 'PancakeSwap',
  //   assets: ['BTCST', 'BNB'],
  //   addLiquidityUrl:
  //     'https://pancakeswap.finance/add/BNB/0x78650b139471520656b9e7aa7a5e9276814a38e9',
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?outputCurrency=0x78650b139471520656b9e7aa7a5e9276814a38e9',
  //   createdAt: 1619285464,
  // },
  // {
  //   id: 'cakev2-ltc-bnb',
  //   logo: 'bnb-pairs/LTC-BNB.svg',
  //   name: 'LTC-BNB LP',
  //   token: 'LTC-BNB LP2',
  //   tokenDescription: 'PancakeSwap',
  //   tokenAddress: '0x71b01eBdDD797c8E9E0b003ea2f4FD207fBF46cC',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooCakev2LTC-BNB',
  //   earnedTokenAddress: '0x5B240BB418bEcFffb6d66CdcA9dc55eCE79B3CC3',
  //   earnContractAddress: '0x5B240BB418bEcFffb6d66CdcA9dc55eCE79B3CC3',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'cakev2-ltc-bnb',
  //   oraclePrice: 0,
  //   depositsPaused: false,
  //   status: 'active',
  //   platform: 'PancakeSwap',
  //   assets: ['LTC', 'BNB'],
  //   addLiquidityUrl:
  //     'https://pancakeswap.finance/add/BNB/0x4338665cbb7b2485a8855a139b75d5e34ab0db94',
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?outputCurrency=0x4338665cbb7b2485a8855a139b75d5e34ab0db94',
  //   createdAt: 1619283393,
  // },
  // {
  //   id: 'cakev2-ada-bnb',
  //   logo: 'bnb-pairs/ADA-BNB.svg',
  //   name: 'ADA-BNB LP',
  //   token: 'ADA-BNB LP2',
  //   tokenDescription: 'PancakeSwap',
  //   tokenAddress: '0x28415ff2C35b65B9E5c7de82126b4015ab9d031F',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooCakev2ADA-BNB',
  //   earnedTokenAddress: '0xAb09FBFcCcc033248fC8790aaF9F2450a856e177',
  //   earnContractAddress: '0xAb09FBFcCcc033248fC8790aaF9F2450a856e177',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'cakev2-ada-bnb',
  //   oraclePrice: 0,
  //   depositsPaused: false,
  //   status: 'active',
  //   platform: 'PancakeSwap',
  //   assets: ['ADA', 'BNB'],
  //   addLiquidityUrl:
  //     'https://pancakeswap.finance/add/BNB/0x4338665cbb7b2485a8855a139b75d5e34ab0db94',
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?outputCurrency=0x4338665cbb7b2485a8855a139b75d5e34ab0db94',
  //   createdAt: 1619281824,
  // },
  // {
  //   id: 'cakev2-dot-bnb',
  //   logo: 'bnb-pairs/DOT-BNB.svg',
  //   name: 'DOT-BNB LP',
  //   token: 'DOT-BNB LP2',
  //   tokenDescription: 'PancakeSwap',
  //   tokenAddress: '0xDd5bAd8f8b360d76d12FdA230F8BAF42fe0022CF',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooCakev2DOT-BNB',
  //   earnedTokenAddress: '0x18B12Ec521f31EaFF5BE813c3E136C75B3B1191E',
  //   earnContractAddress: '0x18B12Ec521f31EaFF5BE813c3E136C75B3B1191E',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'cakev2-dot-bnb',
  //   oraclePrice: 0,
  //   depositsPaused: false,
  //   status: 'active',
  //   platform: 'PancakeSwap',
  //   assets: ['DOT', 'BNB'],
  //   addLiquidityUrl:
  //     'https://pancakeswap.finance/add/BNB/0x7083609fce4d1d8dc0c979aab8c869ea2c873402',
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?outputCurrency=0x7083609fce4d1d8dc0c979aab8c869ea2c873402',
  //   createdAt: 1619283333,
  // },
  // {
  //   id: 'cakev2-zil-bnb-eol',
  //   logo: 'bnb-pairs/ZIL-BNB.svg',
  //   name: 'ZIL-BNB LP',
  //   token: 'ZIL-BNB LP2',
  //   tokenDescription: 'PancakeSwap',
  //   tokenAddress: '0x6A97867a4b7Eb7646ffB1F359ad582e9903aa1C2',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooCakeV2ZIL-BNB',
  //   earnedTokenAddress: '0x96DbE2a8B2a98E4aF2ecf444f95c5A8d853d69a3',
  //   earnContractAddress: '0x96DbE2a8B2a98E4aF2ecf444f95c5A8d853d69a3',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'cakev2-zil-bnb',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'PancakeSwap',
  //   assets: ['ZIL', 'BNB'],
  //   retireReason: 'rewards',
  //   addLiquidityUrl:
  //     'https://pancakeswap.finance/add/BNB/0xb86abcb37c3a4b64f74f59301aff131a1becc787',
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?outputCurrency=0xb86abcb37c3a4b64f74f59301aff131a1becc787',
  //   createdAt: 1619302521,
  // },
  // {
  //   id: 'cakev2-lto-bnb-eol',
  //   logo: 'bnb-pairs/LTO-BNB.svg',
  //   name: 'LTO-BNB LP',
  //   token: 'LTO-BNB LP2',
  //   tokenDescription: 'PancakeSwap',
  //   tokenAddress: '0xa5Bb44c6F5fD9B836E5a654c8AbbCCc96A15deE5',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooCakeV2LTO-BNB',
  //   earnedTokenAddress: '0xFc7423790DcEcf83a93f9D370eA589518c91cC24',
  //   earnContractAddress: '0xFc7423790DcEcf83a93f9D370eA589518c91cC24',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'cakev2-lto-bnb',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   retireReason: 'rewards',
  //   platform: 'PancakeSwap',
  //   assets: ['LTO', 'BNB'],
  //   addLiquidityUrl:
  //     'https://pancakeswap.finance/add/BNB/0x857b222fc79e1cbbf8ca5f78cb133d1b7cf34bbd',
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?outputCurrency=0x857b222fc79e1cbbf8ca5f78cb133d1b7cf34bbd',
  //   createdAt: 1619302554,
  // },
  // {
  //   id: 'cakev2-trade-bnb',
  //   logo: 'bnb-pairs/TRADE-BNB.svg',
  //   name: 'TRADE-BNB LP',
  //   token: 'TRADE-BNB LP2',
  //   tokenDescription: 'PancakeSwap',
  //   tokenAddress: '0x8F6baf368E7A4f6e2C9c995f22702d5e654A0237',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooCakeV2TRADE-BNB',
  //   earnedTokenAddress: '0x770fFB3872dfE4B872491e7517f58f9dcC55aE07',
  //   earnContractAddress: '0x770fFB3872dfE4B872491e7517f58f9dcC55aE07',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'cakev2-trade-bnb',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'PancakeSwap',
  //   assets: ['TRADE', 'BNB'],
  //   addLiquidityUrl:
  //     'https://pancakeswap.finance/add/BNB/0x7af173f350d916358af3e218bdf2178494beb748',
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?outputCurrency=0x7af173f350d916358af3e218bdf2178494beb748',
  //   createdAt: 1619302611,
  // },
  // {
  //   id: 'cakev2-dusk-bnb-eol',
  //   logo: 'bnb-pairs/DUSK-BNB.svg',
  //   name: 'DUSK-BNB LP',
  //   token: 'DUSK-BNB LP2',
  //   tokenDescription: 'PancakeSwap',
  //   tokenAddress: '0x678EDb8B268e73dB57b7694c163e1dc296b6e219',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooCakeV2DUSK-BNB',
  //   earnedTokenAddress: '0x6346eF9D3d9fF022E18152e3E13E263A660bFDC5',
  //   earnContractAddress: '0x6346eF9D3d9fF022E18152e3E13E263A660bFDC5',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'cakev2-dusk-bnb',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   retireReason: 'rewards',
  //   platform: 'PancakeSwap',
  //   assets: ['DUSK', 'BNB'],
  //   addLiquidityUrl:
  //     'https://pancakeswap.finance/add/0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c/0xb2bd0749dbe21f623d9baba856d3b0f0e1bfec9c',
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?outputCurrency=0xb2bd0749dbe21f623d9baba856d3b0f0e1bfec9c',
  //   createdAt: 1619302641,
  // },
  // {
  //   id: 'cakev2-cos-bnb-eol',
  //   logo: 'bnb-pairs/COS-BNB.svg',
  //   name: 'COS-BNB LP',
  //   token: 'COS-BNB LP2',
  //   tokenDescription: 'PancakeSwap',
  //   tokenAddress: '0xe98585bBb2dc81854fF100A3d9D7B0F53E0dafEd',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooCakeV2COS-BNB',
  //   earnedTokenAddress: '0xA648630bD3C5a5FAC65Cd60BD61964f16bc00e88',
  //   earnContractAddress: '0xA648630bD3C5a5FAC65Cd60BD61964f16bc00e88',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'cakev2-cos-bnb',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   retireReason: 'rewards',
  //   platform: 'PancakeSwap',
  //   assets: ['COS', 'BNB'],
  //   addLiquidityUrl:
  //     'https://pancakeswap.finance/add/0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c/0x96Dd399F9c3AFda1F194182F71600F1B65946501',
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?outputCurrency=0x96Dd399F9c3AFda1F194182F71600F1B65946501',
  //   createdAt: 1619302668,
  // },
  // {
  //   id: 'cakev2-watch-bnb-eol',
  //   logo: 'bnb-pairs/WATCH-BNB.svg',
  //   name: 'WATCH-BNB LP',
  //   token: 'WATCH-BNB LP2',
  //   tokenDescription: 'PancakeSwap',
  //   tokenAddress: '0x13321AcfF4A27f3d2bcA64b8bEaC6e5FdAAAf12C',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooCakeV2WATCH-BNB',
  //   earnedTokenAddress: '0x4925Ebaa523b2C2F66F980C7b8bb68207622bc3F',
  //   earnContractAddress: '0x4925Ebaa523b2C2F66F980C7b8bb68207622bc3F',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'cakev2-watch-bnb',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   retireReason: 'rewards',
  //   platform: 'PancakeSwap',
  //   assets: ['WATCH', 'BNB'],
  //   addLiquidityUrl:
  //     'https://pancakeswap.finance/add/BNB/0x7A9f28EB62C791422Aa23CeAE1dA9C847cBeC9b0',
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?outputCurrency=0x7A9f28EB62C791422Aa23CeAE1dA9C847cBeC9b0',
  //   createdAt: 1619302698,
  // },
  // {
  //   id: 'cakev2-bry-bnb',
  //   logo: 'bnb-pairs/BRY-BNB.svg',
  //   name: 'BRY-BNB LP',
  //   token: 'BRY-BNB LP2',
  //   tokenDescription: 'PancakeSwap',
  //   tokenAddress: '0x21dD71aB78EDE3033c976948f769D506E4F489eE',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooCakev2BRY-BNB',
  //   earnedTokenAddress: '0xca2E81FAb1740C533A860935787ea5dB4bF65423',
  //   earnContractAddress: '0xca2E81FAb1740C533A860935787ea5dB4bF65423',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'cakev2-bry-bnb',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'PancakeSwap',
  //   assets: ['BRY', 'BNB'],
  //   addLiquidityUrl:
  //     'https://pancakeswap.finance/add/BNB/0xf859Bf77cBe8699013d6Dbc7C2b926Aaf307F830',
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?outputCurrency=0xf859Bf77cBe8699013d6Dbc7C2b926Aaf307F830',
  //   createdAt: 1619302716,
  // },
  // {
  //   id: 'cakev2-zee-bnb-eol',
  //   logo: 'bnb-pairs/ZEE-BNB.svg',
  //   name: 'ZEE-BNB LP',
  //   token: 'ZEE-BNB LP2',
  //   tokenDescription: 'PancakeSwap',
  //   tokenAddress: '0x8e799cB0737525CeB8A6C6Ad07f748535fF6377B',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooCakeV2ZEE-BNB',
  //   earnedTokenAddress: '0xfeBBCb71C2ff834960289314B629941300c621B2',
  //   earnContractAddress: '0xfeBBCb71C2ff834960289314B629941300c621B2',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'cakev2-zee-bnb',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   retireReason: 'rewards',
  //   platform: 'PancakeSwap',
  //   assets: ['ZEE', 'BNB'],
  //   addLiquidityUrl:
  //     'https://pancakeswap.finance/add/BNB/0x44754455564474a89358b2c2265883df993b12f0',
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?outputCurrency=0x44754455564474a89358b2c2265883df993b12f0',
  //   createdAt: 1619302740,
  // },
  // {
  //   id: 'cakev2-dodo-bnb-eol',
  //   logo: 'bnb-pairs/DODO-BNB.svg',
  //   name: 'DODO-BNB LP',
  //   token: 'DODO-BNB LP2',
  //   tokenDescription: 'PancakeSwap',
  //   tokenAddress: '0xA9986Fcbdb23c2E8B11AB40102990a08f8E58f06',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooCakeV2DODO-BNB',
  //   earnedTokenAddress: '0x5F92E5845661b31bB899d4121b5a20d6b87Fa2Ca',
  //   earnContractAddress: '0x5F92E5845661b31bB899d4121b5a20d6b87Fa2Ca',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'cakev2-dodo-bnb',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   retireReason: 'rewards',
  //   platform: 'PancakeSwap',
  //   assets: ['DODO', 'BNB'],
  //   addLiquidityUrl:
  //     'https://pancakeswap.finance/add/BNB/0x67ee3cb086f8a16f34bee3ca72fad36f7db929e2',
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?outputCurrency=0x67ee3cb086f8a16f34bee3ca72fad36f7db929e2',
  //   createdAt: 1619302767,
  // },
  // {
  //   id: 'cakev2-swingby-bnb-eol',
  //   logo: 'bnb-pairs/SWINGBY-BNB.svg',
  //   name: 'SWINGBY-BNB LP',
  //   token: 'SWINGBY-BNB LP2',
  //   tokenDescription: 'PancakeSwap',
  //   tokenAddress: '0x4Fd6D315bEf387fAD2322fbc64368fC443F0886D',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooCakeV2SWINGBY-BNB',
  //   earnedTokenAddress: '0x0Bc697E03fc1B5aF446E7ceD159195BC2f9E1b20',
  //   earnContractAddress: '0x0Bc697E03fc1B5aF446E7ceD159195BC2f9E1b20',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'cakev2-swingby-bnb',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   retireReason: 'rewards',
  //   platform: 'PancakeSwap',
  //   assets: ['SWINGBY', 'BNB'],
  //   addLiquidityUrl:
  //     'https://pancakeswap.finance/add/BNB/0x71de20e0c4616e7fcbfdd3f875d568492cbe4739',
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?outputCurrency=0x71de20e0c4616e7fcbfdd3f875d568492cbe4739',
  //   createdAt: 1619302800,
  // },
  // {
  //   id: 'cakev2-apys-bnb-eol',
  //   logo: 'bnb-pairs/APYS-BNB.svg',
  //   name: 'APYS-BNB LP',
  //   token: 'APYS-BNB LP2',
  //   tokenDescription: 'PancakeSwap',
  //   tokenAddress: '0x510b29a93ebf098f3fC24A16541aAA0114D07056',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooCakeV2APYS-BNB',
  //   earnedTokenAddress: '0x526c4f570e535B221C86557b8483A0F441b69259',
  //   earnContractAddress: '0x526c4f570e535B221C86557b8483A0F441b69259',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'cakev2-apys-bnb',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   retireReason: 'rewards',
  //   platform: 'PancakeSwap',
  //   assets: ['APYS', 'BNB'],
  //   addLiquidityUrl:
  //     'https://pancakeswap.finance/add/BNB/0x37dfACfaeDA801437Ff648A1559d73f4C40aAcb7',
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?outputCurrency=0x37dfACfaeDA801437Ff648A1559d73f4C40aAcb7',
  //   createdAt: 1619285232,
  // },
  // {
  //   id: 'cakev2-oddz-bnb-eol',
  //   logo: 'bnb-pairs/ODDZ-BNB.svg',
  //   name: 'ODDZ-BNB LP',
  //   token: 'ODDZ-BNB LP2',
  //   tokenDescription: 'PancakeSwap',
  //   tokenAddress: '0x3c2c77353E2F6AC1578807b6b2336Bf3a3CbB014',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooCakeV2ODDZ-BNB',
  //   earnedTokenAddress: '0x19dA11FC81cDe70F55e673C8eec41129DAA94A20',
  //   earnContractAddress: '0x19dA11FC81cDe70F55e673C8eec41129DAA94A20',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'cakev2-oddz-bnb',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'PancakeSwap',
  //   assets: ['ODDZ', 'BNB'],
  //   retireReason: 'rewards',
  //   addLiquidityUrl:
  //     'https://pancakeswap.finance/add/BNB/0xCD40F2670CF58720b694968698A5514e924F742d',
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?outputCurrency=0xCD40F2670CF58720b694968698A5514e924F742d',
  //   createdAt: 1619285286,
  // },
  // {
  //   id: 'cakev2-bondly-bnb-eol',
  //   logo: 'bnb-pairs/BONDLY-BNB.svg',
  //   name: 'BONDLY-BNB LP',
  //   token: 'BONDLY-BNB LP2',
  //   tokenDescription: 'PancakeSwap',
  //   tokenAddress: '0xb8b4383B49d451BBeA63BC4421466E1086da6f18',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooCakeV2BONDLY-BNB',
  //   earnedTokenAddress: '0xC452538b578226933fc5F23d6436D486964736d3',
  //   earnContractAddress: '0xC452538b578226933fc5F23d6436D486964736d3',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'cakev2-bondly-bnb',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'PancakeSwap',
  //   assets: ['BONDLY', 'BNB'],
  //   retireReason: 'rewards',
  //   addLiquidityUrl:
  //     'https://pancakeswap.finance/add/BNB/0x96058f8C3e16576D9BD68766f3836d9A33158f89',
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?outputCurrency=0x96058f8C3e16576D9BD68766f3836d9A33158f89',
  //   createdAt: 1619285319,
  // },
  // {
  //   id: 'cakev2-itam-bnb-eol',
  //   logo: 'bnb-pairs/ITAM-BNB.svg',
  //   name: 'ITAM-BNB LP',
  //   token: 'ITAM-BNB LP2',
  //   tokenDescription: 'PancakeSwap',
  //   tokenAddress: '0xd02DA76c813b9cd4516eD50442923E625f90228f',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooCakeV2ITAM-BNB',
  //   earnedTokenAddress: '0x34753f36d69d00e2112Eb99B3F7f0FE76cC35090',
  //   earnContractAddress: '0x34753f36d69d00e2112Eb99B3F7f0FE76cC35090',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'cakev2-itam-bnb',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   retireReason: 'rewards',
  //   platform: 'PancakeSwap',
  //   assets: ['ITAM', 'BNB'],
  //   addLiquidityUrl:
  //     'https://pancakeswap.finance/add/BNB/0x04C747b40Be4D535fC83D09939fb0f626F32800B',
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?outputCurrency=0x04C747b40Be4D535fC83D09939fb0f626F32800B',
  //   createdAt: 1619285343,
  // },
  // {
  //   id: 'cakev2-arpa-bnb=eol',
  //   logo: 'bnb-pairs/ARPA-BNB.svg',
  //   name: 'ARPA-BNB LP',
  //   token: 'ARPA-BNB LP2',
  //   tokenDescription: 'PancakeSwap',
  //   tokenAddress: '0x9730c791743300E9f984C9264395ce705A55Da7c',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooCakeV2ARPA-BNB',
  //   earnedTokenAddress: '0x2b25fc2e80C3f4A7983306fFC72d95a553f59C9d',
  //   earnContractAddress: '0x2b25fc2e80C3f4A7983306fFC72d95a553f59C9d',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'cakev2-arpa-bnb',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   retireReason: 'rewards',
  //   platform: 'PancakeSwap',
  //   assets: ['ARPA', 'BNB'],
  //   addLiquidityUrl:
  //     'https://pancakeswap.finance/add/BNB/0x6F769E65c14Ebd1f68817F5f1DcDb61Cfa2D6f7e',
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?outputCurrency=0x6F769E65c14Ebd1f68817F5f1DcDb61Cfa2D6f7e',
  //   createdAt: 1619285371,
  // },
  // {
  //   id: 'cakev2-dft-bnb-eol',
  //   logo: 'bnb-pairs/DFT-BNB.svg',
  //   name: 'DFT-BNB LP',
  //   token: 'DFT-BNB LP2',
  //   tokenDescription: 'PancakeSwap',
  //   tokenAddress: '0x24d3B0eD4C444A4f6882d527cBF67aDc8c026582',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooCakeV2DFT-BNB',
  //   earnedTokenAddress: '0xa5529E47C3086D93bB59aB99ca2B20d0Ee2EFcAe',
  //   earnContractAddress: '0xa5529E47C3086D93bB59aB99ca2B20d0Ee2EFcAe',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'cakev2-dft-bnb',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   retireReason: 'rewards',
  //   platform: 'PancakeSwap',
  //   assets: ['DFT', 'BNB'],
  //   addLiquidityUrl:
  //     'https://pancakeswap.finance/add/BNB/0x42712dF5009c20fee340B245b510c0395896cF6e',
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?outputCurrency=0x42712df5009c20fee340b245b510c0395896cf6e',
  //   createdAt: 1619285395,
  // },
  // {
  //   id: 'cakev2-swth-bnb',
  //   logo: 'bnb-pairs/SWTH-BNB.svg',
  //   name: 'SWTH-BNB LP',
  //   token: 'SWTH-BNB LP2',
  //   tokenDescription: 'PancakeSwap',
  //   tokenAddress: '0x123D475E13aA54A43a7421d94CAa4459dA021c77',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooCakeV2SWTH-BNB',
  //   earnedTokenAddress: '0xE7703725e3B294Ef0691577461Ec957AD0e1fF81',
  //   earnContractAddress: '0xE7703725e3B294Ef0691577461Ec957AD0e1fF81',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'cakev2-swth-bnb',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'PancakeSwap',
  //   assets: ['SWTH', 'BNB'],
  //   addLiquidityUrl:
  //     'https://pancakeswap.finance/add/BNB/0x250b211EE44459dAd5Cd3bCa803dD6a7EcB5d46C',
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?outputCurrency=0x250b211EE44459dAd5Cd3bCa803dD6a7EcB5d46C',
  //   createdAt: 1619285425,
  // },
  // {
  //   id: 'cakev2-lien-bnb-eol',
  //   logo: 'bnb-pairs/LIEN-BNB.svg',
  //   name: 'LIEN-BNB LP',
  //   token: 'LIEN-BNB LP2',
  //   tokenDescription: 'PancakeSwap',
  //   tokenAddress: '0xa4963B38b271c0D714593063497Fc786Fa4029Ce',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooCakeV2LIEN-BNB',
  //   earnedTokenAddress: '0x19069ED39D3E0ad756eE9DF2200B42A83D1266e4',
  //   earnContractAddress: '0x19069ED39D3E0ad756eE9DF2200B42A83D1266e4',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'cakev2-lien-bnb',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   retireReason: 'rewards',
  //   platform: 'PancakeSwap',
  //   assets: ['LIEN', 'BNB'],
  //   addLiquidityUrl:
  //     'https://pancakeswap.finance/add/BNB/0x5d684adaf3fcfe9cfb5cede3abf02f0cdd1012e3',
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?outputCurrency=0x5d684adaf3fcfe9cfb5cede3abf02f0cdd1012e3',
  //   createdAt: 1619285461,
  // },
  // {
  //   id: 'cakev2-broobee-bnb',
  //   logo: 'bnb-pairs/bROOBEE-BNB.svg',
  //   name: 'bROOBEE-BNB LP',
  //   token: 'bROOBEE-BNB LP2',
  //   tokenDescription: 'PancakeSwap',
  //   tokenAddress: '0x950FD020F8E4B8C57285EC7020b7a204348dadFa',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooCakeV2bROOBEE-BNB',
  //   earnedTokenAddress: '0x9292dE516411Fe2eEcB9441F9FFcf2bfdDFb0956',
  //   earnContractAddress: '0x9292dE516411Fe2eEcB9441F9FFcf2bfdDFb0956',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'cakev2-broobee-bnb',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'PancakeSwap',
  //   assets: ['bROOBEE', 'BNB'],
  //   addLiquidityUrl:
  //     'https://pancakeswap.finance/add/BNB/0xE64F5Cb844946C1F102Bd25bBD87a5aB4aE89Fbe',
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?outputCurrency=0xE64F5Cb844946C1F102Bd25bBD87a5aB4aE89Fbe',
  //   createdAt: 1619280195,
  // },
  // {
  //   id: 'cakev2-tko-bnb',
  //   logo: 'bnb-pairs/TKO-BNB.svg',
  //   name: 'TKO-BNB LP',
  //   token: 'TKO-BNB LP2',
  //   tokenDescription: 'PancakeSwap',
  //   tokenAddress: '0xFFd4B200d3C77A0B691B5562D804b3bd54294e6e',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooCakeV2TKO-BNB',
  //   earnedTokenAddress: '0xEEdDe64Af1dF1b1d023e326FD914AD5491B5c0eD',
  //   earnContractAddress: '0xEEdDe64Af1dF1b1d023e326FD914AD5491B5c0eD',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'cakev2-tko-bnb',
  //   oraclePrice: 0,
  //   depositsPaused: false,
  //   status: 'active',
  //   platform: 'PancakeSwap',
  //   assets: ['TKO', 'BNB'],
  //   addLiquidityUrl:
  //     'https://pancakeswap.finance/add/BNB/0x9f589e3eabe42ebC94A44727b3f3531C0c877809',
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?outputCurrency=0x9f589e3eabe42ebC94A44727b3f3531C0c877809',
  //   createdAt: 1619280234,
  // },
  // {
  //   id: 'cakev2-tlm-bnb',
  //   logo: 'bnb-pairs/TLM-BNB.svg',
  //   name: 'TLM-BNB LP',
  //   token: 'TLM-BNB LP2',
  //   tokenDescription: 'PancakeSwap',
  //   tokenAddress: '0xE6b421a4408c82381b226Ab5B6F8C4b639044359',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooCakeV2TLM-BNB',
  //   earnedTokenAddress: '0x185647c55633A5706aAA3278132537565c925078',
  //   earnContractAddress: '0x185647c55633A5706aAA3278132537565c925078',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'cakev2-tlm-bnb',
  //   oraclePrice: 0,
  //   depositsPaused: false,
  //   status: 'active',
  //   platform: 'PancakeSwap',
  //   assets: ['TLM', 'BNB'],
  //   addLiquidityUrl:
  //     'https://pancakeswap.finance/add/BNB/0x2222227E22102Fe3322098e4CBfE18cFebD57c95',
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?outputCurrency=0x2222227E22102Fe3322098e4CBfE18cFebD57c95',
  //   createdAt: 1619280249,
  // },
  // {
  //   id: 'cakev2-perl-bnb=eol',
  //   logo: 'bnb-pairs/PERL-BNB.svg',
  //   name: 'PERL-BNB LP',
  //   token: 'PERL-BNB LP2',
  //   tokenDescription: 'PancakeSwap',
  //   tokenAddress: '0x61010e6CbA3b56ba47E9dFd56Da682daCFe76131',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooCakeV2PERL-BNB',
  //   earnedTokenAddress: '0x2dc8683F752305659ff7F97A7CB4291B1c0Df37b',
  //   earnContractAddress: '0x2dc8683F752305659ff7F97A7CB4291B1c0Df37b',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'cakev2-perl-bnb',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   retireReason: 'rewards',
  //   platform: 'PancakeSwap',
  //   assets: ['PERL', 'BNB'],
  //   addLiquidityUrl:
  //     'https://pancakeswap.finance/add/BNB/0x0F9E4D49f25de22c2202aF916B681FBB3790497B',
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?outputCurrency=0x0F9E4D49f25de22c2202aF916B681FBB3790497B',
  //   createdAt: 1619280270,
  // },
  // {
  //   id: 'cakev2-alpa-bnb-eol',
  //   logo: 'bnb-pairs/ALPA-BNB.svg',
  //   name: 'ALPA-BNB LP',
  //   token: 'ALPA-BNB LP2',
  //   tokenDescription: 'PancakeSwap',
  //   tokenAddress: '0x4cC442220BE1cE560C1f2573f8CA8f460B3E4172',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooCakeV2ALPA-BNB',
  //   earnedTokenAddress: '0x29714444254C94061215cB36C610dEED43925F71',
  //   earnContractAddress: '0x29714444254C94061215cB36C610dEED43925F71',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'cakev2-alpa-bnb',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   retireReason: 'rewards',
  //   platform: 'PancakeSwap',
  //   assets: ['ALPA', 'BNB'],
  //   addLiquidityUrl:
  //     'https://pancakeswap.finance/add/BNB/0xc5E6689C9c8B02be7C49912Ef19e79cF24977f03',
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?outputCurrency=0xc5E6689C9c8B02be7C49912Ef19e79cF24977f03',
  //   createdAt: 1619280303,
  // },
  // {
  //   id: 'cakev2-hzn-bnb-eol',
  //   logo: 'bnb-pairs/HZN-BNB.svg',
  //   name: 'HZN-BNB LP',
  //   token: 'HZN-BNB LP2',
  //   tokenDescription: 'PancakeSwap',
  //   tokenAddress: '0xDc9a574b9B341D4a98cE29005b614e1E27430E74',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooCakeV2HZN-BNB',
  //   earnedTokenAddress: '0xeae012C26290f668E29b276247E1A48f60379792',
  //   earnContractAddress: '0xeae012C26290f668E29b276247E1A48f60379792',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'cakev2-hzn-bnb',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   retireReason: 'rewards',
  //   platform: 'PancakeSwap',
  //   assets: ['HZN', 'BNB'],
  //   addLiquidityUrl:
  //     'https://pancakeswap.finance/add/BNB/0xC0eFf7749b125444953ef89682201Fb8c6A917CD',
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?outputCurrency=0xC0eFf7749b125444953ef89682201Fb8c6A917CD',
  //   createdAt: 1619280342,
  // },
  // {
  //   id: 'cakev2-suter-bnb-eol',
  //   logo: 'bnb-pairs/SUTER-BNB.svg',
  //   name: 'SUTER-BNB LP',
  //   token: 'SUTER-BNB LP2',
  //   tokenDescription: 'PancakeSwap',
  //   tokenAddress: '0x2d5DB889392Bc3c8B023A8631ca230A033eEA1B8',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooCakeV2SUTER-BNB',
  //   earnedTokenAddress: '0x10Db0024045cDECc16b8D6581458afa4B47c0c05',
  //   earnContractAddress: '0x10Db0024045cDECc16b8D6581458afa4B47c0c05',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'cakev2-suter-bnb',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   retireReason: 'rewards',
  //   platform: 'PancakeSwap',
  //   assets: ['SUTER', 'BNB'],
  //   addLiquidityUrl:
  //     'https://pancakeswap.finance/add/BNB/0x4cfbbdfbd5bf0814472ff35c72717bd095ada055',
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?outputCurrency=0x4cfbbdfbd5bf0814472ff35c72717bd095ada055',
  //   createdAt: 1619280372,
  // },
  // {
  //   id: 'cakev2-ten-bnb-eol',
  //   logo: 'bnb-pairs/TEN-BNB.svg',
  //   name: 'TEN-BNB LP',
  //   token: 'TEN-BNB LP2',
  //   tokenDescription: 'PancakeSwap',
  //   tokenAddress: '0x1B415C3ec8095AfBF9d78882b3a6263c4ad141B5',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooCakev2TEN-BNB',
  //   earnedTokenAddress: '0xbE1F363921e4e83E308e6741B5470E38ed3cf6be',
  //   earnContractAddress: '0xbE1F363921e4e83E308e6741B5470E38ed3cf6be',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'cakev2-ten-bnb',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'PancakeSwap',
  //   assets: ['TEN', 'BNB'],
  //   addLiquidityUrl:
  //     'https://pancakeswap.finance/add/BNB/0xdff8cb622790b7f92686c722b02cab55592f152c',
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?outputCurrency=0xdff8cb622790b7f92686c722b02cab55592f152c',
  //   createdAt: 1619279428,
  // },
  // {
  //   id: 'cakev2-bscx-bnb-eol',
  //   logo: 'bnb-pairs/BSCX-BNB.svg',
  //   name: 'BSCX-BNB LP',
  //   token: 'BSCX-BNB LP2',
  //   tokenDescription: 'PancakeSwap',
  //   tokenAddress: '0x47C42b0A056A9C6e9C65b9Ef79020Af518e767A5',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooCakev2BSCX-BNB',
  //   earnedTokenAddress: '0x3f8C3120f57b9552e33097B83dFDdAB1539bAd47',
  //   earnContractAddress: '0x3f8C3120f57b9552e33097B83dFDdAB1539bAd47',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'cakev2-bscx-bnb',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   retireReason: 'rewards',
  //   platform: 'PancakeSwap',
  //   assets: ['BSCX', 'BNB'],
  //   addLiquidityUrl:
  //     'https://pancakeswap.finance/add/BNB/0x5ac52ee5b2a633895292ff6d8a89bb9190451587',
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?outputCurrency=0x5ac52ee5b2a633895292ff6d8a89bb9190451587',
  //   createdAt: 1619279524,
  // },
  // {
  //   id: 'cakev2-ctk-bnb-eol',
  //   logo: 'bnb-pairs/CTK-BNB.svg',
  //   name: 'CTK-BNB LP',
  //   token: 'CTK-BNB LP2',
  //   tokenDescription: 'PancakeSwap',
  //   tokenAddress: '0x460b4193Ec4C1a17372Aa5FDcd44c520ba658646',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooCakev2CTK-BNB',
  //   earnedTokenAddress: '0xecdc77241C58ff04fB0Bc259EA4B6d67edD43920',
  //   earnContractAddress: '0xecdc77241C58ff04fB0Bc259EA4B6d67edD43920',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'cakev2-ctk-bnb',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   retireReason: 'rewards',
  //   platform: 'PancakeSwap',
  //   assets: ['CTK', 'BNB'],
  //   addLiquidityUrl:
  //     'https://pancakeswap.finance/add/BNB/0xA8c2B8eec3d368C0253ad3dae65a5F2BBB89c929',
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?outputCurrency=0xA8c2B8eec3d368C0253ad3dae65a5F2BBB89c929',
  //   createdAt: 1619273466,
  // },
  // {
  //   id: 'bunny-bunny-eol',
  //   logo: 'single-assets/BUNNY.png',
  //   name: 'BUNNY',
  //   token: 'BUNNY',
  //   tokenDescription: 'Pancakebunny',
  //   tokenAddress: '0xC9849E6fdB743d08fAeE3E34dd2D1bc69EA11a51',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooBUNNY',
  //   earnedTokenAddress: '0x7f56672fCB5D1d1760511803A0a54c4d1e911dFD',
  //   earnContractAddress: '0x7f56672fCB5D1d1760511803A0a54c4d1e911dFD',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'tokens',
  //   oracleId: 'BUNNY',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   refund: true,
  //   platform: 'Other',
  //   refundContractAddress: '0xeD073c1BeC09Bb1963469910340BD8d0B2CC0413',
  //   assets: ['BUNNY'],
  //   buyTokenUrl: 'https://app.1inch.io/#/56/swap/BNB/BUNNY',
  //   createdAt: 1618937612,
  // },
  // {
  //   id: 'auto-eth-v2-eol',
  //   logo: 'single-assets/ETH.svg',
  //   name: 'ETH',
  //   token: 'ETH',
  //   tokenDescription: 'Venus (Autofarm)',
  //   tokenAddress: '0x2170Ed0880ac9A755fd29B2688956BD959F933F8',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooAutoETHv2',
  //   earnedTokenAddress: '0x0F47eaae1EC5fD11067455210c494a7828024524',
  //   earnContractAddress: '0x0F47eaae1EC5fD11067455210c494a7828024524',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'tokens',
  //   oracleId: 'ETH',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'Other',
  //   assets: ['ETH'],
  //   createdAt: 1618165676,
  // },
  // {
  //   id: 'belt-venus-blp-eol',
  //   logo: 'uncategorized/BELT-VENUSBLP.png',
  //   name: 'BUSD/USDT/USDC/DAI',
  //   token: 'VENUS BLP',
  //   tokenDescription: 'Belt',
  //   tokenAddress: '0x86aFa7ff694Ab8C985b79733745662760e454169',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooBeltVenusBLP',
  //   earnedTokenAddress: '0xb35Dc0b5eFd7c75590a9da55BE46d968c5804e24',
  //   earnContractAddress: '0xb35Dc0b5eFd7c75590a9da55BE46d968c5804e24',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'belt-venus-blp',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'Belt',
  //   assets: ['USDT', 'BUSD', 'USDC', 'DAI', 'VENUS BLP'],
  //   addLiquidityUrl: 'https://belt.fi/',
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?outputCurrency=0xe9e7cea3dedca5984780bafc599bd69add087d56',
  //   createdAt: 1615471190,
  // },
  // {
  //   id: 'mdex-bsc-mdx-bnb',
  //   logo: 'bnb-pairs/MDX-BNB.png',
  //   name: 'MDX-BNB LP',
  //   token: 'MDX-BNB LP',
  //   tokenDescription: 'Mdex',
  //   tokenAddress: '0xAf9Aa53146C5752BF6068A84B970E9fBB22a87bc',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooMdexMDX-BNB',
  //   earnedTokenAddress: '0x31e2d52A04FEb223109506E4c6f195C0cF16b98A',
  //   earnContractAddress: '0x31e2d52A04FEb223109506E4c6f195C0cF16b98A',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'mdex-bsc-mdx-bnb',
  //   oraclePrice: 0,
  //   depositsPaused: false,
  //   status: 'active',
  //   platform: 'Mdex',
  //   assets: ['MDX', 'BNB'],
  //   addLiquidityUrl: 'https://bsc.mdex.com/#/add/BNB/0x9C65AB58d8d978DB963e63f2bfB7121627e3a739',
  //   buyTokenUrl:
  //     'https://bsc.mdex.com/#/swap?outputCurrency=0x9c65ab58d8d978db963e63f2bfb7121627e3a739',
  //   createdAt: 1618044556,
  // },
  // {
  //   id: 'auto-btc-v2-eol',
  //   logo: 'single-assets/BTCB.svg',
  //   name: 'BTCB',
  //   token: 'BTCB',
  //   tokenDescription: 'Venus (Autofarm)',
  //   tokenAddress: '0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooAutoBTCBv2',
  //   earnedTokenAddress: '0x0845813938515FE56730c124Ab8CA2DF58B01463',
  //   earnContractAddress: '0x0845813938515FE56730c124Ab8CA2DF58B01463',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'tokens',
  //   oracleId: 'BTCB',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'Other',
  //   assets: ['BTCB'],
  //   createdAt: 1618165622,
  // },
  // {
  //   id: 'cake-broobee-bnb-eol',
  //   logo: 'bnb-pairs/bROOBEE-BNB.svg',
  //   name: 'bROOBEE-BNB LP',
  //   token: 'bROOBEE-BNB LP',
  //   tokenDescription: 'PancakeSwap',
  //   tokenAddress: '0x9e6f9f3382f9edC683203b528222c554c92382C2',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooPancakebROOBEE-BNB',
  //   earnedTokenAddress: '0xa5F6387fB58AC5268F130bFc2d0A5e53343fB798',
  //   earnContractAddress: '0xa5F6387fB58AC5268F130bFc2d0A5e53343fB798',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'cake-broobee-bnb',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'PancakeSwap',
  //   assets: ['bROOBEE', 'BNB'],
  //   removeLiquidityUrl:
  //     'https://v1exchange.pancakeswap.finance/#/remove/BNB/0xE64F5Cb844946C1F102Bd25bBD87a5aB4aE89Fbe',
  //   createdAt: 1618841436,
  // },
  // {
  //   id: 'banana-ada-eth',
  //   logo: 'eth-pairs/ADA-ETH.svg',
  //   name: 'ADA-ETH LP',
  //   token: 'ADA-ETH LP',
  //   tokenDescription: 'ApeSwap',
  //   tokenAddress: '0x61FE209E404166a53Cc627d0ae30A65606315dA7',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooApeADA-ETH',
  //   earnedTokenAddress: '0xd6D138fb65c2a68E728D70EfbAA54c794B73B6A0',
  //   earnContractAddress: '0xd6D138fb65c2a68E728D70EfbAA54c794B73B6A0',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'banana-ada-eth',
  //   oraclePrice: 0,
  //   depositsPaused: false,
  //   status: 'active',
  //   platform: 'ApeSwap',
  //   assets: ['ADA', 'ETH'],
  //   risks: [
  //     'COMPLEXITY_LOW',
  //     'BATTLE_TESTED',
  //     'IL_LOW',
  //     'MCAP_LARGE',
  //     'AUDIT',
  //     'CONTRACTS_VERIFIED',
  //   ],
  //   addLiquidityUrl:
  //     'https://dex.apeswap.finance/#/add/0x3EE2200Efb3400fAbB9AacF31297cBdD1d435D47/0x2170Ed0880ac9A755fd29B2688956BD959F933F8',
  //   buyTokenUrl:
  //     'https://dex.apeswap.finance/#/swap?inputCurrency=0x2170ed0880ac9a755fd29b2688956bd959f933f8&outputCurrency=0x3ee2200efb3400fabb9aacf31297cbdd1d435d47',
  //   createdAt: 1618840515,
  // },
  // {
  //   id: 'banana-iota-bnb',
  //   logo: 'bnb-pairs/IOTA-BNB.svg',
  //   name: 'IOTA-BNB LP',
  //   token: 'IOTA-BNB LP',
  //   tokenDescription: 'ApeSwap',
  //   tokenAddress: '0x0D70924695B6Ae496F0A74A36bf79d47307dD519',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooApeIOTA-BNB',
  //   earnedTokenAddress: '0x5705Ca9E59485E77240a32AAd50a64bB72996e02',
  //   earnContractAddress: '0x5705Ca9E59485E77240a32AAd50a64bB72996e02',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'banana-iota-bnb',
  //   oraclePrice: 0,
  //   depositsPaused: false,
  //   status: 'active',
  //   platform: 'ApeSwap',
  //   assets: ['IOTA', 'BNB'],
  //   addLiquidityUrl:
  //     'https://dex.apeswap.finance/#/add/ETH/0xd944f1D1e9d5f9Bb90b62f9D45e447D989580782',
  //   buyTokenUrl:
  //     'https://dex.apeswap.finance/#/swap?inputCurrency=0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c&outputCurrency=0xd944f1D1e9d5f9Bb90b62f9D45e447D989580782',
  //   createdAt: 1618840641,
  // },
  // {
  //   id: 'banana-bat-bnb',
  //   logo: 'bnb-pairs/BAT-BNB.svg',
  //   name: 'BAT-BNB LP',
  //   token: 'BAT-BNB LP',
  //   tokenDescription: 'ApeSwap',
  //   tokenAddress: '0x6e425B4fc4Efd070Dc0deF1654a17946C7e6b3C4',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooApeBAT-BNB',
  //   earnedTokenAddress: '0xec10AaC4A445d28Eebd50F908cfc69816C8E9d7d',
  //   earnContractAddress: '0xec10AaC4A445d28Eebd50F908cfc69816C8E9d7d',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'banana-bat-bnb',
  //   oraclePrice: 0,
  //   depositsPaused: false,
  //   status: 'active',
  //   platform: 'ApeSwap',
  //   assets: ['BAT', 'BNB'],
  //   addLiquidityUrl:
  //     'https://dex.apeswap.finance/#/add/ETH/0x101d82428437127bF1608F699CD651e6Abf9766E',
  //   buyTokenUrl:
  //     'https://dex.apeswap.finance/#/swap?inputCurrency=0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c&outputCurrency=0x101d82428437127bF1608F699CD651e6Abf9766E',
  //   createdAt: 1618840593,
  // },
  // {
  //   id: 'ellipsis-fusdt-3eps-eol',
  //   logo: 'uncategorized/epsFUSDT.png',
  //   name: 'fUSDT/BUSD/USDT/USDC',
  //   token: 'EPS fUSDT',
  //   tokenDescription: 'Ellipsis',
  //   tokenAddress: '0x373410A99B64B089DFE16F1088526D399252dacE',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'MooEllipsisFUSDT',
  //   earnedTokenAddress: '0x8D3B7a0b20d490cbDACC2c555c05b7132B856e4b',
  //   earnContractAddress: '0x8D3B7a0b20d490cbDACC2c555c05b7132B856e4b',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'ellipsis-fusdt-3eps',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   retireReason: 'rewards',
  //   platform: 'Ellipsis',
  //   assets: ['fUSDT', 'USDT', 'BUSD', 'USDC'],
  //   addLiquidityUrl: 'https://ellipsis.finance/pool',
  //   createdAt: 1618658840,
  // },
  // {
  //   id: 'cake-apys-bnb-eol',
  //   logo: 'bnb-pairs/APYS-BNB.svg',
  //   name: 'APYS-BNB LP',
  //   token: 'APYS-BNB LP',
  //   tokenDescription: 'PancakeSwap',
  //   tokenAddress: '0xE5783Cc9dFb3E7e474B81B07369a008e80F1cEdb',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooPancakeAPYS-BNB',
  //   earnedTokenAddress: '0xc3991d99a73eD1Fd9d28cd4623DD72732745963b',
  //   earnContractAddress: '0xc3991d99a73eD1Fd9d28cd4623DD72732745963b',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'cake-apys-bnb',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'PancakeSwap',
  //   assets: ['APYS', 'BNB'],
  //   removeLiquidityUrl:
  //     'https://v1exchange.pancakeswap.finance/#/remove/BNB/0x37dfACfaeDA801437Ff648A1559d73f4C40aAcb7',
  //   createdAt: 1618230406,
  // },
  // {
  //   id: 'auto-eth-beth',
  //   logo: 'eth-pairs/BETH-ETH.svg',
  //   name: 'BETH-ETH LP',
  //   token: 'BETH-ETH Mdex LP',
  //   tokenDescription: 'Mdex',
  //   tokenAddress: '0x3fe3DeE31cC404Aa4C8de6A6a3cbBcb60358FcB0',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooAutoMdexBETH-ETH',
  //   earnedTokenAddress: '0xad777a366D5aD4A728A03C2CC61a3c3Ea8935BBB',
  //   earnContractAddress: '0xad777a366D5aD4A728A03C2CC61a3c3Ea8935BBB',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'auto-eth-beth',
  //   oraclePrice: 0,
  //   depositsPaused: false,
  //   status: 'active',
  //   platform: 'Mdex',
  //   assets: ['BETH', 'ETH'],
  //   depositFee: '<0.1%',
  //   addLiquidityUrl:
  //     'https://bsc.mdex.com/#/add/0x250632378E573c6Be1AC2f97Fcdf00515d0Aa91B/0x2170Ed0880ac9A755fd29B2688956BD959F933F8',
  //   buyTokenUrl:
  //     'https://bsc.mdex.com/#/swap?inputCurrency=0x2170ed0880ac9a755fd29b2688956bd959f933f8&outputCurrency=0x250632378e573c6be1ac2f97fcdf00515d0aa91b',
  //   createdAt: 1618409090,
  // },
  // {
  //   id: 'auto-eth-usdt',
  //   logo: 'usdt-pairs/ETH-USDT.svg',
  //   name: 'ETH-USDT LP',
  //   token: 'ETH-USDT Mdex LP',
  //   tokenDescription: 'Mdex',
  //   tokenAddress: '0x0FB881c078434b1C0E4d0B64d8c64d12078b7Ce2',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooAutoMdexETH-USDT',
  //   earnedTokenAddress: '0x68c39886eA459b4a59758F1e94c3d20C93d47133',
  //   earnContractAddress: '0x68c39886eA459b4a59758F1e94c3d20C93d47133',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'auto-eth-usdt',
  //   oraclePrice: 0,
  //   depositsPaused: false,
  //   status: 'active',
  //   platform: 'Mdex',
  //   assets: ['ETH', 'USDT'],
  //   depositFee: '<0.1%',
  //   addLiquidityUrl:
  //     'https://bsc.mdex.com/#/add/0x55d398326f99059fF775485246999027B3197955/0x2170Ed0880ac9A755fd29B2688956BD959F933F8',
  //   buyTokenUrl:
  //     'https://bsc.mdex.com/#/swap?inputCurrency=0x2170ed0880ac9a755fd29b2688956bd959f933f8&outputCurrency=0x55d398326f99059ff775485246999027b3197955',
  //   createdAt: 1618408691,
  // },
  // {
  //   id: 'auto-btcb-usdt',
  //   logo: 'usdt-pairs/BTCB-USDT.svg',
  //   name: 'BTCB-USDT LP',
  //   token: 'BTCB-USDT Mdex LP',
  //   tokenDescription: 'Mdex',
  //   tokenAddress: '0xda28Eb7ABa389C1Ea226A420bCE04Cb565Aafb85',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooAutoMdexBTCB-USDT',
  //   earnedTokenAddress: '0xE5e79043eC57b12F2d15d4a230ED9C7d732Ed93A',
  //   earnContractAddress: '0xE5e79043eC57b12F2d15d4a230ED9C7d732Ed93A',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'auto-btcb-usdt',
  //   oraclePrice: 0,
  //   depositsPaused: false,
  //   status: 'active',
  //   platform: 'Mdex',
  //   assets: ['BTCB', 'USDT'],
  //   depositFee: '<0.1%',
  //   addLiquidityUrl:
  //     'https://bsc.mdex.com/#/add/0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c/0x55d398326f99059fF775485246999027B3197955',
  //   buyTokenUrl:
  //     'https://bsc.mdex.com/#/swap?inputCurrency=0x55d398326f99059ff775485246999027b3197955&outputCurrency=0x7130d2a12b9bcbfae4f2634d864a1ee1ce3ead9c',
  //   createdAt: 1618408661,
  // },
  // {
  //   id: 'auto-btcb-eth',
  //   logo: 'eth-pairs/BTCB-ETH.svg',
  //   name: 'BTCB-ETH LP',
  //   token: 'BTCB-ETH Mdex LP',
  //   tokenDescription: 'Mdex',
  //   tokenAddress: '0x577d005912C49B1679B4c21E334FdB650E92C077',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooAutoMdexBTCB-ETH',
  //   earnedTokenAddress: '0x31e77776b924Cd5f0E624771C9B3d2bD6B9c919E',
  //   earnContractAddress: '0x31e77776b924Cd5f0E624771C9B3d2bD6B9c919E',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'auto-btcb-eth',
  //   oraclePrice: 0,
  //   depositsPaused: false,
  //   status: 'active',
  //   platform: 'Mdex',
  //   assets: ['BTCB', 'ETH'],
  //   risks: [
  //     'COMPLEXITY_LOW',
  //     'BATTLE_TESTED',
  //     'IL_LOW',
  //     'MCAP_LARGE',
  //     'AUDIT',
  //     'CONTRACTS_VERIFIED',
  //   ],
  //   depositFee: '<0.1%',
  //   addLiquidityUrl:
  //     'https://bsc.mdex.com/#/add/0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c/0x2170Ed0880ac9A755fd29B2688956BD959F933F8',
  //   buyTokenUrl:
  //     'https://bsc.mdex.com/#/swap?inputCurrency=0x2170ed0880ac9a755fd29b2688956bd959f933f8&outputCurrency=0x7130d2a12b9bcbfae4f2634d864a1ee1ce3ead9c',
  //   createdAt: 1618409054,
  // },
  // {
  //   id: 'auto-usdt-busd',
  //   logo: 'busd-pairs/USDT-BUSD.png',
  //   name: 'USDT-BUSD LP',
  //   token: 'USDT-BUSD Mdex LP',
  //   tokenDescription: 'Mdex',
  //   tokenAddress: '0x62c1dEC1fF328DCdC157Ae0068Bb21aF3967aCd9',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooAutoMdexUSDT-BUSD',
  //   earnedTokenAddress: '0x17720F863DA01Bc9e266e4eE872E3c98fA1FeAA8',
  //   earnContractAddress: '0x17720F863DA01Bc9e266e4eE872E3c98fA1FeAA8',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'auto-busd-usdt',
  //   oraclePrice: 0,
  //   depositsPaused: false,
  //   status: 'active',
  //   platform: 'Mdex',
  //   assets: ['USDT', 'BUSD'],
  //   depositFee: '<0.1%',
  //   addLiquidityUrl:
  //     'https://bsc.mdex.com/#/add/0x55d398326f99059fF775485246999027B3197955/0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
  //   buyTokenUrl:
  //     'https://bsc.mdex.com/#/swap?inputCurrency=0x55d398326f99059ff775485246999027b3197955&outputCurrency=0xe9e7cea3dedca5984780bafc599bd69add087d56',
  //   createdAt: 1618407251,
  // },
  // {
  //   id: 'mash-mash-busd-eol',
  //   logo: 'degens/mash-busd.png',
  //   name: 'MASH-BUSD LP',
  //   token: 'MASH-BUSD LP',
  //   tokenDescription: 'Marshmallow',
  //   tokenAddress: '0x87C182EDB12f74d561519AB586205fE6CD75363a',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooMashMASH-BUSD',
  //   earnedTokenAddress: '0x8D6130b05c59bf8dCCde1921D40979750f5685E7',
  //   earnContractAddress: '0x8D6130b05c59bf8dCCde1921D40979750f5685E7',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'mash-mash-busd',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'Other',
  //   assets: ['MASH', 'BUSD'],
  //   addLiquidityUrl:
  //     'https://exchange.marshmallowdefi.com/#/add/0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56/0x787732f27D18495494cea3792ed7946BbCFF8db2',
  //   buyTokenUrl:
  //     'https://exchange.marshmallowdefi.com/#/swap?outputCurrency=0x787732f27D18495494cea3792ed7946BbCFF8db2',
  //   createdAt: 1618484416,
  // },
  // {
  //   id: 'mash-mash-bnb-eol',
  //   logo: 'degens/mash-bnb.png',
  //   name: 'MASH-BNB LP',
  //   token: 'MASH-BNB LP',
  //   tokenDescription: 'Marshmallow',
  //   tokenAddress: '0x7621886AC71e985DBea4f3f563BBB5a7865876A8',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooMashMASH-BNB',
  //   earnedTokenAddress: '0x45e13311ee99f040bF9a1207eb864Aae5AEa9119',
  //   earnContractAddress: '0x45e13311ee99f040bF9a1207eb864Aae5AEa9119',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'mash-mash-bnb',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'Other',
  //   assets: ['MASH', 'BNB'],
  //   addLiquidityUrl:
  //     'https://exchange.marshmallowdefi.com/#/add/ETH/0x787732f27D18495494cea3792ed7946BbCFF8db2',
  //   buyTokenUrl:
  //     'https://exchange.marshmallowdefi.com/#/swap?outputCurrency=0x787732f27D18495494cea3792ed7946BbCFF8db2',
  //   createdAt: 1618484353,
  // },
  // {
  //   id: 'mash-mash-eol',
  //   logo: 'degens/mash.png',
  //   name: 'MASH',
  //   token: 'MASH',
  //   tokenDescription: 'Marshmallow',
  //   tokenAddress: '0x787732f27D18495494cea3792ed7946BbCFF8db2',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooMashMASH',
  //   earnedTokenAddress: '0xE76381E4fd8C4492C2e61000D123AE2Ca3BDa41C',
  //   earnContractAddress: '0xE76381E4fd8C4492C2e61000D123AE2Ca3BDa41C',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'tokens',
  //   oracleId: 'MASH',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'Other',
  //   assets: ['MASH'],
  //   buyTokenUrl:
  //     'https://exchange.marshmallowdefi.com/#/swap?outputCurrency=0x787732f27D18495494cea3792ed7946BbCFF8db2',
  //   createdAt: 1618484152,
  // },
  // {
  //   id: 'mdex-bsc-mdx-busd',
  //   logo: 'busd-pairs/MDX-BUSD.png',
  //   name: 'MDX-BUSD LP',
  //   token: 'MDX-BUSD LP',
  //   tokenDescription: 'Mdex',
  //   tokenAddress: '0x223740a259E461aBeE12D84A9FFF5Da69Ff071dD',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooMdexMDX-BUSD',
  //   earnedTokenAddress: '0x38507dAEe5F1E6c397d1767B5202931f12feef74',
  //   earnContractAddress: '0x38507dAEe5F1E6c397d1767B5202931f12feef74',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'mdex-bsc-mdx-busd',
  //   oraclePrice: 0,
  //   depositsPaused: false,
  //   status: 'active',
  //   platform: 'Mdex',
  //   assets: ['MDX', 'BUSD'],
  //   addLiquidityUrl:
  //     'https://bsc.mdex.com/#/add/0x9C65AB58d8d978DB963e63f2bfB7121627e3a739/0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
  //   buyTokenUrl:
  //     'https://bsc.mdex.com/#/swap?outputCurrency=0x9c65ab58d8d978db963e63f2bfb7121627e3a739',
  //   createdAt: 1618318138,
  // },
  // {
  //   id: 'palm-palm-eol',
  //   logo: 'degens/PALM.png',
  //   name: 'PALM',
  //   token: 'PALM',
  //   tokenDescription: 'YieldBay',
  //   tokenAddress: '0x9768E5b2d8e761905BC81Dfc554f9437A46CdCC6',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooPalmPALM',
  //   earnedTokenAddress: '0xFAE7E8b7AB3bd583dEa936E829F1132909A6C6cD',
  //   earnContractAddress: '0xFAE7E8b7AB3bd583dEa936E829F1132909A6C6cD',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'tokens',
  //   oracleId: 'PALM',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'Other',
  //   assets: ['PALM'],
  //   buyTokenUrl:
  //     'https://exchange.yieldbay.finance/#/swap?outputCurrency=0x9768e5b2d8e761905bc81dfc554f9437a46cdcc6',
  //   createdAt: 1618319620,
  // },
  // {
  //   id: 'cake-hoo-busd-eol',
  //   logo: 'busd-pairs/HOO-BUSD.svg',
  //   name: 'HOO-BUSD LP',
  //   token: 'HOO-BUSD LP',
  //   tokenDescription: 'PancakeSwap',
  //   tokenAddress: '0x3C9E55EdBD937ae0aD8C084a1A8302110612A371',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooPancakeHOO-BUSD',
  //   earnedTokenAddress: '0x9C21d39c1e790A966c81EBf5Db4FE5e8C564d1bF',
  //   earnContractAddress: '0x9C21d39c1e790A966c81EBf5Db4FE5e8C564d1bF',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'cake-hoo-busd',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'PancakeSwap',
  //   assets: ['HOO', 'BUSD'],
  //   removeLiquidityUrl:
  //     'https://v1exchange.pancakeswap.finance/#/remove/0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56/0xE1d1F66215998786110Ba0102ef558b22224C016',
  //   createdAt: 1618231003,
  // },
  // {
  //   id: 'cake-itam-bnb-eol',
  //   logo: 'bnb-pairs/ITAM-BNB.svg',
  //   name: 'ITAM-BNB LP',
  //   token: 'ITAM-BNB LP',
  //   tokenDescription: 'PancakeSwap',
  //   tokenAddress: '0xCdC53345192D0e31eEAD03D7E9e008Ee659FAEbE',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooPancakeITAM-BNB',
  //   earnedTokenAddress: '0x4dD62Fc76fD57175151777e845dfe9E981b87aE7',
  //   earnContractAddress: '0x4dD62Fc76fD57175151777e845dfe9E981b87aE7',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'cake-itam-bnb',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'PancakeSwap',
  //   assets: ['ITAM', 'BNB'],
  //   removeLiquidityUrl:
  //     'https://v1exchange.pancakeswap.finance/#/remove/BNB/0x04C747b40Be4D535fC83D09939fb0f626F32800B',
  //   createdAt: 1618230328,
  // },
  // {
  //   id: 'mdex-xvs-eol',
  //   logo: 'single-assets/XVS.png',
  //   name: 'XVS',
  //   token: 'XVS',
  //   tokenDescription: 'Mdex',
  //   tokenAddress: '0xcF6BB5389c92Bdda8a3747Ddb454cB7a64626C63',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooMdexXVS',
  //   earnedTokenAddress: '0x93aBCe226ac5082aD8702681354C48E739eEFDd5',
  //   earnContractAddress: '0x93aBCe226ac5082aD8702681354C48E739eEFDd5',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'tokens',
  //   oracleId: 'XVS',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'Mdex',
  //   assets: ['XVS'],
  //   createdAt: 1618228732,
  // },
  // {
  //   id: 'mdex-uni-eol',
  //   logo: 'single-assets/UNI.png',
  //   name: 'UNI',
  //   token: 'UNI',
  //   tokenDescription: 'Mdex',
  //   tokenAddress: '0xBf5140A22578168FD562DCcF235E5D43A02ce9B1',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooMdexUNI',
  //   earnedTokenAddress: '0xC57869A0bDeF07bf4E9Ffdd598Ffc235248987d4',
  //   earnContractAddress: '0xC57869A0bDeF07bf4E9Ffdd598Ffc235248987d4',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'tokens',
  //   oracleId: 'UNI',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'Mdex',
  //   assets: ['UNI'],
  //   createdAt: 1618228807,
  // },
  // {
  //   id: 'auto-sxp-v2-eol',
  //   logo: 'single-assets/SXP.svg',
  //   name: 'SXP',
  //   token: 'SXP',
  //   tokenDescription: 'Venus (Autofarm)',
  //   tokenAddress: '0x47BEAd2563dCBf3bF2c9407fEa4dC236fAbA485A',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooAutoSXPv2',
  //   earnedTokenAddress: '0xbB7Ed513e421ad454191790199E4731757545793',
  //   earnContractAddress: '0xbB7Ed513e421ad454191790199E4731757545793',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'tokens',
  //   oracleId: 'SXP',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'Other',
  //   assets: ['SXP'],
  //   createdAt: 1618231753,
  // },
  // {
  //   id: 'auto-wbnb-v2-eol',
  //   logo: 'single-assets/WBNB.svg',
  //   name: 'WBNB',
  //   token: 'WBNB',
  //   tokenDescription: 'Venus (Autofarm)',
  //   tokenAddress: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooAutoWbnbFixed',
  //   earnedTokenAddress: '0x5B06aA1ebd2e15bC6001076355E5B4C39Cbc83F3',
  //   earnContractAddress: '0x5B06aA1ebd2e15bC6001076355E5B4C39Cbc83F3',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'tokens',
  //   oracleId: 'WBNB',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'Other',
  //   assets: ['BNB'],
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?outputCurrency=0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
  //   createdAt: 1613479169,
  // },
  // {
  //   id: 'auto-busd-v2-eol',
  //   logo: 'single-assets/BUSD.svg',
  //   name: 'BUSD',
  //   token: 'BUSD',
  //   tokenDescription: 'Venus (Autofarm)',
  //   tokenAddress: '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooAutoBUSDv2',
  //   earnedTokenAddress: '0x4533B96E7737D2158a0DFf9a3a2A97f4419ef646',
  //   earnContractAddress: '0x4533B96E7737D2158a0DFf9a3a2A97f4419ef646',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'tokens',
  //   oracleId: 'BUSD',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'Other',
  //   assets: ['BUSD'],
  //   createdAt: 1618076971,
  // },
  // {
  //   id: 'auto-usdt-v2-eol',
  //   logo: 'single-assets/USDT.svg',
  //   name: 'USDT',
  //   token: 'USDT',
  //   tokenDescription: 'Venus (Autofarm)',
  //   tokenAddress: '0x55d398326f99059fF775485246999027B3197955',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooAutoUSDTv2',
  //   earnedTokenAddress: '0xb0C71ae9a71bbf03BE6F33A29a71C485472f5a1F',
  //   earnContractAddress: '0xb0C71ae9a71bbf03BE6F33A29a71C485472f5a1F',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'tokens',
  //   oracleId: 'USDT',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'Other',
  //   assets: ['USDT'],
  //   createdAt: 1618165532,
  // },
  // {
  //   id: 'auto-usdc-v2-eol',
  //   logo: 'single-assets/USDC.svg',
  //   name: 'USDC',
  //   token: 'USDC',
  //   tokenDescription: 'Venus (Autofarm)',
  //   tokenAddress: '0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooAutoUSDCv2',
  //   earnedTokenAddress: '0x5d931BBcD7010935103AC1FDb8B43625A7CB67A4',
  //   earnContractAddress: '0x5d931BBcD7010935103AC1FDb8B43625A7CB67A4',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'tokens',
  //   oracleId: 'USDC',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'Other',
  //   assets: ['USDC'],
  //   createdAt: 1618165574,
  // },
  // {
  //   id: 'blizzard-xblzd-bnb-eol',
  //   logo: 'degens/BLZD-BNB.png',
  //   name: 'xBLZD-BNB LP',
  //   token: 'xBLZD-BNB LP2',
  //   tokenDescription: 'PancakeSwap',
  //   tokenAddress: '0xD9002B7E7d63A71F04a16840DA028e1cd534889D',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooBlizzardxBLZD-BNBV2',
  //   earnedTokenAddress: '0x415b51780f4B056C5b1b3CB5fab9366e00859175',
  //   earnContractAddress: '0x415b51780f4B056C5b1b3CB5fab9366e00859175',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'blizzard-xblzd-bnb',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'Other',
  //   assets: ['xBLZD', 'BNB'],
  //   retireReason: 'rewards',
  //   addLiquidityUrl:
  //     'https://pancakeswap.finance/add/BNB/0x9a946c3Cb16c08334b69aE249690C236Ebd5583E',
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?outputCurrency=0x9a946c3Cb16c08334b69aE249690C236Ebd5583E',
  //   createdAt: 1619315443,
  // },
  // {
  //   id: 'blizzard-xblzd-busd-eol',
  //   logo: 'degens/BLZD-BUSD.png',
  //   name: 'xBLZD-BUSD LP',
  //   token: 'xBLZD-BUSD LP2',
  //   tokenDescription: 'PancakeSwap',
  //   tokenAddress: '0xeDCd99aD43C6911D37780158370ec1913Ee9e7D3',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooBlizzardxBLZD-BUSDV2',
  //   earnedTokenAddress: '0x17436A6D0ACb7f31D62C7484E5C77eC9A6122837',
  //   earnContractAddress: '0x17436A6D0ACb7f31D62C7484E5C77eC9A6122837',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'blizzard-xblzd-busd',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'Other',
  //   assets: ['xBLZD', 'BUSD'],
  //   addLiquidityUrl:
  //     'https://pancakeswap.finance/add/0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56/0x9a946c3Cb16c08334b69aE249690C236Ebd5583E',
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?inputCurrency=0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56&outputCurrency=0x9a946c3Cb16c08334b69aE249690C236Ebd5583E',
  //   createdAt: 1619315041,
  // },
  // {
  //   id: 'blizzard-xblzd-bnb-old-eol',
  //   logo: 'degens/BLZD-BNB.png',
  //   name: 'xBLZD-BNB LP',
  //   token: 'xBLZD-BNB LP',
  //   tokenDescription: 'PancakeSwap',
  //   tokenAddress: '0xfC207DB720851f52545229E406068b205E02B952',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooBlizzardxBLZD-BNB',
  //   earnedTokenAddress: '0xF7E3273930D8ffdb967BD5B4afe67aa195DF7347',
  //   earnContractAddress: '0xF7E3273930D8ffdb967BD5B4afe67aa195DF7347',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'blizzard-xblzd-bnb',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   retireReason: 'rewards',
  //   platform: 'Other',
  //   assets: ['xBLZD', 'BNB'],
  //   removeLiquidityUrl:
  //     'https://v1exchange.pancakeswap.finance/#/remove/BNB/0x9a946c3Cb16c08334b69aE249690C236Ebd5583E',
  //   createdAt: 1617944295,
  // },
  // {
  //   id: 'blizzard-xblzd-busd-old-eol',
  //   logo: 'degens/BLZD-BUSD.png',
  //   name: 'xBLZD-BUSD LP',
  //   token: 'xBLZD-BUSD LP',
  //   tokenDescription: 'PancakeSwap',
  //   tokenAddress: '0x33e75f29D52a8BcfBC54a79ED6eF9482b2c035a2',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooBlizzardxBLZD-BUSD',
  //   earnedTokenAddress: '0xE4012400f60ffae2584F9D9Ee61Ca65B31A24c7F',
  //   earnContractAddress: '0xE4012400f60ffae2584F9D9Ee61Ca65B31A24c7F',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'blizzard-xblzd-busd',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'Other',
  //   assets: ['xBLZD', 'BUSD'],
  //   removeLiquidityUrl:
  //     'https://v1exchange.pancakeswap.finance/#/remove/0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56/0x9a946c3Cb16c08334b69aE249690C236Ebd5583E',
  //   createdAt: 1617945243,
  // },
  // {
  //   id: 'blizzard-xblzd-eol2',
  //   logo: 'degens/BLZD.png',
  //   name: 'xBLZDv2',
  //   token: 'xBLZDv2',
  //   tokenDescription: 'Blizzard',
  //   tokenAddress: '0x9a946c3Cb16c08334b69aE249690C236Ebd5583E',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooBlizzardxBLZDv2',
  //   earnedTokenAddress: '0x47a27220Db5458DB4b238fefCBb8178232cBFa5D',
  //   earnContractAddress: '0x47a27220Db5458DB4b238fefCBb8178232cBFa5D',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'tokens',
  //   oracleId: 'xBLZD',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   retireReason: 'rewards',
  //   platform: 'Other',
  //   assets: ['xBLZD'],
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?outputCurrency=0x9a946c3Cb16c08334b69aE249690C236Ebd5583E',
  //   createdAt: 1633444720,
  // },
  // {
  //   id: 'blizzard-xblzd-eol',
  //   logo: 'degens/BLZD.png',
  //   name: 'xBLZD',
  //   token: 'xBLZD',
  //   tokenDescription: 'Blizzard',
  //   tokenAddress: '0x9a946c3Cb16c08334b69aE249690C236Ebd5583E',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooBlizzardxBLZD',
  //   earnedTokenAddress: '0xf69bA3131029173Ca97aa43400B10689f5C23f59',
  //   earnContractAddress: '0xf69bA3131029173Ca97aa43400B10689f5C23f59',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'tokens',
  //   oracleId: 'xBLZD',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   retireReason: 'upgrade',
  //   platform: 'Other',
  //   assets: ['xBLZD'],
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?outputCurrency=0x9a946c3Cb16c08334b69aE249690C236Ebd5583E',
  //   createdAt: 1617945456,
  // },
  // {
  //   id: 'blizzard-blzd-bnb-eol',
  //   logo: 'degens/BLZD-BNB.png',
  //   name: 'BLZD-BNB LP',
  //   token: 'BLZD-BNB LP',
  //   tokenDescription: 'PancakeSwap',
  //   tokenAddress: '0x27A5a5c1fF96447F2a0c4baDcF26E7c65C040E3C',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooBlizzardBLZD-BNB',
  //   earnedTokenAddress: '0x57e6f78a7e644b8532d8c4f3A354c921709Aa897',
  //   earnContractAddress: '0x57e6f78a7e644b8532d8c4f3A354c921709Aa897',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'blizzard-blzd-bnb',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'Other',
  //   assets: ['BLZD', 'BNB'],
  //   removeLiquidityUrl:
  //     'https://v1exchange.pancakeswap.finance/#/remove/BNB/0x57067A6BD75c0E95a6A5f158455926e43E79BeB0',
  //   createdAt: 1616212921,
  // },
  // {
  //   id: 'blizzard-blzd-busd-eol',
  //   logo: 'degens/BLZD-BUSD.png',
  //   name: 'BLZD-BUSD LP',
  //   token: 'BLZD-BUSD LP',
  //   tokenDescription: 'PancakeSwap',
  //   tokenAddress: '0xE9C53B5Ab0C9cDBd72A03151a628863C28c55A6A',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooBlizzardBLZD-BUSD',
  //   earnedTokenAddress: '0x1EdfEc6849C54Ee9187539516C6a67ADAcBf9704',
  //   earnContractAddress: '0x1EdfEc6849C54Ee9187539516C6a67ADAcBf9704',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'blizzard-blzd-busd',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'Other',
  //   assets: ['BLZD', 'BUSD'],
  //   removeLiquidityUrl:
  //     'https://v1exchange.pancakeswap.finance/#/remove/0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56/0x57067A6BD75c0E95a6A5f158455926e43E79BeB0',
  //   createdAt: 1616215411,
  // },
  // {
  //   id: 'blizzard-blzd',
  //   logo: 'degens/BLZD.png',
  //   name: 'BLZD',
  //   token: 'BLZD',
  //   tokenDescription: 'Blizzard',
  //   tokenAddress: '0x57067A6BD75c0E95a6A5f158455926e43E79BeB0',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooBlizzardBLZD',
  //   earnedTokenAddress: '0x580b899D8d856DFe3da7895466F779F1A509D2Fd',
  //   earnContractAddress: '0x580b899D8d856DFe3da7895466F779F1A509D2Fd',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'tokens',
  //   oracleId: 'BLZD',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'Other',
  //   assets: ['BLZD'],
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?outputCurrency=0x57067A6BD75c0E95a6A5f158455926e43E79BeB0',
  //   createdAt: 1616302984,
  // },
  // {
  //   id: 'auto-cake-bnb-eol',
  //   name: 'CAKE-BNB LP',
  //   token: 'CAKE-BNB LP',
  //   tokenDescription: 'PancakeSwap (Autofarm)',
  //   tokenAddress: '0xA527a61703D82139F8a06Bc30097cC9CAA2df5A6',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooAutoCAKE-BNB',
  //   earnedTokenAddress: '0x5DA77c7DBF6dBa1155CF343db06a7D79aaaad9Ee',
  //   earnContractAddress: '0x5DA77c7DBF6dBa1155CF343db06a7D79aaaad9Ee',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'auto-cake-bnb',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'Other',
  //   assets: ['CAKE', 'BNB'],
  //   removeLiquidityUrl:
  //     'https://v1exchange.pancakeswap.finance/#/remove/BNB/0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82',
  //   createdAt: 1615208558,
  // },
  // {
  //   id: 'mdex-bsc-eth-usdt',
  //   logo: 'uncategorized/ETH-USDT.png',
  //   name: 'ETH-USDT LP',
  //   token: 'ETH-USDT LP',
  //   tokenDescription: 'Mdex',
  //   tokenAddress: '0x0FB881c078434b1C0E4d0B64d8c64d12078b7Ce2',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooMdexETH-USDT',
  //   earnedTokenAddress: '0x2C74A61556AfF02Be19B3d7a746ba75A38210fd3',
  //   earnContractAddress: '0x2C74A61556AfF02Be19B3d7a746ba75A38210fd3',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'mdex-bsc-eth-usdt',
  //   oraclePrice: 0,
  //   depositsPaused: false,
  //   status: 'active',
  //   platform: 'Mdex',
  //   assets: ['ETH', 'USDT'],
  //   addLiquidityUrl:
  //     'https://bsc.mdex.com/#/add/0x2170Ed0880ac9A755fd29B2688956BD959F933F8/0x55d398326f99059fF775485246999027B3197955',
  //   createdAt: 1618043842,
  // },
  // {
  //   id: 'auto-auto-bnb-eol',
  //   logo: 'bnb-pairs/AUTO-BNB.png',
  //   name: 'AUTO-BNB LP',
  //   token: 'AUTO-BNB LP',
  //   tokenDescription: 'PancakeSwap (Autofarm)',
  //   tokenAddress: '0x4d0228EBEB39f6d2f29bA528e2d15Fc9121Ead56',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooAutoAUTO-BNB',
  //   earnedTokenAddress: '0x6Cb6D451e7E7ca5d5F73715D6B5dfc55EfbC1f82',
  //   earnContractAddress: '0x6Cb6D451e7E7ca5d5F73715D6B5dfc55EfbC1f82',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'auto-auto-bnb',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'Other',
  //   assets: ['AUTO', 'BNB'],
  //   retireReason: 'rewards',
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?outputCurrency=0xa184088a740c695E156F91f5cC086a06bb78b827',
  //   createdAt: 1615294122,
  // },
  // {
  //   id: 'typh-typh-bnb',
  //   logo: 'bnb-pairs/TYPH-BNB.png',
  //   name: 'TYPH-BNB LP',
  //   token: 'TYPH-BNB LP',
  //   tokenDescription: 'PancakeSwap (Typhoon)',
  //   tokenAddress: '0xc9299FE3656d2d1BE9F5f05D046c33b88BB762cA',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooTyphTYPH-BNB',
  //   earnedTokenAddress: '0xFC5C3411d4415E4fDff8f842F1Ae109350e61249',
  //   earnContractAddress: '0xFC5C3411d4415E4fDff8f842F1Ae109350e61249',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'typh-typh-bnb',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'Other',
  //   assets: ['TYPH', 'BNB'],
  //   removeLiquidityUrl:
  //     'https://v1exchange.pancakeswap.finance/#/remove/BNB/0x4090e535f2e251f5f88518998b18b54d26b3b07c',
  //   createdAt: 1617962171,
  // },
  // {
  //   id: 'biti-biti-bnb',
  //   logo: 'bnb-pairs/BITI-BNB.png',
  //   name: 'BITI-BNB LP',
  //   token: 'BITI-BNB LP',
  //   tokenDescription: 'PancakeSwap (Biti)',
  //   tokenAddress: '0xBE105c43Fe09E0BF1697f079ed09E1A2d956e602',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooBitiBITI-BNB',
  //   earnedTokenAddress: '0x554a710F4b5A354C991da0F19CC4D14E94493da1',
  //   earnContractAddress: '0x554a710F4b5A354C991da0F19CC4D14E94493da1',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'biti-biti-bnb',
  //   oraclePrice: 0,
  //   depositsPaused: false,
  //   status: 'eol',
  //   platform: 'Other',
  //   assets: ['BITI', 'BNB'],
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?outputCurrency=0xA25Dab5B75aC0E0738E58E49734295baD43d73F1',
  //   createdAt: 1617884368,
  // },
  // {
  //   id: 'palm-palm-busd-eol',
  //   logo: 'degens/palm-busd.png',
  //   name: 'PALM-BUSD LP',
  //   token: 'PALM-BUSD LP',
  //   tokenDescription: 'YieldBay',
  //   tokenAddress: '0x02600c49c53147ad6F090Ee3805D5705074c289d',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooPalmPALM-BUSD',
  //   earnedTokenAddress: '0x017443Dfc7A70A9d4D5cbCb966E95222955A6a7d',
  //   earnContractAddress: '0x017443Dfc7A70A9d4D5cbCb966E95222955A6a7d',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'palm-palm-busd',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'Other',
  //   assets: ['PALM', 'BUSD'],
  //   addLiquidityUrl:
  //     'https://exchange.yieldbay.finance/#/add/0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56/0x9768E5b2d8e761905BC81Dfc554f9437A46CdCC6',
  //   buyTokenUrl:
  //     'https://exchange.yieldbay.finance/#/swap?outputCurrency=0x9768e5b2d8e761905bc81dfc554f9437a46cdcc6',
  //   createdAt: 1617798298,
  // },
  // {
  //   id: 'palm-palm-bnb-eol',
  //   logo: 'degens/palm-bnb.png',
  //   name: 'PALM-BNB LP',
  //   token: 'PALM-BNB LP',
  //   tokenDescription: 'YieldBay',
  //   tokenAddress: '0x6Fc4c985559233D0b69410316911E5C6e096cCB6',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooPalmPALM-BNB',
  //   earnedTokenAddress: '0xA85D1879E78C1875479635fda2daD25C9743dc39',
  //   earnContractAddress: '0xA85D1879E78C1875479635fda2daD25C9743dc39',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'palm-palm-bnb',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'Other',
  //   assets: ['PALM', 'BNB'],
  //   addLiquidityUrl:
  //     'https://exchange.yieldbay.finance/#/add/ETH/0x9768E5b2d8e761905BC81Dfc554f9437A46CdCC6',
  //   buyTokenUrl:
  //     'https://exchange.yieldbay.finance/#/swap?outputCurrency=0x9768e5b2d8e761905bc81dfc554f9437a46cdcc6',
  //   createdAt: 1617798247,
  // },
  // {
  //   id: 'swamp-cake-eol',
  //   logo: 'single-assets/CAKE.svg',
  //   name: 'CAKE',
  //   token: 'CAKE',
  //   tokenDescription: 'PancakeSwap (Swamp)',
  //   tokenAddress: '0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooSwampyCAKE',
  //   earnedTokenAddress: '0x4d1A2b3119895d887b87509693338b86730bCE06',
  //   earnContractAddress: '0x4d1A2b3119895d887b87509693338b86730bCE06',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'tokens',
  //   oracleId: 'Cake',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'Other',
  //   assets: ['CAKE'],
  //   depositFee: '<0.1%',
  //   withdrawalFee: '0%',
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?outputCurrency=0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82',
  //   createdAt: 1617806173,
  // },
  // {
  //   id: 'swamp-swamp-eol',
  //   logo: 'single-assets/SWAMP.svg',
  //   name: 'SWAMP',
  //   token: 'SWAMP',
  //   tokenDescription: 'Swamp',
  //   tokenAddress: '0xc5A49b4CBe004b6FD55B30Ba1dE6AC360FF9765d',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooSwampySWAMP',
  //   earnedTokenAddress: '0x06C9e0b65ff4e02940c5b0f50b27D799622b2b39',
  //   earnContractAddress: '0x06C9e0b65ff4e02940c5b0f50b27D799622b2b39',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'tokens',
  //   oracleId: 'SWAMP',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   retireReason: 'tvl',
  //   platform: 'Other',
  //   assets: ['SWAMP'],
  //   depositFee: '<0.1%',
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?outputCurrency=0xc5A49b4CBe004b6FD55B30Ba1dE6AC360FF9765d',
  //   createdAt: 1617811436,
  // },
  // {
  //   id: 'auto-cake-eol',
  //   logo: 'single-assets/CAKE.svg',
  //   name: 'CAKE',
  //   token: 'CAKE',
  //   tokenDescription: 'PancakeSwap (Autofarm)',
  //   tokenAddress: '0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooAutoCakeFixed',
  //   earnedTokenAddress: '0xe0B473c0dD6D7Fea5B395c3Ce7ffd4FEF0ab4373',
  //   earnContractAddress: '0xe0B473c0dD6D7Fea5B395c3Ce7ffd4FEF0ab4373',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'tokens',
  //   oracleId: 'Cake',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'Other',
  //   assets: ['CAKE'],
  //   depositFee: '<0.1%',
  //   withdrawalFee: '0%',
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?outputCurrency=0x0e09fabb73bd3ade0a17ecc321fd13a19e81ce82',
  //   createdAt: 1613144057,
  // },
  // {
  //   id: 'swamp-cake-bnb-eol',
  //   name: 'CAKE-BNB LP',
  //   token: 'CAKE-BNB LP',
  //   tokenDescription: 'PancakeSwap (Swamp)',
  //   tokenAddress: '0xA527a61703D82139F8a06Bc30097cC9CAA2df5A6',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooSwampyCAKE-BNB',
  //   earnedTokenAddress: '0xe75E9Cc79c2d1dAa00f30991FBB413C8d4a86537',
  //   earnContractAddress: '0xe75E9Cc79c2d1dAa00f30991FBB413C8d4a86537',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'cake-cake-bnb',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'Other',
  //   assets: ['CAKE', 'BNB'],
  //   removeLiquidityUrl:
  //     'https://v1exchange.pancakeswap.finance/#/remove/BNB/0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82',
  //   createdAt: 1617721572,
  // },
  // {
  //   id: 'bingo-bgo-busd',
  //   logo: 'degens/BGO.png',
  //   name: 'BGO-BUSD LP',
  //   token: 'BGO-BUSD LP',
  //   tokenDescription: 'PancakeSwap (Bingo)',
  //   tokenAddress: '0x9cBEcBA2a5789759f7EdE9D3Af0F76e34f070d06',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooBingoBGO-BUSD',
  //   earnedTokenAddress: '0xa61B7B10B2694E1c283b41be3E55A31a29187290',
  //   earnContractAddress: '0xa61B7B10B2694E1c283b41be3E55A31a29187290',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'bingo-bgo-busd',
  //   oraclePrice: 0,
  //   depositsPaused: false,
  //   status: 'eol',
  //   platform: 'Other',
  //   assets: ['BGO', 'BUSD'],
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?outputCurrency=0x579a6277a6c2c63a5b25006f63bce5dc8d9c25e7',
  //   createdAt: 1617626328,
  // },
  // {
  //   id: 'bingo-sbgo-busd',
  //   logo: 'degens/SBGO.png',
  //   name: 'sBGO-BUSD LP',
  //   token: 'sBGO-BUSD LP',
  //   tokenDescription: 'PancakeSwap (Bingo)',
  //   tokenAddress: '0xAc774129e8a09a6a4baF30f6120b006B667C70D3',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooBingoSBGO-BUSD',
  //   earnedTokenAddress: '0x7d65a619e696cE8e7020C6Af3Aa9f75542BD6a11',
  //   earnContractAddress: '0x7d65a619e696cE8e7020C6Af3Aa9f75542BD6a11',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'bingo-sbgo-busd',
  //   oraclePrice: 0,
  //   depositsPaused: false,
  //   status: 'eol',
  //   platform: 'Other',
  //   assets: ['sBGO', 'BUSD'],
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?outputCurrency=0x53f39324fbb209693332b87aa94d5519a1a49ab0',
  //   createdAt: 1617627249,
  // },
  // {
  //   id: 'ellipsis-3eps-eol',
  //   logo: 'uncategorized/eps3.png',
  //   name: 'BUSD/USDT/USDC',
  //   token: 'EPS 3Pool',
  //   tokenDescription: 'Ellipsis',
  //   tokenAddress: '0xaF4dE8E872131AE328Ce21D909C74705d3Aaf452',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooEllipsis3Pool',
  //   earnedTokenAddress: '0xE563c046147b4dF98bfCD3d00Dc54511F0c3b752',
  //   earnContractAddress: '0xE563c046147b4dF98bfCD3d00Dc54511F0c3b752',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'ellipsis-3eps',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   retireReason: 'rewards',
  //   platform: 'Ellipsis',
  //   assets: ['USDT', 'BUSD', 'USDC', '3EPS'],
  //   addLiquidityUrl: 'https://ellipsis.finance/pool',
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?outputCurrency=0xe9e7cea3dedca5984780bafc599bd69add087d56',
  //   createdAt: 1616851506,
  // },
  // {
  //   id: 'tndr-tndr-busd-eol',
  //   logo: 'degens/tndr-busd.png',
  //   name: 'TNDR-BUSD LP',
  //   token: 'TNDR-BUSD LP',
  //   tokenDescription: 'ThunderSwap',
  //   tokenAddress: '0x3d63B28ea380d6948b4A8d65E30Cb4ECe478abbA',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooTndrTNDR-BUSD',
  //   earnedTokenAddress: '0x94Df3D9d2B7594AAa9D633e88bcF8195BA07819A',
  //   earnContractAddress: '0x94Df3D9d2B7594AAa9D633e88bcF8195BA07819A',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'tndr-tndr-busd',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'Other',
  //   assets: ['TNDR', 'BUSD'],
  //   addLiquidityUrl:
  //     'https://exchange.thunderswap.finance/#/add/0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56/0x7Cc46141AB1057b1928de5Ad5Ee78Bb37eFC4868',
  //   buyTokenUrl:
  //     'https://exchange.thunderswap.finance/#/swap?outputCurrency=0x7Cc46141AB1057b1928de5Ad5Ee78Bb37eFC4868',
  //   createdAt: 1617361475,
  // },
  // {
  //   id: 'tndr-tndr-bnb-eol',
  //   logo: 'degens/tndr-bnb.png',
  //   name: 'TNDR-BNB LP',
  //   token: 'TNDR-BNB LP',
  //   tokenDescription: 'ThunderSwap',
  //   tokenAddress: '0xE3652C5F32B0EbCa4A7752269837d110fbdCfC06',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooTndrTNDR-BNB',
  //   earnedTokenAddress: '0x8BdBc15aE5EbcaA00a27221D5A45469733F147F6',
  //   earnContractAddress: '0x8BdBc15aE5EbcaA00a27221D5A45469733F147F6',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'tndr-tndr-bnb',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'Other',
  //   assets: ['TNDR', 'BNB'],
  //   addLiquidityUrl:
  //     'https://exchange.thunderswap.finance/#/add/ETH/0x7Cc46141AB1057b1928de5Ad5Ee78Bb37eFC4868',
  //   buyTokenUrl:
  //     'https://exchange.thunderswap.finance/#/swap?outputCurrency=0x7Cc46141AB1057b1928de5Ad5Ee78Bb37eFC4868',
  //   createdAt: 1617362536,
  // },
  // {
  //   id: 'swamp-swamp-bnb',
  //   logo: 'bnb-pairs/SWAMP-BNB.svg',
  //   name: 'SWAMP-BNB LP',
  //   token: 'SWAMP-BNB LP',
  //   tokenDescription: 'PancakeSwap',
  //   tokenAddress: '0xa823C74dE2343E922ab24520f29D08390B4e894D',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooSwampySWAMP-BNB',
  //   earnedTokenAddress: '0xb500cd497dD53e8ee889c2553B9Ea82326f21bA9',
  //   earnContractAddress: '0xb500cd497dD53e8ee889c2553B9Ea82326f21bA9',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'swamp-swamp-bnb',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'Other',
  //   assets: ['SWAMP', 'BNB'],
  //   removeLiquidityUrl:
  //     'https://v1exchange.pancakeswap.finance/#/remove/0xc5A49b4CBe004b6FD55B30Ba1dE6AC360FF9765d/BNB',
  //   createdAt: 1617298796,
  // },
  // {
  //   id: 'swamp-swamp-busd',
  //   logo: 'busd-pairs/SWAMP-BUSD.svg',
  //   name: 'SWAMP-BUSD LP',
  //   token: 'SWAMP-BUSD LP',
  //   tokenDescription: 'PancakeSwap',
  //   tokenAddress: '0x9B06C33807a9Dee35DC676C401E6B8D93aB2a2F4',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooSwampySWAMP-BUSD',
  //   earnedTokenAddress: '0x4a759386cacb60A714275F97AbA9b555d91001c9',
  //   earnContractAddress: '0x4a759386cacb60A714275F97AbA9b555d91001c9',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'swamp-swamp-busd',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'Other',
  //   assets: ['SWAMP', 'BUSD'],
  //   removeLiquidityUrl:
  //     'https://v1exchange.pancakeswap.finance/#/remove/0xc5A49b4CBe004b6FD55B30Ba1dE6AC360FF9765d/0xe9e7cea3dedca5984780bafc599bd69add087d56',
  //   createdAt: 1617299657,
  // },
  // {
  //   id: 'swirl-swirl-bnb',
  //   logo: 'bnb-pairs/SWIRL-BNB.png',
  //   name: 'SWIRL-BNB LP',
  //   token: 'SWIRL-BNB LP',
  //   tokenDescription: 'PancakeSwap (Swirl)',
  //   tokenAddress: '0x135AeDCFb35b0b5dCf61Db7891a21253452Eb970',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooSwirlSWIRL-BNB',
  //   earnedTokenAddress: '0x2BE1cB6bc19E4592Beab28DcCfB993E4ad7A43aF',
  //   earnContractAddress: '0x2BE1cB6bc19E4592Beab28DcCfB993E4ad7A43aF',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'swirl-swirl-bnb',
  //   oraclePrice: 0,
  //   depositsPaused: false,
  //   status: 'eol',
  //   platform: 'Other',
  //   assets: ['SWIRL', 'BNB'],
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?outputCurrency=0x52d86850bc8207b520340B7E39cDaF22561b9E56',
  //   createdAt: 1617190565,
  // },
  // {
  //   id: 'cake-dft-bnb',
  //   logo: 'bnb-pairs/DFT-BNB.svg',
  //   name: 'DFT-BNB LP',
  //   token: 'DFT-BNB LP',
  //   tokenDescription: 'PancakeSwap',
  //   tokenAddress: '0x8FbCbD7e30b1733965a8980bf7Ae2ca1c0C456cc',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooPancakeDFT-BNB',
  //   earnedTokenAddress: '0x1f945e77280653549aA1D36f24620A06Bc64e712',
  //   earnContractAddress: '0x1f945e77280653549aA1D36f24620A06Bc64e712',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'cake-dft-bnb',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'PancakeSwap',
  //   assets: ['DFT', 'BNB'],
  //   removeLiquidityUrl:
  //     'https://v1exchange.pancakeswap.finance/#/remove/BNB/0x42712dF5009c20fee340B245b510c0395896cF6e',
  //   createdAt: 1617112149,
  // },
  // {
  //   id: 'cake-swth-bnb',
  //   logo: 'bnb-pairs/SWTH-BNB.svg',
  //   name: 'SWTH-BNB LP',
  //   token: 'SWTH-BNB LP',
  //   tokenDescription: 'PancakeSwap',
  //   tokenAddress: '0x8c5cFfad6cddb96Ee33DA685D0d50a37e030E115',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooPancakeSWTH-BNB',
  //   earnedTokenAddress: '0x2A3166a20a88B04282C17de6cCE1eEd032314f96',
  //   earnContractAddress: '0x2A3166a20a88B04282C17de6cCE1eEd032314f96',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'cake-swth-bnb',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'PancakeSwap',
  //   assets: ['SWTH', 'BNB'],
  //   removeLiquidityUrl:
  //     'https://v1exchange.pancakeswap.finance/#/remove/BNB/0x250b211EE44459dAd5Cd3bCa803dD6a7EcB5d46C',
  //   createdAt: 1617112197,
  // },
  // {
  //   id: 'zefi-zefi-bnb',
  //   logo: 'degens/ZEFI-BNB.svg',
  //   name: 'ZEFI-BNB LP',
  //   token: 'ZEFI-BNB LP',
  //   tokenDescription: 'PancakeSwap (ZCore)',
  //   tokenAddress: '0x0E6027880A04677BAE5E4225000078eE92904bf6',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooZefiZEFI-BNB',
  //   earnedTokenAddress: '0xc9f7337E2c638C48dd7dcF506535e8fEb0c3c786',
  //   earnContractAddress: '0xc9f7337E2c638C48dd7dcF506535e8fEb0c3c786',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'zefi-zefi-bnb',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'Other',
  //   assets: ['ZEFI', 'BNB'],
  //   removeLiquidityUrl:
  //     'https://v1exchange.pancakeswap.finance/#/remove/BNB/0x0288D3E353fE2299F11eA2c2e1696b4A648eCC07',
  //   createdAt: 1617105351,
  // },
  // {
  //   id: 'zefi-zefi-busd',
  //   logo: 'degens/ZEFI-BUSD.svg',
  //   name: 'ZEFI-BUSD LP',
  //   token: 'ZEFI-BUSD LP',
  //   tokenDescription: 'PancakeSwap (ZCore)',
  //   tokenAddress: '0x24B87c29e907D6329ED8fD6d7B2ecb074089093E',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooZefiZEFI-BUSD',
  //   earnedTokenAddress: '0x8152cd7b2EA135022a5E0baF625CB655302F2E7b',
  //   earnContractAddress: '0x8152cd7b2EA135022a5E0baF625CB655302F2E7b',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'zefi-zefi-busd',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'Other',
  //   assets: ['ZEFI', 'BUSD'],
  //   removeLiquidityUrl:
  //     'https://v1exchange.pancakeswap.finance/#/remove/0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56/0x0288D3E353fE2299F11eA2c2e1696b4A648eCC07',
  //   createdAt: 1617105291,
  // },
  // {
  //   id: 'hps-hps-bnb-eol',
  //   logo: 'single-assets/HPS.png',
  //   name: 'HPS-BNB LP',
  //   token: 'HPS-BNB LP',
  //   tokenDescription: 'JulSwap (BillionHappiness)',
  //   tokenAddress: '0xcaC3b7DE7D5c44E8E1048241C7dE29a61b5C3E7d',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooBillionHPS-BNB',
  //   earnedTokenAddress: '0x17cB1Ddb71ca34958e54f8152f0913C25bC50DF6',
  //   earnContractAddress: '0x17cB1Ddb71ca34958e54f8152f0913C25bC50DF6',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'hps-hps-bnb',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'Other',
  //   assets: ['HPS', 'BNB'],
  //   withdrawalFee: '0%',
  //   retireReason: 'rewards',
  //   removeLiquidityUrl:
  //     'https://julswap.com/#/remove/BNB/0xeDa21B525Ac789EaB1a08ef2404dd8505FfB973D',
  //   buyTokenUrl:
  //     'https://julswap.com/#/swap?inputCurrency=BNB&outputCurrency=0xeDa21B525Ac789EaB1a08ef2404dd8505FfB973D',
  //   createdAt: 1617017395,
  // },
  // {
  //   id: 'ellipsis-eps-bnb-eol',
  //   logo: 'uncategorized/epsBNB.png',
  //   name: 'EPS-BNB LP',
  //   token: 'EPS-BNB LP',
  //   tokenDescription: 'Ellipsis',
  //   tokenAddress: '0xf9045866e7b372DeF1EFf3712CE55FAc1A98dAF0',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooEllipsisEPS-BNB',
  //   earnedTokenAddress: '0xD467af8C117AcaF7B2725a6d35Dc5A471A683055',
  //   earnContractAddress: '0xD467af8C117AcaF7B2725a6d35Dc5A471A683055',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'ellipsis-eps-bnb',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   retireReason: 'rewards',
  //   platform: 'Ellipsis',
  //   assets: ['EPS', 'BNB'],
  //   addLiquidityUrl:
  //     'https://pancake.ellipsis.finance/#/add/BNB/0xA7f552078dcC247C2684336020c03648500C6d9F',
  //   buyTokenUrl:
  //     'https://pancake.ellipsis.finance/#/swap?outputCurrency=0xA7f552078dcC247C2684336020c03648500C6d9F',
  //   createdAt: 1616828768,
  // },
  // {
  //   id: 'alpaca-salpaca-eol',
  //   logo: 'single-assets/sALPACA.svg',
  //   name: 'sALPACA',
  //   token: 'sALPACA',
  //   tokenDescription: 'Alpaca',
  //   tokenAddress: '0x6F695Bd5FFD25149176629f8491A5099426Ce7a7',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooAlpacaSALPACA',
  //   earnedTokenAddress: '0xCd1C6adca69071F61EfE5aaa0BB63cA3419D5088',
  //   earnContractAddress: '0xCd1C6adca69071F61EfE5aaa0BB63cA3419D5088',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'tokens',
  //   oracleId: 'ALPACA',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'Other',
  //   assets: ['sALPACA'],
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?inputCurrency=0x8f0528ce5ef7b51152a59745befdd91d97091d2f&outputCurrency=0x6F695Bd5FFD25149176629f8491A5099426Ce7a7',
  //   createdAt: 1616706283,
  // },
  // {
  //   id: 'naut-naut-bnb',
  //   logo: 'degens/NAUT.png',
  //   name: 'NAUT-BNB LP',
  //   token: 'NAUT-BNB LP',
  //   tokenDescription: 'ApeSwap',
  //   tokenAddress: '0x288EA5437c7aaD045a393cee2F41E548df24d1C8',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooNautNAUT-BNB',
  //   earnedTokenAddress: '0x693402b80b073b872589433e6fa1b08665E12025',
  //   earnContractAddress: '0x693402b80b073b872589433e6fa1b08665E12025',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'naut-naut-bnb',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'ApeSwap',
  //   assets: ['NAUT', 'BNB'],
  //   removeLiquidityUrl:
  //     'https://dex.apeswap.finance/#/remove/ETH/0x05B339B0A346bF01f851ddE47a5d485c34FE220c',
  //   createdAt: 1616763592,
  // },
  // {
  //   id: 'cake-lto-bnb',
  //   logo: 'bnb-pairs/LTO-BNB.svg',
  //   name: 'LTO-BNB LP',
  //   token: 'LTO-BNB LP',
  //   tokenDescription: 'PancakeSwap',
  //   tokenAddress: '0x85644FcD00C401E1A0a0a10d2aE6Bbe04A73e4ab',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooPancakeLTO-BNB',
  //   earnedTokenAddress: '0x37A36B817f372A9F1bDb2Ee59bA1D2c599B2AE77',
  //   earnContractAddress: '0x37A36B817f372A9F1bDb2Ee59bA1D2c599B2AE77',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'cake-lto-bnb',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'PancakeSwap',
  //   assets: ['LTO', 'BNB'],
  //   removeLiquidityUrl:
  //     'https://v1exchange.pancakeswap.finance/#/remove/BNB/0x857b222fc79e1cbbf8ca5f78cb133d1b7cf34bbd',
  //   createdAt: 1616695017,
  // },
  // {
  //   id: 'cake-trade-bnb',
  //   logo: 'bnb-pairs/TRADE-BNB.svg',
  //   name: 'TRADE-BNB LP',
  //   token: 'TRADE-BNB LP',
  //   tokenDescription: 'PancakeSwap',
  //   tokenAddress: '0x2562f94E90dE6D9eb4fB6B3b8Eab56b15Aa4FC72',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooPancakeTRADE-BNB',
  //   earnedTokenAddress: '0x3F3601229F6fE7D9f3FA8554f0C3Ca4CaE8043B4',
  //   earnContractAddress: '0x3F3601229F6fE7D9f3FA8554f0C3Ca4CaE8043B4',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'cake-trade-bnb',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'PancakeSwap',
  //   assets: ['TRADE', 'BNB'],
  //   removeLiquidityUrl:
  //     'https://v1exchange.pancakeswap.finance/#/remove/BNB/0x7af173f350d916358af3e218bdf2178494beb748',
  //   createdAt: 1616694954,
  // },
  // {
  //   id: 'alpaca-alpaca-bnb',
  //   logo: 'bnb-pairs/ALPACA-BNB.png',
  //   name: 'ALPACA-BNB LP',
  //   token: 'ALPACA-BNB LP',
  //   tokenDescription: 'Alpaca',
  //   tokenAddress: '0xF3CE6Aac24980E6B657926dfC79502Ae414d3083',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooAlpacaALPACA-BNB',
  //   earnedTokenAddress: '0xB4CF4b0d79AF3A5627D5595483e0df9620B7d236',
  //   earnContractAddress: '0xB4CF4b0d79AF3A5627D5595483e0df9620B7d236',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'alpaca-alpaca-bnb',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'Other',
  //   assets: ['ALPACA', 'BNB'],
  //   removeLiquidityUrl:
  //     'https://v1exchange.pancakeswap.finance/#/remove/BNB/0x8F0528cE5eF7B51152A59745bEfDD91D97091d2F',
  //   createdAt: 1616621126,
  // },
  // {
  //   id: 'space-space-bnb-eol',
  //   logo: 'degens/SPACE-BNB.png',
  //   name: 'SPACE-BNB LP',
  //   token: 'SPACE-BNB LP',
  //   tokenDescription: 'PancakeSwap',
  //   tokenAddress: '0x78D9a0c50F6484291b4122f61888Bb9EE71879Ff',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooPancakeSPACE-BNB',
  //   earnedTokenAddress: '0xbC943C4C00E837e65Cf76BcD8Df8C365FB44Dd6f',
  //   earnContractAddress: '0xbC943C4C00E837e65Cf76BcD8Df8C365FB44Dd6f',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'space-space-bnb',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'PancakeSwap',
  //   assets: ['SPACE', 'BNB'],
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?outputCurrency=0x0abd3E3502c15ec252f90F64341cbA74a24fba06',
  //   createdAt: 1616599212,
  //   retiredReason: 'tvl',
  // },
  // {
  //   id: 'space-space-busd-eol',
  //   logo: 'degens/SPACE-BUSD.png',
  //   name: 'SPACE-BUSD LP',
  //   token: 'SPACE-BUSD LP',
  //   tokenDescription: 'PancakeSwap',
  //   tokenAddress: '0x6B936c5C1FD7De08E03684b0588A87DBD8ce6b63',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooPancakeSPACE-BUSD',
  //   earnedTokenAddress: '0xd71A3edcdAe126a30960df40CB5247d87980Bf08',
  //   earnContractAddress: '0xd71A3edcdAe126a30960df40CB5247d87980Bf08',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'space-space-busd',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   retireReason: 'rewards',
  //   platform: 'PancakeSwap',
  //   assets: ['SPACE', 'BUSD'],
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?inputCurrency=0x0abd3E3502c15ec252f90F64341cbA74a24fba06&outputCurrency=0xe9e7cea3dedca5984780bafc599bd69add087d56',
  //   createdAt: 1616599971,
  // },
  // {
  //   id: 'cake-bdigg-btcb',
  //   logo: 'uncategorized/BDIGG-BTCB.svg',
  //   name: 'bDIGG-BTCB LP',
  //   token: 'bDIGG-BTCB LP',
  //   tokenDescription: 'PancakeSwap',
  //   tokenAddress: '0xE1E33459505bB3763843a426F7Fd9933418184ae',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooPancakeBDIGG-BNB',
  //   earnedTokenAddress: '0x2c1a0950aC426f920f260C9647BCD3AFD0482f4e',
  //   earnContractAddress: '0x2c1a0950aC426f920f260C9647BCD3AFD0482f4e',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'cake-bdigg-btcb',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'PancakeSwap',
  //   assets: ['bDIGG', 'BTCB'],
  //   removeLiquidityUrl:
  //     'https://v1exchange.pancakeswap.finance/#/remove/0x5986D5c77c65e5801a5cAa4fAE80089f870A71dA/0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c',
  //   createdAt: 1616516966,
  // },
  // {
  //   id: 'pumpy-pmp-bnb',
  //   logo: 'single-assets/PMP.svg',
  //   name: 'PMP-BNB LP',
  //   token: 'PMP-BNB LP',
  //   tokenDescription: 'PancakeSwap (Pumpy)',
  //   tokenAddress: '0x18CCA70DDa1699148cB37941d75ccF4847BEa188',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooPumpyPMP-BNB',
  //   earnedTokenAddress: '0xE929d65cBf543cC3cA6b18Fe7872fccC6abBf480',
  //   earnContractAddress: '0xE929d65cBf543cC3cA6b18Fe7872fccC6abBf480',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'pumpy-pmp-bnb',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'Other',
  //   assets: ['PMP', 'BNB'],
  //   addLiquidityUrl:
  //     'https://pancakeswap.finance/add/BNB/0x8d4FBB3AC63bf33851dCE80D63613Df1A515BC00',
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?outputCurrency=0x8d4FBB3AC63bf33851dCE80D63613Df1A515BC00',
  //   createdAt: 1616413974,
  // },
  // {
  //   id: 'cake-bifi-bnb',
  //   logo: 'bnb-pairs/BIFI-BNB.svg',
  //   name: 'BIFI-BNB LP',
  //   token: 'BIFI-BNB LP',
  //   tokenDescription: 'PancakeSwap',
  //   tokenAddress: '0xd132D2C24F29EE8ABb64a66559d1b7aa627Bd7fD',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooPancakeBIFI-BNB',
  //   earnedTokenAddress: '0x3B5332A476AbCdb80Cde6645e9e5563435e97772',
  //   earnContractAddress: '0x3B5332A476AbCdb80Cde6645e9e5563435e97772',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'cake-bifi-bnb',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'PancakeSwap',
  //   assets: ['BIFI', 'BNB'],
  //   removeLiquidityUrl:
  //     'https://v1exchange.pancakeswap.finance/#/remove/BNB/0xCa3F508B8e4Dd382eE878A314789373D80A5190A',
  //   createdAt: 1615960933,
  // },
  // {
  //   id: 'jul-twt-bnb',
  //   logo: 'bnb-pairs/JTWT-BNB.svg',
  //   name: 'TWT-BNB LP',
  //   token: 'TWT-BNB SLP',
  //   tokenDescription: 'JulSwap',
  //   tokenAddress: '0x14aAC5bA46418d625db4f506988E3Ee5a0963887',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooJulTWT-BNB',
  //   earnedTokenAddress: '0x7091E04422d1D9Be48C211cf2F818a7E88EFd736',
  //   earnContractAddress: '0x7091E04422d1D9Be48C211cf2F818a7E88EFd736',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'jul-twt-bnb',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'Other',
  //   assets: ['TWT', 'BNB'],
  //   removeLiquidityUrl:
  //     'https://julswap.com/#/remove/BNB/0x4B0F1812e5Df2A09796481Ff14017e6005508003',
  //   createdAt: 1616264123,
  // },
  // {
  //   id: 'venus-eth-eol',
  //   logo: 'single-assets/ETH.svg',
  //   name: 'ETH',
  //   token: 'ETH',
  //   tokenDescription: 'Venus',
  //   tokenAddress: '0x2170Ed0880ac9A755fd29B2688956BD959F933F8',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooVenusETH',
  //   earnedTokenAddress: '0xA9936272065e6DDAc9D2453C9a2712B581e9aE1B',
  //   earnContractAddress: '0xA9936272065e6DDAc9D2453C9a2712B581e9aE1B',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'tokens',
  //   oracleId: 'ETH',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'Venus',
  //   assets: ['ETH'],
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?outputCurrency=0x2170ed0880ac9a755fd29b2688956bd959f933f8',
  //   createdAt: 1608173110,
  // },
  // {
  //   id: 'com-com-bnb-eol',
  //   logo: 'bnb-pairs/COM-BNB.png',
  //   name: 'COM-BNB LP',
  //   token: 'COM-BNB LP',
  //   tokenDescription: 'Complus',
  //   tokenAddress: '0x7FEAb71e859843D56358069B1374E266F7c0a75f',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooComplusCOM-BNB',
  //   earnedTokenAddress: '0x0DA3EB14c8d07a87A366D029d7f2341Ac0eca775',
  //   earnContractAddress: '0x0DA3EB14c8d07a87A366D029d7f2341Ac0eca775',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'com-com-bnb',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'Other',
  //   assets: ['COM', 'BNB'],
  //   retireReason: 'tvl',
  //   addLiquidityUrl:
  //     'https://bscdex.complus.exchange/#/add/BNB/0x7fa892544D49598460B821De4D99E8c28b1Decaa',
  //   buyTokenUrl:
  //     'https://bscdex.complus.exchange/#/swap?outputCurrency=0x7fa892544d49598460b821de4d99e8c28b1decaa&outputCurrency=BNB',
  //   createdAt: 1616170726,
  // },
  // {
  //   id: 'cake-dusk-bnb',
  //   logo: 'bnb-pairs/DUSK-BNB.svg',
  //   name: 'DUSK-BNB LP',
  //   token: 'DUSK-BNB LP',
  //   tokenDescription: 'PancakeSwap',
  //   tokenAddress: '0xB7918560273FD56e50E9c215CC0DFE8D764C36C5',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooPancakeDUSK-BNB',
  //   earnedTokenAddress: '0x4BeE93b29e1b3fd90bb3e6EdE9539Fe92E9836Cd',
  //   earnContractAddress: '0x4BeE93b29e1b3fd90bb3e6EdE9539Fe92E9836Cd',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'cake-dusk-bnb',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'PancakeSwap',
  //   assets: ['DUSK', 'BNB'],
  //   removeLiquidityUrl:
  //     'https://v1exchange.pancakeswap.finance/#/remove/0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c/0xb2bd0749dbe21f623d9baba856d3b0f0e1bfec9c',
  //   createdAt: 1616070863,
  // },
  // {
  //   id: 'squirrel-nuts-bnb-eol',
  //   logo: 'single-assets/NUTS.png',
  //   name: 'NUTS-BNB LP',
  //   token: 'NUTS-BNB LP',
  //   tokenDescription: 'PancakeSwap (Squirrel)',
  //   tokenAddress: '0x034C9E6B08c09a6144e0D0E52161338fB105e656',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooSquirrelNUTS-BNB',
  //   earnedTokenAddress: '0xB1C9A27394dBa451Bfc4a5dce8Fc829f8a3E9278',
  //   earnContractAddress: '0xB1C9A27394dBa451Bfc4a5dce8Fc829f8a3E9278',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'squirrel-nuts-bnb',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'Other',
  //   assets: ['NUTS', 'BNB'],
  //   removeLiquidityUrl:
  //     'https://v1exchange.pancakeswap.finance/#/remove/BNB/0x8893D5fA71389673C5c4b9b3cb4EE1ba71207556',
  //   createdAt: 1615984690,
  // },
  // {
  //   id: 'squirrel-nuts-eol',
  //   logo: 'single-assets/NUTS.png',
  //   name: 'NUTS',
  //   token: 'NUTS',
  //   tokenDescription: 'Squirrel',
  //   tokenAddress: '0x8893D5fA71389673C5c4b9b3cb4EE1ba71207556',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooSquirrelNUTS',
  //   earnedTokenAddress: '0x2D40beB0122aBcAD0E6b49934d47adac6Dddd97B',
  //   earnContractAddress: '0x2D40beB0122aBcAD0E6b49934d47adac6Dddd97B',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'tokens',
  //   oracleId: 'NUTS',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'Other',
  //   retireReason: 'rewards',
  //   assets: ['NUTS'],
  //   withdrawalFee: '0%',
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?outputCurrency=0x8893D5fA71389673C5c4b9b3cb4EE1ba71207556',
  //   createdAt: 1615985963,
  // },
  // {
  //   id: 'bakery-bat-bake-eol',
  //   logo: 'uncategorized/BAT-BAKE.svg',
  //   name: 'BAT-BAKE LP',
  //   token: 'BAT-BAKE LP',
  //   tokenDescription: 'Bakery',
  //   tokenAddress: '0x675Ec26cF1c99A5cD227658f5d0e2fAcbbf3Dcf7',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooBakeryBAT-BAKE',
  //   earnedTokenAddress: '0x92E586d7dB14483C103c2e0FE6A596F8b55DA752',
  //   earnContractAddress: '0x92E586d7dB14483C103c2e0FE6A596F8b55DA752',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'bakery-bat-bake',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   retireReason: 'rewards',
  //   platform: 'Other',
  //   assets: ['BAT', 'BAKE'],
  //   addLiquidityUrl:
  //     'https://www.bakeryswap.org/#/add/0x101d82428437127bf1608f699cd651e6abf9766e/0xe02df9e3e622debdd69fb838bb799e3f168902c5',
  //   buyTokenUrl:
  //     'https://www.bakeryswap.org/#/swap?inputCurrency=0x101d82428437127bf1608f699cd651e6abf9766e&outputCurrency=0xe02df9e3e622debdd69fb838bb799e3f168902c5',
  //   createdAt: 1615901040,
  // },
  // {
  //   id: 'memefarm-mfrm-bnb',
  //   logo: 'degens/mfrm-bnb.png',
  //   name: 'MFRM-BNB LP',
  //   token: 'MFRM-BNB LP',
  //   tokenDescription: 'PancakeSwap (MemeFarm)',
  //   tokenAddress: '0x9E6463BB3b6ff6A768290A4eFC452dC92F2CfA2A',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooMemefarmMFRM-BNB',
  //   earnedTokenAddress: '0x94d75413A29287Dde5766E5595dc61e668965170',
  //   earnContractAddress: '0x94d75413A29287Dde5766E5595dc61e668965170',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'memefarm-mfrm-bnb',
  //   oraclePrice: 0,
  //   depositsPaused: false,
  //   status: 'eol',
  //   platform: 'Other',
  //   assets: ['MFRM', 'BNB'],
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?outputCurrency=0x206340f3361404910F45cA0893980EF3f9b418ea',
  //   createdAt: 1615791800,
  // },
  // {
  //   id: 'slime-slime-busd',
  //   logo: 'degens/slime-busd.png',
  //   name: 'SLME-BUSD LP',
  //   token: 'SLME-BUSD LP',
  //   tokenDescription: 'SlimeFinance',
  //   tokenAddress: '0xfbd0B87f4132E5A14aA85C21476738C0C13Fd06c',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooSlimeSLIME-BUSD',
  //   earnedTokenAddress: '0xd7987DB14509FACb9CC44c0b82CC86c188f5cEEF',
  //   earnContractAddress: '0xd7987DB14509FACb9CC44c0b82CC86c188f5cEEF',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'slime-slime-busd',
  //   oraclePrice: 0,
  //   depositsPaused: false,
  //   status: 'eol',
  //   platform: 'Other',
  //   assets: ['SLME', 'BUSD'],
  //   addLiquidityUrl:
  //     'https://slime.finance/swap-slimeeees#/add/0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56/0x4fCfA6cC8914ab455B5b33Df916d90BFe70b6AB1',
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?inputCurrency=0x4fCfA6cC8914ab455B5b33Df916d90BFe70b6AB1&outputCurrency=0xe9e7cea3dedca5984780bafc599bd69add087d56',
  //   createdAt: 1615637983,
  // },
  // {
  //   id: 'slime-slime-bnb',
  //   logo: 'degens/slime-bnb.png',
  //   name: 'SLME-BNB LP',
  //   token: 'SLME-BNB LP',
  //   tokenDescription: 'SlimeFinance',
  //   tokenAddress: '0xcb645714520080EF4E65De3254d61356262F0818',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooSlimeSLIME-BNB',
  //   earnedTokenAddress: '0x62d7eC40A1a3f0AB5D1fCC0D8bd3dCb015aFe6a3',
  //   earnContractAddress: '0x62d7eC40A1a3f0AB5D1fCC0D8bd3dCb015aFe6a3',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'slime-slime-bnb',
  //   oraclePrice: 0,
  //   depositsPaused: false,
  //   status: 'eol',
  //   platform: 'Other',
  //   assets: ['SLME', 'BNB'],
  //   addLiquidityUrl:
  //     'https://slime.finance/swap-slimeeees#/add/BNB/0x4fCfA6cC8914ab455B5b33Df916d90BFe70b6AB1',
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?outputCurrency=0x4fCfA6cC8914ab455B5b33Df916d90BFe70b6AB1',
  //   createdAt: 1615636861,
  // },
  // {
  //   id: 'cakev2-alice-bnb',
  //   logo: 'bnb-pairs/ALICE-BNB.svg',
  //   name: 'ALICE-BNB LP',
  //   token: 'ALICE-BNB LP2',
  //   tokenDescription: 'PancakeSwap',
  //   tokenAddress: '0xcAD7019D6d84a3294b0494aEF02e73BD0f2572Eb',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooCakeV2ALICE-BNB',
  //   earnedTokenAddress: '0x0c65883778f16770F4d43f5524708e18BC62881b',
  //   earnContractAddress: '0x0c65883778f16770F4d43f5524708e18BC62881b',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'cakev2-alice-bnb',
  //   oraclePrice: 0,
  //   depositsPaused: false,
  //   status: 'active',
  //   platform: 'PancakeSwap',
  //   assets: ['ALICE', 'BNB'],
  //   addLiquidityUrl:
  //     'https://pancakeswap.finance/add/BNB/0xAC51066d7bEC65Dc4589368da368b212745d63E8',
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?outputCurrency=0xAC51066d7bEC65Dc4589368da368b212745d63E8',
  //   createdAt: 1619303271,
  // },
  // {
  //   id: 'cake-alice-bnb',
  //   logo: 'bnb-pairs/ALICE-BNB.svg',
  //   name: 'ALICE-BNB LP',
  //   token: 'ALICE-BNB LP',
  //   tokenDescription: 'PancakeSwap',
  //   tokenAddress: '0xe022baa3E5E87658f789c9132B10d7425Fd3a389',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooPancakeALICE-BNB',
  //   earnedTokenAddress: '0x0C098a54FeE7EC15a59587E69cD340BBd9d22077',
  //   earnContractAddress: '0x0C098a54FeE7EC15a59587E69cD340BBd9d22077',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'cake-alice-bnb',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'PancakeSwap',
  //   assets: ['ALICE', 'BNB'],
  //   removeLiquidityUrl:
  //     'https://v1exchange.pancakeswap.finance/#/remove/BNB/0xAC51066d7bEC65Dc4589368da368b212745d63E8',
  //   createdAt: 1615785029,
  // },
  // {
  //   id: 'auto-beth-eth',
  //   logo: 'uncategorized/BETH-ETH.svg',
  //   name: 'BETH-ETH LP',
  //   token: 'BETH-ETH LP',
  //   tokenDescription: 'PancakeSwap (Autofarm)',
  //   tokenAddress: '0x99d865Ed50D2C32c1493896810FA386c1Ce81D91',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooAutoBETH-ETH',
  //   earnedTokenAddress: '0xF3C1EB01E40c47fd32D0397e56569809aae0e9c7',
  //   earnContractAddress: '0xF3C1EB01E40c47fd32D0397e56569809aae0e9c7',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'auto-beth-eth',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'Other',
  //   assets: ['BETH', 'ETH'],
  //   removeLiquidityUrl:
  //     'https://v1exchange.pancakeswap.finance/#/remove/0x2170Ed0880ac9A755fd29B2688956BD959F933F8/0x250632378E573c6Be1AC2f97Fcdf00515d0Aa91B',
  //   createdAt: 1615029624,
  // },
  // {
  //   id: 'belt-belt-bnb',
  //   logo: 'bnb-pairs/BELT-BNB-BELT.png',
  //   name: 'BELT-BNB LP',
  //   token: 'BELT-BNB LP',
  //   tokenDescription: 'PancakeSwap (Belt)',
  //   tokenAddress: '0x83B92D283cd279fF2e057BD86a95BdEfffED6faa',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooBeltBeltBNB',
  //   earnedTokenAddress: '0xfc01F7a17eEb5DABc97312E13954444b00217621',
  //   earnContractAddress: '0xfc01F7a17eEb5DABc97312E13954444b00217621',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'cake-belt-bnb',
  //   oraclePrice: 0,
  //   depositsPaused: false,
  //   status: 'eol',
  //   platform: 'Belt',
  //   assets: ['BELT', 'BNB'],
  //   removeLiquidityUrl:
  //     'https://v1exchange.pancakeswap.finance/#/remove/BNB/0xE0e514c71282b6f4e823703a39374Cf58dc3eA4f',
  //   createdAt: 1615485635,
  // },
  // {
  //   id: 'cake-belt-bnb',
  //   logo: 'bnb-pairs/BELT-BNB.svg',
  //   name: 'BELT-BNB LP',
  //   token: 'BELT-BNB LP',
  //   tokenDescription: 'PancakeSwap',
  //   tokenAddress: '0x83B92D283cd279fF2e057BD86a95BdEfffED6faa',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooPancakeBELT-BNB',
  //   earnedTokenAddress: '0xee43C2D11Bc6DF95dcECd9be05ae648863D6f9dC',
  //   earnContractAddress: '0xee43C2D11Bc6DF95dcECd9be05ae648863D6f9dC',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'cake-belt-bnb',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'PancakeSwap',
  //   assets: ['BELT', 'BNB'],
  //   removeLiquidityUrl:
  //     'https://v1exchange.pancakeswap.finance/#/remove/BNB/0xE0e514c71282b6f4e823703a39374Cf58dc3eA4f',
  //   createdAt: 1615370547,
  // },
  // {
  //   id: 'auto-busd-bnb',
  //   logo: 'bnb-pairs/BUSD-BNB.svg',
  //   name: 'BUSD-BNB LP',
  //   token: 'BUSD-BNB LP',
  //   tokenDescription: 'PancakeSwap (Autofarm)',
  //   tokenAddress: '0x1B96B92314C44b159149f7E0303511fB2Fc4774f',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooAutoBUSD-BNB',
  //   earnedTokenAddress: '0xb01e3C7789858beD3b142c8f2499F21Ab3ea3f0f',
  //   earnContractAddress: '0xb01e3C7789858beD3b142c8f2499F21Ab3ea3f0f',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'auto-busd-bnb',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'Other',
  //   assets: ['BUSD', 'BNB'],
  //   removeLiquidityUrl:
  //     'https://v1exchange.pancakeswap.finance/#/remove/BNB/0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
  //   createdAt: 1615208693,
  // },
  // {
  //   id: 'auto-btc-bnb',
  //   logo: 'bnb-pairs/BTCB-BNB.svg',
  //   name: 'BTC-BNB LP',
  //   token: 'BTC-BNB LP',
  //   tokenDescription: 'PancakeSwap (Autofarm)',
  //   tokenAddress: '0x7561EEe90e24F3b348E1087A005F78B4c8453524',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooAutoBTC-BNB',
  //   earnedTokenAddress: '0xcf2D6e0030e24F01e4750cAE376E2126e728E0c2',
  //   earnContractAddress: '0xcf2D6e0030e24F01e4750cAE376E2126e728E0c2',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'auto-btc-bnb',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'Other',
  //   assets: ['BTCB', 'BNB'],
  //   removeLiquidityUrl:
  //     'https://v1exchange.pancakeswap.finance/#/remove/0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c/BNB',
  //   createdAt: 1615208756,
  // },
  // {
  //   id: 'venus-btcb-eol',
  //   logo: 'single-assets/BTCB.svg',
  //   name: 'BTCB',
  //   token: 'BTCB',
  //   tokenDescription: 'Venus',
  //   tokenAddress: '0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooVenusBTCB',
  //   earnedTokenAddress: '0x7255Ae13BA4484eD33fB48c4b833000dC12888B4',
  //   earnContractAddress: '0x7255Ae13BA4484eD33fB48c4b833000dC12888B4',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'tokens',
  //   oracleId: 'BTCB',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'Venus',
  //   assets: ['BTCB'],
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?outputCurrency=0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c',
  //   createdAt: 1608173080,
  // },
  // {
  //   id: 'cake-dexe-busd',
  //   logo: 'busd-pairs/DEXE-BUSD.svg',
  //   name: 'DEXE-BUSD LP',
  //   token: 'DEXE-BUSD LP',
  //   tokenDescription: 'PancakeSwap',
  //   tokenAddress: '0x69AB367BC1BEA1D2c0fB4dBaEc6b7197951Da56C',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooPancakeDEXE-BUSD',
  //   earnedTokenAddress: '0xfEDB3855761B9e356671Fb1eC95593e99b153EfD',
  //   earnContractAddress: '0xfEDB3855761B9e356671Fb1eC95593e99b153EfD',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'cake-dexe-busd',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'PancakeSwap',
  //   assets: ['DEXE', 'BUSD'],
  //   removeLiquidityUrl:
  //     'https://v1exchange.pancakeswap.finance/#/remove/0xe9e7cea3dedca5984780bafc599bd69add087d56/0x039cb485212f996a9dbb85a9a75d898f94d38da6',
  //   createdAt: 1615195112,
  // },
  // {
  //   id: 'jul-bifi-bnb-eol',
  //   logo: 'bnb-pairs/BIFI-BNB.png',
  //   name: 'BIFI-BNB LP',
  //   token: 'BIFI-BNB SLP',
  //   tokenDescription: 'JulSwap',
  //   tokenAddress: '0xd3665568474cC090aB6B243333c2A1A3f13a9C7F',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooJulBIFI-BNB',
  //   earnedTokenAddress: '0x6a3fF116a10349BB40B22FD7687871d5D560d715',
  //   earnContractAddress: '0x6a3fF116a10349BB40B22FD7687871d5D560d715',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'jul-bifi-bnb',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'Other',
  //   assets: ['BIFI', 'BNB'],
  //   addLiquidityUrl: 'https://julswap.com/#/add/BNB/0xCa3F508B8e4Dd382eE878A314789373D80A5190A',
  //   buyTokenUrl:
  //     'https://julswap.com/#/swap?inputCurrency=BNB&outputCurrency=0xca3f508b8e4dd382ee878a314789373d80a5190a',
  //   createdAt: 1614785554,
  // },
  // {
  //   id: 'jul-juld-bnb-eol',
  //   logo: 'bnb-pairs/JULD-BNB.svg',
  //   name: 'JulD-BNB LP',
  //   token: 'JulD-BNB SLP',
  //   tokenDescription: 'JulSwap',
  //   tokenAddress: '0x0c8f9a56544CC988f1873A038de2f3e4C6fE6f27',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooJulJulD-BNB',
  //   earnedTokenAddress: '0xcB8c4416cD10836ff7A35F4b2AfD5BE0257Cc9DB',
  //   earnContractAddress: '0xcB8c4416cD10836ff7A35F4b2AfD5BE0257Cc9DB',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'jul-juld-bnb',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'Other',
  //   assets: ['JulD', 'BNB'],
  //   addLiquidityUrl: 'https://julswap.com/#/add/0x5a41f637c3f7553dba6ddc2d3ca92641096577ea/BNB',
  //   buyTokenUrl:
  //     'https://julswap.com/#/swap?inputCurrency=BNB&outputCurrency=0x5a41f637c3f7553dba6ddc2d3ca92641096577ea',
  //   createdAt: 1614954499,
  // },
  // {
  //   id: 'venus-ada-eol',
  //   logo: 'single-assets/ADA.svg',
  //   name: 'ADA',
  //   token: 'ADA',
  //   tokenDescription: 'Venus',
  //   tokenAddress: '0x3EE2200Efb3400fAbB9AacF31297cBdD1d435D47',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooVenusADA',
  //   earnedTokenAddress: '0x53c0CE2EBdA0c2A39327bE0B58a3c28A8Dcde420',
  //   earnContractAddress: '0x53c0CE2EBdA0c2A39327bE0B58a3c28A8Dcde420',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'tokens',
  //   oracleId: 'ADA',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'Venus',
  //   assets: ['ADA'],
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?outputCurrency=0x3ee2200efb3400fabb9aacf31297cbdd1d435d47',
  //   createdAt: 1614710200,
  // },
  // {
  //   id: 'cake-bel-bnb',
  //   logo: 'bnb-pairs/BEL-BNB.svg',
  //   name: 'BEL-BNB LP',
  //   token: 'BEL-BNB LP',
  //   tokenDescription: 'PancakeSwap',
  //   tokenAddress: '0xAB97952a2806D5c92b7046c7aB13a72A87e0097b',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooPancakeBEL-BNB',
  //   earnedTokenAddress: '0x7C001B962029C0a59b148293838dc780d7E5d24C',
  //   earnContractAddress: '0x7C001B962029C0a59b148293838dc780d7E5d24C',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'cake-bel-bnb',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'PancakeSwap',
  //   assets: ['BEL', 'BNB'],
  //   removeLiquidityUrl:
  //     'https://v1exchange.pancakeswap.finance/#/remove/BNB/0x8443f091997f06a61670b735ed92734f5628692f',
  //   createdAt: 1615043247,
  // },
  // {
  //   id: 'cake-tpt-busd',
  //   logo: 'busd-pairs/TPT-BUSD.svg',
  //   name: 'TPT-BUSD LP',
  //   token: 'TPT-BUSD LP',
  //   tokenDescription: 'PancakeSwap',
  //   tokenAddress: '0x4db28767D1527bA545CA5bbdA1C96a94ED6ff242',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooPancakeTPT-BUSD',
  //   earnedTokenAddress: '0x6b4971b8dAfc3c426EdE7E629394f31B0BdF3c16',
  //   earnContractAddress: '0x6b4971b8dAfc3c426EdE7E629394f31B0BdF3c16',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'cake-tpt-busd',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'PancakeSwap',
  //   assets: ['TPT', 'BUSD'],
  //   removeLiquidityUrl:
  //     'https://v1exchange.pancakeswap.finance/#/remove/0xe9e7cea3dedca5984780bafc599bd69add087d56/0xeca41281c24451168a37211f0bc2b8645af45092',
  //   createdAt: 1615043163,
  // },
  // {
  //   id: 'cake-watch-bnb',
  //   logo: 'bnb-pairs/WATCH-BNB.svg',
  //   name: 'WATCH-BNB LP',
  //   token: 'WATCH-BNB LP',
  //   tokenDescription: 'PancakeSwap',
  //   tokenAddress: '0xdC6C130299E53ACD2CC2D291fa10552CA2198a6b',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooPancakeWATCH-BNB',
  //   earnedTokenAddress: '0x31b4d6D89531BB32307737C441a8cD7d82224B91',
  //   earnContractAddress: '0x31b4d6D89531BB32307737C441a8cD7d82224B91',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'cake-watch-bnb',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'PancakeSwap',
  //   assets: ['WATCH', 'BNB'],
  //   removeLiquidityUrl:
  //     'https://v1exchange.pancakeswap.finance/#/remove/BNB/0x7A9f28EB62C791422Aa23CeAE1dA9C847cBeC9b0',
  //   createdAt: 1614862041,
  // },
  // {
  //   id: 'cake-xmark-busd',
  //   logo: 'busd-pairs/XMARK-BUSD.svg',
  //   name: 'XMARK-BUSD LP',
  //   token: 'XMARK-BUSD LP',
  //   tokenDescription: 'PancakeSwap',
  //   tokenAddress: '0x292Ca56ED5b3330A19037f835Af4A9c0098EA6Fa',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooPancakeXMARK-BUSD',
  //   earnedTokenAddress: '0x9De53755e913913dEcA7360a19C0634F2214FB6d',
  //   earnContractAddress: '0x9De53755e913913dEcA7360a19C0634F2214FB6d',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'cake-xmark-busd',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'PancakeSwap',
  //   assets: ['XMARK', 'BUSD'],
  //   removeLiquidityUrl:
  //     'https://v1exchange.pancakeswap.finance/#/remove/0xe9e7cea3dedca5984780bafc599bd69add087d56/0x26a5dfab467d4f58fb266648cae769503cec9580',
  //   createdAt: 1614906937,
  // },
  // {
  //   id: 'soup-soup-bnb-eol',
  //   logo: 'degens/SOUP-BNB.svg',
  //   name: 'SOUP-BNB LP',
  //   token: 'SOUP-BNB LP',
  //   tokenDescription: 'PancakeSwap (Soup)',
  //   tokenAddress: '0x284A5D8712C351Ca28417d131003120808dcE48B',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooSoupSoup-BNB',
  //   earnedTokenAddress: '0xDB89f01094c6e882d06aBC724aEBc7d15036fe14',
  //   earnContractAddress: '0xDB89f01094c6e882d06aBC724aEBc7d15036fe14',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'soup-soup-bnb',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'Other',
  //   assets: ['SOUP', 'BNB'],
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?outputCurrency=0x94F559aE621F1c810F31a6a620Ad7376776fe09E',
  //   createdAt: 1614849256,
  // },
  // {
  //   id: 'soup-soups-bnb-eol',
  //   logo: 'degens/SOUPS-BNB.svg',
  //   name: 'SOUPS-BNB LP',
  //   token: 'SOUPS-BNB LP',
  //   tokenDescription: 'PancakeSwap (Soup)',
  //   tokenAddress: '0x6304Ae062c6bDf3D24Ac86374C7019A025443247',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooSoupSoups-BNB',
  //   earnedTokenAddress: '0xe698edcC279f68c2c37f5c122a26faBd5f6D98A3',
  //   earnContractAddress: '0xe698edcC279f68c2c37f5c122a26faBd5f6D98A3',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'soup-soups-bnb',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'Other',
  //   assets: ['SOUPS', 'BNB'],
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?outputCurrency=0x69f27e70e820197a6e495219d9ac34c8c6da7eee',
  //   createdAt: 1614853224,
  // },
  // {
  //   id: 'cake-bmxx-bnb',
  //   logo: 'bnb-pairs/BMXX-BNB.svg',
  //   name: 'BMXX-BNB LP',
  //   token: 'BMXX-BNB LP',
  //   tokenDescription: 'PancakeSwap',
  //   tokenAddress: '0x4D5aA94Ce6BbB1BC4eb73207a5a5d4D052cFcD67',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooPancakeBMXX-BNB',
  //   earnedTokenAddress: '0xE7DCEFacf1c9c523c1ccCc3Fc1Ae3EB654a4e805',
  //   earnContractAddress: '0xE7DCEFacf1c9c523c1ccCc3Fc1Ae3EB654a4e805',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'cake-bmxx-bnb',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'PancakeSwap',
  //   assets: ['BMXX', 'BNB'],
  //   removeLiquidityUrl:
  //     'https://v1exchange.pancakeswap.finance/#/remove/BNB/0x4131b87f74415190425ccd873048c708f8005823',
  //   createdAt: 1614694792,
  // },
  // {
  //   id: '1inch-1inch-bnb-eol',
  //   logo: 'single-assets/INCH.png',
  //   name: '1INCH-BNB LP',
  //   token: '1INCH-BNB LP',
  //   tokenDescription: '1Inch',
  //   tokenAddress: '0xdaF66c0B7e8E2FC76B15B07AD25eE58E04a66796',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'moo1INCH1INCH-BNB',
  //   earnedTokenAddress: '0x2139E4CaDA8438a41F4009ff07D09Db29A62De04',
  //   earnContractAddress: '0x2139E4CaDA8438a41F4009ff07D09Db29A62De04',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: '1inch-1inch-bnb',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'Other',
  //   assets: ['1INCH', 'BNB'],
  //   addLiquidityUrl:
  //     'https://1inch.exchange/#/dao/pools?network=56&token0=0x0000000000000000000000000000000000000000&token1=0x111111111117dc0aa78b770fa6a738034120c302',
  //   buyTokenUrl:
  //     'https://1inch.exchange/#/r/0xF4cb25a1FF50E319c267b3E51CBeC2699FB2A43B/BNB/1INCH/?network=56',
  //   createdAt: 1614690289,
  // },
  // {
  //   id: 'cake-iotx-busd',
  //   logo: 'busd-pairs/IOTX-BUSD.svg',
  //   name: 'IOTX-BUSD LP',
  //   token: 'IOTX-BUSD LP',
  //   tokenDescription: 'PancakeSwap',
  //   tokenAddress: '0x36B8B28D37f93372188F2aa2507b68A5CD8b2664',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooPancakeIOTX-BUSD',
  //   earnedTokenAddress: '0x8a27f380626CAc25DF8D3Ea50Ed1fd7Db882e653',
  //   earnContractAddress: '0x8a27f380626CAc25DF8D3Ea50Ed1fd7Db882e653',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'cake-iotx-busd',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'PancakeSwap',
  //   assets: ['IOTX', 'BUSD'],
  //   removeLiquidityUrl:
  //     'https://v1exchange.pancakeswap.finance/#/remove/0xe9e7cea3dedca5984780bafc599bd69add087d56/0x9678e42cebeb63f23197d726b29b1cb20d0064e5',
  //   createdAt: 1614727665,
  // },
  // {
  //   id: 'cake-lina-busd',
  //   logo: 'busd-pairs/LINA-BUSD.svg',
  //   name: 'LINA-BUSD LP',
  //   token: 'LINA-BUSD LP',
  //   tokenDescription: 'PancakeSwap',
  //   tokenAddress: '0xeB325a8ea1c5abf40c7ceAf645596c1F943d0948',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooPancakeLINA-BUSD',
  //   earnedTokenAddress: '0x310DF4c1DD93337A165AeF8e3d45A770CaaDe3b6',
  //   earnContractAddress: '0x310DF4c1DD93337A165AeF8e3d45A770CaaDe3b6',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'cake-lina-busd',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'PancakeSwap',
  //   assets: ['LINA', 'BUSD'],
  //   removeLiquidityUrl:
  //     'https://v1exchange.pancakeswap.finance/#/remove/0xe9e7cea3dedca5984780bafc599bd69add087d56/0x762539b45a1dcce3d36d080f74d1aed37844b878',
  //   createdAt: 1614727590,
  // },
  // {
  //   id: 'cake-dai-busd',
  //   logo: 'busd-pairs/DAI-BUSD.svg',
  //   name: 'DAI-BUSD LP',
  //   token: 'DAI-BUSD LP',
  //   tokenDescription: 'PancakeSwap',
  //   tokenAddress: '0x3aB77e40340AB084c3e23Be8e5A6f7afed9D41DC',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooPancakeDAI-BUSD',
  //   earnedTokenAddress: '0xE372825C7b436244Cd5fA79cAa16e4Cc3091D4B0',
  //   earnContractAddress: '0xE372825C7b436244Cd5fA79cAa16e4Cc3091D4B0',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'cake-dai-busd',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'PancakeSwap',
  //   assets: ['DAI', 'BUSD'],
  //   removeLiquidityUrl:
  //     'https://v1exchange.pancakeswap.finance/#/remove/0xe9e7cea3dedca5984780bafc599bd69add087d56/0x1af3f329e8be154074d8769d1ffa4ee058b1dbc3',
  //   createdAt: 1614726127,
  // },
  // {
  //   id: 'sponge-steam-bnb-eol',
  //   logo: 'bnb-pairs/STEAM-BNB.svg',
  //   name: 'STEAM-BNB LP',
  //   token: 'STEAM-BNB LP',
  //   tokenDescription: 'Sponge',
  //   tokenAddress: '0x5e797B329e6B82b62442477D976a56864AE4396e',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooSpongeSTEAM-BNB',
  //   earnedTokenAddress: '0x04D0bE0169694ca083996899a14752c82A953D22',
  //   earnContractAddress: '0x04D0bE0169694ca083996899a14752c82A953D22',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'sponge-steam-bnb',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'Other',
  //   assets: ['STEAM', 'BNB'],
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?outputCurrency=0x072e3d0d4f14dd82a16b0a33678c6104e9009ed9',
  //   createdAt: 1614451674,
  // },
  // {
  //   id: 'salt-salt-busd-eol',
  //   logo: 'degens/salt-busd.png',
  //   name: 'SALT-BUSD LP',
  //   token: 'SALT-BUSD LP',
  //   tokenDescription: 'SaltSwap',
  //   tokenAddress: '0x6596f770786915556C47E301cC8290aa14288d99',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooSaltSalt-BUSD',
  //   earnedTokenAddress: '0x62ad4253FB38265Da05eb389ac547F1B2c09a689',
  //   earnContractAddress: '0x62ad4253FB38265Da05eb389ac547F1B2c09a689',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'salt-salt-busd',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'Other',
  //   assets: ['SALT', 'BUSD'],
  //   addLiquidityUrl:
  //     'https://exchange.saltswap.finance/#/add/0xe9e7cea3dedca5984780bafc599bd69add087d56/0x2849b1ae7e04a3d9bc288673a92477cf63f28af4',
  //   buyTokenUrl:
  //     'https://exchange.saltswap.finance/#/swap?inputCurrency=0xcc2E12a9b5b75360c6FBf23B584c275D52cDdb0&outputCurrency=0x2849b1ae7e04a3d9bc288673a92477cf63f28af4',
  //   createdAt: 1614669316,
  //   retiredReason: 'tvl',
  // },
  // {
  //   id: 'salt-salt-bnb-eol',
  //   logo: 'degens/salt-bnb.png',
  //   name: 'SALT-BNB LP',
  //   token: 'SALT-BNB LP',
  //   tokenDescription: 'SaltSwap',
  //   tokenAddress: '0x6284B49544c8900B4612f8450DCE8D484E5c2631',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooSaltSalt-BNB',
  //   earnedTokenAddress: '0x57e07d6958fd38AA2f46699744e322d3fB3db60f',
  //   earnContractAddress: '0x57e07d6958fd38AA2f46699744e322d3fB3db60f',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'salt-salt-bnb',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'Other',
  //   assets: ['SALT', 'BNB'],
  //   addLiquidityUrl:
  //     'https://exchange.saltswap.finance/#/add/BNB/0x2849b1ae7e04a3d9bc288673a92477cf63f28af4',
  //   buyTokenUrl:
  //     'https://exchange.saltswap.finance/#/swap?inputCurrency=ETH&outputCurrency=0x2849b1ae7e04a3d9bc288673a92477cf63f28af4',
  //   createdAt: 1614674680,
  //   retiredReason: 'tvl',
  // },
  // {
  //   id: 'cake-sushi-eth',
  //   logo: 'uncategorized/SUSHI-ETH.svg',
  //   name: 'SUSHI-ETH LP',
  //   token: 'SUSHI-ETH LP',
  //   tokenDescription: 'PancakeSwap',
  //   tokenAddress: '0x17580340F3dAEDAE871a8C21D15911742ec79e0F',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooPancakeSUSHI-ETH',
  //   earnedTokenAddress: '0x07622B8cF9130934643522E7c9D621707EF1d34F',
  //   earnContractAddress: '0x07622B8cF9130934643522E7c9D621707EF1d34F',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'cake-sushi-eth',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'PancakeSwap',
  //   assets: ['SUSHI', 'ETH'],
  //   removeLiquidityUrl:
  //     'https://v1exchange.pancakeswap.finance/#/remove/0x2170ed0880ac9a755fd29b2688956bd959f933f8/0x947950bcc74888a40ffa2593c5798f11fc9124c4',
  //   createdAt: 1614355685,
  // },
  // {
  //   id: 'crow-crow-busd',
  //   logo: 'crow/crow.png',
  //   name: 'CROW-BUSD LP',
  //   token: 'CROW-BUSD LP',
  //   tokenDescription: 'PancakeSwap (Crow)',
  //   tokenAddress: '0xEC3B97802FB4883FdB7bf70f0924Bf5EE0520cDE',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooCrowCROW-BUSD',
  //   earnedTokenAddress: '0x39C803716F67D6d4b190E633a961bF3503b8444E',
  //   earnContractAddress: '0x39C803716F67D6d4b190E633a961bF3503b8444E',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'crow-crow-busd',
  //   oraclePrice: 0,
  //   depositsPaused: false,
  //   status: 'eol',
  //   platform: 'Other',
  //   assets: ['CROW', 'BUSD'],
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?inputCurrency=0xcc2E12a9b5b75360c6FBf23B584c275D52cDdb0&outputCurrency=0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
  //   createdAt: 1614530571,
  // },
  // {
  //   id: 'crow-crow-bnb',
  //   logo: 'crow/crow.png',
  //   name: 'CROW-BNB LP',
  //   token: 'CROW-BNB LP',
  //   tokenDescription: 'PancakeSwap (Crow)',
  //   tokenAddress: '0x74d2a66650bbd8027Fd1F4A3454a16D75A05cc14',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooCrowCROW-BNB',
  //   earnedTokenAddress: '0xbD701BC24485Ebfd3680285fB152509637Ab8ad4',
  //   earnContractAddress: '0xbD701BC24485Ebfd3680285fB152509637Ab8ad4',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'crow-crow-bnb',
  //   oraclePrice: 0,
  //   depositsPaused: false,
  //   status: 'eol',
  //   platform: 'Other',
  //   assets: ['CROW', 'BNB'],
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?inputCurrency=BNB&outputCurrency=0xcc2E12a9b5b75360c6FBf23B584c275D52cDdb0E',
  //   createdAt: 1614529623,
  // },
  // {
  //   id: 'ramen-ramen-bnb-eol',
  //   logo: 'degens/ramen-bnb.svg',
  //   name: 'RAMEN-BNB LP',
  //   token: 'RAMEN-BNB LP',
  //   tokenDescription: 'RamenSwap',
  //   tokenAddress: '0x82f504D655ec6dbA8d5eBbd0036a908A719A295f',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooRamenRamen-BNB',
  //   earnedTokenAddress: '0x42b41189e9B371B7952b77aE791FE25E78D22681',
  //   earnContractAddress: '0x42b41189e9B371B7952b77aE791FE25E78D22681',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'ramen-ramen-bnb',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   retireReason: 'tvl',
  //   platform: 'Other',
  //   assets: ['RAMEN', 'BNB'],
  //   addLiquidityUrl:
  //     'https://exchange.ramenswap.finance/#/add/ETH/0x4F47A0d15c1E53F3d94c069C7D16977c29F9CB6B',
  //   buyTokenUrl:
  //     'https://exchange.ramenswap.finance/#/swap?outputCurrency=0x4F47A0d15c1E53F3d94c069C7D16977c29F9CB6B',
  //   createdAt: 1614171311,
  // },
  // {
  //   id: 'ramen-ramen-busd-eol',
  //   logo: 'degens/ramen-busd.svg',
  //   name: 'RAMEN-BUSD LP',
  //   token: 'RAMEN-BUSD LP',
  //   tokenDescription: 'RamenSwap',
  //   tokenAddress: '0x04F81B56B6947Cd0fD35fbea570fc09d1b946C56',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooRamenRamen-BUSD',
  //   earnedTokenAddress: '0x605d96De6068f08489392a35E9DBE90201574bbC',
  //   earnContractAddress: '0x605d96De6068f08489392a35E9DBE90201574bbC',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'ramen-ramen-busd',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'Other',
  //   assets: ['RAMEN', 'BUSD'],
  //   addLiquidityUrl:
  //     'https://exchange.ramenswap.finance/#/add/0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56/0x4F47A0d15c1E53F3d94c069C7D16977c29F9CB6B',
  //   buyTokenUrl:
  //     'https://exchange.ramenswap.finance/#/swap?inputCurrency=0x4F47A0d15c1E53F3d94c069C7D16977c29F9CB6B&outputCurrency=0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
  //   createdAt: 1614173476,
  //   retiredReason: 'tvl',
  // },
  // {
  //   id: 'cafe-brew-busd',
  //   logo: 'degens/brew-busd.svg',
  //   name: 'BREW-BUSD LP',
  //   token: 'BREW-BUSD LP',
  //   tokenDescription: 'CafeSwap',
  //   tokenAddress: '0x1FD9Af4999De0d61c2a6CBD3d4892b675a082999',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooCafeBREW-BUSD',
  //   earnedTokenAddress: '0x10120B5550214ab9451B9B00e4f78e82C02D6482',
  //   earnContractAddress: '0x10120B5550214ab9451B9B00e4f78e82C02D6482',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'cafe-brew-busd',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'Other',
  //   assets: ['BREW', 'BUSD'],
  //   removeLiquidityUrl:
  //     'https://exchange.cafeswap.finance/#/remove/0x790Be81C3cA0e53974bE2688cDb954732C9862e1/0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
  //   createdAt: 1614063926,
  // },
  // {
  //   id: 'cafe-bifi-bnb',
  //   logo: 'degens/BIFI-BNB.svg',
  //   name: 'BIFI-BNB LP',
  //   token: 'BIFI-BNB LP',
  //   tokenDescription: 'CafeSwap',
  //   tokenAddress: '0xd132D2C24F29EE8ABb64a66559d1b7aa627Bd7fD',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooCafeBIFI-BNB',
  //   earnedTokenAddress: '0x8afc0f9BdC5DcA9f0408Df03A03520bFa98A15aF',
  //   earnContractAddress: '0x8afc0f9BdC5DcA9f0408Df03A03520bFa98A15aF',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'cafe-bifi-bnb',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'Other',
  //   assets: ['BIFI', 'BNB'],
  //   removeLiquidityUrl:
  //     'https://exchange.cafeswap.finance/#/remove/0xCa3F508B8e4Dd382eE878A314789373D80A5190A/0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
  //   createdAt: 1614452547,
  // },
  // {
  //   id: 'cake-bry-bnb',
  //   logo: 'bnb-pairs/BRY-BNB.svg',
  //   name: 'BRY-BNB LP',
  //   token: 'BRY-BNB LP',
  //   tokenDescription: 'PancakeSwap',
  //   tokenAddress: '0x5E3CD27F36932Bc0314aC4e2510585798C34a2fC',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooPancakeBRYBNB',
  //   earnedTokenAddress: '0x578C066b5c7289f8Ba7c4DB43c110F19D7c51E03',
  //   earnContractAddress: '0x578C066b5c7289f8Ba7c4DB43c110F19D7c51E03',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'cake-bry-bnb',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'PancakeSwap',
  //   assets: ['BRY', 'BNB'],
  //   removeLiquidityUrl:
  //     'https://v1exchange.pancakeswap.finance/#/remove/BNB/0xf859Bf77cBe8699013d6Dbc7C2b926Aaf307F830',
  //   createdAt: 1613506147,
  // },
  // {
  //   id: 'cake-zee-bnb',
  //   logo: 'bnb-pairs/ZEE-BNB.svg',
  //   name: 'ZEE-BNB LP',
  //   token: 'ZEE-BNB LP',
  //   tokenDescription: 'PancakeSwap',
  //   tokenAddress: '0xB5Ab3996808c7e489DCDc0f1Af2AB212ae0059aF',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooPancakeZEEBNB',
  //   earnedTokenAddress: '0x29C4C22eC7dF7e936Cb3e6C4A7E5eAE751C7d86B',
  //   earnContractAddress: '0x29C4C22eC7dF7e936Cb3e6C4A7E5eAE751C7d86B',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'cake-zee-bnb',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'PancakeSwap',
  //   assets: ['ZEE', 'BNB'],
  //   removeLiquidityUrl:
  //     'https://v1exchange.pancakeswap.finance/#/remove/BNB/0x44754455564474a89358b2c2265883df993b12f0',
  //   createdAt: 1613499567,
  // },
  // {
  //   id: 'cake-dodo-bnb',
  //   logo: 'bnb-pairs/DODO-BNB.svg',
  //   name: 'DODO-BNB LP',
  //   token: 'DODO-BNB LP',
  //   tokenDescription: 'PancakeSwap',
  //   tokenAddress: '0x9e642d174B14fAEa31D842Dc83037c42b53236E6',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooPancakeDODO-BNB',
  //   earnedTokenAddress: '0x00AeC34489A7ADE91A0507B6b9dBb0a50938B7c0',
  //   earnContractAddress: '0x00AeC34489A7ADE91A0507B6b9dBb0a50938B7c0',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'cake-dodo-bnb',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'PancakeSwap',
  //   assets: ['DODO', 'BNB'],
  //   removeLiquidityUrl:
  //     'https://v1exchange.pancakeswap.finance/#/remove/BNB/0x67ee3cb086f8a16f34bee3ca72fad36f7db929e2',
  //   createdAt: 1614195621,
  // },
  // {
  //   id: 'cake-swingby-bnb',
  //   logo: 'bnb-pairs/SWINGBY-BNB.svg',
  //   name: 'SWINGBY-BNB LP',
  //   token: 'SWINGBY-BNB LP',
  //   tokenDescription: 'PancakeSwap',
  //   tokenAddress: '0x4576C456AF93a37a096235e5d83f812AC9aeD027',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooPancakeSWINGBY-BNB',
  //   earnedTokenAddress: '0xc1C1eB984218B9570beA53C0Dad14283a6E9E81C',
  //   earnContractAddress: '0xc1C1eB984218B9570beA53C0Dad14283a6E9E81C',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'cake-swingby-bnb',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'PancakeSwap',
  //   assets: ['SWINGBY', 'BNB'],
  //   removeLiquidityUrl:
  //     'https://v1exchange.pancakeswap.finance/#/remove/BNB/0x71de20e0c4616e7fcbfdd3f875d568492cbe4739',
  //   createdAt: 1614193328,
  // },
  // {
  //   id: 'kebab-usdt-busd',
  //   logo: 'kebab/USDT-BUSD.png',
  //   name: 'USDT-BUSD LP',
  //   token: 'USDT-BUSD LP',
  //   tokenDescription: 'Kebab',
  //   tokenAddress: '0xc15fa3E22c912A276550F3E5FE3b0Deb87B55aCd',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooKebabUSDT-BUSD',
  //   earnedTokenAddress: '0x9dc7d9c4488223c0Ad50F0AE742D80AE1abEc859',
  //   earnContractAddress: '0x9dc7d9c4488223c0Ad50F0AE742D80AE1abEc859',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'kebab-usdt-busd',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'Kebab',
  //   assets: ['USDT', 'BUSD'],
  //   addLiquidityUrl:
  //     'https://swap.kebabfinance.com/#/add/0x55d398326f99059ff775485246999027b3197955/0xe9e7cea3dedca5984780bafc599bd69add087d56',
  //   buyTokenUrl:
  //     'https://swap.kebabfinance.com/#/swap?inputCurrency=0x55d398326f99059ff775485246999027b3197955&outputCurrency=0xe9e7cea3dedca5984780bafc599bd69add087d56',
  //   createdAt: 1614374598,
  // },
  // {
  //   id: 'kebab-eth-btcb',
  //   logo: 'kebab/ETH-BTCB.png',
  //   name: 'ETH-BTCB LP',
  //   token: 'ETH-BTCB LP',
  //   tokenDescription: 'Kebab',
  //   tokenAddress: '0x7380E10F5C5f9DFF4857de3cf9c39Bb16F4C6dcf',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooKebabETH-BTCB',
  //   earnedTokenAddress: '0x70CBc8B3a74A857056a6acE7e994229A24D4ab94',
  //   earnContractAddress: '0x70CBc8B3a74A857056a6acE7e994229A24D4ab94',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'kebab-eth-btcb',
  //   oraclePrice: 0,
  //   depositsPaused: false,
  //   status: 'active',
  //   platform: 'Kebab',
  //   assets: ['ETH', 'BTCB'],
  //   addLiquidityUrl:
  //     'https://swap.kebabfinance.com/#/add/0x7130d2a12b9bcbfae4f2634d864a1ee1ce3ead9c/0x2170ed0880ac9a755fd29b2688956bd959f933f8',
  //   buyTokenUrl:
  //     'https://swap.kebabfinance.com/#/swap?inputCurrency=0x7130d2a12b9bcbfae4f2634d864a1ee1ce3ead9c&outputCurrency=0x2170ed0880ac9a755fd29b2688956bd959f933f8',
  //   createdAt: 1614171440,
  // },
  // {
  //   id: 'monster-msc-busd',
  //   logo: 'single-assets/MSC.png',
  //   name: 'MSC-BUSD LP',
  //   token: 'MSC-BUSD LP',
  //   tokenDescription: 'PancakeSwap (Monster)',
  //   tokenAddress: '0xbCbF0C95C94798fFEF0e71A087a7E98F29D5C0Ee',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooMonsterMSC-BUSD',
  //   earnedTokenAddress: '0x1d9a453FCe2AC7b92FBe7b33BdC40F6e7aE14F47',
  //   earnContractAddress: '0x1d9a453FCe2AC7b92FBe7b33BdC40F6e7aE14F47',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'monster-msc-busd',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'Other',
  //   assets: ['MSC', 'BUSD'],
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?inputCurrency=0x8C784C49097Dcc637b93232e15810D53871992BF&outputCurrency=0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
  //   createdAt: 1612353483,
  // },
  // {
  //   id: 'monster-msc-bnb',
  //   logo: 'single-assets/MSC.png',
  //   name: 'MSC-BNB LP',
  //   token: 'MSC-BNB LP',
  //   tokenDescription: 'PancakeSwap (Monster)',
  //   tokenAddress: '0x981DB69F2F2F96e0f08D6519BEFDa0B927C22190',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooMonsterMSC-BNB',
  //   earnedTokenAddress: '0x0F88Ce20f8af6D4c51b635BFBd8DFa03f2a36ACa',
  //   earnContractAddress: '0x0F88Ce20f8af6D4c51b635BFBd8DFa03f2a36ACa',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'monster-msc-bnb',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'Other',
  //   assets: ['MSC', 'BNB'],
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?outputCurrency=0x8C784C49097Dcc637b93232e15810D53871992BF',
  //   createdAt: 1613998911,
  // },
  // {
  //   id: 'cake-bts-busd-eol',
  //   logo: 'single-assets/BTS.png',
  //   name: 'BTS-BUSD LP',
  //   token: 'BTS-BUSD LP',
  //   tokenDescription: 'PancakeSwap (Bolt)',
  //   tokenAddress: '0xE86493D416EEB7800F08787de759d179B61dB921',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooBoltDollarBTS-BUSD',
  //   earnedTokenAddress: '0x6b40106ebEDaA719B14cfbA606b89954EaeeaE9D',
  //   earnContractAddress: '0x6b40106ebEDaA719B14cfbA606b89954EaeeaE9D',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'cake-bts-busd',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   retireReason: 'rewards',
  //   status: 'eol',
  //   platform: 'Other',
  //   assets: ['BTS', 'BUSD'],
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?inputCurrency=0xc2e1acef50ae55661855e8dcb72adb182a3cc259&outputCurrency=0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
  //   createdAt: 1613435149,
  // },
  // {
  //   id: 'cake-btd-busd-eol',
  //   logo: 'single-assets/BTD.png',
  //   name: 'BTD-BUSD LP',
  //   token: 'BTD-BUSD LP',
  //   tokenDescription: 'PancakeSwap (Bolt)',
  //   tokenAddress: '0xebaaA639a55ecBEbAC4D7D43A5A6D7C5c1216A09',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooBoltDollarBTD-BUSD',
  //   earnedTokenAddress: '0x0D3837E00C47353e68680C39e6DA6B5b08fE6897',
  //   earnContractAddress: '0x0D3837E00C47353e68680C39e6DA6B5b08fE6897',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'cake-btd-busd',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'Other',
  //   assets: ['BTD', 'BUSD'],
  //   retireReason: 'tvl',
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?inputCurrency=0xD1102332a213E21faF78B69C03572031F3552c33&outputCurrency=0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
  //   createdAt: 1613429530,
  // },
  // {
  //   id: 'auto-btc-eol',
  //   logo: 'single-assets/BTCB.svg',
  //   name: 'BTCB',
  //   token: 'BTCB',
  //   tokenDescription: 'Venus (Autofarm)',
  //   tokenAddress: '0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooAutoBtc',
  //   earnedTokenAddress: '0xFf750EE63F1E428De1D6C8D9d92676b5FC8376A8',
  //   earnContractAddress: '0xFf750EE63F1E428De1D6C8D9d92676b5FC8376A8',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'tokens',
  //   oracleId: 'BTCB',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'Other',
  //   assets: ['BTCB'],
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?outputCurrency=0x7130d2a12b9bcbfae4f2634d864a1ee1ce3ead9c',
  //   createdAt: 1613659177,
  // },
  // {
  //   id: 'auto-eth-eol',
  //   logo: 'single-assets/ETH.svg',
  //   name: 'ETH',
  //   token: 'ETH',
  //   tokenDescription: 'Venus (Autofarm)',
  //   tokenAddress: '0x2170Ed0880ac9A755fd29B2688956BD959F933F8',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooAutoEth',
  //   earnedTokenAddress: '0x0a406FCBEA86aC57ACcc812E8F1B509acE5E054c',
  //   earnContractAddress: '0x0a406FCBEA86aC57ACcc812E8F1B509acE5E054c',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'tokens',
  //   oracleId: 'ETH',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'Other',
  //   assets: ['ETH'],
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?outputCurrency=0x2170ed0880ac9a755fd29b2688956bd959f933f8',
  //   createdAt: 1613659231,
  // },
  // {
  //   id: 'auto-busd-eol',
  //   logo: 'single-assets/BUSD.svg',
  //   name: 'BUSD',
  //   token: 'BUSD',
  //   tokenDescription: 'Venus (Autofarm)',
  //   tokenAddress: '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooAutoBusdFixed',
  //   earnedTokenAddress: '0x559C88946e1958812dCfD8edd4f89B0C7d811F74',
  //   earnContractAddress: '0x559C88946e1958812dCfD8edd4f89B0C7d811F74',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'tokens',
  //   oracleId: 'BUSD',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'Other',
  //   assets: ['BUSD'],
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?outputCurrency=0xe9e7cea3dedca5984780bafc599bd69add087d56',
  //   createdAt: 1613659056,
  // },
  // {
  //   id: 'auto-usdt-eol',
  //   logo: 'single-assets/USDT.svg',
  //   name: 'USDT',
  //   token: 'USDT',
  //   tokenDescription: 'Venus (Autofarm)',
  //   tokenAddress: '0x55d398326f99059fF775485246999027B3197955',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooAutoUsdt',
  //   earnedTokenAddress: '0x58327471A57EE89Aa4b91753fF9E09f7291236C7',
  //   earnContractAddress: '0x58327471A57EE89Aa4b91753fF9E09f7291236C7',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'tokens',
  //   oracleId: 'USDT',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'Other',
  //   assets: ['USDT'],
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?outputCurrency=0x55d398326f99059ff775485246999027b3197955',
  //   createdAt: 1613659295,
  // },
  // {
  //   id: 'kebab-kebab-btc-eol',
  //   logo: 'kebab/KEBAB-BTCB.png',
  //   name: 'KEBAB-BTCB LP',
  //   token: 'KEBAB-BTCB LP',
  //   tokenDescription: 'Kebab',
  //   tokenAddress: '0x45D8D5d9bD425Efc26e3259848BCFEfA16F927F0',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooKebabKEBAB-BTCB',
  //   earnedTokenAddress: '0x02a77B01BE5BB6a73b70E2b5615694F71a2c4F41',
  //   earnContractAddress: '0x02a77B01BE5BB6a73b70E2b5615694F71a2c4F41',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'kebab-kebab-btc',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'Kebab',
  //   assets: ['KEBAB', 'BTCB'],
  //   addLiquidityUrl:
  //     'https://swap.kebabfinance.com/#/add/0x7979f6c54eba05e18ded44c4f986f49a5de551c2/0x7130d2a12b9bcbfae4f2634d864a1ee1ce3ead9c',
  //   buyTokenUrl:
  //     'https://swap.kebabfinance.com/#/swap?inputCurrency=0x7130d2a12b9bcbfae4f2634d864a1ee1ce3ead9c&outputCurrency=0x7979f6c54eba05e18ded44c4f986f49a5de551c2',
  //   createdAt: 1613582986,
  //   retiredReason: 'tvl',
  // },
  // {
  //   id: 'cake-swgb-bnb',
  //   logo: 'bnb-pairs/SWGB-BNB.svg',
  //   name: 'SWGB-BNB LP',
  //   token: 'SWGB-BNB LP',
  //   tokenDescription: 'PancakeSwap',
  //   tokenAddress: '0xC1800c29CF91954357cd0bf3f0accAADa3D0109c',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooPancakeSWGB-BNB',
  //   earnedTokenAddress: '0xed324b068e31c6fB7C7921e79E54d95B1Ca9d759',
  //   earnContractAddress: '0xed324b068e31c6fB7C7921e79E54d95B1Ca9d759',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'cake-swgb-bnb',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'PancakeSwap',
  //   assets: ['SWGB', 'BNB'],
  //   removeLiquidityUrl:
  //     'https://v1exchange.pancakeswap.finance/#/remove/BNB/0xe40255c5d7fa7ceec5120408c78c787cecb4cfdb',
  //   createdAt: 1613228660,
  // },
  // {
  //   id: 'cake-lit-bnb',
  //   logo: 'bnb-pairs/LIT-BNB.svg',
  //   name: 'LIT-BNB LP',
  //   token: 'LIT-BNB LP',
  //   tokenDescription: 'PancakeSwap',
  //   tokenAddress: '0x60bB03D1010b99CEAdD0dd209b64bC8bd83da161',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooPancakeLIT-BNB',
  //   earnedTokenAddress: '0x747f66e2Ab1b887a2207e15ADb7f9dAB79D9EAcf',
  //   earnContractAddress: '0x747f66e2Ab1b887a2207e15ADb7f9dAB79D9EAcf',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'cake-lit-bnb',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'PancakeSwap',
  //   assets: ['LIT', 'BNB'],
  //   removeLiquidityUrl:
  //     'https://v1exchange.pancakeswap.finance/#/remove/BNB/0xb59490aB09A0f526Cc7305822aC65f2Ab12f9723',
  //   createdAt: 1613228585,
  // },
  // {
  //   id: 'kebab-bifi-busd',
  //   logo: 'busd-pairs/BIFI-BUSD.png',
  //   name: 'BIFI-BUSD LP',
  //   token: 'BIFI-BUSD LP',
  //   tokenDescription: 'Kebab',
  //   tokenAddress: '0xd3F004E303114423f122c78AFDeD4AcfE97675B1',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooKebabBIFI-BUSD',
  //   earnedTokenAddress: '0x9c1630eA66310fA03D756FBd92B68a1f08c487b6',
  //   earnContractAddress: '0x9c1630eA66310fA03D756FBd92B68a1f08c487b6',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'kebab-bifi-busd',
  //   oraclePrice: 0,
  //   depositsPaused: false,
  //   status: 'active',
  //   platform: 'Kebab',
  //   assets: ['BIFI', 'BUSD'],
  //   risks: [
  //     'COMPLEXITY_LOW',
  //     'BATTLE_TESTED',
  //     'IL_LOW',
  //     'MCAP_MEDIUM',
  //     'AUDIT',
  //     'CONTRACTS_VERIFIED',
  //   ],
  //   stratType: 'StratLP',
  //   addLiquidityUrl:
  //     'https://swap.kebabfinance.com/#/add/0xCa3F508B8e4Dd382eE878A314789373D80A5190A/0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
  //   buyTokenUrl:
  //     'https://swap.kebabfinance.com/#/swap?inputCurrency=0xCa3F508B8e4Dd382eE878A314789373D80A5190A',
  //   createdAt: 1613176028,
  // },
  // {
  //   id: 'cake-ditto-bnb',
  //   logo: 'bnb-pairs/DITTO-BNB.svg',
  //   name: 'DITTO-BNB LP',
  //   token: 'DITTO-BNB LP',
  //   tokenDescription: 'PancakeSwap',
  //   tokenAddress: '0x470BC451810B312BBb1256f96B0895D95eA659B1',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooPancakeDITTO-BNB',
  //   earnedTokenAddress: '0x8FF55869e05492c127cEEc6A400D7e3803fC44E1',
  //   earnContractAddress: '0x8FF55869e05492c127cEEc6A400D7e3803fC44E1',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'cake-ditto-bnb',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'PancakeSwap',
  //   assets: ['DITTO', 'BNB'],
  //   removeLiquidityUrl:
  //     'https://v1exchange.pancakeswap.finance/#/remove/BNB/0x233d91a0713155003fc4dce0afa871b508b3b715',
  //   createdAt: 1612978544,
  // },
  // {
  //   id: 'auto-cake-old-eol',
  //   logo: 'single-assets/SMART.png',
  //   name: 'CAKE',
  //   token: 'CAKE',
  //   tokenDescription: 'Venus (Autofarm)',
  //   tokenAddress: '0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooAutoCake',
  //   earnedTokenAddress: '0x895CF1E805cBd86355caC101aE9B252c806Dc59b',
  //   earnContractAddress: '0x895CF1E805cBd86355caC101aE9B252c806Dc59b',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'tokens',
  //   oracleId: 'Cake',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'Other',
  //   assets: ['CAKE'],
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?outputCurrency=0x0e09fabb73bd3ade0a17ecc321fd13a19e81ce82',
  //   createdAt: 1613055503,
  // },
  // {
  //   id: 'auto-busd-old-eol',
  //   logo: 'single-assets/BUSD.svg',
  //   name: 'BUSD',
  //   token: 'BUSD',
  //   tokenDescription: 'Venus (Autofarm)',
  //   tokenAddress: '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooAutoBusd',
  //   earnedTokenAddress: '0xBd198f5132631e597518529268000A0199f07492',
  //   earnContractAddress: '0xBd198f5132631e597518529268000A0199f07492',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'tokens',
  //   oracleId: 'BUSD',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'Other',
  //   assets: ['BUSD'],
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?outputCurrency=0xe9e7cea3dedca5984780bafc599bd69add087d56',
  //   createdAt: 1613055197,
  // },
  // {
  //   id: 'sponge-sponge-bnb-eol',
  //   logo: 'bnb-pairs/SPG-BNB.svg',
  //   name: 'SPG-BNB LP',
  //   token: 'SPG-BNB LP',
  //   tokenDescription: 'Sponge',
  //   tokenAddress: '0xBB74572F3C00B4d18fAbeADb7d5984D7b3AE05d1',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooSpongeSPG-BNB',
  //   earnedTokenAddress: '0x1B3564F698988804b5D05a2C10B1d71bE051662F',
  //   earnContractAddress: '0x1B3564F698988804b5D05a2C10B1d71bE051662F',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'sponge-sponge-bnb',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'Other',
  //   assets: ['SPG', 'BNB'],
  //   addLiquidityUrl:
  //     'https://swap.sponge.finance/#/add/BNB/0x3aabcf53a1930a42e18d938c019e83ebee50a849',
  //   buyTokenUrl:
  //     'https://swap.sponge.finance/#/swap?outputCurrency=0x3aabcf53a1930a42e18d938c019e83ebee50a849',
  //   createdAt: 1612829714,
  // },
  // {
  //   id: 'sponge-soak-bnb-eol',
  //   logo: 'bnb-pairs/SOAK-BNB.svg',
  //   name: 'SOAK-BNB LP',
  //   token: 'SOAK-BNB LP',
  //   tokenDescription: 'Sponge',
  //   tokenAddress: '0xc8FCBA816eF9B0c5bc03d9178CfCc7EF785B4f2f',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooSpongeSOAK-BNB',
  //   earnedTokenAddress: '0xE035Dce0E345Cbd46F3c0dCBf602f96c5F741813',
  //   earnContractAddress: '0xE035Dce0E345Cbd46F3c0dCBf602f96c5F741813',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'sponge-soak-bnb',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'Other',
  //   assets: ['SOAK', 'BNB'],
  //   addLiquidityUrl:
  //     'https://swap.sponge.finance/#/add/BNB/0x849233ff1aea15d80ef658b2871664c9ca994063',
  //   buyTokenUrl:
  //     'https://swap.sponge.finance/#/swap?outputCurrency=0x849233ff1aea15d80ef658b2871664c9ca994063',
  //   createdAt: 1612829132,
  // },
  // {
  //   id: 'cake-sfp-bnb',
  //   logo: 'bnb-pairs/SFP-BNB.svg',
  //   name: 'SFP-BNB LP',
  //   token: 'SFP-BNB LP',
  //   tokenDescription: 'PancakeSwap',
  //   tokenAddress: '0xcBe2cF3bd012e9C1ADE2Ee4d41DB3DaC763e78F3',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooPancakeSFP-BNB',
  //   earnedTokenAddress: '0xCa38d57755f29c9ad17AbB1A6ae059DdAD913A0e',
  //   earnContractAddress: '0xCa38d57755f29c9ad17AbB1A6ae059DdAD913A0e',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'cake-sfp-bnb',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'PancakeSwap',
  //   assets: ['SFP', 'BNB'],
  //   removeLiquidityUrl:
  //     'https://v1exchange.pancakeswap.finance/#/remove/BNB/0xd41fdb03ba84762dd66a0af1a6c8540ff1ba5dfb',
  //   createdAt: 1612804452,
  // },
  // {
  //   id: 'nyacash-nyas-usdt',
  //   logo: 'single-assets/NYAS.png',
  //   name: 'NYAS-USDT NYAN-LP',
  //   token: 'NYAS-USDT NYAN-LP',
  //   tokenDescription: 'Nyanswop',
  //   tokenAddress: '0xd3179b44548f3309622B4F966503C1143e8840Ca',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooNyacashNYAS-BNB',
  //   earnedTokenAddress: '0x5ee82A50D1C7a1B599a5d4DA2f54AdB0d7a497a6',
  //   earnContractAddress: '0x5ee82A50D1C7a1B599a5d4DA2f54AdB0d7a497a6',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'nyanswop-nyas-usdt',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'Other',
  //   assets: ['NYAS', 'USDT'],
  //   createdAt: 1612710035,
  // },
  // {
  //   id: 'monster-mss-busd',
  //   logo: 'single-assets/MSS.png',
  //   name: 'MSS-BUSD LP',
  //   token: 'MSS-BUSD LP',
  //   tokenDescription: 'PancakeSwap (Monster)',
  //   tokenAddress: '0x73601076A92E0D1bD81737B38B150D6842aa1aA1',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooMonsterMSS-BUSD',
  //   earnedTokenAddress: '0x3B6C7bC3BE5C60c875cF415A97e6539cC28ECa32',
  //   earnContractAddress: '0x3B6C7bC3BE5C60c875cF415A97e6539cC28ECa32',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'monster-mss-busd',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'Other',
  //   assets: ['MSS', 'BUSD'],
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?inputCurrency=0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56&outputCurrency=0xacabd3f9b8f76ffd2724604185fa5afa5df25ac6',
  //   createdAt: 1612354467,
  // },
  // {
  //   id: 'jul-juld',
  //   logo: 'single-assets/JULD.png',
  //   name: 'JULD',
  //   token: 'JULD',
  //   tokenDescription: 'JulSwap',
  //   tokenAddress: '0x5A41F637C3f7553dBa6dDC2D3cA92641096577ea',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooJulJULD',
  //   earnedTokenAddress: '0x90d8AB2731f937f9ec98d0bbe6D4CBD4D95473db',
  //   earnContractAddress: '0x90d8AB2731f937f9ec98d0bbe6D4CBD4D95473db',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'tokens',
  //   oracleId: 'JULD',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'Other',
  //   assets: ['JulD'],
  //   buyTokenUrl:
  //     'https://julswap.com/#/swap?inputCurrency=BNB&outputCurrency=0x5a41f637c3f7553dba6ddc2d3ca92641096577ea',
  //   createdAt: 1612545332,
  // },
  // {
  //   id: 'cake-bdo-bnb',
  //   logo: 'single-assets/BDO.png',
  //   name: 'BDO-BNB LP',
  //   token: 'BDO-BNB LP',
  //   tokenDescription: 'PancakeSwap',
  //   tokenAddress: '0x74690f829fec83ea424ee1F1654041b2491A7bE9',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooPancakeBDO-BNB',
  //   earnedTokenAddress: '0x48Cb3540CB0BB4C6dDF638209e0631Ec85c1a477',
  //   earnContractAddress: '0x48Cb3540CB0BB4C6dDF638209e0631Ec85c1a477',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'cake-bdo-bnb',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'PancakeSwap',
  //   assets: ['BDO', 'BNB'],
  //   removeLiquidityUrl:
  //     'https://v1exchange.pancakeswap.finance/#/remove/BNB/0x190b589cf9fb8ddeabbfeae36a813ffb2a702454',
  //   createdAt: 1612278503,
  // },
  // {
  //   id: 'kebab-kebab-eol',
  //   logo: 'single-assets/KEBAB.png',
  //   name: 'KEBAB',
  //   token: 'KEBAB',
  //   tokenDescription: 'Kebab',
  //   tokenAddress: '0x7979F6C54ebA05E18Ded44C4F986F49a5De551c2',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooKebab',
  //   earnedTokenAddress: '0xE2231D4ca4921Cb35680bac714C9d40C8d217494',
  //   earnContractAddress: '0xE2231D4ca4921Cb35680bac714C9d40C8d217494',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'tokens',
  //   oracleId: 'KEBAB',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'Kebab',
  //   assets: ['KEBAB'],
  //   buyTokenUrl:
  //     'https://swap.kebabfinance.com/#/swap?outputCurrency=0x7979f6c54eba05e18ded44c4f986f49a5de551c2',
  //   createdAt: 1611760317,
  //   retiredReason: 'tvl',
  // },
  // {
  //   id: 'kebab-kebab-busd-eol',
  //   logo: 'busd-pairs/KEBAB-BUSD.png',
  //   name: 'KEBAB-BUSD LP',
  //   token: 'KEBAB-BUSD LP',
  //   tokenDescription: 'Kebab',
  //   tokenAddress: '0xD51bee2E0A3886289F6D229b6f30c0C2b34fC0Ec',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooKebabKEBAB-BUSD',
  //   earnedTokenAddress: '0xF0A7626eCcBee00aF144BB1f77CD187AF85BBf41',
  //   earnContractAddress: '0xF0A7626eCcBee00aF144BB1f77CD187AF85BBf41',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'kebab-kebab-busd',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'Kebab',
  //   assets: ['KEBAB', 'BUSD'],
  //   addLiquidityUrl:
  //     'https://swap.kebabfinance.com/#/add/0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56/0x7979f6c54eba05e18ded44c4f986f49a5de551c2',
  //   buyTokenUrl:
  //     'https://swap.kebabfinance.com/#/swap?inputCurrency=0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56&outputCurrency=0x7979f6c54eba05e18ded44c4f986f49a5de551c2',
  //   createdAt: 1611764741,
  //   retiredReason: 'tvl',
  // },
  // {
  //   id: 'kebab-kebab-bnb-eol',
  //   logo: 'bnb-pairs/KEBAB-BNB.png',
  //   name: 'KEBAB-BNB LP',
  //   token: 'KEBAB-BNB LP',
  //   tokenDescription: 'Kebab',
  //   tokenAddress: '0x25fd42D82d5c238ee7AA277261AA6CA5BDFE5CD4',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooKebabKEBAB-BNB',
  //   earnedTokenAddress: '0x2Eb92E2882030B8e153370d208462D4dDf7041d2',
  //   earnContractAddress: '0x2Eb92E2882030B8e153370d208462D4dDf7041d2',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'kebab-kebab-bnb',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'Kebab',
  //   assets: ['KEBAB', 'BNB'],
  //   addLiquidityUrl:
  //     'https://swap.kebabfinance.com/#/add/BNB/0x7979f6c54eba05e18ded44c4f986f49a5de551c2',
  //   buyTokenUrl:
  //     'https://swap.kebabfinance.com/#/swap?outputCurrency=0x7979f6c54eba05e18ded44c4f986f49a5de551c2',
  //   createdAt: 1611765218,
  //   retiredReason: 'tvl',
  // },
  // {
  //   id: 'cake-egld-bnb',
  //   logo: 'bnb-pairs/EGLD-BNB.svg',
  //   name: 'EGLD-BNB LP',
  //   token: 'EGLD-BNB LP',
  //   tokenDescription: 'PancakeSwap',
  //   tokenAddress: '0x3Ef4952C7a9AfbE374EA02d1Bf5eD5a0015b7716',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooPancakeEGLD-BNB',
  //   earnedTokenAddress: '0x3d0bd3C73eF66C8b487A49f50410173C44261285',
  //   earnContractAddress: '0x3d0bd3C73eF66C8b487A49f50410173C44261285',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'cake-egld-bnb',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'PancakeSwap',
  //   assets: ['EGLD', 'BNB'],
  //   removeLiquidityUrl:
  //     'https://v1exchange.pancakeswap.finance/#/remove/BNB/0xbf7c81fff98bbe61b40ed186e4afd6ddd01337fe',
  //   createdAt: 1611761568,
  // },
  // {
  //   id: 'cake-mgoogl-ust',
  //   logo: 'ust-pairs/MGOOGL-UST.svg',
  //   name: 'MGOOGL-UST LP',
  //   token: 'MGOOGL-UST LP',
  //   tokenDescription: 'PancakeSwap',
  //   tokenAddress: '0x852A68181f789AE6d1Da3dF101740a59A071004f',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooPancakeMGOOGL-UST',
  //   earnedTokenAddress: '0xfBa61aC24B46c5B7298De02FBa8F577C1c5005e9',
  //   earnContractAddress: '0xfBa61aC24B46c5B7298De02FBa8F577C1c5005e9',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'cake-mgoogl-ust',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'PancakeSwap',
  //   assets: ['MGOOGL', 'UST'],
  //   removeLiquidityUrl:
  //     'https://v1exchange.pancakeswap.finance/#/remove/0x23396cf899ca06c4472205fc903bdb4de249d6fc/0x62D71B23bF15218C7d2D7E48DBbD9e9c650B173f',
  //   createdAt: 1611697697,
  // },
  // {
  //   id: 'cake-mtsla-ust',
  //   logo: 'ust-pairs/MTSLA-UST.svg',
  //   name: 'MTSLA-UST LP',
  //   token: 'MTSLA-UST LP',
  //   tokenDescription: 'PancakeSwap',
  //   tokenAddress: '0xD5664D2d15cdffD597515f1c0D945c6c1D3Bf85B',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooPancakeMTSLA-UST',
  //   earnedTokenAddress: '0x42BeB2855aa620C5c9dD073FA888c43c8a65c34f',
  //   earnContractAddress: '0x42BeB2855aa620C5c9dD073FA888c43c8a65c34f',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'cake-mtsla-ust',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'PancakeSwap',
  //   assets: ['MTSLA', 'UST'],
  //   removeLiquidityUrl:
  //     'https://v1exchange.pancakeswap.finance/#/remove/0x23396cf899ca06c4472205fc903bdb4de249d6fc/0xF215A127A196e3988C09d052e16BcFD365Cd7AA3',
  //   createdAt: 1611699959,
  // },
  // {
  //   id: 'cake-wsote-bnb',
  //   logo: 'bnb-pairs/WSOTE-BNB.svg',
  //   name: 'WSOTE-BNB LP',
  //   token: 'WSOTE-BNB LP',
  //   tokenDescription: 'PancakeSwap',
  //   tokenAddress: '0xFfb9E2d5ce4378F1a89b29bf53F80804CC078102',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooPancakeWSOTE-BNB',
  //   earnedTokenAddress: '0x5B6c3432B724Ce198B93769970f1dF36Ac5552F8',
  //   earnContractAddress: '0x5B6c3432B724Ce198B93769970f1dF36Ac5552F8',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'cake-wsote-bnb',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'PancakeSwap',
  //   assets: ['WSOTE', 'BNB'],
  //   removeLiquidityUrl:
  //     'https://v1exchange.pancakeswap.finance/#/remove/BNB/0x541e619858737031a1244a5d0cd47e5ef480342c',
  //   createdAt: 1611327477,
  // },
  // {
  //   id: 'cake-front-bnb',
  //   logo: 'bnb-pairs/FRONT-BNB.svg',
  //   name: 'FRONT-BNB LP',
  //   token: 'FRONT-BNB LP',
  //   tokenDescription: 'PancakeSwap',
  //   tokenAddress: '0x36b7D2e5C7877392Fb17f9219efaD56F3D794700',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooPancakeFRONT-BNB',
  //   earnedTokenAddress: '0x1Ae7E76e2Eb74070774bbd9EAC75585452f24C23',
  //   earnContractAddress: '0x1Ae7E76e2Eb74070774bbd9EAC75585452f24C23',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'cake-front-bnb',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'PancakeSwap',
  //   assets: ['FRONT', 'BNB'],
  //   removeLiquidityUrl:
  //     'https://v1exchange.pancakeswap.finance/#/remove/BNB/0x928e55dab735aa8260af3cedada18b5f70c72f1b',
  //   createdAt: 1611326706,
  // },
  // {
  //   id: 'cake-btcst-bnb',
  //   logo: 'bnb-pairs/BTCST-BNB.svg',
  //   name: 'BTCST-BNB LP',
  //   token: 'BTCST-BNB LP',
  //   tokenDescription: 'PancakeSwap',
  //   tokenAddress: '0x91589786D36fEe5B27A5539CfE638a5fc9834665',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooPancakeBTCST-BNB',
  //   earnedTokenAddress: '0xfC083Cb104b48924AB44C69537b1450B2e78eBD6',
  //   earnContractAddress: '0xfC083Cb104b48924AB44C69537b1450B2e78eBD6',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'cake-btcst-bnb',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'PancakeSwap',
  //   assets: ['BTCST', 'BNB'],
  //   removeLiquidityUrl:
  //     'https://v1exchange.pancakeswap.finance/#/remove/BNB/0x78650b139471520656b9e7aa7a5e9276814a38e9',
  //   createdAt: 1611325875,
  // },
  // {
  //   id: 'thugs-drugs-btri',
  //   logo: 'single-assets/DRUGS.png',
  //   name: 'DRUGS BTRI',
  //   token: 'DRUGS',
  //   tokenDescription: 'Traphouse',
  //   tokenAddress: '0x339550404Ca4d831D12B1b2e4768869997390010',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooDrugsBTRI',
  //   earnedTokenAddress: '0xFAE4a35305a1dcED8303029ac05e51386f667ED8',
  //   earnContractAddress: '0xFAE4a35305a1dcED8303029ac05e51386f667ED8',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'tokens',
  //   oracleId: 'DRUGS',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'Other',
  //   assets: ['DRUGS'],
  //   createdAt: 1611000233,
  // },
  // {
  //   id: 'cake-ada-bnb',
  //   logo: 'bnb-pairs/ADA-BNB.svg',
  //   name: 'ADA-BNB LP',
  //   token: 'ADA-BNB LP',
  //   tokenDescription: 'PancakeSwap',
  //   tokenAddress: '0xBA51D1AB95756ca4eaB8737eCD450cd8F05384cF',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooPancakeADA-BNB',
  //   earnedTokenAddress: '0x1d23ecC0645B07791b7D99349e253ECEbe43f614',
  //   earnContractAddress: '0x1d23ecC0645B07791b7D99349e253ECEbe43f614',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'cake-ada-bnb',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'PancakeSwap',
  //   assets: ['ADA', 'BNB'],
  //   removeLiquidityUrl:
  //     'https://v1exchange.pancakeswap.finance/#/remove/BNB/0x3ee2200efb3400fabb9aacf31297cbdd1d435d47',
  //   createdAt: 1610894177,
  // },
  // {
  //   id: 'bakery-bake-busd-eol',
  //   logo: 'busd-pairs/BAKE-BUSD.png',
  //   name: 'BAKE-BUSD BLP',
  //   token: 'BAKE-BUSD BLP',
  //   tokenDescription: 'Bakery',
  //   tokenAddress: '0x6E218EA042BeF40a8baF706b00d0f0A7b4fCE50a',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooBakeryBAKE-BUSD',
  //   earnedTokenAddress: '0x23293B8A7c65aF396Bda8Ff75E02c62f6db8f202',
  //   earnContractAddress: '0x23293B8A7c65aF396Bda8Ff75E02c62f6db8f202',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'bakery-bake-busd',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'Other',
  //   retireReason: 'rewards',
  //   assets: ['BAKE', 'BUSD'],
  //   addLiquidityUrl:
  //     'https://www.bakeryswap.org/#/add/0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56/0xE02dF9e3e622DeBdD69fb838bB799E3F168902c5',
  //   buyTokenUrl:
  //     'https://www.bakeryswap.org/#/swap?inputCurrency=0xE02dF9e3e622DeBdD69fb838bB799E3F168902c5&outputCurrency=0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
  //   createdAt: 1607060614,
  // },
  // {
  //   id: 'jetfuel-vapor-bnb-eol',
  //   logo: 'bnb-pairs/VAPOR-BNB.png',
  //   name: 'VAPOR-BNB LP',
  //   token: 'VAPOR-BNB LP',
  //   tokenDescription: 'PancakeSwap',
  //   tokenAddress: '0x01ffd4B8dE49442ab3EF755cC6bb57deEB6B871a',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooJfVAPOR-BNB',
  //   earnedTokenAddress: '0x00EcbB8f9853dbC621b39ab205a148A26eAC3B11',
  //   earnContractAddress: '0x00EcbB8f9853dbC621b39ab205a148A26eAC3B11',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'jetfuel-vapor-bnb',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'Other',
  //   assets: ['VAPOR', 'BNB'],
  //   removeLiquidityUrl:
  //     'https://v1exchange.pancakeswap.finance/#/remove/BNB/0x86ac8a05cc4eed2bea32d271de50e2a3d129b6b6',
  //   createdAt: 1610780683,
  // },
  // {
  //   id: 'cake-bdo-bnb-sbdo',
  //   logo: 'single-assets/SBDO.png',
  //   name: 'BDO-BNB LP (SBDO)',
  //   token: 'BDO-BNB LP (SBDO)',
  //   tokenDescription: 'PancakeSwap (bDollar)',
  //   tokenAddress: '0x74690f829fec83ea424ee1F1654041b2491A7bE9',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooBdollarBDO-BNB-SBDO',
  //   earnedTokenAddress: '0x9355af5ABD030E8BC2Dc63a38F1C956F1e150308',
  //   earnContractAddress: '0x9355af5ABD030E8BC2Dc63a38F1C956F1e150308',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'cake-bdo-bnb-sbdo',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'Other',
  //   assets: ['BDO', 'BNB'],
  //   addLiquidityUrl:
  //     'https://pancakeswap.finance/add/BNB/0x190b589cf9fb8ddeabbfeae36a813ffb2a702454',
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?outputCurrency=0x190b589cf9fb8ddeabbfeae36a813ffb2a702454',
  //   createdAt: 1610714437,
  // },
  // {
  //   id: 'cake-bdo-bnb-old',
  //   logo: 'single-assets/BDO.png',
  //   name: 'BDO-BNB LP',
  //   token: 'BDO-BNB LP',
  //   tokenDescription: 'PancakeSwap (bDollar)',
  //   tokenAddress: '0x74690f829fec83ea424ee1F1654041b2491A7bE9',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooBdollarBDO-BNB',
  //   earnedTokenAddress: '0x2B19461954cCBa9e1b60bD0bC6453FeaC8aa1a17',
  //   earnContractAddress: '0x2B19461954cCBa9e1b60bD0bC6453FeaC8aa1a17',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'cake-bdo-bnb',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'Other',
  //   assets: ['BDO', 'BNB'],
  //   removeLiquidityUrl:
  //     'https://v1exchange.pancakeswap.finance/#/remove/BNB/0x190b589cf9fb8ddeabbfeae36a813ffb2a702454',
  //   createdAt: 1610247300,
  // },
  // {
  //   id: 'cake-sbdo-busd',
  //   logo: 'single-assets/SBDO.png',
  //   name: 'SBDO-BUSD LP',
  //   token: 'SBDO-BUSD LP',
  //   tokenDescription: 'PancakeSwap (bDollar)',
  //   tokenAddress: '0xA0718093baa3E7AAE054eED71F303A4ebc1C076f',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooBdollarSBDO-BUSD',
  //   earnedTokenAddress: '0x9327029cf20B78e445397dAf0c4e9c19a931D62e',
  //   earnContractAddress: '0x9327029cf20B78e445397dAf0c4e9c19a931D62e',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'cake-sbdo-busd',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'Other',
  //   assets: ['SBDO', 'BUSD'],
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?inputCurrency=0xe9e7cea3dedca5984780bafc599bd69add087d56&outputCurrency=0x0d9319565be7f53cefe84ad201be3f40feae2740',
  //   createdAt: 1610326965,
  // },
  // {
  //   id: 'cake-bdo-busd',
  //   logo: 'single-assets/SBDO.png',
  //   name: 'BDO-BUSD LP',
  //   token: 'BDO-BUSD LP',
  //   tokenDescription: 'PancakeSwap (bDollar)',
  //   tokenAddress: '0xc5b0d73A7c0E4eaF66baBf7eE16A2096447f7aD6',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooBdollarBDO-BUSD',
  //   earnedTokenAddress: '0x0fA31301EaA9633b3e4F7dF22F7543115759cf5a',
  //   earnContractAddress: '0x0fA31301EaA9633b3e4F7dF22F7543115759cf5a',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'cake-bdo-busd',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'Other',
  //   assets: ['BDO', 'BUSD'],
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?inputCurrency=0x190b589cf9Fb8DDEabBFeae36a813FFb2A702454&outputCurrency=0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
  //   createdAt: 1610325933,
  // },
  // {
  //   id: 'venus-beth-eol',
  //   logo: 'single-assets/BETH.png',
  //   name: 'BETH',
  //   token: 'BETH',
  //   tokenDescription: 'Venus',
  //   tokenAddress: '0x250632378E573c6Be1AC2f97Fcdf00515d0Aa91B',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooVenusBETH',
  //   earnedTokenAddress: '0xB38D32336538559Ce6680952B0Be4917ed015c39',
  //   earnContractAddress: '0xB38D32336538559Ce6680952B0Be4917ed015c39',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'tokens',
  //   oracleId: 'BETH',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'Venus',
  //   assets: ['BETH'],
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?outputCurrency=0x250632378e573c6be1ac2f97fcdf00515d0aa91b',
  //   createdAt: 1610341717,
  // },
  // {
  //   id: 'venus-dai-eol',
  //   logo: 'single-assets/DAI.svg',
  //   name: 'DAI',
  //   token: 'DAI',
  //   tokenDescription: 'Venus',
  //   tokenAddress: '0x1AF3F329e8BE154074D8769D1FFa4eE058B1DBc3',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooVenusDAI',
  //   earnedTokenAddress: '0x813aDbc7b9a9822FA37510F52aD14bDBc45aA306',
  //   earnContractAddress: '0x813aDbc7b9a9822FA37510F52aD14bDBc45aA306',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'tokens',
  //   oracleId: 'DAI',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'Venus',
  //   assets: ['DAI'],
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?outputCurrency=0x1af3f329e8be154074d8769d1ffa4ee058b1dbc3',
  //   createdAt: 1610286156,
  // },
  // {
  //   id: 'venus-fil-eol',
  //   logo: 'single-assets/FIL.svg',
  //   name: 'FIL',
  //   token: 'FIL',
  //   tokenDescription: 'Venus',
  //   tokenAddress: '0x0D8Ce2A99Bb6e3B7Db580eD848240e4a0F9aE153',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooVenusFIL',
  //   earnedTokenAddress: '0x8B80E58328aEF3796D70D29F65d516d29ACD944d',
  //   earnContractAddress: '0x8B80E58328aEF3796D70D29F65d516d29ACD944d',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'tokens',
  //   oracleId: 'FIL',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'Venus',
  //   assets: ['FIL'],
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?outputCurrency=0x0d8ce2a99bb6e3b7db580ed848240e4a0f9ae153',
  //   createdAt: 1610288694,
  // },
  // {
  //   id: 'cake-balbt-bnb',
  //   logo: 'bnb-pairs/BALBT-BNB.svg',
  //   name: 'bALBT-BNB LP',
  //   token: 'bALBT-BNB LP',
  //   tokenDescription: 'PancakeSwap',
  //   tokenAddress: '0xbe14f3a89A4F7f279Af9d99554cf12E8C29dB921',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooPancakebALBT-BNB',
  //   earnedTokenAddress: '0xc2c792807A32fB9F9f985749Ef90673D8e1Bad44',
  //   earnContractAddress: '0xc2c792807A32fB9F9f985749Ef90673D8e1Bad44',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'cake-balbt-bnb',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'PancakeSwap',
  //   assets: ['bALBT', 'BNB'],
  //   removeLiquidityUrl:
  //     'https://v1exchange.pancakeswap.finance/#/remove/BNB/0x72faa679e1008ad8382959ff48e392042a8b06f7',
  //   createdAt: 1610267196,
  // },
  // {
  //   id: 'bakery-btc-bnb-eol',
  //   logo: 'bnb-pairs/BTC-BNB.png',
  //   name: 'BTC-BNB BLP',
  //   token: 'BTC-BNB BLP',
  //   tokenDescription: 'Bakery',
  //   tokenAddress: '0x58521373474810915b02FE968D1BCBe35Fc61E09',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooBakeryBTC-BNB',
  //   earnedTokenAddress: '0xeD4ea30c755676C07d3e0e0f74Ff84C0193B4551',
  //   earnContractAddress: '0xeD4ea30c755676C07d3e0e0f74Ff84C0193B4551',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'bakery-btc-bnb',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   retireReason: 'rewards',
  //   platform: 'Other',
  //   assets: ['BTCB', 'BNB'],
  //   addLiquidityUrl:
  //     'https://www.bakeryswap.org/#/add/ETH/0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c',
  //   buyTokenUrl:
  //     'https://www.bakeryswap.org/#/swap?outputCurrency=0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c',
  //   createdAt: 1610102461,
  // },
  // {
  //   id: 'street-bhc-bnb',
  //   logo: 'bnb-pairs/BHC-BNB.png',
  //   name: 'BHC-BNB TLP',
  //   token: 'BHC-BNB TLP',
  //   tokenDescription: 'Traphouse',
  //   tokenAddress: '0xd46E7f33f8788f87D6017074dC4e4d781D3df91E',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooStreetBHC-BNB',
  //   earnedTokenAddress: '0x7EED74dacB305C03A8f624282d6AEF15f1CD3777',
  //   earnContractAddress: '0x7EED74dacB305C03A8f624282d6AEF15f1CD3777',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'street-bhc-bnb',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'Other',
  //   assets: ['BHC', 'BNB'],
  //   createdAt: 1609772742,
  // },
  // {
  //   id: 'cake-reef-bnb',
  //   logo: 'bnb-pairs/REEF-BNB.svg',
  //   name: 'REEF-BNB LP',
  //   token: 'REEF-BNB LP',
  //   tokenDescription: 'PancakeSwap',
  //   tokenAddress: '0x58B58cab6C5cF158f63A2390b817710826d116D0',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooPancakeREEF-BNB',
  //   earnedTokenAddress: '0x6E0F2e199FC10B3F354C6825fCbC24a5de96B4bF',
  //   earnContractAddress: '0x6E0F2e199FC10B3F354C6825fCbC24a5de96B4bF',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'cake-reef-bnb',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'PancakeSwap',
  //   assets: ['REEF', 'BNB'],
  //   removeLiquidityUrl:
  //     'https://v1exchange.pancakeswap.finance/#/remove/BNB/0xf21768ccbc73ea5b6fd3c687208a7c2def2d966e',
  //   createdAt: 1609774230,
  // },
  // {
  //   id: 'cake-nar-cake',
  //   logo: 'cake-pairs/NAR-CAKE.svg',
  //   name: 'NAR-CAKE LP',
  //   token: 'NAR-CAKE LP',
  //   tokenDescription: 'PancakeSwap',
  //   tokenAddress: '0x745C4fD226E169d6da959283275A8E0EcDd7F312',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooPancakeNAR-CAKE',
  //   earnedTokenAddress: '0xFaa95d27A91eb2305bD1B4b12a9cE564874eE349',
  //   earnContractAddress: '0xFaa95d27A91eb2305bD1B4b12a9cE564874eE349',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'cake-nar-cake',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'PancakeSwap',
  //   assets: ['NAR', 'CAKE'],
  //   removeLiquidityUrl:
  //     'https://v1exchange.pancakeswap.finance/#/remove/0xa1303e6199b319a891b79685f0537d289af1fc83/0x0e09fabb73bd3ade0a17ecc321fd13a19e81ce82',
  //   createdAt: 1609783441,
  // },
  // {
  //   id: 'cake-nya-cake',
  //   logo: 'cake-pairs/NYA-CAKE.svg',
  //   name: 'NYA-CAKE LP',
  //   token: 'NYA-CAKE LP',
  //   tokenDescription: 'PancakeSwap',
  //   tokenAddress: '0x2730bf486d658838464A4ef077880998D944252d',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooPancakeNYA-CAKE',
  //   earnedTokenAddress: '0x5f640a217026FeFD0C4F1E581b44794c3d5F4638',
  //   earnContractAddress: '0x5f640a217026FeFD0C4F1E581b44794c3d5F4638',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'cake-nya-cake',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'PancakeSwap',
  //   assets: ['NYA', 'CAKE'],
  //   createdAt: 1609783522,
  // },
  // {
  //   id: 'thugs-drugs-drugs',
  //   logo: 'single-assets/DRUGS.png',
  //   name: 'DRUGS v2',
  //   token: 'DRUGS v2',
  //   tokenDescription: 'Traphouse',
  //   tokenAddress: '0x339550404Ca4d831D12B1b2e4768869997390010',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooDrugsGunsV2',
  //   earnedTokenAddress: '0x8AE31751A226B0C5357a377E53B6DB12bDF5e64d',
  //   earnContractAddress: '0x8AE31751A226B0C5357a377E53B6DB12bDF5e64d',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'tokens',
  //   oracleId: 'DRUGS',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'Other',
  //   assets: ['DRUGS'],
  //   createdAt: 1606183056,
  // },
  // {
  //   id: 'thugs-drugs-bhc',
  //   logo: 'single-assets/DRUGS.png',
  //   name: 'DRUGS BHC',
  //   token: 'DRUGS',
  //   tokenDescription: 'Traphouse',
  //   tokenAddress: '0x339550404Ca4d831D12B1b2e4768869997390010',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooDrugsBHC',
  //   earnedTokenAddress: '0xE7408598DB157Cd4A24Bc26B579910AF36a72fad',
  //   earnContractAddress: '0xE7408598DB157Cd4A24Bc26B579910AF36a72fad',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'tokens',
  //   oracleId: 'DRUGS',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'Other',
  //   assets: ['DRUGS'],
  //   createdAt: 1609374384,
  // },
  // {
  //   id: 'thugs-drugs-jgn',
  //   logo: 'single-assets/DRUGS.png',
  //   name: 'DRUGS JGN',
  //   token: 'DRUGS',
  //   tokenDescription: 'Traphouse',
  //   tokenAddress: '0x339550404Ca4d831D12B1b2e4768869997390010',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooDrugsJGN',
  //   earnedTokenAddress: '0x40e39743dBcb30c7Fc4152F57F29Cd22D9fc7932',
  //   earnContractAddress: '0x40e39743dBcb30c7Fc4152F57F29Cd22D9fc7932',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'tokens',
  //   oracleId: 'DRUGS',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'Other',
  //   assets: ['DRUGS'],
  //   createdAt: 1609373877,
  // },
  // {
  //   id: 'jetfuel-fuel-bnb-ss-eol',
  //   logo: 'bnb-pairs/FUEL-BNB.png',
  //   name: 'FUEL-BNB TLP',
  //   token: 'FUEL-BNB TLP',
  //   tokenDescription: 'Traphouse',
  //   tokenAddress: '0xd26092c6ce6FB359302A9453dc0c7f312794Ef7E',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooJfFUEL-BNB-SS',
  //   earnedTokenAddress: '0xda643fBC5faA4FB3a95c62dBF729fE2B0Ff4F577',
  //   earnContractAddress: '0xda643fBC5faA4FB3a95c62dBF729fE2B0Ff4F577',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'jetfuel-fuel-bnb-ss',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'Other',
  //   assets: ['FUEL', 'BNB'],
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?outputCurrency=0x2090c8295769791ab7a3cf1cc6e0aa19f35e441a',
  //   createdAt: 1609126521,
  // },
  // {
  //   id: 'jetfuel-fuel-bnb-pcs-eol',
  //   logo: 'bnb-pairs/FUEL-BNB.png',
  //   name: 'FUEL-BNB LP',
  //   token: 'FUEL-BNB LP',
  //   tokenDescription: 'PancakeSwap',
  //   tokenAddress: '0x3763A3263CEaca5e7Cc1Bc22A43920bAd9f743Cd',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooJfFUEL-BNB-PCS',
  //   earnedTokenAddress: '0x9d3Aa54172D49659Ee9948d1b7D602989d39Ffa6',
  //   earnContractAddress: '0x9d3Aa54172D49659Ee9948d1b7D602989d39Ffa6',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'jetfuel-fuel-bnb-pcs',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'Other',
  //   assets: ['FUEL', 'BNB'],
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?outputCurrency=0x2090c8295769791ab7A3CF1CC6e0AA19F35e441A',
  //   createdAt: 1609125132,
  // },
  // {
  //   id: 'jetfuel-bhc-bnb-eol',
  //   logo: 'bnb-pairs/BHC-BNB.png',
  //   name: 'BHC-BNB LP',
  //   token: 'BHC-BNB LP',
  //   tokenDescription: 'Traphouse',
  //   tokenAddress: '0xd46E7f33f8788f87D6017074dC4e4d781D3df91E',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooJfBHC-BNB',
  //   earnedTokenAddress: '0xfb62a8d9d162632C5AaD94f6bf353a19AeCd2855',
  //   earnContractAddress: '0xfb62a8d9d162632C5AaD94f6bf353a19AeCd2855',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'jetfuel-bhc-bnb',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'Other',
  //   assets: ['BHC', 'BNB'],
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?outputCurrency=0x6fd7c98458a943f469e1cf4ea85b173f5cd342f4',
  //   createdAt: 1609126605,
  // },
  // {
  //   id: 'jetfuel-eth-bnb-eol',
  //   logo: 'bnb-pairs/ETH-BNB.svg',
  //   name: 'ETH-BNB LP',
  //   token: 'ETH-BNB LP',
  //   tokenDescription: 'PancakeSwap (Fuel)',
  //   tokenAddress: '0x70D8929d04b60Af4fb9B58713eBcf18765aDE422',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooJfETH-BNB',
  //   earnedTokenAddress: '0x25Fb99cf1DCe844cD823bD1e8FB0c1DCc85dAD0E',
  //   earnContractAddress: '0x25Fb99cf1DCe844cD823bD1e8FB0c1DCc85dAD0E',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'jetfuel-eth-bnb',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'Other',
  //   assets: ['ETH', 'BNB'],
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?outputCurrency=0x2170ed0880ac9a755fd29b2688956bd959f933f8',
  //   createdAt: 1609126638,
  // },
  // {
  //   id: 'jetfuel-cake-bnb-eol',
  //   logo: 'bnb-pairs/CAKE-BNB.svg',
  //   name: 'CAKE-BNB LP',
  //   token: 'CAKE-BNB LP',
  //   tokenDescription: 'PancakeSwap (Fuel)',
  //   tokenAddress: '0xA527a61703D82139F8a06Bc30097cC9CAA2df5A6',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooJfCAKE-BNB',
  //   earnedTokenAddress: '0xC0766D0F482c7174274bE653dcB0B80c0436dFCc',
  //   earnContractAddress: '0xC0766D0F482c7174274bE653dcB0B80c0436dFCc',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'jetfuel-cake-bnb',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'Other',
  //   assets: ['CAKE', 'BNB'],
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?outputCurrency=0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82',
  //   createdAt: 1609126665,
  // },
  // {
  //   id: 'jetfuel-uni-bnb-eol',
  //   logo: 'bnb-pairs/UNI-BNB.svg',
  //   name: 'UNI-BNB LP',
  //   token: 'UNI-BNB LP',
  //   tokenDescription: 'PancakeSwap (Fuel)',
  //   tokenAddress: '0x4269e7F43A63CEA1aD7707Be565a94a9189967E9',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooJfUNI-BNB',
  //   earnedTokenAddress: '0xD96c8527d20acfa7D5D2054Bfb375fd0cB428322',
  //   earnContractAddress: '0xD96c8527d20acfa7D5D2054Bfb375fd0cB428322',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'jetfuel-uni-bnb',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'Other',
  //   assets: ['UNI', 'BNB'],
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?outputCurrency=0xbf5140a22578168fd562dccf235e5d43a02ce9b1',
  //   createdAt: 1609126695,
  // },
  // {
  //   id: 'jetfuel-busd-bnb-eol',
  //   logo: 'bnb-pairs/BUSD-BNB.svg',
  //   name: 'BUSD-BNB LP',
  //   token: 'BUSD-BNB LP',
  //   tokenDescription: 'PancakeSwap (Fuel)',
  //   tokenAddress: '0x1B96B92314C44b159149f7E0303511fB2Fc4774f',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooJfBUSD-BNB',
  //   earnedTokenAddress: '0x8af2D7289A093CF1019D9e20554F6c7aD5791A94',
  //   earnContractAddress: '0x8af2D7289A093CF1019D9e20554F6c7aD5791A94',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'jetfuel-busd-bnb',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'Other',
  //   assets: ['BUSD', 'BNB'],
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?outputCurrency=0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
  //   createdAt: 1609126755,
  // },
  // {
  //   id: 'cake-smart-eol',
  //   logo: 'single-assets/CAKE.svg',
  //   name: 'CAKE SMART',
  //   token: 'CAKE',
  //   tokenDescription: 'PancakeSwap',
  //   tokenAddress: '0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: 'https://docs.beefy.finance/beefyfinance/products/pancake-swap#cake-smart',
  //   earnedToken: 'mooCakeSmart',
  //   earnedTokenAddress: '0xB0BDBB9E507dBF55f4aC1ef6ADa3E216202e06FD',
  //   earnContractAddress: '0xB0BDBB9E507dBF55f4aC1ef6ADa3E216202e06FD',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'tokens',
  //   oracleId: 'Cake',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'PancakeSwap',
  //   assets: ['CAKE'],
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?outputCurrency=0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82',
  //   createdAt: 1605728520,
  // },
  // {
  //   id: 'venus-dot-eol',
  //   logo: 'single-assets/DOT.png',
  //   name: 'DOT',
  //   token: 'DOT',
  //   tokenDescription: 'Venus',
  //   tokenAddress: '0x7083609fCE4d1d8Dc0C979AAb8c869Ea2C873402',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooVenusDOT',
  //   earnedTokenAddress: '0x2211191ff72505ED54Aa6aD3F6Fcc72c87CAb338',
  //   earnContractAddress: '0x2211191ff72505ED54Aa6aD3F6Fcc72c87CAb338',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'tokens',
  //   oracleId: 'DOT',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'Venus',
  //   assets: ['DOT'],
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?outputCurrency=0x7083609fCE4d1d8Dc0C979AAb8c869Ea2C873402',
  //   createdAt: 1609009921,
  // },
  // {
  //   id: 'venus-link-eol',
  //   logo: 'single-assets/LINK.png',
  //   name: 'LINK',
  //   token: 'LINK',
  //   tokenDescription: 'Venus',
  //   tokenAddress: '0xF8A0BF9cF54Bb92F17374d9e9A321E6a111a51bD',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooVenusLINK',
  //   earnedTokenAddress: '0x8da7167860EDfc2bFfd790f217AB5e398803Bbc8',
  //   earnContractAddress: '0x8da7167860EDfc2bFfd790f217AB5e398803Bbc8',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'tokens',
  //   oracleId: 'LINK',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'Venus',
  //   assets: ['LINK'],
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?outputCurrency=0xF8A0BF9cF54Bb92F17374d9e9A321E6a111a51bD',
  //   createdAt: 1609009968,
  // },
  // {
  //   id: 'cake-atom-bnb',
  //   logo: 'bnb-pairs/ATOM-BNB.svg',
  //   name: 'ATOM-BNB LP',
  //   token: 'ATOM-BNB LP',
  //   tokenDescription: 'PancakeSwap',
  //   tokenAddress: '0x2333c77FC0B2875c11409cdCD3C75D42D402E834',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooPancakeATOM-BNB',
  //   earnedTokenAddress: '0x4884cD2C9F11Dc9e8f90d177576c46fdbd1b7641',
  //   earnContractAddress: '0x4884cD2C9F11Dc9e8f90d177576c46fdbd1b7641',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'cake-atom-bnb',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'PancakeSwap',
  //   assets: ['ATOM', 'BNB'],
  //   removeLiquidityUrl:
  //     'https://v1exchange.pancakeswap.finance/#/remove/BNB/0x0eb3a705fc54725037cc9e008bdede697f62f335',
  //   createdAt: 1608605486,
  // },
  // {
  //   id: 'cake-vai-busd',
  //   logo: 'busd-pairs/VAI-BUSD.svg',
  //   name: 'VAI-BUSD LP',
  //   token: 'VAI-BUSD LP',
  //   tokenDescription: 'PancakeSwap',
  //   tokenAddress: '0xfF17ff314925Dff772b71AbdFF2782bC913B3575',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooPancakeVAI-BUSD',
  //   earnedTokenAddress: '0xa40488536B1BAeECA9D2277Fde28394D3c04aD73',
  //   earnContractAddress: '0xa40488536B1BAeECA9D2277Fde28394D3c04aD73',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'cake-vai-busd',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'PancakeSwap',
  //   assets: ['VAI', 'BUSD'],
  //   removeLiquidityUrl:
  //     'https://v1exchange.pancakeswap.finance/#/remove/0x4bd17003473389a42daf6a0a729f6fdb328bbbd7/0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
  //   createdAt: 1608596246,
  // },
  // {
  //   id: 'cake-broobee-cake',
  //   logo: 'cake-pairs/BROOBEE-CAKE.svg',
  //   name: 'BROOBEE-CAKE LP',
  //   token: 'BROOBEE-CAKE LP',
  //   tokenDescription: 'PancakeSwap',
  //   tokenAddress: '0x970858016C963b780E06f7DCfdEf8e809919BcE8',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooPancakeBROOBEE-CAKE',
  //   earnedTokenAddress: '0x4CcF7dC91389396801ec1f4A5E6cC675549f39E6',
  //   earnContractAddress: '0x4CcF7dC91389396801ec1f4A5E6cC675549f39E6',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'cake-broobee-cake',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'PancakeSwap',
  //   assets: ['bROOBEE', 'CAKE'],
  //   removeLiquidityUrl:
  //     'https://v1exchange.pancakeswap.finance/#/remove/0xe64f5cb844946c1f102bd25bbd87a5ab4ae89fbe/0x0e09fabb73bd3ade0a17ecc321fd13a19e81ce82',
  //   createdAt: 1608596300,
  // },
  // {
  //   id: 'venus-bnb',
  //   logo: 'single-assets/BNB.png',
  //   name: 'BNB',
  //   token: 'BNB',
  //   tokenDescription: 'Venus',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooVenusBNB',
  //   earnedTokenAddress: '0x6BE4741AB0aD233e4315a10bc783a7B923386b71',
  //   earnContractAddress: '0x6BE4741AB0aD233e4315a10bc783a7B923386b71',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'tokens',
  //   oracleId: 'WBNB',
  //   oraclePrice: 0,
  //   depositsPaused: false,
  //   status: 'active',
  //   platform: 'Venus',
  //   assets: ['BNB'],
  //   risks: [
  //     'COMPLEXITY_LOW',
  //     'BATTLE_TESTED',
  //     'IL_NONE',
  //     'MCAP_LARGE',
  //     'AUDIT',
  //     'CONTRACTS_VERIFIED',
  //   ],
  //   stratType: 'Lending',
  //   withdrawalFee: '0.05%',
  //   createdAt: 1608060201,
  // },
  // {
  //   id: 'venus-wbnb',
  //   logo: 'single-assets/WBNB.svg',
  //   name: 'WBNB',
  //   token: 'WBNB',
  //   tokenDescription: 'Venus',
  //   tokenAddress: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooVenusBNB',
  //   earnedTokenAddress: '0x6BE4741AB0aD233e4315a10bc783a7B923386b71',
  //   earnContractAddress: '0x6BE4741AB0aD233e4315a10bc783a7B923386b71',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'tokens',
  //   oracleId: 'WBNB',
  //   oraclePrice: 0,
  //   depositsPaused: false,
  //   status: 'active',
  //   platform: 'Venus',
  //   assets: ['BNB'],
  //   risks: [
  //     'COMPLEXITY_LOW',
  //     'BATTLE_TESTED',
  //     'IL_NONE',
  //     'MCAP_LARGE',
  //     'AUDIT',
  //     'CONTRACTS_VERIFIED',
  //   ],
  //   withdrawalFee: '0.05%',
  //   stratType: 'Lending',
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?outputCurrency=0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
  //   createdAt: 1608060201,
  // },
  // {
  //   id: 'venus-sxp-eol',
  //   logo: 'single-assets/SXP.svg',
  //   name: 'SXP',
  //   token: 'SXP',
  //   tokenDescription: 'Venus',
  //   tokenAddress: '0x47BEAd2563dCBf3bF2c9407fEa4dC236fAbA485A',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooVenusSXP',
  //   earnedTokenAddress: '0x9aaD247bAc2AFc3027256aD0Cc09A27551069bd6',
  //   earnContractAddress: '0x9aaD247bAc2AFc3027256aD0Cc09A27551069bd6',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'tokens',
  //   oracleId: 'SXP',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'Venus',
  //   assets: ['SXP'],
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?outputCurrency=0x47BEAd2563dCBf3bF2c9407fEa4dC236fAbA485A',
  //   createdAt: 1608172975,
  // },
  // {
  //   id: 'venus-usdc-eol',
  //   logo: 'single-assets/USDC.svg',
  //   name: 'USDC',
  //   token: 'USDC',
  //   tokenDescription: 'Venus',
  //   tokenAddress: '0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooVenusUSDC',
  //   earnedTokenAddress: '0x07D478b679C6c7F4222388856f53F8dca7E7b378',
  //   earnContractAddress: '0x07D478b679C6c7F4222388856f53F8dca7E7b378',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'tokens',
  //   oracleId: 'USDC',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'Venus',
  //   assets: ['USDC'],
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?outputCurrency=0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d',
  //   createdAt: 1608173020,
  // },
  // {
  //   id: 'venus-usdt-eol',
  //   logo: 'single-assets/USDT.svg',
  //   name: 'USDT',
  //   token: 'USDT',
  //   tokenDescription: 'Venus',
  //   tokenAddress: '0x55d398326f99059fF775485246999027B3197955',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooVenusUSDT',
  //   earnedTokenAddress: '0x1997D7cF0A63A374A853ca9d5b1dAcCFeCa5cEb8',
  //   earnContractAddress: '0x1997D7cF0A63A374A853ca9d5b1dAcCFeCa5cEb8',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'tokens',
  //   oracleId: 'USDT',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'Venus',
  //   assets: ['USDT'],
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?outputCurrency=0x55d398326f99059fF775485246999027B3197955',
  //   createdAt: 1608173053,
  // },
  // {
  //   id: 'venus-busd-eol',
  //   logo: 'single-assets/BUSD.svg',
  //   name: 'BUSD',
  //   token: 'BUSD',
  //   tokenDescription: 'Venus',
  //   tokenAddress: '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooVenusBUSD',
  //   earnedTokenAddress: '0x2d3d152b3b94c0E0B217AC5bc1115E3D8b8018F9',
  //   earnContractAddress: '0x2d3d152b3b94c0E0B217AC5bc1115E3D8b8018F9',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'tokens',
  //   oracleId: 'BUSD',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'Venus',
  //   assets: ['BUSD'],
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?outputCurrency=0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
  //   createdAt: 1608170851,
  // },
  // {
  //   id: 'venus-ltc-eol',
  //   logo: 'single-assets/LTC.svg',
  //   name: 'LTC',
  //   token: 'LTC',
  //   tokenDescription: 'Venus',
  //   tokenAddress: '0x4338665CBB7B2485A8855A139b75D5e34AB0DB94',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooVenusLTC',
  //   earnedTokenAddress: '0xd61420f20e945d0B617fEdA5971f692A3DCE711c',
  //   earnContractAddress: '0xd61420f20e945d0B617fEdA5971f692A3DCE711c',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'tokens',
  //   oracleId: 'LTC',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'Venus',
  //   assets: ['LTC'],
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?outputCurrency=0x4338665CBB7B2485A8855A139b75D5e34AB0DB94',
  //   createdAt: 1608174331,
  // },
  // {
  //   id: 'venus-xrp-eol',
  //   logo: 'single-assets/XRP.svg',
  //   name: 'XRP',
  //   token: 'XRP',
  //   tokenDescription: 'Venus',
  //   tokenAddress: '0x1D2F0da169ceB9fC7B3144628dB156f3F6c60dBE',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooVenusXRP',
  //   earnedTokenAddress: '0x3eb560209B438d4e23833aFf6751fcf0B810F60b',
  //   earnContractAddress: '0x3eb560209B438d4e23833aFf6751fcf0B810F60b',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'tokens',
  //   oracleId: 'XRP',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'Venus',
  //   assets: ['XRP'],
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?outputCurrency=0x1D2F0da169ceB9fC7B3144628dB156f3F6c60dBE',
  //   createdAt: 1608173173,
  // },
  // {
  //   id: 'cake-twt-bnb',
  //   logo: 'bnb-pairs/TWT-BNB.svg',
  //   name: 'TWT-BNB LP',
  //   token: 'TWT-BNB LP',
  //   tokenDescription: 'PancakeSwap',
  //   tokenAddress: '0x610e7a287c27dfFcaC0F0a94f547Cc1B770cF483',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooPancakeTWT-BNB',
  //   earnedTokenAddress: '0x3b2c93f9455786e74C68648EaaB09B20ce557537',
  //   earnContractAddress: '0x3b2c93f9455786e74C68648EaaB09B20ce557537',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'cake-twt-bnb',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'PancakeSwap',
  //   assets: ['TWT', 'BNB'],
  //   removeLiquidityUrl:
  //     'https://v1exchange.pancakeswap.finance/#/remove/BNB/0x4b0f1812e5df2a09796481ff14017e6005508003',
  //   createdAt: 1607382402,
  // },
  // {
  //   id: 'cake-xvs-bnb',
  //   logo: 'bnb-pairs/XVS-BNB.svg',
  //   name: 'XVS-BNB LP',
  //   token: 'XVS-BNB LP',
  //   tokenDescription: 'PancakeSwap',
  //   tokenAddress: '0x41182c32F854dd97bA0e0B1816022e0aCB2fc0bb',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooPancakeXVS-BNB',
  //   earnedTokenAddress: '0xbB63c8858b64a856d79A80575Df60867622E4782',
  //   earnContractAddress: '0xbB63c8858b64a856d79A80575Df60867622E4782',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'cake-xvs-bnb',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'PancakeSwap',
  //   assets: ['XVS', 'BNB'],
  //   removeLiquidityUrl:
  //     'https://v1exchange.pancakeswap.finance/#/remove/BNB/0xcf6bb5389c92bdda8a3747ddb454cb7a64626c63',
  //   createdAt: 1607382459,
  // },
  // {
  //   id: 'narwhal-gold-bnb-eol',
  //   logo: 'bnb-pairs/GOLD-BNB.png',
  //   name: 'GOLD-BNB NLP',
  //   token: 'GOLD-BNB NLP',
  //   tokenDescription: 'Narwhal',
  //   tokenAddress: '0x903903A2aa2A222360D8f3c4A7205b933Da60e95',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooNarwhalGOLD-BNB',
  //   earnedTokenAddress: '0x179701FD814F17838605c1990EDA96C1EbeC984e',
  //   earnContractAddress: '0x179701FD814F17838605c1990EDA96C1EbeC984e',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'narwhal-gold-bnb',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   retireReason: 'rewards',
  //   status: 'eol',
  //   platform: 'Other',
  //   assets: ['GOLD', 'BNB'],
  //   addLiquidityUrl:
  //     'https://narwhalswap.org/#/page/add/0x8f4087cb09e0f378f4278a314c94a636665de24b/BNB',
  //   buyTokenUrl:
  //     'https://narwhalswap.org/#/page/swap?outputCurrency=0x8f4087cb09e0f378f4278a314c94a636665de24b&outputCurrency=BNB',
  //   createdAt: 1607445097,
  // },
  // {
  //   id: 'bakery-bake-eol',
  //   logo: 'single-assets/BAKE.png',
  //   name: 'BAKE',
  //   token: 'BAKE',
  //   tokenDescription: 'Bakery',
  //   tokenAddress: '0xE02dF9e3e622DeBdD69fb838bB799E3F168902c5',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooBakeryBAKE',
  //   earnedTokenAddress: '0xaC112E7744C129ae54E65F5D2cb4eA472E08eA0B',
  //   earnContractAddress: '0xaC112E7744C129ae54E65F5D2cb4eA472E08eA0B',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'tokens',
  //   oracleId: 'BAKE',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   retireReason: 'rewards',
  //   platform: 'Other',
  //   assets: ['BAKE'],
  //   buyTokenUrl:
  //     'https://bakeryswap.org/#/swap?outputCurrency=0xE02dF9e3e622DeBdD69fb838bB799E3F168902c5',
  //   createdAt: 1607060332,
  // },
  // {
  //   id: 'bakery-bake-bnb-eol',
  //   logo: 'bnb-pairs/BAKE-BNB.png',
  //   name: 'BAKE-BNB BLP',
  //   token: 'BAKE-BNB BLP',
  //   tokenDescription: 'Bakery',
  //   tokenAddress: '0xc2Eed0F5a0dc28cfa895084bC0a9B8B8279aE492',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooBakeryBAKE-BNB',
  //   earnedTokenAddress: '0x3e1B60EB79b17d41F8E268936d853A43BEA24259',
  //   earnContractAddress: '0x3e1B60EB79b17d41F8E268936d853A43BEA24259',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'bakery-bake-bnb',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   retireReason: 'rewards',
  //   platform: 'Other',
  //   assets: ['BAKE', 'BNB'],
  //   addLiquidityUrl:
  //     'https://www.bakeryswap.org/#/add/ETH/0xE02dF9e3e622DeBdD69fb838bB799E3F168902c5',
  //   buyTokenUrl:
  //     'https://bakeryswap.org/#/swap?outputCurrency=0x072e3d0d4f14dd82a16b0a33678c6104e9009ed9',
  //   createdAt: 1607060527,
  // },
  // {
  //   id: 'street-bifi-bnb',
  //   logo: 'bnb-pairs/BIFI-BNB.png',
  //   name: 'BIFI-BNB TLP',
  //   token: 'BIFI-BNB TLP',
  //   tokenDescription: 'Traphouse',
  //   tokenAddress: '0x223B53b64AA5F87D2De194a15701Fc0bC7474A31',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooStreetBIFI-BNB',
  //   earnedTokenAddress: '0x29C36e5fcB1dC4a3D4B46C00F82b2cee39c5540e',
  //   earnContractAddress: '0x29C36e5fcB1dC4a3D4B46C00F82b2cee39c5540e',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'street-bifi-bnb',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'Other',
  //   assets: ['BIFI', 'BNB'],
  //   removeLiquidityUrl:
  //     'https://v1exchange.pancakeswap.finance/#/remove/0xCa3F508B8e4Dd382eE878A314789373D80A5190A/BNB',
  //   createdAt: 1606423596,
  // },
  // {
  //   id: 'street-drugs-bnb',
  //   logo: 'bnb-pairs/DRUGS-BNB.png',
  //   name: 'DRUGS-BNB LP v2',
  //   token: 'DRUGS-BNB TLP',
  //   tokenDescription: 'Traphouse',
  //   tokenAddress: '0x421DF185Ff87bc5f19bd5a90102A51452b70c4A4',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooStreetDRUGS-BNBV2',
  //   earnedTokenAddress: '0x9D729179E429153a6aca26389e6a9b625431C79B',
  //   earnContractAddress: '0x9D729179E429153a6aca26389e6a9b625431C79B',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'street-drugs-bnb',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'Other',
  //   assets: ['DRUGS', 'BNB'],
  //   createdAt: 1606169751,
  // },
  // {
  //   id: 'street-thugs-bnb',
  //   logo: 'bnb-pairs/THUGS-BNB.png',
  //   name: 'THUGS-BNB LP v2',
  //   token: 'THUGS-BNB TLP',
  //   tokenDescription: 'Traphouse',
  //   tokenAddress: '0x9354caB0F1083135439B23aA6c364329e578F39a',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooStreetTHUGS-BNBV2',
  //   earnedTokenAddress: '0xe789711992020575e07381fdb3dFDc251Ec4d027',
  //   earnContractAddress: '0xe789711992020575e07381fdb3dFDc251Ec4d027',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'street-thugs-bnb',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'Other',
  //   assets: ['THUGS', 'BNB'],
  //   createdAt: 1606173714,
  // },
  // {
  //   id: 'street-cred-bnb',
  //   logo: 'bnb-pairs/CRED-BNB.png',
  //   name: 'CRED-BNB LP',
  //   token: 'CRED-BNB TLP',
  //   tokenDescription: 'Traphouse',
  //   tokenAddress: '0x9CBE4F200b474a1C33bfFe7c6e531e1D47ECab46',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooStreetCRED-BNB',
  //   earnedTokenAddress: '0x0adc208151e6b7AE1D884b64fDA662d10B4F6174',
  //   earnContractAddress: '0x0adc208151e6b7AE1D884b64fDA662d10B4F6174',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'street-cred-bnb',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'Other',
  //   assets: ['CRED', 'BNB'],
  //   createdAt: 1606173765,
  // },
  // {
  //   id: 'street-busd-bnb',
  //   logo: 'bnb-pairs/BUSD-BNB.svg',
  //   name: 'BUSD-BNB LP v2',
  //   token: 'BUSD-BNB TLP',
  //   tokenDescription: 'Traphouse',
  //   tokenAddress: '0xf2e4E3F9B58b3eDaC88Ad11D689a23f3119a782D',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooStreetBUSD-BNBV2',
  //   earnedTokenAddress: '0x2091b25f02275a4D791075f83c904Fa5Fb96B5A7',
  //   earnContractAddress: '0x2091b25f02275a4D791075f83c904Fa5Fb96B5A7',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'street-busd-bnb',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'Other',
  //   assets: ['BUSD', 'BNB'],
  //   createdAt: 1606173867,
  // },
  // {
  //   id: 'street-panacakelp-thugs-bnb',
  //   logo: 'bnb-pairs/THUGS-BNB.png',
  //   name: 'THUGS-BNB Pancake LP v2',
  //   token: 'THUGS-BNB Pancake LP',
  //   tokenDescription: 'Traphouse',
  //   tokenAddress: '0x40257481f09dB2211a3274B34554Bbb00B0a7Fb1',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooStreetPancakeLP-THUGS-BNBV2',
  //   earnedTokenAddress: '0x7A99078ba8960871233377C7aE6B5d2A61229A43',
  //   earnContractAddress: '0x7A99078ba8960871233377C7aE6B5d2A61229A43',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'street-panacakelp-thugs-bnb',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'Other',
  //   assets: ['THUGS', 'BNB'],
  //   createdAt: 1606192225,
  // },
  // {
  //   id: 'street-dai-bnb',
  //   logo: 'bnb-pairs/DAI-BNB.svg',
  //   name: 'DAI-BNB LP',
  //   token: 'DAI-BNB LP',
  //   tokenDescription: 'Traphouse',
  //   tokenAddress: '0xed8Ecb790Be568461d85Cf82C386E51124E46a52',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooStreetDAI-BNB',
  //   earnedTokenAddress: '0x3CAd2fF669865359b80a2d948A10e1810f7486D7',
  //   earnContractAddress: '0x3CAd2fF669865359b80a2d948A10e1810f7486D7',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'street-dai-bnb',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'Other',
  //   assets: ['DAI', 'BNB'],
  //   createdAt: 1606174227,
  // },
  // {
  //   id: 'street-bliq-bnb',
  //   logo: 'bnb-pairs/BLIQ-BNB.png',
  //   name: 'BLIQ-BNB LP',
  //   token: 'BLIQ-BNB LP',
  //   tokenDescription: 'Traphouse',
  //   tokenAddress: '0xe835ed82C7d9d018f8F5173EECfBb56f29f38570',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooStreetBLIQ-BNB',
  //   earnedTokenAddress: '0x84d95337c83B84688DFE678117e1539786f8c4Cb',
  //   earnContractAddress: '0x84d95337c83B84688DFE678117e1539786f8c4Cb',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'street-bliq-bnb',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'Other',
  //   assets: ['BLIQ', 'BNB'],
  //   createdAt: 1606174413,
  // },
  // {
  //   id: 'street-drugs-bnb-v1',
  //   logo: 'bnb-pairs/DRUGS-BNB.png',
  //   name: 'DRUGS-BNB LP v1',
  //   token: 'DRUGS-BNB LP v1',
  //   tokenDescription: 'Please migrate to v2',
  //   tokenAddress: '0x5BcE5d58682f15541EFc72d8f56C7d6221d541f1',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl:
  //     'https://docs.beefy.finance/beefyfinance/products/thugs#farming-drugs-with-drugs-bnb-lp',
  //   earnedToken: 'mooStreetDRUGS-BNB',
  //   earnedTokenAddress: '0xa4aB709f5eB75f3213734fC227D9e27352d1DB6c',
  //   earnContractAddress: '0xa4aB709f5eB75f3213734fC227D9e27352d1DB6c',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'street-drugs-bnb',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'Other',
  //   assets: ['DRUGS', 'BNB'],
  //   createdAt: 1605660664,
  // },
  // {
  //   id: 'cake-cake-eol',
  //   logo: 'single-assets/CAKE.svg',
  //   name: 'CAKE',
  //   token: 'CAKE',
  //   tokenDescription: 'Please use Smart Cake',
  //   tokenAddress: '0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl:
  //     'https://docs.beefy.finance/beefyfinance/products/pancake-swap#farming-cake-with-cake',
  //   earnedToken: 'mooCake',
  //   earnedTokenAddress: '0xc713ca7cb8edfE238ea652D772d41dde47A9a62d',
  //   earnContractAddress: '0xc713ca7cb8edfE238ea652D772d41dde47A9a62d',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'tokens',
  //   oracleId: 'Cake',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'PancakeSwap',
  //   assets: ['CAKE'],
  //   createdAt: 1604704611,
  // },
  // {
  //   id: 'cake-twt',
  //   logo: 'single-assets/cakeTWT.png',
  //   name: 'CAKE TWT v2',
  //   token: 'CAKE',
  //   tokenDescription: 'PancakeSwap',
  //   tokenAddress: '0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl:
  //     'https://docs.beefy.finance/beefyfinance/products/pancake-swap#farming-twt-with-cake',
  //   earnedToken: 'mooCakeTWTV2',
  //   earnedTokenAddress: '0x260879777DB293541DbC9a27c3C2fAD9c9bb48D3',
  //   earnContractAddress: '0x260879777DB293541DbC9a27c3C2fAD9c9bb48D3',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'tokens',
  //   oracleId: 'Cake',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'PancakeSwap',
  //   assets: ['CAKE'],
  //   createdAt: 1605108145,
  // },
  // {
  //   id: 'cake-hard',
  //   logo: 'single-assets/cakeHARD.png',
  //   name: 'CAKE HARD',
  //   token: 'CAKE',
  //   tokenDescription: 'PancakeSwap',
  //   tokenAddress: '0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl:
  //     'https://docs.beefy.finance/beefyfinance/products/pancake-swap#farming-hard-with-cake',
  //   earnedToken: 'mooCakeHARD',
  //   earnedTokenAddress: '0x4B4e5F2CeEdfF00D2DD13874714C1Ef6Ea1Cd8a7',
  //   earnContractAddress: '0x4B4e5F2CeEdfF00D2DD13874714C1Ef6Ea1Cd8a7',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'tokens',
  //   oracleId: 'Cake',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'PancakeSwap',
  //   assets: ['CAKE'],
  //   createdAt: 1605043021,
  // },
  // {
  //   id: 'cake-cake-bnb-eol',
  //   logo: 'bnb-pairs/CAKE-BNB.svg',
  //   name: 'CAKE-BNB LP',
  //   token: 'CAKE-BNB LP',
  //   tokenDescription: 'PancakeSwap',
  //   tokenAddress: '0xA527a61703D82139F8a06Bc30097cC9CAA2df5A6',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl:
  //     'https://docs.beefy.finance/beefyfinance/products/pancake-swap#farming-cake-with-cake-bnb-lp',
  //   earnedToken: 'mooPancakeCAKE-BNB',
  //   earnedTokenAddress: '0x502AB55Cf22f38c4fd8663dEE041A96B72264c53',
  //   earnContractAddress: '0x502AB55Cf22f38c4fd8663dEE041A96B72264c53',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'cake-cake-bnb',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'PancakeSwap',
  //   assets: ['CAKE', 'BNB'],
  //   removeLiquidityUrl:
  //     'https://v1exchange.pancakeswap.finance/#/remove/BNB/0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82',
  //   createdAt: 1604768244,
  // },
  // {
  //   id: 'cake-busd-bnb',
  //   logo: 'bnb-pairs/BUSD-BNB.svg',
  //   name: 'BUSD-BNB LP',
  //   token: 'BUSD-BNB LP',
  //   tokenDescription: 'PancakeSwap',
  //   tokenAddress: '0x1B96B92314C44b159149f7E0303511fB2Fc4774f',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl:
  //     'https://docs.beefy.finance/beefyfinance/products/pancake-swap#farming-cake-with-busd-bnb-lp',
  //   earnedToken: 'mooPancakeBNB-BUSD',
  //   earnedTokenAddress: '0x0c36Bee5cF5E5b1BE5371574cEBF9A5f01DE5Fc4',
  //   earnContractAddress: '0x0c36Bee5cF5E5b1BE5371574cEBF9A5f01DE5Fc4',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'cake-busd-bnb',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'PancakeSwap',
  //   assets: ['BUSD', 'BNB'],
  //   removeLiquidityUrl:
  //     'https://v1exchange.pancakeswap.finance/#/remove/BNB/0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
  //   createdAt: 1604768346,
  // },
  // {
  //   id: 'cake-usdt-busd',
  //   logo: 'busd-pairs/USDT-BUSD.svg',
  //   name: 'USDT-BUSD LP',
  //   token: 'USDT-BUSD LP',
  //   tokenDescription: 'PancakeSwap',
  //   tokenAddress: '0xc15fa3E22c912A276550F3E5FE3b0Deb87B55aCd',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl:
  //     'https://docs.beefy.finance/beefyfinance/products/pancake-swap#farming-cake-with-usdt-busd-lp',
  //   earnedToken: 'mooPancakeUSDT-BUSD',
  //   earnedTokenAddress: '0x348039C69B5fD7b333CE0a17E8361E501D2e3fa8',
  //   earnContractAddress: '0x348039C69B5fD7b333CE0a17E8361E501D2e3fa8',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'cake-usdt-busd',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'PancakeSwap',
  //   assets: ['USDT', 'BUSD'],
  //   removeLiquidityUrl:
  //     'https://v1exchange.pancakeswap.finance/#/remove/0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56/0x55d398326f99059ff775485246999027b3197955',
  //   createdAt: 1604768436,
  // },
  // {
  //   id: 'fortube-fil',
  //   logo: 'single-assets/FIL.svg',
  //   name: 'FIL',
  //   token: 'FIL',
  //   tokenDescription: 'ForTube',
  //   tokenAddress: '0x0D8Ce2A99Bb6e3B7Db580eD848240e4a0F9aE153',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: 'https://filecoin.io/',
  //   earnedToken: 'mooForFIL',
  //   earnedTokenAddress: '0x5Bea18aBa4825bbE6450c280fBE8c22B1489cDd4',
  //   earnContractAddress: '0x5Bea18aBa4825bbE6450c280fBE8c22B1489cDd4',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'tokens',
  //   oracleId: 'FIL',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'Other',
  //   assets: ['FIL'],
  //   createdAt: 1604003351,
  // },
  // {
  //   id: 'fortube-busd',
  //   logo: 'single-assets/BUSD.svg',
  //   name: 'BUSD',
  //   token: 'BUSD',
  //   tokenDescription: 'ForTube',
  //   tokenAddress: '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: 'https://www.paxos.com/busd/',
  //   earnedToken: 'mooBUSD',
  //   earnedTokenAddress: '0xD6fca61Edb1cD0259320A6E0899E8aD1044BBCda',
  //   earnContractAddress: '0xD6fca61Edb1cD0259320A6E0899E8aD1044BBCda',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'tokens',
  //   oracleId: 'BUSD',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'Other',
  //   assets: ['BUSD'],
  //   createdAt: 1602194737,
  // },
  // {
  //   id: 'fortube-dot',
  //   logo: 'single-assets/DOT.png',
  //   name: 'DOT',
  //   token: 'DOT',
  //   tokenDescription: 'ForTube',
  //   tokenAddress: '0x7083609fCE4d1d8Dc0C979AAb8c869Ea2C873402',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: 'https://polkadot.network/',
  //   earnedToken: 'mooDOT',
  //   earnedTokenAddress: '0x63a2CEF28904956552714ddb226ceE96c3F0C9EF',
  //   earnContractAddress: '0x63a2CEF28904956552714ddb226ceE96c3F0C9EF',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'tokens',
  //   oracleId: 'DOT',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'Other',
  //   assets: ['DOT'],
  //   createdAt: 1602195523,
  // },
  // {
  //   id: 'fortube-usdt',
  //   logo: 'single-assets/USDT.svg',
  //   name: 'USDT',
  //   token: 'USDT',
  //   tokenDescription: 'ForTube',
  //   tokenAddress: '0x55d398326f99059fF775485246999027B3197955',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: 'https://tether.to',
  //   earnedToken: 'mooUSDT',
  //   earnedTokenAddress: '0xc16187F3eb15aEE949e7d784906d53fd7E364B26',
  //   earnContractAddress: '0xc16187F3eb15aEE949e7d784906d53fd7E364B26',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'tokens',
  //   oracleId: 'USDT',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'Other',
  //   assets: ['USDT'],
  //   createdAt: 1602195418,
  // },
  // {
  //   id: 'fortube-btcb',
  //   logo: 'single-assets/BTCB.svg',
  //   name: 'BTCB',
  //   token: 'BTCB',
  //   tokenDescription: 'ForTube',
  //   tokenAddress: '0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: 'https://coinmarketcap.com/currencies/bitcoin-bep2/',
  //   earnedToken: 'mooBTCB',
  //   earnedTokenAddress: '0x6F0Df0858542fC4A1c7f82c14fAA490d7763592F',
  //   earnContractAddress: '0x6F0Df0858542fC4A1c7f82c14fAA490d7763592F',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'tokens',
  //   oracleId: 'BTCB',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'Other',
  //   assets: ['BTCB'],
  //   createdAt: 1602195640,
  // },
  // {
  //   id: 'fry-burger-v2',
  //   logo: 'single-assets/BURGER.png',
  //   name: 'BURGER v2',
  //   token: 'BURGER',
  //   tokenDescription: 'Retired Vault',
  //   tokenAddress: '0xAe9269f27437f0fcBC232d39Ec814844a51d6b8f',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl:
  //     'https://docs.beefy.finance/beefyfinance/products/fryworld#farming-fries-with-burgers',
  //   earnedToken: 'mooFryBURGERv2',
  //   earnedTokenAddress: '0x2fd5f579e2cF9e3cb8127BBAd1B52c25750b1aCE',
  //   earnContractAddress: '0x2fd5f579e2cF9e3cb8127BBAd1B52c25750b1aCE',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'tokens',
  //   oracleId: 'BURGER',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'Other',
  //   assets: ['BURGER'],
  //   createdAt: 1603391564,
  // },
  // {
  //   id: 'cake-syrup-twt',
  //   logo: 'single-assets/cakeTWT.png',
  //   name: 'CAKE TWT v1',
  //   token: 'CAKE',
  //   tokenDescription: 'Refunds available',
  //   tokenAddress: '0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl:
  //     'https://docs.beefy.finance/beefyfinance/products/pancake-swap#farming-twt-with-cake',
  //   earnedToken: 'mooCakeTWT',
  //   earnedTokenAddress: '0x214e640c638fbe68Fb24e11BfBd8Cd7d6cb8E37b',
  //   earnContractAddress: '0x214e640c638fbe68Fb24e11BfBd8Cd7d6cb8E37b',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'tokens',
  //   oracleId: 'Cake',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   refund: true,
  //   refundContractAddress: '0x2fd5caAB20191dcd4423F588e254CC298C7F66A1',
  //   platform: 'PancakeSwap',
  //   assets: ['CAKE'],
  //   createdAt: 1603823935,
  // },
  // {
  //   id: 'fry-burger-v1',
  //   logo: 'single-assets/BURGER.png',
  //   name: 'BURGER v1',
  //   token: 'BURGER',
  //   tokenDescription: 'Please migrate to v2',
  //   tokenAddress: '0xAe9269f27437f0fcBC232d39Ec814844a51d6b8f',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: 'https://fry.world/',
  //   earnedToken: 'mooFryBURGER',
  //   earnedTokenAddress: '0x78d60Da18c09bdE41397010198F454021Ec810Ad',
  //   earnContractAddress: '0x78d60Da18c09bdE41397010198F454021Ec810Ad',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'tokens',
  //   oracleId: 'BURGER',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'Other',
  //   assets: ['BURGER'],
  //   createdAt: 1602803649,
  // },
  // {
  //   id: 'beltv2-4belt',
  //   logo: 'uncategorized/BELT-VENUSBLP.png',
  //   name: 'BUSD/USDT/USDC/DAI',
  //   token: '4BELT LP',
  //   tokenDescription: 'Belt',
  //   tokenAddress: '0x9cb73F20164e399958261c289Eb5F9846f4D1404',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'moo4BeltV2',
  //   earnedTokenAddress: '0x1bBBf8910E5A074c7B62D891Dbab9f4af82C14D4',
  //   earnContractAddress: '0x1bBBf8910E5A074c7B62D891Dbab9f4af82C14D4',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'belt-4belt',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   platform: 'Belt',
  //   assets: ['USDT', 'BUSD', 'USDC', 'DAI', '4BELT'],
  //   withdrawalFee: '0%',
  //   addLiquidityUrl: 'https://belt.fi/',
  //   createdAt: 1632312203,
  // },
  // {
  //   id: 'cakev2-idia-busd-eol',
  //   name: 'IDIA-BUSD LP',
  //   token: 'IDIA-BUSD LP2',
  //   tokenDescription: 'PancakeSwap',
  //   tokenAddress: '0x71E6de81381eFE0Aa98f56b3B43eB3727D640715',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooCakeV2IDIA-BUSD',
  //   earnedTokenAddress: '0x9D91E206639FF4A3Aac5C9112D874BC1be2A098b',
  //   earnContractAddress: '0x9D91E206639FF4A3Aac5C9112D874BC1be2A098b',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'cakev2-idia-busd',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   retireReason: 'tvl',
  //   platform: 'PancakeSwap',
  //   assets: ['IDIA', 'BUSD'],
  //   risks: [
  //     'COMPLEXITY_LOW',
  //     'BATTLE_TESTED',
  //     'IL_HIGH',
  //     'MCAP_SMALL',
  //     'AUDIT',
  //     'CONTRACTS_VERIFIED',
  //   ],
  //   stratType: 'StratLP',
  //   addLiquidityUrl:
  //     'https://pancakeswap.finance/add/0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56/0x0b15Ddf19D47E6a86A56148fb4aFFFc6929BcB89',
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?outputCurrency=0x0b15Ddf19D47E6a86A56148fb4aFFFc6929BcB89',
  //   createdAt: 1640215127,
  // },
  // {
  //   id: 'cakev2-xcv-busd-eol',
  //   name: 'XCV-BUSD LP',
  //   token: 'XCV-BUSD LP2',
  //   tokenDescription: 'PancakeSwap',
  //   tokenAddress: '0xD39F05AB936Aa201235005c47B83268f2d9833f8',
  //   tokenDecimals: 18,
  //   tokenDescriptionUrl: '#',
  //   earnedToken: 'mooCakeV2XCV-BUSD',
  //   earnedTokenAddress: '0x4E9aAC9a233b7E15F2730274D9fFAaD264b45190',
  //   earnContractAddress: '0x4E9aAC9a233b7E15F2730274D9fFAaD264b45190',
  //   pricePerFullShare: 1,
  //   tvl: 0,
  //   oracle: 'lps',
  //   oracleId: 'cakev2-xcv-busd',
  //   oraclePrice: 0,
  //   depositsPaused: true,
  //   status: 'eol',
  //   retireReason: 'tvl',
  //   platform: 'PancakeSwap',
  //   assets: ['XCV', 'BUSD'],
  //   risks: [
  //     'COMPLEXITY_LOW',
  //     'BATTLE_TESTED',
  //     'IL_HIGH',
  //     'MCAP_MICRO',
  //     'AUDIT',
  //     'CONTRACTS_VERIFIED',
  //   ],
  //   stratType: 'StratLP',
  //   addLiquidityUrl:
  //     'https://pancakeswap.finance/add/0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56/0x4be63a9b26EE89b9a3a13fd0aA1D0b2427C135f8',
  //   buyTokenUrl:
  //     'https://pancakeswap.finance/swap?outputCurrency=0x4be63a9b26EE89b9a3a13fd0aA1D0b2427C135f8',
  //   createdAt: 1640795451,
  // },
];
